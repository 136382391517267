import { Layout } from 'antd';
import { Buffer } from 'buffer';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useClientIP } from '../../../foundation/cutom_hooks/useClientIP';
import { selectUser } from '../../authentication/redux/selectors';
import AiReport from '../AiReport';
import { fetchSharedReportData } from '../redux/async_thunks';
import { setIsEditedVersion } from '../redux/slice';

/* Take note that this component & its sub-components are utilized by:
 * - Registered accounts with specific roles
 * - Users who have verified access to the report link
 *
 * Refer to the getPayload() function for the user required params
 */
const ReportAccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const clientIp = useClientIP();

  const { Header: LayoutHeader } = Layout;

  const user = useSelector(selectUser);

  const { reportCodeParam }: any = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isPublicViewVerified, setIsPublicViewVerified] = useState(false);

  // Query string parameters coming from the publicly shared suburb report URL
  const { r, t, u } = queryString.parse(history.location.search);
  const isPublicView = !!r && !!t && !!u;

  const getPayload = () => {
    return isPublicView
      ? {
          data: {
            reportCode: r,
            userId: Buffer.from(u as string, 'base64').toString('utf-8'),
          },
          token: Buffer.from(t as string, 'base64').toString('utf-8'),
        }
      : {
          data: {
            reportCode: reportCodeParam,
            userId: user?.user_id,
          },
          token: user?.token,
        };
  };

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const getSharedReport = async () => {
    const storageKey = 'report_code';
    setIsLoading(true);

    try {
      const response = await dispatch(fetchSharedReportData(getPayload()))
        // @ts-ignore
        .unwrap();
      dispatch(setIsEditedVersion(response.editedReport));
      sessionStorage.removeItem(storageKey);

      if (isPublicView) {
        setIsLoading(false);
        setIsPublicViewVerified(true);
      } else {
        // Client view
        history.push(`/suburb-scoring-internal/report/${response.suburbCode}`);
      }
    } catch (error) {
      errorHandler(error);
      sessionStorage.removeItem(storageKey);
      history.push('/');
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isPublicView) {
        if (user && reportCodeParam) {
          getSharedReport();
        } else {
          history.push('/');
        }
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (isPublicView) {
        setIsLoading(true);

        if (clientIp) {
          getSharedReport();
        }
      }
    }, 0);

    return () => {
      clearTimeout(timeout);
    };
  }, [clientIp]);

  return (
    <>
      {!isPublicViewVerified && (
        <div style={{ position: 'relative', height: '100%' }}>
          {isLoading && (
            <FullPageLoader
              classNames="h-fixed"
              message={'Verifying access...'}
            />
          )}
        </div>
      )}
      {isPublicViewVerified && (
        <div className="c-app-layout">
          <LayoutHeader className="c-app-layout__header">
            <div className="c-app-layout__header-left-side">
              <div className="c-app-layout__header-logo">
                <Logo />
              </div>
            </div>
          </LayoutHeader>
          <AiReport />
        </div>
      )}
    </>
  );
};

export default ReportAccess;
