import { Button, Input } from 'antd';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import AuthenticationFooter from '../elements/AuthenticationFooter';
import AuthenticationPageImage from '../elements/AuthenticationPageImage';
import DeleteAccountSuccessModal from '../modals/DeleteAcccountSuccessModal';
import { deleteAccount } from '../redux/async_thunks';
import { deleteAccountValidationSchema } from '../validations/delete_account_validation';

const DeleteAccount = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const { deleteCode } = useParams<{ deleteCode: string }>();

  const handleSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const data = {
        otp: values.otp,
        deleteCode: deleteCode,
      };

      // @ts-ignore
      await dispatch(deleteAccount({ data: data })).unwrap();
      setIsLoading(false);
      setIsSuccessModalVisible(true);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  const handleLoginClick = () => {
    history.push('/login');
  };

  useEffect(() => {
    if (!deleteCode) {
      history.push('/login');
    }
  });

  return (
    <div className="client-dash-login">
      {isLoading && <FullPageLoader />}
      {isSuccessModalVisible && <DeleteAccountSuccessModal />}
      <div className="client-dash-login__form-wrapper">
        <div className="client-dash-login__logo-container">
          <Logo />
        </div>
        <Formik
          initialValues={{
            otp: undefined,
          }}
          enableReinitialize
          onSubmit={handleSubmit}
          validationSchema={deleteAccountValidationSchema}
        >
          {({
            errors,
            handleChange,
            values,
            handleSubmit,
            handleBlur,
            isValid,
            dirty,
          }) => {
            return (
              <form onSubmit={handleSubmit} className="client-dash-login__form">
                <div className="client-dash-login__form-heading">
                  Delete Account
                </div>

                <div className="c-form-field">
                  <div className="c-form-field__label">OTP:</div>
                  <div className="c-form-field__wrapper">
                    <Input
                      name="otp"
                      onChange={handleChange}
                      type="text"
                      value={values.otp}
                      onBlur={handleBlur}
                      placeholder={'Enter one time password'}
                    />
                    <div className="c-form-field__error">
                      {errors.otp ? errors.otp : undefined}
                    </div>
                  </div>
                </div>

                <div className="c-form__btn-wrapper">
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="client-dash-login__form-btn"
                    disabled={!(isValid && dirty) || isLoading}
                  >
                    Proceed Delete
                  </Button>
                </div>

                <div className="client-dash-login__link-ref">
                  Changed your mind?
                  <a
                    className="client-dash-login__link"
                    onClick={handleLoginClick}
                  >
                    Log in
                  </a>
                </div>
              </form>
            );
          }}
        </Formik>
        <AuthenticationFooter />
      </div>

      <AuthenticationPageImage />
    </div>
  );
};

export default DeleteAccount;
