import { Button, Modal } from 'antd';
import React from 'react';
import { useHistory } from 'react-router';

import InviteSentIcon from '../../../foundation/assets/svgs/InviteSentIcon';

const DeleteAccountSuccessModal = () => {
  const history = useHistory();
  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <InviteSentIcon />
        </span>
        <span>Account Deleted</span>
      </div>
    ),
    open: true,
    wrapClassName: '',
    closable: false,
    footer: null,
    centered: true,
    maskClosable: false,
    width: '400px',
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal">
      <div
        className="h-invite-success-modal__text"
        style={{ textAlign: 'center' }}
      >
        Your account has been successfully deleted.
      </div>
      <div
        className="h-invite-success-modal__btn-wrapper"
        style={{ justifyContent: 'center', marginTop: '30px' }}
      >
        <Button
          type="primary"
          className="h-invite-success-modal__btn"
          onClick={() => {
            history.push('/invite');
          }}
          style={{ width: '200px' }}
        >
          Back To Sign Up
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAccountSuccessModal;
