import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useClientIP } from '../../../foundation/cutom_hooks/useClientIP';
import {
  agencyLeadSignup,
  getInviteInfo,
} from '../../authentication/redux/async_thunks';

const AgencyLeadAccess = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const clientIp = useClientIP();

  const { t: inviteIdForVerification } = queryString.parse(
    history.location.search,
  );

  const [inviteIdForFinalizing, setInviteIdForFinalizing] = useState(false);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const verifyInvitation = async () => {
    try {
      if (inviteIdForVerification) {
        const response = await dispatch(
          getInviteInfo({ inviteId: inviteIdForVerification as string }),
        ).unwrap();

        if (response) {
          setInviteIdForFinalizing(response.inviteId);
        } else {
          history.push('/login');
        }
      }
    } catch (error) {
      errorHandler(error);

      if (error.message === '410') {
        history.push('/invite');
      } else {
        history.push('/login');
      }
    }
  };

  const finalizeSignup = async () => {
    try {
      await dispatch(
        agencyLeadSignup({
          data: {
            inviteId: inviteIdForFinalizing,
          },
        }),
        // @ts-ignore
      ).unwrap();

      sessionStorage.setItem('signup_success', 'yes');

      // Redirect to initiate a full page reload in order to embed tracking codes in the index.html file
      // Instead of using a regular router history.push
      window.location.href = '/suburb-scoring';
    } catch (error) {
      errorHandler(error);
      history.push('/login');
    }
  };

  useEffect(() => {
    if (clientIp) {
      if (inviteIdForVerification) {
        verifyInvitation();
      } else {
        history.push('/login');
      }
    }
  }, [clientIp]);

  useEffect(() => {
    if (inviteIdForFinalizing) {
      finalizeSignup();
    }
  }, [inviteIdForFinalizing]);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <FullPageLoader
        classNames="h-fixed"
        message={'Finalizing registration...'}
      />
    </div>
  );
};

export default AgencyLeadAccess;
