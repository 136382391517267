import 'mapbox-gl/dist/mapbox-gl.css';

import { HomeFilled } from '@ant-design/icons';
import difference from '@turf/difference';
import { featureCollection, multiPolygon } from '@turf/helpers';
import { notification } from 'antd';
import { forEach } from 'lodash';
import mapboxgl from 'mapbox-gl/dist/mapbox-gl';
import React, { useEffect, useRef, useState } from 'react';
import ReactMapGL, { Layer, Source } from 'react-map-gl';
import { useSelector } from 'react-redux';
import { useElementSize } from 'usehooks-ts';
import WebMercatorViewport from 'viewport-mercator-project';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import envConstants from '../../../internals/env/env_constants.json';
import { selectSuburb } from '../redux/selectors';

const MAPBOX_ACCESS_TOKEN = envConstants.MAPBOX_ACCESS_TOKEN;
const SUBURB_GEOJSON_DATA_API_BASE_URL =
  envConstants.SUBURB_GEOJSON_DATA_API_BASE_URL;

const SUBURB_GEOJSON_DATA_API_PARTIALS_BASE_URL =
  envConstants.SUBURB_GEOJSON_DATA_API_PARTIALS_BASE_URL;

const COUNTRY_GEOJSON_DATA_FEATURE_FILE =
  envConstants.COUNTRY_GEOJSON_DATA_FEATURE_FILE;

const SuburbMap = () => {
  const dataDefault: any = {
    type: 'FeatureCollection',
    features: [],
  };

  const layers = {
    countryMaskedLayer: {
      id: 'country-masked-layer',
      type: 'fill',
      paint: {
        'fill-color': 'rgba(83, 143, 157, .7)',
      },
    },
    highlightLayer: {
      id: 'suburb-highlighted',
      type: 'fill',
      paint: {
        'fill-outline-color': '#484896',
        'line-color': '#fff',
        'line-opacity': 1,
        'line-width': 4,
      },
    },
    selectedLayer: {
      id: 'suburb-selected',
      type: 'fill',
      paint: {
        'line-color': 'rgba(255, 255, 255, 0.3)',
        'line-opacity': 1,
        'line-width': 2,
      },
    },
  };

  const mapRef = useRef<any>();
  const [mapHtmlRef, { width, height }] = useElementSize();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [allData, setAllData] = useState<any>(dataDefault);
  const [suburbData, setSuburbData] = useState<any>(dataDefault);

  const [coordinates, setCoordinates] = useState<any[]>();
  const [isViewReset, setIsViewReset] = useState<boolean>(false);

  const suburb = useSelector(selectSuburb);

  const DEFAULT_VIEWPORT = {
    initialViewState: {
      latitude: -28.274398,
      longitude: 133.775136,
      zoom: 3.8,
    },
  };

  const DEFAULT_MAP_SETTINGS = {
    attributionControl: false,
    mapStyle: 'mapbox://styles/mapbox/satellite-v9',
    mapboxAccessToken: MAPBOX_ACCESS_TOKEN,
  };

  const [viewport, setViewport] = useState<any>(DEFAULT_VIEWPORT);

  const errorHandler = () => {
    notification.error({
      message: 'Error',
      description: 'Something went wrong while fetching map data.',
    });
  };

  const focusSuburb = () => {
    if (coordinates) {
      let flatCoordinates: any = [];

      // Flatten when there are more than 1 coordinates
      if (coordinates.length > 1) {
        forEach(coordinates, (c: any) => {
          flatCoordinates = flatCoordinates.concat(c[0]);
        });

        // Follow original coordinate set structure
        flatCoordinates = [flatCoordinates];
      } else {
        flatCoordinates = coordinates;
      }

      const bounds = flatCoordinates[0].reduce(
        (bound: any, coord: any) => {
          return bound.extend(coord);
        },
        new mapboxgl.LngLatBounds(flatCoordinates[0][0], flatCoordinates[0][0]),
      );

      const webViewport = new WebMercatorViewport({
        width: width,
        height: height,
      }).fitBounds(
        [
          [bounds._ne.lng, bounds._ne.lat],
          [bounds._sw.lng, bounds._sw.lat],
        ],
        {
          padding: height / 8,
        },
      );

      const { longitude, latitude, zoom } = webViewport;

      setViewport({
        longitude,
        latitude,
        zoom,
      });
    }
  };

  const fetchCountryGeoJson = async () => {
    setIsLoading(true);

    const timestamp = new Date().getTime();
    const query = '?_=' + timestamp;

    await fetch(
      `${SUBURB_GEOJSON_DATA_API_PARTIALS_BASE_URL}${COUNTRY_GEOJSON_DATA_FEATURE_FILE}${query}`,
    )
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        // Subtract the selected suburb feature from the country feature
        const jsonDiff = difference(
          featureCollection([
            multiPolygon(json.geometries[0].coordinates),
            multiPolygon(suburbData.features[0].geometry.coordinates),
          ]),
        );

        setAllData({
          type: 'FeatureCollection',
          features: [jsonDiff],
        });
      })
      .catch((error) => {
        console.log(error);
        errorHandler();
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  const fetchSuburbGeoJson = async () => {
    setIsLoading(true);

    const timestamp = new Date().getTime();
    const query = '?_=' + timestamp;

    await fetch(
      // @ts-ignore
      `${SUBURB_GEOJSON_DATA_API_BASE_URL}${suburb.sscCode.toString()}.json${query}`,
    )
      .then((resp) => {
        return resp.json();
      })
      .then((json) => {
        setSuburbData(json);
      })
      .catch(() => {
        errorHandler();
        setIsLoading(false);
      });

    setIsLoading(false);
  };

  const viewportChangeHandler: any = (nextViewport: any) => {
    setViewport(nextViewport);
  };

  useEffect(() => {
    if (suburb.sscCode) {
      fetchSuburbGeoJson();
    }
  }, [suburb.sscCode]);

  useEffect(() => {
    if (suburbData.features.length) {
      fetchCountryGeoJson();
    }
  }, [suburbData]);

  useEffect(() => {
    if (
      suburb.sscCode &&
      allData.features.length &&
      suburbData.features.length
    ) {
      setCoordinates(suburbData.features[0].geometry.coordinates);
    }
  }, [suburbData, allData]);

  useEffect(() => {
    if (coordinates) {
      focusSuburb();
    }
  }, [coordinates]);

  useEffect(() => {
    if (isViewReset) {
      focusSuburb();
      setIsViewReset(false);
    }
  }, [isViewReset]);

  if (!suburb.sscCode) {
    return null;
  }

  return (
    <div className="map-section">
      <div className="l-suburb-map" ref={mapHtmlRef}>
        <div className="l-suburb-map__map-wrapper">
          {isLoading && <FullPageLoader />}
          <button
            type="button"
            className="map-home"
            onClick={() => {
              setIsViewReset(true);
            }}
          >
            <HomeFilled style={{ opacity: 0.6 }} />
          </button>
          <ReactMapGL
            {...DEFAULT_MAP_SETTINGS}
            {...DEFAULT_VIEWPORT}
            {...viewport}
            ref={mapRef}
            initialViewState={{
              latitude: -28.274398,
              longitude: 133.775136,
              zoom: 3.8,
            }}
            onViewportChange={viewportChangeHandler}
            onMove={(e) => {
              setViewport(e.viewState);
            }}
          >
            {/* @ts-ignore */}
            <Source data={allData} type="geojson">
              <Layer {...layers.countryMaskedLayer} type="fill" />
            </Source>
            {/* @ts-ignore */}
            <Source data={suburbData} type="geojson">
              <Layer {...layers.selectedLayer} type="line" />
            </Source>
          </ReactMapGL>
        </div>
      </div>
    </div>
  );
};

export default SuburbMap;
