import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input, Modal, notification } from 'antd';
import { Buffer } from 'buffer';
import { Formik } from 'formik';
import randomstring from 'randomstring';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../store/hooks';
import {
  selectAuthStrongPassword,
  selectUser,
} from '../../authentication/redux/selectors';
import { changePassword } from '../redux/async_thunks';
import { changePassFormValidationSchema } from '../validation_schemas/change_pass_validations';

type ChangePasswordProps = { closeModal: () => void };

const ChangePassword = ({ closeModal }: ChangePasswordProps) => {
  const dispatch = useAppDispatch();
  const [isChangingPass, setIsChangingPass] = useState(false);

  const user = useSelector(selectUser);

  const isStrongPassword = useSelector(selectAuthStrongPassword);

  const handleModalClose = () => {
    if (isChangingPass) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: 'Change Password',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
  };

  const handleFormSubmit = async (values: any) => {
    if (isChangingPass) {
      return;
    }

    try {
      if (user) {
        setIsChangingPass(true);

        const currentPassCodeSuffix = randomstring.generate(20);
        const currentPassCodePrefix = randomstring.generate(20);
        const newPassCodeSuffix = randomstring.generate(20);
        const newPassCodePrefix = randomstring.generate(20);

        const appendedCurrentPass = `${currentPassCodePrefix}${values.currentPassCode}${currentPassCodeSuffix}`;
        const appendedNewPass = `${newPassCodePrefix}${values.newPassCode}${newPassCodeSuffix}`;

        const encodedCurrentPass =
          Buffer.from(appendedCurrentPass).toString('base64');
        const encodedNewPass = Buffer.from(appendedNewPass).toString('base64');

        const data = {
          currentPassCode: encodedCurrentPass,
          newPassCode: encodedNewPass,
          agencyId: user.agency_id,
          email: user.email,
          userId: user.user_id,
        };

        await dispatch(
          changePassword({
            token: user.token,
            data,
          }),
        ).unwrap();

        setIsChangingPass(false);

        notification.success({
          message: 'Success!',
          description: 'Password changed successfully.',
        });

        handleModalClose();
      }
    } catch (error) {
      setIsChangingPass(false);
      handleModalClose();
    }
  };

  return (
    <Modal {...modalOpts} className="l-change-pass c-form-modal">
      {isChangingPass && <FullPageLoader />}
      <Formik
        initialValues={{
          currentPassCode: undefined,
          newPassCode: undefined,
          confirmNewPassCode: undefined,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={() =>
          changePassFormValidationSchema(isStrongPassword)
        }
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="c-form-field">
                <div className="c-form-field__label">Old Password:</div>
                <div className="c-form-field__wrapper">
                  <Input.Password
                    name="currentPassCode"
                    onChange={handleChange}
                    type="text"
                    value={values.currentPassCode}
                    onBlur={handleBlur}
                    placeholder={'*********'}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  <div className="c-form-field__error">
                    {errors.currentPassCode
                      ? errors.currentPassCode
                      : undefined}
                  </div>
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">New Password:</div>
                <div className="c-form-field__wrapper">
                  <Input.Password
                    name="newPassCode"
                    onChange={handleChange}
                    type="text"
                    value={values.newPassCode}
                    onBlur={handleBlur}
                    placeholder={'*********'}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  <div className="c-form-field__error">
                    {errors.newPassCode ? errors.newPassCode : undefined}
                  </div>
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">Confirm New Password:</div>
                <div className="c-form-field__wrapper">
                  <Input.Password
                    name="confirmNewPassCode"
                    onChange={handleChange}
                    type="text"
                    value={values.confirmNewPassCode}
                    onBlur={handleBlur}
                    placeholder={'*********'}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                  />
                  <div className="c-form-field__error">
                    {errors.confirmNewPassCode
                      ? errors.confirmNewPassCode
                      : undefined}
                  </div>
                </div>
              </div>

              <div className="c-form__btn-wrapper ">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="l-change-pass__form-submit-btn"
                  disabled={!(isValid && dirty) || isChangingPass}
                >
                  Change Password
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ChangePassword;
