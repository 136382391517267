import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

const arrangeUserProfileData = (data: any) => {
  return {
    id: data.id,
    role_id: data.roleId,
    email: data.email,
    first_name: data.firstName,
    last_name: data.lastName,
    name: data.name,
    agency_id: data.agencyId,
    enterprise_id: data.enterpriseId,
    agency_name: data.agencyName,
    is_active: data.isActive,
    picture: data.userPicture,
  };
};

export const updateProfile = createAsyncThunk(
  'profile/update',
  async (apiParams: { token: string; data: any; picture: any }) => {
    const options = {
      headers: {
        ...getBasicAuthAPIOptions(apiParams.token).headers,
        'content-type': 'multipart/form-data',
      },
    };

    const encrypyedData = encryptData(apiParams.data);

    const formData = new FormData();
    formData.append('e', encrypyedData);

    if (apiParams.picture) {
      formData.append('f', apiParams.picture || null);
    }

    const response = await axios.put(
      `${env_constants.PP_API_BASE_URL}/Profile`,
      formData,
      options,
    );

    const data = decryptData(response.data);

    return arrangeUserProfileData(data.usersProfile);
  },
);

export const fetchProfile = createAsyncThunk(
  'profile/fetch-profile',
  async (apiParams: { token: string; agencyId: string; email: string }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Profile`,
      options,
    );

    const data = decryptData(response.data);

    return arrangeUserProfileData(data.usersProfile);
  },
);

export const changePassword = createAsyncThunk(
  'profile/change-password',
  async (apiParams: { token: string; data: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypyedData = encryptData(apiParams.data);

    await axios.put(
      `${env_constants.PP_API_BASE_URL}/Profile/password`,
      encrypyedData,
      options,
    );

    // decryptData(response.data);

    return;
  },
);

export const sendMessage = createAsyncThunk(
  'profile/support',
  async (apiParams: { token: string; data: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypyedData = encryptData(apiParams.data);

    await axios.post(
      `${env_constants.PP_API_BASE_URL}/Profile/support`,
      encrypyedData,
      options,
    );

    return;
  },
);

export const deleteAccountRequest = createAsyncThunk(
  'auth/delete-account-request',
  async (apiParams: { token: string; data: any }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encryptedData = encryptData(apiParams.data);
    const response = await axios.delete(
      `${env_constants.PP_API_BASE_URL}/Profile`,
      {
        ...options,
        data: encryptedData,
      },
    );

    const data = decryptData(response.data);

    return data;
  },
);
