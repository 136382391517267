import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { useRole } from '../../../foundation/cutom_hooks/useRole';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageFormatter,
  percentageParser,
} from '../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { updateLeverageEquity } from '../redux/async_thunks';
import { selectPurchaseScenarioLeverageEquity } from '../redux/selectors';
import LeverageEquityModal from './LeverageEquityModal';
import { leverageEquityValidationSchema } from './validations';

type Props = {
  isLoading: boolean;
  propertyId: string;
};

const LeverageEquity = ({ isLoading, propertyId }: Props) => {
  const dispatch = useAppDispatch();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);

  const dataSource = useSelector(selectPurchaseScenarioLeverageEquity);

  const user = useSelector(selectUser);

  const [isClientView] = useRole();

  const onEditRecord = (record: any) => () => {
    if (isClientView) {
      return;
    }
    if (record.redrawLoanInputs) {
      setModalData({
        ...record,
        amountAvailable: percentageFormatter(record.amountAvailable, false),
        ...record.redrawLoanInputs,
        loanRate: percentageParser(record.redrawLoanInputs.loanRate),
        baselineRefinancingMaxLvr: percentageParser(
          record.baselineRefinancingMaxLvr,
        ),
        actualRefinancingMaxLvr: percentageParser(
          record.actualRefinancingMaxLvr,
        ),
        switchBackRate: percentageParser(
          record.redrawLoanInputs.switchBackRate,
        ),
      });
    } else {
      setModalData({
        ...record,
        baselineRefinancingMaxLvr: percentageParser(
          record.baselineRefinancingMaxLvr,
        ),
        actualRefinancingMaxLvr: percentageParser(
          record.actualRefinancingMaxLvr,
        ),
        amountAvailable: percentageFormatter(record.amountAvailable, false),
      });
    }

    setModalVisibility(true);
  };

  const titlesToBold = ['Total', 'Remaining Equity Available'];

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Equity',
      dataIndex: 'equity',
      key: 'equity',
      ellipsis: true,
      width: 250,
      render: (text: string, record: any) =>
        colValueRenderer(text, record, undefined, titlesToBold, 'equity'),
    },
    {
      title: 'Baseline Refinancing Max LVR',
      dataIndex: 'baselineRefinancingMaxLvr',
      key: 'baselineRefinancingMaxLvr',
      // ellipsis: true,
      align: 'center',
      width: 100,
      render: (text: string, record: any) =>
        colValueRenderer(text, record, '%', titlesToBold, 'equity'),
      //   width: 80,
    },
    {
      title: 'Current LVR',
      dataIndex: 'currentLvr',
      key: 'currentLvr',
      width: 100,
      align: 'center',
      // ellipsis: true,
      render: (text: string, record: any) =>
        colValueRenderer(text, record, '%', titlesToBold, 'equity'),
    },
    {
      title: 'Actual Refinancing Max LVR',
      dataIndex: 'actualRefinancingMaxLvr',
      key: 'actualRefinancingMaxLvr',
      width: 100,
      align: 'center',
      // ellipsis: true,
      render: (text: string, record: any) =>
        colValueRenderer(text, record, '%', titlesToBold, 'equity'),
    },
    {
      title: 'Amount Available',
      dataIndex: 'amountAvailable',
      key: 'amountAvailable',
      ellipsis: true,
      align: 'center',
      width: 140,
      render: (text: string, record: any) =>
        colValueRenderer(text, record, '$', titlesToBold, 'equity'),
    },

    {
      title: 'Redraw',
      dataIndex: 'redraw',
      key: 'redraw',
      ellipsis: true,
      align: 'center',
      width: 100,
      render: (text: string, record: any) =>
        colValueRenderer(
          text,
          record,
          '$',
          titlesToBold,
          'equity',
          record.equity === 'Remaining Shortfall',
        ),
    },
    {
      title: 'Resulting LVR',
      dataIndex: 'resultingLvr',
      key: 'resultingLvr',
      align: 'center',
      // ellipsis: true,
      width: 100,
      render: (text: string, record: any) =>
        colValueRenderer(text, record, '%', titlesToBold, 'equity'),
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'status',
    //   key: 'status',
    //   ellipsis: true,
    //   render: (_text: string, record: any) => {
    //     return (
    //       <StatusComponent
    //         amount={
    //           record.equity !== 'Remaining Shortfall' &&
    //           record.equity !== 'Total Redraw'
    //             ? record.amount
    //             : undefined
    //         }
    //         use={record.redraw}
    //       />
    //     );
    //   },
    // },

    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'type',
      key: 'type',
      width: 80,
      fixed: 'right',
      clientViewHidden: true,
      render: (_text: any, record: any) => {
        if (record.isEditable) {
          return (
            <EditOutlined
              onClick={onEditRecord(record)}
              className="c-edit-record-btn"
            />
          );
        }
      },
    },
  ].filter((c: any) => {
    if (isClientView) {
      return !c.clientViewHidden;
    }
    return true;
  });

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleFormSubmission = (values: any) => {
    if (isLoading || isClientView) {
      return;
    }
    try {
      const data: any = {
        propertyId,
        redrawLoanOption: values.redrawLoanOption,
        rowId: values.rowId,
        isActive: values.isActive,
        redraw: values.redraw,
        actualRefinancingMaxLvr: values.actualRefinancingMaxLvr / 100,
        baselineRefinancingMaxLvr: values.baselineRefinancingMaxLvr / 100,
        amountAvailable: values.amountAvailable,
      };

      if (data.redrawLoanOption === 'Split Loan') {
        data.splitOptionLoanInfo = {
          maximumLoanableAmount: parseFloat(
            (values.amountAvailable * (parseFloat(values.lvr) / 100)).toFixed(
              2,
            ),
          ),
          lvr: values?.lvr / 100,
          loanType: values.loanType,
          product: values.product,
          loanRate: values.loanRate / 100,
          loanTerm: values.loanTerm,
          switchBackTerm: values.switchBackTerm,
          switchBackProduct: values.switchBackProduct,
          switchBackLoanTerm:
            values && values.loanTerm && values.switchBackTerm
              ? values?.loanTerm - values?.switchBackTerm
              : '',
          switchBackRate: values.switchBackRate / 100,
          switchBackDue: values.switchBackDue,
        };
      }

      if (user?.token) {
        data.userId = user.user_id;

        return dispatch(
          updateLeverageEquity({ token: user.token, values: data }),
          // @ts-ignore
        ).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="annual-figures__section">
      <div className="annual-figures__section-title">Using Leverage Equity</div>
      {isModalVisible && (
        <LeverageEquityModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Data`}
          onSubmit={handleFormSubmission}
          validationSchema={leverageEquityValidationSchema}
          isDraggable
        />
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        size={useTableSize()}
        pagination={false}
        scroll={{ x: true }}
        bordered
      />
    </div>
  );
};

export default LeverageEquity;
