import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Divider, Input } from 'antd';
import { Buffer } from 'buffer';
import { Formik } from 'formik';
import randomstring from 'randomstring';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import AuthenticationFooter from '../elements/AuthenticationFooter';
import { loginFormValidationSchema } from '../validations/login_form_validation';
import { signupFormValidationSchema } from '../validations/signup_form_validation';

type ClientAuthenticationFormProps = {
  handleFormSubmit: (values: any) => void;
  isFormLoading: boolean;
  formType: 'login' | 'signup';
  redactedEmail?: string | undefined;
  email?: string | undefined;
  isLeadSignup?: boolean;
  isStrongPassword: boolean;
};

const ClientAuthenticationForm = ({
  handleFormSubmit,
  isFormLoading,
  formType,
  redactedEmail = '***@****.com',
  email,
  isLeadSignup,
  isStrongPassword,
}: ClientAuthenticationFormProps) => {
  const history = useHistory();

  const handleForgotPasswordClick = () => {
    history.push('/reset-password');
  };

  const handleGoToLogin = (e) => {
    e.preventDefault();
    history.push('/login');
  };

  const handleGoToSignUp = (e) => {
    e.preventDefault();
    history.push('/invite');
  };

  const formHeading = useMemo(() => {
    if (formType === 'login') {
      return 'Login';
    } else {
      return `Let’s sign you up`;
    }
  }, [formType]);

  const formSubmitHandler = (values: any) => {
    const passSuffix = randomstring.generate(20);
    const passPrefix = randomstring.generate(20);

    const appendedPass = `${passPrefix}${values.passCode}${passSuffix}`;

    const encodedPass = Buffer.from(appendedPass).toString('base64');

    const finalData: any = {
      email: values.email,
      passCode: encodedPass,
    };

    if (formType === 'signup') {
      finalData.firstName = values.firstName;
      finalData.lastName = values.lastName;

      if (values?.phone) {
        finalData.phone = values.phone;
      }
    }

    handleFormSubmit(finalData);
  };

  return (
    <div className="client-dash-login__form-wrapper">
      <div className="client-dash-login__logo-container">
        <Logo />
      </div>
      <Formik
        initialValues={{
          firstName: undefined,
          lastName: undefined,
          email: email,
          passCode: undefined,
          phone: undefined,
          isLeadSignup,
        }}
        enableReinitialize
        onSubmit={formSubmitHandler}
        validationSchema={
          formType === 'login'
            ? () => loginFormValidationSchema(isStrongPassword)
            : () => signupFormValidationSchema(isLeadSignup, isStrongPassword)
        }
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="client-dash-login__form">
              <div className="client-dash-login__form-heading">
                {formHeading}
              </div>

              {formType === 'signup' && (
                <>
                  <div className="c-form-field">
                    <div className="c-form-field__label">First Name:</div>
                    <div className="c-form-field__wrapper">
                      <Input
                        name="firstName"
                        onChange={handleChange}
                        type="text"
                        value={values.firstName}
                        onBlur={handleBlur}
                        placeholder={'First Name'}
                      />
                      <div className="c-form-field__error">
                        {errors.firstName ? errors.firstName : undefined}
                      </div>
                    </div>
                  </div>
                  <div className="c-form-field">
                    <div className="c-form-field__label">Last Name:</div>
                    <div className="c-form-field__wrapper">
                      <Input
                        name="lastName"
                        onChange={handleChange}
                        type="text"
                        value={values.lastName}
                        onBlur={handleBlur}
                        placeholder={'Last Name'}
                      />
                      <div className="c-form-field__error">
                        {errors.lastName ? errors.lastName : undefined}
                      </div>
                    </div>
                  </div>
                </>
              )}

              <div className="c-form-field">
                <div className="c-form-field__label">Email:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    onBlur={handleBlur}
                    placeholder={'Enter your email'}
                    disabled={!!email}
                  />
                  <div className="c-form-field__error">
                    {errors.email ? errors.email : undefined}
                  </div>
                </div>
              </div>

              {formType === 'signup' && (
                <div className="c-form-field">
                  <div className="c-form-field__label">Phone:</div>
                  <div className="c-form-field__wrapper">
                    <Input
                      name="phone"
                      onChange={handleChange}
                      type="text"
                      value={values.phone}
                      onBlur={handleBlur}
                      placeholder={'Enter your phone'}
                      addonBefore="+61"
                    />
                    <div className="c-form-field__error">
                      {errors.phone ? errors.phone : undefined}
                    </div>
                  </div>
                </div>
              )}
              {!isLeadSignup && (
                <div className="c-form-field">
                  <div className="c-form-field__label">Password:</div>
                  <div className="c-form-field__wrapper">
                    <Input.Password
                      name="passCode"
                      onChange={handleChange}
                      type="text"
                      value={values.passCode}
                      onBlur={handleBlur}
                      placeholder={'*********'}
                      iconRender={(visible) =>
                        visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                      }
                    />
                    <div className="c-form-field__error">
                      {errors.passCode ? errors.passCode : undefined}
                    </div>
                  </div>
                </div>
              )}
              {formType === 'login' && (
                <div className="client-dash-login__form-actions">
                  <div></div>
                  <div
                    className="client-dash-login__form-btn-forgot-pass"
                    onClick={handleForgotPasswordClick}
                  >
                    Forgot password
                  </div>
                </div>
              )}
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="client-dash-login__form-btn"
                  disabled={!(isValid && dirty) || isFormLoading}
                >
                  {formType === 'login' ? 'Login' : 'Sign up'}
                </Button>
              </div>
              {formType === 'signup' && (
                <>
                  <div
                    style={{
                      textAlign: 'center',
                      maxWidth: '600px',
                      margin: '12px auto 0',
                      fontSize: '14px',
                    }}
                  >
                    By clicking <strong>Sign Up</strong>, you agree to our{' '}
                    <a
                      href="/terms-and-conditions"
                      target="_blank"
                      style={{ textAlign: 'right' }}
                    >
                      Terms &amp; Conditions
                    </a>{' '}
                    and{' '}
                    <a
                      href="/privacy-policy"
                      target="_blank"
                      style={{ textAlign: 'left' }}
                    >
                      Privacy Policy
                    </a>
                    .
                  </div>

                  <Divider />
                </>
              )}
              {formType === 'login' && (
                <div className="client-dash-login__link-ref">
                  {`Don't have an account? `}
                  <a
                    className="client-dash-login__link"
                    onClick={handleGoToSignUp}
                  >
                    Sign Up
                  </a>
                </div>
              )}
              {formType === 'signup' && (
                <div className="client-dash-login__link-ref">
                  Already have an account?{' '}
                  <a
                    className="client-dash-login__link"
                    onClick={handleGoToLogin}
                  >
                    Log in
                  </a>
                </div>
              )}
            </form>
          );
        }}
      </Formik>
      <AuthenticationFooter />
    </div>
  );
};

export default ClientAuthenticationForm;
