import {
  AppstoreAddOutlined,
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  FolderOpenOutlined,
  GroupOutlined,
  HarmonyOSOutlined,
  UserOutlined,
  UserSwitchOutlined,
} from '@ant-design/icons';
import { Avatar, Dropdown, Layout, MenuProps, Modal } from 'antd';
import React, { ReactNode, useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import { RouteType } from '../../../foundation/config/routes';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import useUTM from '../../../foundation/cutom_hooks/useUTM';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { removeItemToStorage } from '../../../foundation/utils/storageHandler';
import { useAppDispatch } from '../../../store/hooks';
import {
  selectRoleOptions,
  selectUser,
} from '../../authentication/redux/selectors';
import ClientModal from '../../client/modals/client_modal/ClientModal';
import { selectClient } from '../../client/redux/selectors';
import { setClient } from '../../client/redux/slice';
import PlanDuplicate from '../../plan/plan_duplicate/PlanDuplicate';
import PlanNameModal from '../../plan/plan_name_modal/PlanNameModal';
import { deletePlan } from '../../plan/redux/async_thunks';
import { selectPlan } from '../../plan/redux/selectors';
import { setPlan } from '../../plan/redux/slice';
import { selectNavIndex } from '../../profile/redux/selectors';
import { setNavIndex } from '../../profile/redux/slice';
import { resetSearch } from '../../suburb_scoring/redux/slice';
import ProfileDropdown from './ProfileDropdown';

const { Header: LayoutHeader } = Layout;

const { confirm } = Modal;

const Header = ({
  children,
  routeConfig,
}: {
  children: ReactNode;
  routeConfig: RouteType | undefined;
}) => {
  const history = useHistory();

  const ROUTE = '/free-pgp';
  const utmRoute = useUTM(ROUTE);

  const dispatch = useAppDispatch();

  const { isDesktopViewport } = useViewport();

  const [loading, setLoading] = useState(false);

  const [isEditPlanModalVisible, setIsEditPlanModalVisible] = useState(false);

  const [isClientModalVisible, setIsClientModalVisible] = useState(false);

  const [isDuplicatePlanModalVisible, setIsDuplicatePlanModalVisible] =
    useState(false);

  const [, , , isAgencyAdminView, isAgencyUserView, isAgencyLeadView] =
    useRole();

  const user = useSelector(selectUser);

  const [isClientView] = useRole();

  const plan = useSelector(selectPlan);

  const client = useSelector(selectClient);

  const roleIds = useSelector(selectRoleOptions);

  const navIndex = useSelector(selectNavIndex);

  const navClassName = 'c-app-layout__header-nav-item';
  const activeNavClassName = ' c-app-layout__header-nav-item--active';

  let homeNavDisplayConfig: any = null;

  if (roleIds) {
    homeNavDisplayConfig = {
      [roleIds?.superAdmin]: {
        displayText: 'Manage Enterprises',
        displayIcon: <GroupOutlined />,
      },
      [roleIds.enterpriseAdmin]: {
        displayText: 'Manage Agencies',
        displayIcon: <AppstoreAddOutlined />,
      },
      [roleIds.agencyAdmin]: {
        displayText: 'Search Clients',
        displayIcon: <HarmonyOSOutlined />,
      },
      [roleIds.agencyUser]: {
        displayText: 'Search Clients',
        displayIcon: <HarmonyOSOutlined />,
      },
      [roleIds.client]: {
        displayText: 'Current Plans & Shared Reports',
        displayIcon: <FolderOpenOutlined />,
      },
    };
  }

  const handleSelectNewClient = useCallback(() => {
    /**
     * Remove values for client and plan.
     */
    removeItemToStorage('client');
    removeItemToStorage('plan');
    dispatch(setClient(undefined));
    dispatch(setPlan(undefined));

    // Show client selection page.
    history.push('/');
  }, []);

  const handleSelectNewPlan = useCallback(() => {
    /**
     * Remove values for  plan.
     */
    removeItemToStorage('plan');
    dispatch(setPlan(undefined));

    // Show plan selection page.
    history.push('/plan');
  }, []);

  const handlePlanDelete = async () => {
    if (loading) {
      return;
    }
    try {
      setLoading(true);

      if (user) {
        const reqData = {
          planId: plan?.planId,
          userId: user?.user_id,
        };

        const respClient = await dispatch(
          deletePlan({ token: user.token, data: reqData }),
          // @ts-ignore
        ).unwrap();

        dispatch(setClient(respClient));

        setLoading(false);
        // Show plan selection page.
        history.push('/plan');
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const showDeleteConfirm = () => {
    confirm({
      title: 'Are you sure you want to delete this plan?',
      icon: <ExclamationCircleOutlined />,
      content:
        'This action is irreversible, and deleting this plan will also delete all the properties associated with it.',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      autoFocusButton: 'cancel',
      keyboard: false,
      okButtonProps: {
        loading,
        disabled: loading,
      },
      cancelButtonProps: {
        loading,
        disabled: loading,
      },
      onOk: handlePlanDelete,
    });
  };

  const clearActivity = () => {
    if (!isClientView) {
      removeItemToStorage('client');
      removeItemToStorage('plan');

      dispatch(setClient(undefined));
      dispatch(setPlan(undefined));
      dispatch(resetSearch());
    }
  };

  const handleEditPlanNameClick = () => {
    setIsEditPlanModalVisible(true);
  };

  const handleDuplicatePlanNameClick = () => {
    setIsDuplicatePlanModalVisible(true);
  };

  const handleEditClient = () => {
    setIsClientModalVisible(true);
  };

  const handleEditPlanNameClose = () => {
    setIsEditPlanModalVisible(false);
  };

  const handleDuplicatePlanClose = () => {
    setIsDuplicatePlanModalVisible(false);
  };

  const handleClientModalClose = () => {
    setIsClientModalVisible(false);
  };

  const handleHomeNavClick = (e: any) => {
    e.preventDefault();
    clearActivity();
    dispatch(setNavIndex(1));

    history.push('/');
  };

  const handleScoringNavClick = (e: any) => {
    e.preventDefault();
    const route = `/suburb-scoring${!isAgencyLeadView ? '-internal' : ''}`;

    if (history?.location?.pathname !== route) {
      clearActivity();
    }

    dispatch(setNavIndex(isAgencyLeadView ? 1 : 0));
    history.push(route);
  };

  const handlePropertyStrategyNavClick = (e: any) => {
    e.preventDefault();

    if (history?.location?.pathname !== ROUTE) {
      clearActivity();
    }

    dispatch(setNavIndex(0));
    history.push(utmRoute);
  };

  const clienMenu: MenuProps['items'] = useMemo(() => {
    const items = [
      {
        key: '1',
        icon: <UserSwitchOutlined />,
        onClick: handleSelectNewClient,
        label: `Select new client`,
      },
    ];

    if (client?.isEditable) {
      items.push({
        key: '2',
        icon: <EditOutlined />,
        onClick: handleEditClient,
        label: `Edit client`,
      });
    }

    return items;
  }, [client]);

  const planMenuItems: () => MenuProps['items'] = () => {
    const planItems: MenuProps['items'] = [
      {
        key: '1',
        icon: <AppstoreAddOutlined />,
        onClick: handleSelectNewPlan,
        label: `Select new plan`,
      },
    ];

    if (!isClientView) {
      const restrictedPlanItems = [
        {
          key: '2',
          icon: <EditOutlined />,
          onClick: handleEditPlanNameClick,
          label: `Edit plan`,
        },
        {
          key: '3',
          icon: <CopyOutlined />,
          onClick: handleDuplicatePlanNameClick,
          label: `Duplicate this plan`,
        },
        {
          key: '4',
          icon: <DeleteOutlined style={{ color: '#eb5757' }} />,
          onClick: showDeleteConfirm,
          label: <span style={{ color: '#eb5757' }}>Delete this plan</span>,
        },
      ];

      planItems.push(...restrictedPlanItems);
    }

    return planItems;
  };

  return (
    <LayoutHeader className="c-app-layout__header">
      {isDuplicatePlanModalVisible && (
        <PlanDuplicate closeModal={handleDuplicatePlanClose} />
      )}
      {isEditPlanModalVisible && (
        <PlanNameModal closeModal={handleEditPlanNameClose} />
      )}

      {isClientModalVisible && (
        <ClientModal closeModal={handleClientModalClose} />
      )}
      <div className="c-app-layout__header-left-side">
        <div className="c-app-layout__header-logo">
          <Logo />
        </div>
      </div>
      <div className="c-app-layout__header-right-side">
        {isDesktopViewport && (
          <div className="c-app-layout__header-nav">
            {!isAgencyLeadView && (
              <button
                className={`${navClassName}${navIndex === 1 ? activeNavClassName : ''}`}
                onClick={handleHomeNavClick}
              >
                {homeNavDisplayConfig
                  ? homeNavDisplayConfig[user?.role_id ?? '']?.displayText
                  : ''}
              </button>
            )}
            {(isAgencyAdminView || isAgencyUserView || isAgencyLeadView) && (
              <button
                className={`${navClassName}${(isAgencyLeadView && navIndex === 1) || (!isAgencyLeadView && navIndex === 0) ? activeNavClassName : ''}`}
                onClick={handleScoringNavClick}
              >
                Suburb Scoring
              </button>
            )}
            {isAgencyLeadView && (
              <button
                className={`${navClassName}${navIndex === 0 ? activeNavClassName : ''}`}
                onClick={handlePropertyStrategyNavClick}
              >
                Free Portfolio Growth Plan
              </button>
            )}
          </div>
        )}
        <div className="c-app-layout__header-all-details-wrapper">
          {client && client.clientName && (
            <Dropdown menu={{ items: clienMenu }} disabled={isClientView}>
              <div
                className="c-app-layout__header-client"
                style={isClientView ? { cursor: 'default' } : {}}
              >
                <div className="c-app-layout__header-client-details">
                  <div
                    style={{ marginRight: 10 }}
                    className="c-app-layout__header-client-name-wrapper"
                  >
                    {client.clientName}
                  </div>
                  <div className="c-app-layout__header-client-image-wrapper">
                    <Avatar src={client.clientPhoto} icon={<UserOutlined />} />
                  </div>
                </div>

                <div style={{ fontSize: 12 }}>
                  <DownOutlined />
                </div>
              </div>
            </Dropdown>
          )}
          {plan && plan.planName && (
            <Dropdown menu={{ items: planMenuItems() }}>
              <div className="c-app-layout__header-details-wrapper">
                <div className="c-app-layout__header-details">
                  Portfolio Plan
                </div>
                <div className="c-app-layout__header-details c-app-layout__header-portfolio-type">
                  <div className="c-app-layout__header-portfolio-type-text">
                    {plan?.planName}
                  </div>
                  <div style={{ fontSize: 12, marginLeft: 20 }}>
                    <DownOutlined />
                  </div>
                </div>
              </div>
            </Dropdown>
          )}
        </div>
        <ProfileDropdown
          homeClick={handleHomeNavClick}
          scoringClick={handleScoringNavClick}
          strategyClick={handlePropertyStrategyNavClick}
          homeNavDisplayConfig={homeNavDisplayConfig}
          setNavIndex={setNavIndex}
        />
      </div>
    </LayoutHeader>
  );
};

export default Header;
