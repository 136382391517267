import React from 'react';

function UserIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      fill="none"
      viewBox="0 0 36 36"
    >
      <rect width="32" height="32" x="2" y="2" fill="#D6F7F4" rx="16"></rect>
      <path
        stroke="#019386"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M23.334 24v-1.333A2.667 2.667 0 0020.667 20h-5.333a2.667 2.667 0 00-2.667 2.667V24m8-9.333a2.667 2.667 0 11-5.333 0 2.667 2.667 0 015.333 0z"
      ></path>
      <rect
        width="32"
        height="32"
        x="2"
        y="2"
        stroke="#EFFCFB"
        strokeWidth="4"
        rx="16"
      ></rect>
    </svg>
  );
}

export default UserIcon;
