import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { selectUser } from '../authentication/redux/selectors';

const PropertyStrategy = () => {
  const user = useSelector(selectUser);

  const [isLoaded, setIsLoaded] = useState(false);

  const handleLoad = () => {
    setIsLoaded(true);
  };

  return (
    <>
      {!isLoaded && (
        <FullPageLoader classNames="h-fixed" message={'Just a moment...'} />
      )}
      <div className="l-suburb-scoring" style={{ paddingTop: 5 }}>
        <iframe
          style={{ height: '100%', width: '100%' }}
          className="l-property-search__preview-iframe"
          src={`${user?.propertyStrategyLink}`}
          title="Property Strategy"
          onLoad={handleLoad}
        />
      </div>
    </>
  );
};

export default PropertyStrategy;
