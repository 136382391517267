import { Layout, Menu, notification } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RouteType, useRoutes } from '../../../foundation/config/routes';
import { useRole } from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import CreateOffsetAccountModal from '../../offset_account/create_offset_account_modal/CreateOffsetAccountModal';
import { selectPlanInputs } from '../../plan/redux/selectors';
import CreatePropertyModal from '../../property/create_property_modal/CreatePropertyModal';
import {
  selectedPlanOffsetAccounts,
  selectedPlanRecords,
  selectPlanProperties,
} from '../../property/redux/selectors';
import { setSelectedPropertyId } from '../../property/redux/slice';

const { Sider: LayoutSider } = Layout;
const { SubMenu } = Menu;

const Sider = ({ routeConfig }: { routeConfig: RouteType | undefined }) => {
  const { isDesktopViewport } = useViewport();
  const history = useHistory();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [isOffsetModalVisible, setOffsetModalVisibility] = useState(false);
  const [isMenuCollapsed, setIsMenuCollapsed] = useState(!isDesktopViewport);

  const [selectedKeys, setSelectedKeys] = useState([history.location.pathname]);
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const [previousOpenKeys, setPreviousOpenKeys] = useState<string[]>([]);

  const planProperties = useSelector(selectPlanProperties);

  const planOffsetAccounts = useSelector(selectedPlanOffsetAccounts);

  const planRecords = useSelector(selectedPlanRecords);

  const planInputs = useSelector(selectPlanInputs);

  const [isClientView] = useRole();

  const routes = useRoutes(
    planProperties,
    isClientView,
    planOffsetAccounts,
    planRecords,
  );

  const dispatch = useDispatch();

  const theme = isDesktopViewport ? 'light' : 'dark';

  const toggleMenuCollapse = () => {
    setIsMenuCollapsed(!isMenuCollapsed);

    if (isMenuCollapsed) {
      setOpenKeys(previousOpenKeys);
    } else {
      setPreviousOpenKeys(openKeys);
    }
  };

  const handleModalClose = () => {
    setModalVisibility(false);
    setOffsetModalVisibility(false);
  };

  const menuRenderer = (item: any) => {
    if (!item.isSubMenu) {
      return (
        <Menu.Item
          key={`${item.completePath}`}
          icon={item.icon ? <item.icon /> : null}
        >
          {item.name}
        </Menu.Item>
      );
    } else {
      const items: React.ReactNode[] = [];

      for (const route of item.routes) {
        if (!route.hideInMenu) {
          items.push(menuRenderer(route));
        }
      }

      return (
        <SubMenu
          key={`${item.completePath}`}
          icon={item.icon ? <item.icon /> : null}
          title={
            item.data?.isSold ? (
              <span>
                <span className="c-app-layout__sold-indicator">S</span>
                {item.name}
              </span>
            ) : (
              item.name
            )
          }
        >
          {items}
        </SubMenu>
      );
    }
  };

  const menuItems = useMemo(() => {
    const items: any = [];

    for (const item of routes) {
      if (!item.hideInMenu) {
        items.push(menuRenderer(item));
      }
    }

    return items;
  }, [routes]);

  useEffect(() => {
    setSelectedKeys([history.location.pathname]);
  }, [history.location.pathname]);

  useEffect(() => {
    dispatch(setSelectedPropertyId(routeConfig?.data?.propertyId));
  }, [routeConfig]);

  useEffect(() => {
    setIsMenuCollapsed(!isDesktopViewport);
  }, []);

  const handleMenuSelect = ({ key }) => {
    if (!isDesktopViewport) {
      toggleMenuCollapse();
    }

    if (key === '/properties/new') {
      if (!planInputs?.cashAvailable) {
        notification.info({
          message: 'Info',
          description: 'Please update Plan Inputs before creating a property.',
        });
      } else {
        setModalVisibility(true);
      }
    } else if (key === '/oa-transactions/new') {
      // Create new Offset Account Page.
      setOffsetModalVisibility(true);
    } else {
      history.push(key);
      setSelectedKeys([key]);
    }
  };

  if (routeConfig?.displaySidebar) {
    return (
      <LayoutSider
        className="c-app-layout__sider"
        theme={theme}
        breakpoint="xl"
        collapsedWidth={0}
        collapsible={!isDesktopViewport}
        collapsed={isMenuCollapsed}
        onCollapse={toggleMenuCollapse}
        width={250}
      >
        {isModalVisible && (
          <CreatePropertyModal closeModal={handleModalClose} />
        )}
        {isOffsetModalVisible && (
          <CreateOffsetAccountModal closeModal={handleModalClose} />
        )}
        <Scrollbars style={{ height: '100%' }}>
          <Menu
            style={{ height: '100%' }}
            mode="inline"
            theme={theme}
            selectedKeys={selectedKeys}
            defaultOpenKeys={openKeys}
            openKeys={openKeys}
            onSelect={handleMenuSelect}
            onOpenChange={(keys) => {
              setOpenKeys(keys);
            }}
          >
            {menuItems}
          </Menu>
        </Scrollbars>
      </LayoutSider>
    );
  } else {
    return null;
  }
};

export default Sider;
