import { Col, notification, Popover, Row } from 'antd';
import { Buffer } from 'buffer';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Info from '../../../../../foundation/assets/svgs/Info';
import { selectUser } from '../../../../authentication/redux/selectors';
import { fetchGraphData, fetchGraphsData } from '../../../redux/async_thunks';
import {
  selectGraphDisplaySettings,
  selectReportDataByCurrentTemplate,
  selectSuburb,
  selectTemplate,
  selectTimelineOptions,
  selectTimelineType,
} from '../../../redux/selectors';
import {
  setIsGraphLoading,
  setIsReportLoading,
  setTimelineType,
} from '../../../redux/slice';
import DisclaimerLink from '../../../static_content/disclaimer_link/DisclaimerLink';
import LineGraph from '../line_graph/LineGraph';

const Graph = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const keyIndicators = useSelector(selectReportDataByCurrentTemplate);
  const suburb = useSelector(selectSuburb);
  const timelineType = useSelector(selectTimelineType);
  const user = useSelector(selectUser);
  const graphDisplaySettings = useSelector(selectGraphDisplaySettings);

  const template = useSelector(selectTemplate);

  const [supplyDemandRatioTrendType, setSupplyDemandRatioTrendType] =
    useState<string>('3');

  const [rentalVacancyRatioTrendType, setRentalVacancyRatioTrendType] =
    useState<string>('3');

  const [medianWeeklyAskingRentType, setMedianWeeklyAskingRentType] =
    useState<string>('3');

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const { r, t, u } = queryString.parse(history.location.search);
  const isPublicView = !!r && !!t && !!u;

  // Removed the following graphs from the graph type options
  // (StockMarketVolumeSales, LgaWeightedInvCapita, SuburbTotalWeightedInvesmentPerCapita,
  // MedianSalesPricePrediction, MedianWeeklyAskingRentPrediction)

  // Displaying graph types in preferred order
  const graphTypes = [
    {
      key: 'Supply & Demand Indicators',
      value: 'SupplyDemandIndicator',
    },
    {
      key: 'Demand To Supply Ratio',
      value: 'SupplyDemandRatio',
    },
    {
      key: 'Median Sales Price',
      value: 'MedianSalePrice',
    },
    {
      key: 'Rental Vacancy Ratio',
      value: 'RentalVacancyRatio',
    },
    {
      key: 'Volume of Rental Listings',
      value: 'VolumeRentalListings',
    },
    {
      key: 'Gross Rental Yield',
      value: 'GrossRentalYield',
    },
    {
      key: 'Median Weekly Asking Rent',
      value: 'MedianWeeklyAskingRent',
    },
    {
      key: 'Median Time On Market',
      value: 'MedianMarketTime',
    },
    {
      key: 'Vendor Discount',
      value: 'VendorDiscount',
    },
  ];

  const fromCoreLogic = [
    'SupplyDemandIndicator',
    'MedianSalePrice',
    'RentalVacancyRatio',
    'VolumeRentalListings',
    'GrossRentalYield',
    'MedianWeeklyAskingRent',
    'MedianMarketTime',
  ];

  const timelineOptions = useSelector(selectTimelineOptions);

  const trendlineOptions = [
    {
      key: '2 Years',
      value: '2',
    },
    {
      key: '3 Years',
      value: '3',
    },
  ];

  const errorHandler = () => {
    notification.error({
      message: 'Error: Graph section',
      description: 'Something went wrong with the request',
    });
  };

  const getGraphs = async (trendlineType = '3') => {
    try {
      dispatch(setIsReportLoading(true));

      const data: any = {
        sscCode: suburb.sscCode,
        userId: !isPublicView
          ? user?.user_id
          : Buffer.from(u as string, 'base64').toString('utf-8'),
        timeLineYears: timelineType,
        trendLineYears: trendlineType,
      };

      await dispatch(
        fetchGraphsData({
          data: data,
          template: template,
          token: !isPublicView
            ? user?.token
            : Buffer.from(t as string, 'base64').toString('utf-8'),
        }),
        // @ts-ignore
      ).unwrap();
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }

      if (isPublicView) {
        history.push('/');
      }
    }

    dispatch(setIsReportLoading(false));
  };

  const getGraph = async (graphType: string, trendlineType = '3') => {
    // @ts-ignore
    dispatch(setIsGraphLoading({ key: graphType, status: true }));

    try {
      dispatch(setIsReportLoading(true));

      const data: any = {
        sscCode: suburb.sscCode,
        userId: !isPublicView
          ? user?.user_id
          : Buffer.from(u as string, 'base64').toString('utf-8'),
        timeLineYears: timelineType,
        trendLineYears: trendlineType,
        graphType: graphType,
      };

      await dispatch(
        fetchGraphData({
          data: data,
          template: template,
          token: !isPublicView
            ? user?.token
            : Buffer.from(t as string, 'base64').toString('utf-8'),
          graphType: graphType,
        }),
        // @ts-ignore
      ).unwrap();
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }

      if (isPublicView) {
        history.push('/');
      }
    }

    dispatch(setIsReportLoading(false));
  };

  const groupFetch = () => {
    if (
      (user || isPublicView) &&
      timelineType &&
      suburb &&
      graphDisplaySettings
    ) {
      getGraphs();

      setSupplyDemandRatioTrendType('3');
      setRentalVacancyRatioTrendType('3');
      setMedianWeeklyAskingRentType('3');
    }
  };

  useEffect(() => {
    if (!isFirstLoad) {
      groupFetch();
    } else {
      setIsFirstLoad(false);
    }
  }, [suburb, timelineType]);

  if (!suburb) {
    return null;
  }

  return (
    <Col span={24}>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row gutter={[0, 15]}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                flexDirection: 'column',
                borderRadius: '16px',
                border: '1px solid #d9dbe9',
                padding: '16px',
                maxWidth: '430px',
                margin: '0 auto',
              }}
            >
              <p
                style={{
                  fontWeight: 'bold',
                  fontSize: '16px',
                  marginBottom: '9px',
                }}
              >
                Graph Timeline
              </p>
              <ul className="l-select-year">
                {timelineOptions.map((option: any, i: any) => {
                  return (
                    <li
                      className={`l-select-year__option${
                        option.value === timelineType
                          ? ' l-select-year__option--active'
                          : ''
                      }`}
                      key={option.value}
                    >
                      <a
                        href="#"
                        data-value={option.value}
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(
                            // @ts-ignore
                            setTimelineType(e.currentTarget.dataset.value),
                          );
                        }}
                      >
                        {option.value}Y
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>

            {graphTypes.map((g: any, i: number) => {
              const graphType: string = g.value;

              if (!graphDisplaySettings?.[graphType]) {
                return null;
              }

              const graphName = g.key;

              let infoPopoverContent: any = null;

              if (keyIndicators?.graphDescriptor) {
                infoPopoverContent = (
                  <p className="l-descriptor l-descriptor--wide">
                    {
                      keyIndicators.graphDescriptor[
                        `${graphType.charAt(0).toLowerCase()}${graphType.slice(
                          1,
                        )}`
                      ]
                    }
                  </p>
                );
              }

              return (
                <Col span={24} key={g.key}>
                  <div className="l-dashboard-card">
                    <div className="l-dashboard-card__body">
                      <div
                        className="l-dashboard-card__title"
                        style={{ marginBottom: '0', paddingBottom: '0' }}
                      >
                        <span
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          {graphName}{' '}
                          {keyIndicators.graphDescriptor && (
                            <Popover
                              content={infoPopoverContent}
                              title={null}
                              placement="top"
                              trigger="click"
                              arrowPointAtCenter={true}
                            >
                              <span className="l-descriptor-trigger">
                                <Info />
                              </span>
                            </Popover>
                          )}
                        </span>
                      </div>
                      {fromCoreLogic.includes(graphType) && (
                        <p style={{ marginBottom: '18px', fontSize: '13px' }}>
                          <DisclaimerLink />
                        </p>
                      )}
                      {g.value === 'SupplyDemandRatio' && timelineType && (
                        <ul
                          className="l-select-year"
                          style={{ marginTop: '10px' }}
                        >
                          <li className="l-select-year__label">Trendline</li>
                          {trendlineOptions.map((option: any, i: any) => {
                            return (
                              <li
                                className={`l-select-year__option${
                                  option.value === supplyDemandRatioTrendType
                                    ? ' l-select-year__option--active'
                                    : ''
                                }`}
                                key={option.value}
                              >
                                <a
                                  href="#"
                                  data-value={option.value}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const val: any =
                                      e.currentTarget.dataset.value;
                                    setSupplyDemandRatioTrendType(val);
                                    getGraph('SupplyDemandRatio', val);
                                  }}
                                >
                                  {option.value}Y
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {g.value === 'RentalVacancyRatio' && timelineType && (
                        <ul className="l-select-year">
                          <li className="l-select-year__label">Trendline</li>
                          {trendlineOptions.map((option: any) => {
                            return (
                              <li
                                className={`l-select-year__option${
                                  option.value === rentalVacancyRatioTrendType
                                    ? ' l-select-year__option--active'
                                    : ''
                                }`}
                                key={option.value}
                              >
                                <a
                                  href="#"
                                  data-value={option.value}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const val: any =
                                      e.currentTarget.dataset.value;
                                    setRentalVacancyRatioTrendType(val);
                                    getGraph('RentalVacancyRatio', val);
                                  }}
                                >
                                  {option.value}Y
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      {g.value === 'MedianWeeklyAskingRent' && timelineType && (
                        <ul className="l-select-year">
                          <li className="l-select-year__label">Trendline</li>
                          {trendlineOptions.map((option: any) => {
                            return (
                              <li
                                className={`l-select-year__option${
                                  option.value === medianWeeklyAskingRentType
                                    ? ' l-select-year__option--active'
                                    : ''
                                }`}
                                key={option.value}
                              >
                                <a
                                  href="#"
                                  data-value={option.value}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    const val: any =
                                      e.currentTarget.dataset.value;
                                    setMedianWeeklyAskingRentType(val);
                                    getGraph('MedianWeeklyAskingRent', val);
                                  }}
                                >
                                  {option.value}Y
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                      <LineGraph graphType={g.value} />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default Graph;
