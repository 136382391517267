import { useMemo } from 'react';

const useUTM = (baseRoute: string) => {
  const queryString =
    'utm_source=Suburb+Scoring+Tool&utm_medium=Property+Pathfinder&utm_campaign=ppf+free+pgp ';

  const combinedRoute = useMemo(
    () => `${baseRoute}?${queryString}`,
    [baseRoute, queryString],
  );

  return combinedRoute;
};

export default useUTM;
