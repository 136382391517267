import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { deleteUser, fetchAgencies, fetchAgency } from './async_thunks';
import { Agency, AgencyState } from './types';

const initialState: AgencyState = {
  list: [],
  agency: undefined,
  agencyUsers: [],
};

export const agencySlice = createSlice({
  name: 'agency',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setAgency: (state, action: PayloadAction<Agency | undefined>) => {
      state.agency = action.payload;
    },
    setAgencies: (state, action: PayloadAction<Agency[] | undefined>) => {
      state.list = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAgencies.fulfilled, (state, action) => {
        state.list = action.payload;
      })
      .addCase(fetchAgency.fulfilled, (state, action) => {
        state.agency = action.payload.agencyProfile;
        state.agencyUsers = action.payload.agencyUsers;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.agency = action.payload.agencyProfile;
        state.agencyUsers = action.payload.agencyUsers;
      });
  },
});

export const { setAgencies, setAgency } = agencySlice.actions;

export default agencySlice.reducer;
