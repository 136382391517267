import { EditOutlined } from '@ant-design/icons';
import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { FieldType } from '../../../../foundation/components/form_modal/form_modal_types';
import useTableSize from '../../../../foundation/cutom_hooks/useTableSize';
import {
  colValueRenderer,
  percentageParser,
} from '../../../../foundation/utils/helperFunctions';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import RecordModal from '../../record_modal/RecordModal';
import { updateAnnualFiguresCashIn } from '../../redux/async_thunks';
import { selectAnnualFiguresCashIn } from '../../redux/selectors';

type Props = {
  isLoading: boolean;
  isClientView: boolean;
  propertyId: string;
};

const CashIn = ({ isLoading, propertyId, isClientView }: Props) => {
  const dispatch = useAppDispatch();

  const [isModalVisible, setModalVisibility] = useState(false);
  const [modalData, setModalData] = useState(undefined);

  const dataSource = useSelector(selectAnnualFiguresCashIn);

  const user = useSelector(selectUser);

  const onEditRecord = (record: any) => () => {
    if (isClientView) {
      return;
    }
    setModalData({
      ...record,
      rate: percentageParser(record.rate),
    });
    setModalVisibility(true);
  };

  const titlesToBold = ['Gross Annual Property Income'];

  /**
   * Data table's columns.
   */
  const columns: any = [
    {
      title: 'Details',
      dataIndex: 'details',
      key: 'details',
      ellipsis: true,
      width: 300,
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          undefined,
          titlesToBold,
          'details',
        );
      },
    },
    {
      title: 'Rate',
      dataIndex: 'rate',
      key: 'rate',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '%', titlesToBold, 'details');
      },
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'details');
      },
      //   width: 80,
    },
    {
      title: <span className="c-action-title">Action</span>,
      dataIndex: 'type',
      key: 'type',
      width: 80,
      fixed: 'right',
      clientViewHidden: true,
      render: (text: any, record: any) => {
        if (record.isEditable) {
          return (
            <EditOutlined
              onClick={onEditRecord(record)}
              className="c-edit-record-btn"
            />
          );
        }
      },
    },
  ].filter((c: any) => {
    if (isClientView) {
      return !c.clientViewHidden;
    }
    return true;
  });

  /**
   * Form fields
   */
  const formFieldsArray: FieldType[] = useMemo(
    () => [
      {
        label: 'Details',
        name: 'details',
        key: 'details',
        type: 'text',
        isRequired: true,
        requiredMessage: 'Details is required',
      },
      {
        label: 'Rate',
        name: 'rate',
        key: 'rate',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Rate is required',
        addonBefore: '%',
        min: 0,
        max: 100,
      },
      {
        label: 'Amount',
        name: 'amount',
        key: 'amount',
        type: 'number',
        isRequired: true,
        requiredMessage: 'Amount is required',
        addonBefore: '$',
      },
    ],
    [],
  );

  const handleModalClose = () => {
    setModalVisibility(false);
    setModalData(undefined);
  };

  const handleFormSubmission = (values: any) => {
    if (isLoading || isClientView) {
      return;
    }
    try {
      const data = { ...values, rate: values?.rate / 100 };
      delete data.isEditable;
      delete data.editableFields;

      if (user?.token) {
        data.userId = user.user_id;
        data.propertyId = propertyId;

        return dispatch(
          updateAnnualFiguresCashIn({ token: user.token, values: data }),
          // @ts-ignore
        ).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="annual-figures__section">
      <div className="annual-figures__section-title">Cash In</div>
      {isModalVisible && (
        <RecordModal
          visible={isModalVisible}
          closeModal={handleModalClose}
          modalData={modalData}
          modalTitle={`Update Data`}
          onSubmit={handleFormSubmission}
          formFieldsArray={formFieldsArray}
        />
      )}
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        scroll={{ x: true }}
        size={useTableSize()}
        bordered
      />
    </div>
  );
};

export default CashIn;
