import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../../foundation/assets/svgs/Close';
import useTableSize from '../../../../foundation/cutom_hooks/useTableSize';
import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import { selectEnterpriseAdmins } from '../../redux/selectors';

type EnterpriseAdminModalProps = {
  closeModal: () => void;
};
const EnterpriseAdminModal = ({ closeModal }: EnterpriseAdminModalProps) => {
  const { isMobileViewport } = useViewport();
  const enterpriseAdmins = useSelector(selectEnterpriseAdmins);

  const columns: ColumnsType<any> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: isMobileViewport ? 150 : 200,
      render: (text, record) => {
        return (
          <div className="l-enterprise-detail__name-row-wrapper">
            <Avatar
              src={record.userPicture}
              icon={<UserOutlined />}
              style={{ marginRight: 10 }}
              className="l-enterprise-detail__name-row-avatar"
            />
            {record.status === 'Invited' ? (
              <div className="l-enterprise-detail__name-row-awaiting">
                Awaiting sign up...
              </div>
            ) : (
              <div className="l-enterprise-detail__name-row-name">{text}</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        let className = 'l-enterprise-detail__user-status-wrapper--active';
        let dotClassName = 'l-enterprise-detail__user-status-dot--active';

        if (text === 'Invited') {
          className = 'l-enterprise-detail__user-status-wrapper--invited';
          dotClassName = 'l-enterprise-detail__user-status-dot--invited';
        }

        if (text === 'Deactivated') {
          className = 'l-enterprise-detail__user-status-wrapper--deactivated';
          dotClassName = 'l-enterprise-detail__user-status-dot--deactivated';
        }

        return (
          <span
            className={`l-enterprise-detail__user-status-wrapper ${className}`}
          >
            <span
              className={`l-enterprise-detail__user-status-dot ${dotClassName}`}
            ></span>
            {text}
          </span>
        );
      },
    },
  ];

  const handleModalClose = () => {
    closeModal();
  };

  const modalOpts = {
    title: 'Enterprise Admins',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal" width={800}>
      <Table
        columns={columns}
        size={useTableSize()}
        pagination={false}
        scroll={{ x: 'max-content' }}
        dataSource={enterpriseAdmins}
      />
      <div className="h-invite-success-modal__btn-wrapper">
        <Button
          className="h-invite-success-modal__btn"
          onClick={handleModalClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default EnterpriseAdminModal;
