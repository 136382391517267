import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { getItemFromStorage } from '../../../foundation/utils/storageHandler';

// Typescript Props
type Props = {
  component: any;
};

const PublicRoute = ({ component: Component, ...rest }: Props): JSX.Element => (
  <Route
    {...rest}
    render={(props) => {
      if (getItemFromStorage('user')) {
        return (
          <Redirect
            to={{
              pathname: '/',
              state: { from: props.location },
            }}
          />
        );
      }
      return <Component {...props} />;
    }}
  />
);

// PropTypes
PublicRoute.propTypes = {
  location: PropTypes.object,
  component: PropTypes.any,
  rest: PropTypes.any,
  path: PropTypes.any,
  strict: PropTypes.bool,
  exact: PropTypes.bool,
};

export default PublicRoute;
