import { Pagination as AntPagination } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { selectOptions, selectResults } from '../redux/selectors';

const Pagination = ({ fetchResults }: any) => {
  const options = useSelector(selectOptions);
  const results = useSelector(selectResults);
  const { isMobileViewport } = useViewport();

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a className="l-suburb-scoring__pagination-step">Prev</a>;
    }
    if (type === 'next') {
      return <a className="l-suburb-scoring__pagination-step">Next</a>;
    }

    return originalElement;
  };

  if (!results || results.pageCount < 2) {
    return <></>;
  }

  return (
    <div className="l-suburb-scoring__pagination">
      <AntPagination
        current={options.pageIndex}
        total={results.resultCount}
        pageSize={results.itemsPerPage}
        onChange={(pageNumber: number) => {
          fetchResults({ pageIndex: pageNumber });
        }}
        showSizeChanger={false}
        hideOnSinglePage={true}
        className="custom-pagination"
        itemRender={itemRender}
        size={isMobileViewport ? 'small' : 'default'}
      />
    </div>
  );
};

export default Pagination;
