import { Row } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import {
  selectReportDataByCurrentTemplate,
  selectSuburb,
} from '../../redux/selectors';
import Graph from './graph/Graph';
import Growth from './growth/Growth';

const KeyIndicators = () => {
  const keyIndicators = useSelector(selectReportDataByCurrentTemplate);
  const suburb = useSelector(selectSuburb);

  const { isMobileViewport } = useViewport();

  return (
    <Row gutter={[0, isMobileViewport ? 25 : 50]}>
      <Growth />
      {!!suburb && !!keyIndicators?.allGraphs && <Graph />}
    </Row>
  );
};

export default KeyIndicators;
