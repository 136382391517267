import {
  Alert,
  Col,
  notification,
  Pagination,
  Popover,
  Row,
  Slider,
  Table,
  Typography,
} from 'antd';
import { Buffer } from 'buffer';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Info from '../../../../foundation/assets/svgs/Info';
import Select from '../../../../foundation/components/select/Select';
import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import { selectUser } from '../../../authentication/redux/selectors';
import { fetchReportData } from '../../redux/async_thunks';
import {
  selectProjectSettings,
  selectProjectsValues,
  selectReportDataByCurrentTemplate,
  selectSuburb,
  selectTemplate,
} from '../../redux/selectors';
import { setIsReportLoading, setProjectSettings } from '../../redux/slice';
import LineGraph from '../key_indicators/line_graph/LineGraph';

const Projects = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const descriptor = {
    totalInvestment:
      'This is the total sum of all proposed public and private infrastructure project spending within the defined radius.',
    totalWeightedEstInv:
      'This is the adjusted total sum of all proposed public and private infrastructure project spending within the defined distance radius, based on the probability of each of these projects actually being completed. The probability is calculated by assessing which stage of the development process the project is currently in.',
    totalWeightedEstInvPerCapita:
      'This shows the total, probability adjusted infrastructure spending, divided by the total number of people in this suburb.',
  };

  const projects = useSelector(selectReportDataByCurrentTemplate);
  const projectSettings = useSelector(selectProjectSettings);
  const { orderType, sortKey, orderByKey } = projectSettings;
  const suburb = useSelector(selectSuburb);
  const template = useSelector(selectTemplate);
  const user = useSelector(selectUser);

  const [groupedSortedProjects, setGroupedSortedProjects] = useState();
  const [prevOrderType, setPrevOrderType] = useState<string>();
  const [pageIndex, setPageIndex] = useState(1);
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const projectValues = useSelector(selectProjectsValues);
  const projectsDistanceValues = projectValues.distance;
  const projectsOrderTypes = projectValues.orderTypes || [];

  const graphType = 'SuburbTotalWeightedInvesmentPerCapita';

  const { Title } = Typography;

  const { isMobileViewport } = useViewport();

  const { r, t, u } = queryString.parse(history.location.search);
  const isPublicView = !!r && !!t && !!u;

  const scrollToTop = () => {
    const elem = document.getElementById('project-number');
    const appLayout = document.querySelector('.c-app-layout');

    if (elem && appLayout) {
      appLayout.scrollTo({
        behavior: 'smooth',
        top:
          elem.getBoundingClientRect().top +
          appLayout.scrollTop -
          elem.clientTop,
      });
    }
  };

  const errorHandler = () => {
    notification.error({
      message: 'Error: Projects tab',
      description: 'Something went wrong with the request',
    });
  };

  const fetchProjects = async (kmRadiusParam?: any, orderTypeParam?: any) => {
    try {
      dispatch(setIsReportLoading(true));

      const param =
        orderTypeParam === null || !!orderTypeParam
          ? orderTypeParam
          : projectSettings.orderType || null;

      await dispatch(
        fetchReportData({
          data: {
            kmRadius: kmRadiusParam || projectSettings.kmRadius,
            pageIndex: pageIndex,
            userId: !isPublicView
              ? user?.user_id
              : Buffer.from(u as string, 'base64').toString('utf-8'),
            orderType: param,
            suburbCode: suburb?.suburbCode,
          },
          template: template,
          token: !isPublicView
            ? user?.token
            : Buffer.from(t as string, 'base64').toString('utf-8'),
        }),
      )
        // @ts-ignore
        .unwrap();

      if (param) {
        setPrevOrderType(
          param === 'Industry'
            ? 'category'
            : param[0].toLowerCase() + param.slice(1),
        );
      }
    } catch (error) {
      // @ts-ignore
      if (error.message && !error.message.includes('404')) {
        errorHandler();
      }

      if (isPublicView) {
        history.push('/');
      }
    }

    dispatch(setIsReportLoading(false));
  };

  useEffect(() => {
    if (!isFirstLoad && projectSettings.kmRadius) {
      fetchProjects();
    } else {
      setIsFirstLoad(false);

      setPrevOrderType(
        projectSettings.orderType[0].toLowerCase() +
          projectSettings.orderType.slice(1) || null,
      );
    }
  }, [pageIndex]);

  useEffect(() => {
    if (!isFirstLoad && projects?.projects && orderType) {
      let newGroupedSortedProjects = projects.projects.reduce(
        (projects: any, project: any) => {
          const orderTypeChecked =
            orderType === 'Stage'
              ? 'stageIndex'
              : orderType[0].toLowerCase() + orderType.slice(1);

          const group = projects[project[orderTypeChecked]] || [];

          group.push(project);
          projects[project[orderTypeChecked]] = group.sort(sortProjects);

          return projects;
        },
        {},
      );

      newGroupedSortedProjects = Object.keys(newGroupedSortedProjects)
        .sort()
        .reduce((obj: any, key) => {
          obj[key] = newGroupedSortedProjects[key];
          return obj;
        }, {});
      setGroupedSortedProjects(newGroupedSortedProjects);
    }
  }, [projects, sortKey, orderByKey]);

  if (!suburb) {
    return null;
  }

  if (!!suburb && !projects) {
    return (
      <Row gutter={[0, 50]}>
        <Col span={24}>
          <Alert
            message="No projects found"
            description={<></>}
            type="warning"
            showIcon
          />
        </Col>
      </Row>
    );
  }

  let columns: any = [
    {
      title: '',
      dataIndex: 'name',
      render: (name: string, project: any) => {
        // Revisit
        return (
          <div className="l-project-details">
            <div className="l-project-details__name-wrap">
              {isMobileViewport && (
                <div className="h-image--table">
                  <img src={project.categoryUri} alt="" />
                </div>
              )}
              <div className="l-project-details__name">
                <span className="l-project-details__label">Name</span>
                <Title level={5} className="h-text--name">
                  {capitalizeName(name)}
                </Title>
              </div>
            </div>
            <ul className="l-project-details__items">
              <li className="l-project-details__item" style={{ width: '100%' }}>
                <span className="l-project-details__label">Category</span>
                <span className="l-project-details__value">
                  {project.category}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Stage</span>
                <span className="l-project-details__value">
                  {project.stage}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Post Code</span>
                <span className="l-project-details__value">
                  {project.postCode}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Likelihood</span>
                <span className="l-project-details__value">
                  {project.likelihood}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Est. Value</span>
                <span className="l-project-details__value">
                  {project.value}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Est. Start</span>
                <span className="l-project-details__value">
                  {project.commenceDate}
                </span>
              </li>
              <li className="l-project-details__item">
                <span className="l-project-details__label">Est. End</span>
                <span className="l-project-details__value">
                  {project.completionDate || 'n/a'}
                </span>
              </li>
            </ul>
          </div>
        );
      },
    },
  ];

  if (!isMobileViewport) {
    columns = [
      {
        title: 'Project',
        dataIndex: 'categoryUri',
        render: (categoryUri: string) => {
          return (
            <div className="h-image--table">
              <img src={categoryUri} alt="" />
            </div>
          );
        },
        width: isMobileViewport ? '150px' : '0',
      },
      columns[0],
    ];
  }

  const sortKeyOptions = [
    {
      key: 'Name',
      value: 'name',
    },
    {
      key: 'Category',
      value: 'category',
    },
    {
      key: 'Post Code',
      value: 'postCode',
    },
    {
      key: 'Likelihood',
      value: 'likelihood',
    },
    {
      key: 'Estimated Value',
      value: 'realValue',
    },
    {
      key: 'Estimated Start',
      value: 'commenceDate',
    },
    {
      key: 'Estimated End',
      value: 'completionDate',
    },
    {
      key: 'Stage',
      value: 'stage',
    },
  ];

  const orderByOptions = [
    {
      key: 'Ascending',
      value: 'ascending',
    },
    {
      key: 'Descending',
      value: 'descending',
    },
  ];

  const groupByOptions = [
    {
      key: 'No Grouping',
      value: '',
    },
    ...projectsOrderTypes.map((item: any) => {
      if (item === 'PostCode') {
        return {
          key: 'Post Code',
          value: item,
        };
      }

      return {
        key: item,
        value: item,
      };
    }),
  ];

  const {
    totalInvestment,
    weightedInvestmentPerCapita,
    weightedInvestment,
    country,
    countryInvestmentPerCapita,
    totalProjects,
  } = projects;

  const sortProjects = (a: any, b: any) => {
    let check = a[sortKey] > b[sortKey];

    if (a === b) {
      return 0;
    }

    if (sortKey === 'commenceDate' || sortKey === 'completionDate') {
      check = Date.parse(a[sortKey]) > Date.parse(b[sortKey]);
    }

    if (sortKey === 'likelihood' || sortKey === 'postCode') {
      check = parseFloat(a[sortKey]) > parseFloat(b[sortKey]);
    }

    if (sortKey === 'likelihood' || sortKey === 'postCode') {
      check = parseFloat(a[sortKey]) > parseFloat(b[sortKey]);
    }

    if (sortKey === 'value') {
      const regex = /\D+/g;

      check =
        parseFloat(a[sortKey].replace(regex, '')) >
        parseFloat(b[sortKey].replace(regex, ''));
    }

    if (orderByKey === 'descending') {
      return check ? -1 : 1;
    }

    return check ? 1 : -1;
  };

  const capitalizeName = (name: string) => {
    const words = name.toLowerCase().split(' ');

    for (let i = 0; i < words.length; i++) {
      if (words[i][0]) {
        words[i] = words[i][0].toUpperCase() + words[i].substring(1);
      }
    }

    return words.join(' ');
  };

  const formatter = (value: any) => {
    if (value) {
      return <span>{value} km</span>;
    }

    return null;
  };

  const renderActions = () => {
    const heading = (
      <div className="l-project-actions__heading">
        No. of Projects: <strong>{totalProjects}</strong>
      </div>
    );

    const groupBy = (
      <>
        <span className="l-dashboard-card__label">Group All Projects By</span>
        <Select
          options={groupByOptions}
          value={projectSettings.orderType}
          onChange={(v: string) => {
            dispatch(
              setProjectSettings({
                ...projectSettings,
                orderType: v,
              }),
            );

            if (pageIndex !== 1) {
              setPageIndex(1);
            } else if (
              (user || isPublicView) &&
              suburb &&
              projectSettings.kmRadius
            ) {
              fetchProjects(null, v || null);
            }
          }}
        />
      </>
    );

    const sortBy = (
      <span
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          flexDirection: 'column',
        }}
      >
        <span className="l-dashboard-card__label">Sort Page Result By</span>
        <Select
          options={sortKeyOptions}
          value={sortKey}
          onChange={(v: string) => {
            dispatch(
              setProjectSettings({
                ...projectSettings,
                sortKey: v,
              }),
            );
          }}
          placeholder="Order By"
        />
      </span>
    );

    const orderBy = (
      <Select
        options={orderByOptions}
        value={orderByKey}
        onChange={(v: string) => {
          dispatch(
            setProjectSettings({
              ...projectSettings,
              orderByKey: v,
            }),
          );
        }}
      />
    );

    return (
      <>
        <Col span={24} className="l-project-actions">
          {heading}
        </Col>
        <Col xs={24} sm={10} className="l-project-actions">
          {groupBy}
        </Col>
        <Col
          xs={24}
          sm={8}
          className="l-project-actions l-project-actions--report-margin"
          style={isMobileViewport ? {} : { alignItems: 'flex-end' }}
        >
          {sortBy}
        </Col>
        <Col
          xs={24}
          sm={6}
          className={`l-project-actions ${
            isMobileViewport ? '' : 'l-project-actions--report-width'
          }`}
        >
          {orderBy}
        </Col>
      </>
    );
  };

  const renderGroupedSortedProjects = () => {
    if (!groupedSortedProjects) {
      return null;
    }

    return (
      <>
        {Object.keys(groupedSortedProjects).map(
          (key: string, index: number) => {
            const mapKey = JSON.stringify(groupedSortedProjects[key][0]);

            return (
              <Col span={24} key={mapKey}>
                <div className="l-dashboard-card">
                  <div className="l-dashboard-card__header  l-dashboard-card__header--border l-dashboard-card__header--dark">
                    <h5 className="l-dashboard-card__title l-dashboard-card__title--no-padding l-dashboard-card__title--dark">
                      <span className="l-dashboard-card__title-type">
                        {orderType}
                      </span>
                      {prevOrderType && (
                        <strong>
                          {groupedSortedProjects[key][0][prevOrderType]}
                        </strong>
                      )}
                    </h5>
                  </div>
                  <div className="l-dashboard-card__body l-dashboard-card__body--no-padding">
                    <div className="h-override h-override--hide-table-header">
                      <Table
                        pagination={false}
                        dataSource={groupedSortedProjects[key]}
                        columns={columns}
                        size="large"
                        rowKey="name"
                        className="l-projects-table"
                      />
                    </div>
                  </div>
                </div>
              </Col>
            );
          },
        )}
      </>
    );
  };

  const renderSortedProjects = () => {
    const sortedProjects = [].concat(projects.projects).sort(sortProjects);

    return (
      <Col span={24}>
        <div className="l-dashboard-card">
          <div className="l-dashboard-card__body l-dashboard-card__body--no-padding">
            <div className="h-override h-override--hide-table-header">
              <Table
                pagination={false}
                dataSource={sortedProjects}
                columns={columns}
                size="large"
                className="l-projects-table"
              />
            </div>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <>
      <Row gutter={[15, 50]}>
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col span={isMobileViewport ? 24 : 8}>
              <Row gutter={[0, 15]}>
                <Col span={24}>
                  <div className="l-dashboard-card l-dashboard-card--projects">
                    <div className="l-dashboard-card__body">
                      <h5 className="l-dashboard-card__title">
                        Total Investment
                        {descriptor['totalInvestment'] && (
                          <Popover
                            content={
                              <p className="l-descriptor">
                                {descriptor['totalInvestment']}
                              </p>
                            }
                            title={null}
                            placement="top"
                            trigger="click"
                          >
                            <span className="l-descriptor-trigger">
                              <Info />
                            </span>
                          </Popover>
                        )}
                      </h5>
                      <dl>
                        <dt className="l-dashboard-card__values-wrapper">
                          <span className="l-dashboard-card__value">
                            {totalInvestment || 'N/a'}
                          </span>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="l-dashboard-card l-dashboard-card--projects">
                    <div className="l-dashboard-card__body">
                      <h5 className="l-dashboard-card__title">
                        Total Weighted Est. Investment
                        {descriptor['totalWeightedEstInv'] && (
                          <Popover
                            content={
                              <p className="l-descriptor">
                                {descriptor['totalWeightedEstInv']}
                              </p>
                            }
                            title={null}
                            placement="top"
                            trigger="click"
                          >
                            <span className="l-descriptor-trigger">
                              <Info />
                            </span>
                          </Popover>
                        )}
                      </h5>
                      <dl>
                        <dt className="l-dashboard-card__values-wrapper">
                          <span className="l-dashboard-card__value">
                            {weightedInvestment || 'N/a'}
                          </span>
                        </dt>
                      </dl>
                    </div>
                  </div>
                </Col>
                <Col span={24}>
                  <div className="l-dashboard-card l-dashboard-card--projects">
                    <div className="l-dashboard-card__body">
                      <h5 className="l-dashboard-card__title">
                        Total Weighted Est. Investment Per Capita
                        {descriptor['totalWeightedEstInvPerCapita'] && (
                          <Popover
                            content={
                              <p className="l-descriptor">
                                {descriptor['totalWeightedEstInvPerCapita']}
                              </p>
                            }
                            title={null}
                            placement="top"
                            trigger="click"
                          >
                            <span className="l-descriptor-trigger">
                              <Info />
                            </span>
                          </Popover>
                        )}
                      </h5>
                      <dl>
                        <dt className="l-dashboard-card__values-wrapper">
                          <span className="l-dashboard-card__value">
                            {weightedInvestmentPerCapita || 'N/a'}
                          </span>
                        </dt>
                        <dd className="l-dashboard-card__details">
                          <span>
                            {country} is{' '}
                            <strong>
                              {countryInvestmentPerCapita || 'N/a'}
                            </strong>
                          </span>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={isMobileViewport ? 24 : 16}>
              <section className="l-project-wrapper" style={{ padding: '0' }}>
                <div className="l-dashboard-card">
                  <div
                    className="l-dashboard-card__body"
                    style={{ height: '100%' }}
                  >
                    <div
                      className="l-dashboard-card__title"
                      style={{ paddingBottom: '0' }}
                    >
                      Investment Indicator
                    </div>
                    <LineGraph
                      graphType={graphType}
                      // maintainAspectRatio={false}
                      isXUnitsInMonths={true}
                      yMaxTicksLimit={5}
                      style={{ height: !isMobileViewport ? '100%' : '300px' }}
                      isProjects
                    />
                  </div>
                </div>
              </section>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="l-project-controls" id="project-number">
        <div className="l-slider">
          {!isMobileViewport && (
            <span className="l-slider__label" style={{ whiteSpace: 'nowrap' }}>
              Distance Radius
            </span>
          )}
          <span className="l-slider__wrapper">
            <span className="l-slider__mark-label">
              {projectsDistanceValues.min} km
            </span>
            <Slider
              step={projectsDistanceValues.interval}
              style={{ maxWidth: '250px', width: '100%' }}
              tooltip={{
                formatter: formatter,
              }}
              min={projectsDistanceValues.min}
              max={projectsDistanceValues.max}
              value={projectSettings.kmRadius}
              onChange={(value: any) => {
                dispatch(
                  setProjectSettings({
                    ...projectSettings,
                    kmRadius: value,
                  }),
                );
              }}
              onAfterChange={(value: any) => {
                if (pageIndex !== 1) {
                  setPageIndex(1);
                } else if (
                  (user || isPublicView) &&
                  suburb &&
                  projectSettings.kmRadius
                ) {
                  fetchProjects(value);
                }
              }}
            />
            <span className="l-slider__mark-label">
              {projectsDistanceValues.max} km
            </span>
          </span>
          {!isMobileViewport && (
            <span className="l-slider__active-label">
              {projectSettings.kmRadius} km
            </span>
          )}
        </div>
      </div>

      <Row
        gutter={[0, 50]}
        justify="center"
        style={{
          marginTop: isMobileViewport ? '15px' : '50px',
          marginBottom: '0',
        }}
      >
        <Col span={24}>
          <Row gutter={[15, 0]}>{renderActions()}</Row>
        </Col>
      </Row>

      <Row gutter={[0, 10]} justify="center">
        <Col span={24}>
          <Row gutter={[0, 25]}>
            {orderType && groupedSortedProjects
              ? renderGroupedSortedProjects()
              : renderSortedProjects()}
            <Col span={24}>
              <div className="l-project-pagination">
                <Pagination
                  className="custom-pagination"
                  current={pageIndex}
                  total={totalProjects}
                  pageSize={20}
                  onChange={(page: number) => {
                    scrollToTop();
                    setPageIndex(page);
                  }}
                  showSizeChanger={false}
                  showLessItems
                  style={{
                    margin: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Projects;
