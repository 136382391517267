import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Image, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import TabPane from 'antd/es/tabs/TabPane';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import AgencyIcon from '../../../foundation/assets/svgs/AgencyIcon';
import ArrowLeft from '../../../foundation/assets/svgs/ArrowLeft';
import UserIcon from '../../../foundation/assets/svgs/UserIcon';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import useRole from '../../../foundation/cutom_hooks/useRole';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../../store/hooks';
import {
  selectRoleOptions,
  selectUser,
} from '../../authentication/redux/selectors';
import { setNavIndex } from '../../profile/redux/slice';
import AgencyModal from '../modals/agency_modal/AgencyModal';
import DeleteAgencyUserModal from '../modals/delete_agency_user_modal/DeleteAgencyUserModal';
import EditInviteModal from '../modals/invite_modal/EditInviteModal';
import InviteModal from '../modals/invite_modal/InviteModal';
import InviteSuccessModal from '../modals/invite_success_modal/InviteSuccessModal';
import { fetchAgency } from '../redux/async_thunks';
import { selectAgency, selectAgencyUsers } from '../redux/selectors';
import { setAgency } from '../redux/slice';
import { AgencyUser } from '../redux/types';

const AgencyDetail = () => {
  const { isMobileViewport } = useViewport();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const { id }: any = useParams();

  const [isAgencyLoading, setIsAgencyLoading] = useState(true);

  const [isAgencyModalVisible, setAgencyModalVisibility] = useState(false);

  const [isInviteModalVisible, setInviteModalVisibility] = useState(false);

  const [isEditInviteModalVisible, setEditInviteModalVisibility] =
    useState(false);

  const [isDeleteUserModalVisible, setDeleteUserModalVisibility] =
    useState(false);

  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

  const [selectedUser, setSelectedUser] = useState<AgencyUser | undefined>(
    undefined,
  );
  const [isInviteSuccessModalVisible, setInviteSuccessModalVisibility] =
    useState(false);

  const [roleType, setRoleType] = useState<'user' | 'client'>('user');

  const agency = useSelector(selectAgency);

  const agencyUsers = useSelector(selectAgencyUsers);

  const ROLE_IDS: any = useSelector(selectRoleOptions);

  const [, , , isAgencyAdminView] = useRole();

  const users = agencyUsers?.length
    ? agencyUsers.filter((u) => {
        return (
          u.roleId !== ROLE_IDS?.client && u.roleId !== ROLE_IDS?.agencyLead
        );
      })
    : [];

  const clients = agencyUsers?.length
    ? agencyUsers.filter((u) => {
        return (
          u.roleId === ROLE_IDS?.client || u.roleId === ROLE_IDS?.agencyLead
        );
      })
    : [];

  const user = useSelector(selectUser);

  const fetchAgencyData = async () => {
    try {
      if (user) {
        const data = { agencyId: id, token: user.token };

        await dispatch(fetchAgency(data)).unwrap();

        setIsAgencyLoading(false);
      }
    } catch (error) {
      setIsAgencyLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchAgencyData();
    }

    return () => {
      dispatch(setAgency(undefined));
    };
  }, [id]);

  const onBackClickHandler = () => {
    if (isAgencyAdminView) {
      dispatch(setNavIndex(1));
    }
    history.push('/');
  };

  const handleDeleteUserBtnClick = () => {
    setDeleteUserModalVisibility(true);
  };

  const handleEditRowClick = (record: AgencyUser) => () => {
    setEditInviteModalVisibility(true);
    setSelectedUser(record);
  };

  const columns: ColumnsType<AgencyUser> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: isMobileViewport ? 150 : 250,
      render: (text, record) => {
        return (
          <div className="l-agency-detail__name-row-wrapper">
            <Avatar
              src={record.userPicture}
              icon={<UserOutlined />}
              style={{ marginRight: 10 }}
              className="l-agency-detail__name-row-avatar"
            />
            {record.status === 'Invited' ? (
              <div className="l-agency-detail__name-row-awaiting">
                Awaiting sign up...
              </div>
            ) : (
              <div className="l-agency-detail__name-row-name">{text}</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Team Permissions',
      dataIndex: 'roleId',
      key: 'roleId',
      render: (roleId) => {
        let roleKey = '';

        if (roleId === ROLE_IDS?.nonClient) {
          return 'Non-client';
        }

        for (const [key, value] of Object.entries(ROLE_IDS)) {
          if (value === roleId) {
            roleKey = key.replace('agency', '');
          }
        }

        return roleKey.charAt(0).toUpperCase() + roleKey.slice(1);
      },
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return (
          <span
            className={`l-agency-detail__user-status-wrapper ${
              text === 'Invited'
                ? ' l-agency-detail__user-status-wrapper--invited'
                : ' l-agency-detail__user-status-wrapper--active'
            }`}
          >
            <span
              className={`l-agency-detail__user-status-dot ${
                text === 'Invited'
                  ? ' l-agency-detail__user-status-dot--invited'
                  : ' l-agency-detail__user-status-dot--active'
              }`}
            ></span>
            {text}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record) => {
        return (
          <div className="l-agency-detail__table-control-btns">
            {record.status === 'Invited' ? (
              <span
                className="l-agency-detail__table-control-btn"
                style={{ marginRight: 10 }}
                onClick={handleEditRowClick(record)}
              >
                <EditOutlined />
              </span>
            ) : (
              <span
                className="l-agency-detail__table-control-btn"
                style={{ marginRight: 10, width: 14 }}
              ></span>
            )}
            {record?.email !== user?.email && (
              // Hide delete button when the record is referring to the logged in user
              <span
                className="l-agency-detail__table-control-btn"
                onClick={() => {
                  setSelectedUser(record);
                  handleDeleteUserBtnClick();
                }}
              >
                <DeleteOutlined />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleAgencyModalClose = () => {
    setAgencyModalVisibility(false);
  };

  const handleInviteModalClose = () => {
    setInviteModalVisibility(false);
  };

  const handleInviteSuccessModalClose = () => {
    setInviteSuccessModalVisibility(false);
    fetchAgencyData();
  };

  const handleEditAgencyClick = () => {
    setAgencyModalVisibility(true);
  };

  const handleInviteUserBtnCLick = () => {
    setInviteModalVisibility(true);
  };

  const handleEditInviteModalClose = () => {
    setEditInviteModalVisibility(false);
  };

  const handleDeleteUserModalClose = () => {
    setDeleteUserModalVisibility(false);
    setSelectedUser(undefined);
  };

  return (
    <div className="l-agency-detail">
      {isAgencyLoading && <FullPageLoader />}

      {isAgencyModalVisible && (
        <AgencyModal
          closeModal={handleAgencyModalClose}
          setInviteModalVisibility={setInviteModalVisibility}
        />
      )}

      {isEditInviteModalVisible && selectedUser && (
        <EditInviteModal
          closeModal={handleEditInviteModalClose}
          setInviteSuccessModalVisibility={setInviteSuccessModalVisibility}
          setInvitedEmails={setInvitedEmails}
          selectedUser={selectedUser}
          editType={roleType}
        />
      )}

      {isInviteModalVisible && (
        <InviteModal
          closeModal={handleInviteModalClose}
          setInviteSuccessModalVisibility={setInviteSuccessModalVisibility}
          setInvitedEmails={setInvitedEmails}
        />
      )}

      {isInviteSuccessModalVisible && (
        <InviteSuccessModal
          closeModal={handleInviteSuccessModalClose}
          emails={invitedEmails}
          inviteType={roleType}
        />
      )}

      {isDeleteUserModalVisible && (
        <DeleteAgencyUserModal
          closeModal={handleDeleteUserModalClose}
          selectedAgencyUser={selectedUser}
          deleteType={roleType}
        />
      )}

      <div className="l-agency-detail__view">
        <div className="l-agency-detail__header">
          <div className="l-agency-detail__header-icon">
            <ArrowLeft />
          </div>
          <div
            className="l-agency-detail__header-text"
            onClick={onBackClickHandler}
          >
            Back to dashboard
          </div>
        </div>
        <div className="l-agency-detail__agency-info-wrapper">
          <div className="l-agency-detail__agency-picture">
            {agency?.agencyPicture ? (
              <Image width={200} src={agency?.agencyPicture} />
            ) : (
              <AgencyIcon />
            )}
          </div>
          <div className="l-agency-detail__agency-name">
            {agency?.agencyName}
          </div>
          <div className="l-agency-detail__agency-address">
            {agency?.agencyAddress}
          </div>
          <div className="l-agency-detail__agency-edit-btn-wrapper">
            <Button onClick={handleEditAgencyClick}>Edit agency profile</Button>
          </div>
        </div>
        <Tabs
          style={{ width: '100%' }}
          tabBarStyle={{ padding: '0 16px' }}
          onTabClick={(key) => {
            setRoleType(key as 'user' | 'client');
          }}
        >
          <TabPane tab="Agency Users" key="user">
            <div className="l-agency-detail__users-section">
              <div className="l-agency-detail__users-section-header">
                <div className="l-agency-detail__users-section-header-title">
                  Agency Users
                </div>
                <div>
                  <Button type="primary" onClick={handleInviteUserBtnCLick}>
                    Invite Users
                  </Button>
                </div>
              </div>
              <Table
                columns={columns}
                size={useTableSize()}
                pagination={false}
                scroll={{ x: 'max-content' }}
                dataSource={users}
              />
            </div>
            <div className="l-agency-detail__permission-section">
              <div className="l-agency-detail__permission-section-title">
                Permissions
              </div>
              <div
                className="l-agency-detail__permission-info-wrapper"
                style={{ marginBottom: 15 }}
              >
                <div style={{ marginRight: 10 }}>
                  <UserIcon />
                </div>
                <div>
                  <div className="l-agency-detail__permission-info-title">
                    Agency users can perform the following functions:
                  </div>
                  <div className="l-agency-detail__permission-info-description">
                    Create/edit/delete agency clients and clients plans.
                  </div>
                </div>
              </div>
              <div className="l-agency-detail__permission-info-wrapper">
                <div style={{ marginRight: 10 }}>
                  <UserIcon />
                </div>
                <div>
                  <div className="l-agency-detail__permission-info-title">
                    Agency admins can perform the following functions:
                  </div>
                  <div className="l-agency-detail__permission-info-description">
                    Edit an agency profile, manage agency admins/users/clients,
                    and manage client&apos;s plans.
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
          <TabPane tab="Clients" key="client">
            <div className="l-agency-detail__users-section">
              <div className="l-agency-detail__users-section-header">
                <div className="l-agency-detail__users-section-header-title">
                  Clients
                </div>
              </div>
              <Table
                // @ts-ignore
                columns={columns}
                size={useTableSize()}
                pagination={false}
                scroll={{ x: 'max-content' }}
                dataSource={clients}
              />
            </div>
            <div className="l-agency-detail__permission-section">
              <div className="l-agency-detail__permission-section-title">
                Permissions
              </div>
              <div className="l-agency-detail__permission-info-wrapper">
                <div style={{ marginRight: 10 }}>
                  <UserIcon />
                </div>
                <div>
                  <div className="l-agency-detail__permission-info-title">
                    Clients can perform the following functions:
                  </div>
                  <div className="l-agency-detail__permission-info-description">
                    Have access to view the plans without editing.
                  </div>
                </div>
              </div>
            </div>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

export default AgencyDetail;
