import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const fetchMonthlyModelingByDataView: any = createAsyncThunk(
  'property/fetch-monthly-modeling-dataview',
  async (
    apiParams: {
      token: string;
      propertyId: string;
      year: string;
      dataView: string;
    },
    { getState },
  ) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const state: any = getState();

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/MonthlyModelling/${apiParams.propertyId}/${apiParams.dataView}/${apiParams.year}`,
      options,
    );

    const data = decryptData(response.data);

    const reduxData: any = {
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      isSold: data.isSold,
      monthKey: data.monthKey,
      yearDisplayed: data.yearDisplayed,
      interestRateForDisplayedYear: data.interestRateForDisplayedYear,
    };

    if (apiParams.dataView === 'm') {
      reduxData.monthlyModeling = data.monthlyModelData;
      reduxData.quaterlyModeling =
        state?.property?.monthly_modeling?.quaterlyModeling;
      reduxData.yearlyModeling =
        state?.property?.monthly_modeling?.yearlyModeling;
      // Columns
      reduxData.monthlyColumns = data.columns;
      reduxData.quaterlyColumns =
        state?.property?.monthly_modeling?.quaterlyColumns;
      reduxData.yearlyColumns =
        state?.property?.monthly_modeling?.yearlyColumns;
      // For Quaterly View
    } else if (apiParams.dataView === 'q') {
      reduxData.monthlyModeling =
        state.property?.monthly_modeling?.monthlyModeling;
      reduxData.quaterlyModeling = data.monthlyModelData;
      reduxData.yearlyModeling =
        state.property?.monthly_modeling?.yearlyModeling;

      // Columns
      reduxData.monthlyColumns =
        state?.property?.monthly_modeling?.monthlyColumns;
      reduxData.quaterlyColumns = data.columns;
      reduxData.yearlyColumns =
        state?.property?.monthly_modeling?.yearlyColumns;
    } else {
      reduxData.monthlyModeling =
        state.property?.monthly_modeling?.monthlyModeling;
      reduxData.quaterlyModeling =
        state.property?.monthly_modeling?.quaterlyModeling;
      reduxData.yearlyModeling = data.monthlyModelData;

      // Columns
      reduxData.monthlyColumns =
        state?.property?.monthly_modeling?.monthlyColumns;
      reduxData.quaterlyColumns =
        state?.property?.monthly_modeling?.quaterlyColumns;
      reduxData.yearlyColumns = data.columns;
    }

    return reduxData;
  },
);

export const updateMonthlyModelingPurchaseValue: any = createAsyncThunk(
  'property/update-monthly-modeling-purchase-value',
  async (apiParams: { token: string; data: any; dataView: string }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const ecnryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/MonthlyModelling/property-value`,
      ecnryptedData,
      options,
    );

    const data = decryptData(response.data);
    const reduxData: any = {
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      monthKey: data.monthKey,
      yearDisplayed: data.yearDisplayed,
      interestRateForDisplayedYear: data.interestRateForDisplayedYear,
      columns: data.columns,
      isSold: data.isSold,
    };
    if (apiParams.dataView === 'm') {
      reduxData.monthlyModeling = data.monthlyModelData;
      reduxData.monthlyColumns = data.columns;
    } else if (apiParams.dataView === 'q') {
      reduxData.quaterlyModeling = data.monthlyModelData;
      reduxData.quaterlyColumns = data.columns;
    } else {
      reduxData.yearlyModeling = data.monthlyModelData;
      reduxData.yearlyColumns = data.columns;
    }

    return reduxData;
  },
);

export const updateMonthlyModelingInterestRate: any = createAsyncThunk(
  'property/update-monthly-modelling-interest-rate',
  async (apiParams: { token: string; data: any; dataView: string }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const ecnryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/MonthlyModelling/interest-rate`,
      ecnryptedData,
      options,
    );

    const data = decryptData(response.data);

    const reduxData: any = {
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      monthKey: data.monthKey,
      yearDisplayed: data.yearDisplayed,
      interestRateForDisplayedYear: data.interestRateForDisplayedYear,
      columns: data.columns,
    };

    if (apiParams.dataView === 'm') {
      reduxData.monthlyModeling = data.monthlyModelData;
      reduxData.monthlyColumns = data.columns;
    } else if (apiParams.dataView === 'q') {
      reduxData.quaterlyModeling = data.monthlyModelData;
      reduxData.quaterlyColumns = data.columns;
    } else {
      reduxData.yearlyModeling = data.monthlyModelData;
      reduxData.yearlyColumns = data.columns;
    }

    return reduxData;
  },
);

export const updateMonthlyModelingGrossIncome: any = createAsyncThunk(
  'property/monthly-modelling-gross-income',
  async (apiParams: { token: string; data: any; dataView: string }) => {
    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const ecnryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/MonthlyModelling/gross-income`,
      ecnryptedData,
      options,
    );

    const data = decryptData(response.data);

    const reduxData: any = {
      propertyName: data.propertyName,
      propertyId: data.propertyId,
      contractDate: data.contractDate,
      monthKey: data.monthKey,
      yearDisplayed: data.yearDisplayed,
      interestRateForDisplayedYear: data.interestRateForDisplayedYear,
      columns: data.columns,
    };
    if (apiParams.dataView === 'm') {
      reduxData.monthlyModeling = data.monthlyModelData;
      reduxData.monthlyColumns = data.columns;
    } else if (apiParams.dataView === 'q') {
      reduxData.quaterlyModeling = data.monthlyModelData;
      reduxData.quaterlyColumns = data.columns;
    } else {
      reduxData.yearlyModeling = data.monthlyModelData;
      reduxData.yearlyColumns = data.columns;
    }

    return reduxData;
  },
);
