import { CheckOutlined, CloseOutlined, EditOutlined } from '@ant-design/icons';
import { Button, Input, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useRole from '../../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { selectUser } from '../../authentication/redux/selectors';
import { updateReportHtml } from '../redux/async_thunks';
import {
  selectReportHtmlByCurrentTemplate,
  selectSuburb,
  selectTemplate,
} from '../redux/selectors';
import { setIsEditedVersion, setIsReportToggleEnabled } from '../redux/slice';

const ReportContent = ({ heading }: { heading: any }) => {
  const dispatch = useDispatch();

  const { TextArea } = Input;
  const [, , , isAgencyAdminView] = useRole();

  const reportHtml = useSelector(selectReportHtmlByCurrentTemplate);
  const template = useSelector(selectTemplate);
  const user = useSelector(selectUser);
  const suburb = useSelector(selectSuburb);

  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModeLoading, setIsEditModeLoading] = useState(false);
  const [reportHtmlValue, setReportHtmlValue] = useState<any>();

  const { isMobileViewport } = useViewport();

  function parseEscapeSequencesToHTML(text: any) {
    const escapeSequences: any = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
      "'": '&#39;',
      '\n': '<br>',
      '\r': '',
      '\t': '&nbsp;&nbsp;&nbsp;&nbsp;',
    };

    return text.replace(
      /[&<>"'\n\r\t]/g,
      (match: any) => escapeSequences[match],
    );
  }

  function removeEscapeSequencesAtStart(text: any) {
    if (text.startsWith('\n\n')) {
      return text.slice(2);
    }
    return text;
  }

  function transformString(inputString: string) {
    const words = inputString.split('-');
    const transformedString = words
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join('');

    return transformedString;
  }

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const parsedText = (text: any) => {
    const parsedText = parseEscapeSequencesToHTML(text);
    return <div dangerouslySetInnerHTML={{ __html: parsedText }} />;
  };

  const saveReport = async () => {
    setIsEditModeLoading(true);

    const data: any = {
      suburbCode: suburb.suburbCode,
      userId: user?.user_id,
      template:
        template === 'pricerent-forecast'
          ? 'PriceAndRentForecast'
          : transformString(template),
      reportHtml: reportHtmlValue || null,
    };

    try {
      await dispatch(
        updateReportHtml({
          data: data,
          token: user?.token,
        }),
      )
        // @ts-ignore
        .unwrap();

      notification.success({
        message: 'Success!',
        description: 'Report content updated successfully.',
      });

      dispatch(setIsReportToggleEnabled(true));
      dispatch(setIsEditedVersion(true));
    } catch (error) {
      errorHandler(error);
    }

    setIsEditModeLoading(false);
    setIsEditMode(false);
  };

  useEffect(() => {
    if (reportHtml) {
      setReportHtmlValue(reportHtml);
    }
  }, [reportHtml]);

  return (
    <div style={{ marginTop: '15px', padding: '0 10px' }}>
      <h6>
        <div>{heading}</div>
        {isAgencyAdminView && (
          <>
            {' '}
            {!isEditMode && (
              <Button
                onClick={() => {
                  setIsEditMode(true);
                }}
              >
                <EditOutlined />
              </Button>
            )}
            {isEditMode && (
              <div>
                <Button
                  style={{ marginRight: '4px', color: '#ff0000' }}
                  onClick={() => {
                    setIsEditMode(false);
                    setReportHtmlValue(reportHtml);
                  }}
                  disabled={isEditModeLoading}
                >
                  <CloseOutlined />
                </Button>
                <Button
                  style={{ color: '#33b433' }}
                  onClick={() => {
                    if (!isEditModeLoading) {
                      saveReport();
                    }
                  }}
                  loading={isEditModeLoading}
                  disabled={!reportHtmlValue.length}
                >
                  <CheckOutlined />
                </Button>
              </div>
            )}
          </>
        )}
      </h6>

      {reportHtml && (
        <div
          style={
            isMobileViewport ? { margin: '0 0 24px' } : { margin: '0 0 45px' }
          }
        >
          {isEditMode && isAgencyAdminView && (
            <TextArea
              style={{
                height: isMobileViewport ? '200px' : '400px',
                maxHeight: '400px',
              }}
              disabled={isEditModeLoading}
              onChange={(e) => {
                setReportHtmlValue(e.target.value);
              }}
              value={reportHtmlValue}
            />
          )}
          {!isEditMode &&
            parsedText(removeEscapeSequencesAtStart(reportHtml.trim()))}
        </div>
      )}
    </div>
  );
};

export default ReportContent;
