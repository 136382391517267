import { Col, Row, Table } from 'antd';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import { colValueRenderer } from '../../../foundation/utils/helperFunctions';
import { selectOffsetAccountSourceAccounts } from '../redux/selectors';
// import { setSourceAccounts } from '../redux/slice';
import { OffsetAccount, SourceAccount } from '../redux/types';

type SourceAccountsProps = {
  offsetAccDataSource: OffsetAccount[];
  sourceAccDataSource: SourceAccount[];
  setSourceAccDataSource: React.Dispatch<React.SetStateAction<SourceAccount[]>>;
};
const SourceAccounts = ({
  offsetAccDataSource,
  sourceAccDataSource,
  setSourceAccDataSource,
}: SourceAccountsProps) => {
  const titlesToBold = ['Total Cash Available'];

  const sourceAccounts = useSelector(selectOffsetAccountSourceAccounts);

  const getIndexAndRowOfSourceAccounts = (
    sourceName: string,
    newSourceAccounts: SourceAccount[],
  ) => {
    const index = newSourceAccounts.findIndex(
      (item) => item.sourceName === sourceName,
    );

    const row = newSourceAccounts[index];

    return {
      index,
      row,
    };
  };

  const handleTotalCashAvailableRow = (sourceArr: SourceAccount[]) => {
    const newSourceArr = [...sourceArr];

    if (
      newSourceArr[newSourceArr.length - 1].sourceName ===
      'Total Cash Available'
    ) {
      const totalRow: SourceAccount = {
        ...newSourceArr[newSourceArr.length - 1],
      };

      totalRow.newAvailableAmount = 0;
      for (const item of newSourceArr) {
        if (item.sourceName !== 'Total Cash Available') {
          totalRow.newAvailableAmount += item.newAvailableAmount;
        }
      }

      newSourceArr[newSourceArr.length - 1] = totalRow;
    }

    return newSourceArr;
  };

  const handleOffsetCalculation = () => {
    if (sourceAccounts && offsetAccDataSource) {
      const newOffsetAccounts = [...offsetAccDataSource];
      const newSourceAccounts = [...sourceAccounts];

      for (let i = 0; i < newOffsetAccounts.length; i++) {
        // Single row of offset account.
        const info = newOffsetAccounts[i];

        let index = -1;

        let sourceAccRow: any = undefined;

        if (info.transactionType === 'Withdrawal') {
          if (info.fromTo === 'Savings') {
            const data = getIndexAndRowOfSourceAccounts(
              'Savings',
              newSourceAccounts,
            );
            index = data.index;
            sourceAccRow = { ...data.row };
            sourceAccRow.newAvailableAmount =
              sourceAccRow.newAvailableAmount + info.use;
          }
        } else if (info.transactionType === 'Deposit') {
          if (info.fromTo === 'Savings') {
            const data = getIndexAndRowOfSourceAccounts(
              'Savings',
              newSourceAccounts,
            );
            index = data.index;
            sourceAccRow = { ...data.row };

            sourceAccRow.newAvailableAmount =
              sourceAccRow.newAvailableAmount - info.use;
          } else if (info.fromTo === 'Portfolio Cashflow') {
            const data = getIndexAndRowOfSourceAccounts(
              'Portfolio Cashflow',
              newSourceAccounts,
            );
            index = data.index;
            sourceAccRow = { ...data.row };
            sourceAccRow.newAvailableAmount =
              sourceAccRow.newAvailableAmount - info.use;
          }
        }

        if (sourceAccRow) {
          newSourceAccounts.splice(index, 1, {
            ...sourceAccRow,
          });
        }
      }

      const source = handleTotalCashAvailableRow(newSourceAccounts);

      setSourceAccDataSource(source);
    }
  };

  useEffect(() => {
    handleOffsetCalculation();
  }, [offsetAccDataSource]);

  /**
   * Data table's columns.
   */
  const sourceAccountsCols: any = [
    {
      title: 'Cash',
      dataIndex: 'sourceName',
      key: 'sourceName',
      ellipsis: true,
      width: 300,
      render: (text: string, record: any) => {
        return colValueRenderer(
          text,
          record,
          undefined,
          titlesToBold,
          'sourceName',
        );
      },
    },
    {
      title: 'Amount Available',
      dataIndex: 'availableAmount',
      key: 'availableAmount',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'sourceName');
      },
      //   width: 150,
    },
    {
      title: 'New Amount Available',
      dataIndex: 'newAvailableAmount',
      key: 'newAvailableAmount',
      ellipsis: true,
      width: 150,
      render: (text: string, record: any) => {
        return colValueRenderer(text, record, '$', titlesToBold, 'sourceName');
      },
    },
  ];

  return (
    <Row className="offset-account__row">
      <Col xs={24}>
        <Table
          columns={sourceAccountsCols}
          dataSource={sourceAccDataSource}
          pagination={false}
          scroll={{ x: true }}
          bordered
          size={useTableSize()}
        />
      </Col>
    </Row>
  );
};

export default SourceAccounts;
