import { notification } from 'antd';
import { Buffer } from 'buffer';
import queryString from 'query-string';
import randomstring from 'randomstring';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useClientIP } from '../../../foundation/cutom_hooks/useClientIP';
import { useAppDispatch } from '../../../store/hooks';
import AuthenticationFooter from '../elements/AuthenticationFooter';
import AuthenticationPageImage from '../elements/AuthenticationPageImage';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import {
  forgotPassword,
  resetPassword,
  verifyResetPassId,
} from '../redux/async_thunks';
import { selectAuthStrongPassword } from '../redux/selectors';
import EmailSuccessMessage from './EmailSuccessMessage';

const ResetPassword = () => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const clientIp = useClientIP();

  const [formType, setFormType] = useState<'forgot' | 'reset'>('forgot');

  const [isResetPassFormLoading, setIsResetPassFormLoading] = useState(false);

  const [resetId, setResetId] = useState<any>(undefined);

  const [isEmailMsgVisible, setEmailMsgVisibility] = useState(false);

  const shouldImplementStrongPassword = useSelector(selectAuthStrongPassword);

  useEffect(() => {
    const parsed = queryString.parse(history.location.search);
    if (parsed.t) {
      setIsResetPassFormLoading(true);
      setResetId(parsed.t);
      setFormType('reset');
    }
  }, [history.location.search]);

  const handleResetIdVerification = async () => {
    try {
      const data = {
        resetId,
      };

      await dispatch(verifyResetPassId(data)).unwrap();

      setIsResetPassFormLoading(false);

      setFormType('reset');
    } catch (error) {
      setIsResetPassFormLoading(false);
      history.push('/login');
    }
  };

  useEffect(() => {
    if (resetId && clientIp) {
      handleResetIdVerification();
    }
  }, [resetId, clientIp]);

  const handleForgotPasswordFormSubmit = async (values) => {
    if (isResetPassFormLoading) {
      return;
    }
    try {
      setIsResetPassFormLoading(true);

      const reqData = {
        email: values.email,
      };

      // @ts-ignore
      const resp = await dispatch(forgotPassword({ data: reqData })).unwrap();
      console.log(resp);
      setIsResetPassFormLoading(false);

      setEmailMsgVisibility(true);
    } catch (error) {
      setIsResetPassFormLoading(false);
    }
  };

  const handleResetPassword = async (values) => {
    if (isResetPassFormLoading) {
      return;
    }

    try {
      if (resetId) {
        setIsResetPassFormLoading(true);

        const passSuffix = randomstring.generate(20);
        const passPrefix = randomstring.generate(20);

        const appendedPass = `${passPrefix}${values.newPassCode}${passSuffix}`;

        const encodedPass = Buffer.from(appendedPass).toString('base64');

        const data = {
          resetId,
          passCode: encodedPass,
        };

        await dispatch(resetPassword({ data })).unwrap();

        setIsResetPassFormLoading(false);

        notification.success({
          message: 'Success!',
          description: 'Password reset successfully.',
        });

        history.push('/login');
      }
    } catch (error) {
      console.log(error);
      setIsResetPassFormLoading(false);
    }
  };

  return (
    <div className="l-reset-pass">
      {isResetPassFormLoading && <FullPageLoader />}
      <div className="l-reset-pass__content-wrapper">
        <div className="l-reset-pass__dummy-container"></div>

        {isEmailMsgVisible ? (
          <EmailSuccessMessage />
        ) : (
          <ResetPasswordForm
            formType={formType}
            isFormLoading={isResetPassFormLoading}
            handleFormSubmit={
              formType === 'forgot'
                ? handleForgotPasswordFormSubmit
                : handleResetPassword
            }
            isStrongPassword={shouldImplementStrongPassword}
          />
        )}

        <AuthenticationFooter />
      </div>
      <AuthenticationPageImage />
    </div>
  );
};

export default ResetPassword;
