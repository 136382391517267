import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../../internals/env/env_constants.json';

export const fetchInterestRateProjectionData = createAsyncThunk(
  'interest_rate/get-data',
  async (apiParams: { token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);

    const response = await axios.get(
      `${env_constants.PP_API_BASE_URL}/Reference/interest-rates`,
      options,
    );
    const data = decryptData(response.data);

    return data;
  },
);

export const updateInterestRateProjection = createAsyncThunk(
  'interest_rate/update',
  async (apiParams: { data: object; token: string }) => {
    const options = getBasicAuthAPIOptions(apiParams.token);
    const encryptedData = encryptData(apiParams.data);

    const response = await axios.post(
      `${env_constants.PP_API_BASE_URL}/Reference/interest-rates`,
      encryptedData,
      options,
    );

    const data = decryptData(response.data);

    return data;
  },
);
