import { FormOutlined } from '@ant-design/icons';
import { Modal } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import FieldError from '../../foundation/components/field_error/FieldError';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import WysiwygEditor from '../../foundation/components/wysiwyg_editor/WysiwygEditor';
import { selectUser } from '../authentication/redux/selectors';
import { fetchPropertyNotes, updatePropertyNotes } from './redux/async_thunks';
import { selectPropertyNotes } from './redux/selectors';

const Notes = () => {
  const { id: propertyId }: any = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [isDragginDisabled, disableDraggging] = useState(true);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  const [bounds, setBounds] = useState({
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
  });

  const user = useSelector(selectUser);

  const notes = useSelector(selectPropertyNotes);

  const draggleRef = useRef<HTMLDivElement | null>(null);

  const editorRef: any = useRef(null);

  const dispatch = useDispatch();

  const fetchData = async () => {
    try {
      if (user) {
        setIsLoading(true);

        await dispatch(
          fetchPropertyNotes({ token: user.token, propertyId }),
          // @ts-ignore
        ).unwrap();

        setIsLoading(false);
      }
    } catch (error) {
      handleCancel();
    }
  };

  useEffect(() => {
    fetchData();
  }, [dispatch]);

  const openModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async (e: any) => {
    if (isLoading) {
      return;
    }
    try {
      if (editorRef.current && user) {
        const content = editorRef.current.getContent();

        if (content === '') {
          setErrorText('Notes are required');
          return;
        } else {
          setErrorText(undefined);
        }

        setIsLoading(true);

        const data = {
          userId: user?.user_id,
          propertyId,
          notes: content,
        };

        await dispatch(
          updatePropertyNotes({ token: user.token, data }),
          // @ts-ignore
        ).unwrap();
      }

      handleCancel();
    } catch (error) {
      console.log(error);
      handleCancel();
    }
  };

  const handleCancel = () => {
    setIsLoading(false);
    setIsModalVisible(false);
  };

  const onStart = (event: any, uiData: any) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    if (isModalVisible) {
      setIsLoading(true);

      setTimeout(() => setIsLoading(false), 400);
    }
  }, [isModalVisible, setIsLoading]);

  return (
    <>
      {isModalVisible && (
        <Modal
          okButtonProps={{
            style: {
              width: 100,
              margin: '0 25px 30px 10px',
            },
          }}
          okText="Save"
          title={
            <div
              style={{
                width: '100%',
                cursor: 'move',
              }}
              onMouseOver={() => {
                if (isDragginDisabled) {
                  disableDraggging(false);
                }
              }}
              onMouseOut={() => {
                disableDraggging(true);
              }}
            >
              Notes
            </div>
          }
          bodyStyle={{ minHeight: 400 }}
          open={isModalVisible}
          onOk={handleOk}
          maskClosable={false}
          onCancel={handleCancel}
          modalRender={(modal) => (
            <Draggable
              disabled={isDragginDisabled}
              bounds={bounds}
              onStart={(event, uiData) => onStart(event, uiData)}
            >
              <div ref={draggleRef}>{modal}</div>
            </Draggable>
          )}
          className="property-notes__modal"
        >
          {isLoading && <FullPageLoader />}
          <WysiwygEditor initialValue={notes ? notes : ''} ref={editorRef} />
          {errorText && <FieldError message={errorText} />}
        </Modal>
      )}
      <div className="property-notes" onClick={openModal}>
        <FormOutlined />
      </div>
    </>
  );
};

export default Notes;
