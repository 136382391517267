import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchClientData } from '../../features/authentication/redux/async_thunks';
import {
  setMarketingCampaigns,
  setRoles,
} from '../../features/authentication/redux/slice';

export const useClientIP = () => {
  const [clientIp, setClientIp] = useState(null);

  const dispatch = useDispatch();
  const getClientIp = async () => {
    try {
      const response = await dispatch(
        fetchClientData(), // @ts-ignore
      ).unwrap();
      if (response) {
        setClientIp(response.ip);
        dispatch(setMarketingCampaigns(response.marketingCampaigns));
        dispatch(setRoles(response.roles));
      }
    } catch (error) {
      setClientIp(null);
    }
  };

  useEffect(() => {
    getClientIp();
  }, []);

  return clientIp;
};
