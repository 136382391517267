import { Avatar, Card } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { addItemToStorage } from '../../../foundation/utils/storageHandler';
import {
  ClientStateType,
  PlanDuplicateSteps,
} from '../../plan/plan_duplicate/types';
import { setClient, setClients } from '../redux/slice';
import { Client } from '../redux/types';

type Props = {
  client: Client;
  isPlanDuplicateView?: boolean;
  planDuplicateStep?: PlanDuplicateSteps;
  setClientForPlanDuplicate?: React.Dispatch<
    React.SetStateAction<ClientStateType>
  >;
  setPlanDuplicateStep?: React.Dispatch<
    React.SetStateAction<PlanDuplicateSteps>
  >;
};

const ClientCard = ({
  client,
  isPlanDuplicateView,
  setClientForPlanDuplicate,
  setPlanDuplicateStep,
}: Props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleClientClick = () => {
    if (isPlanDuplicateView && setClientForPlanDuplicate) {
      const data = {
        clientId: client.clientId,
        isNew: false,
      };
      setClientForPlanDuplicate(data);
      if (setPlanDuplicateStep) {
        setPlanDuplicateStep('plan');
      }
    } else {
      dispatch(setClient(client));
      addItemToStorage('client', JSON.stringify(client));
      history.push('/plan');
    }

    dispatch(setClients(undefined));
  };

  return (
    <Card className="client-view__card" onClick={handleClientClick}>
      <Avatar
        size={80}
        className="client-view__card-avatar"
        src={client.clientPhoto}
      />
      <p className="client-view__card-name">{client.clientName}</p>
    </Card>
  );
};

export default ClientCard;
