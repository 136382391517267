import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useClientIP } from '../../../foundation/cutom_hooks/useClientIP';
import useUTM from '../../../foundation/cutom_hooks/useUTM';
import env_constants from '../../../internals/env/env_constants.json';
import { signup } from '../../authentication/redux/async_thunks';
import { selectMarketingCampaigns } from '../../authentication/redux/selectors';

const AgencyLeadAutoSignup = () => {
  const [isLoading, setIsLoading] = useState(false);

  const clientIp = useClientIP();
  const marketingCampaigns = useSelector(selectMarketingCampaigns);
  const [details, setDetails] = useState<any>(null);

  const ROUTE = '/invite-lead-success';
  const utmRoute = useUTM(ROUTE);

  const dispatch = useDispatch();
  const history = useHistory();

  const generatePropertyStrategyURL = () => {
    const {
      firstName = '',
      lastName = '',
      email = '',
      phone = '',
      marketingCampaign = '',
    } = details;
    const marketingCampaignObj = marketingCampaigns?.find(
      (campaign) => campaign.key === marketingCampaign,
    );

    const fullName = `${firstName}${lastName ? ' ' + lastName : ''}`;
    const nameEncoded = encodeURIComponent(fullName);
    const emailEncoded = encodeURIComponent(email);
    const phoneEncoded = encodeURIComponent(phone);

    return `${marketingCampaignObj?.value}/?Name=${nameEncoded}&Email=${emailEncoded}&Mobile=${phoneEncoded}`;
  };

  const proceedSignUp = async () => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      await dispatch(
        signup({ values: details, isLeadSignup: true }),
        // @ts-ignore
      ).unwrap();

      history.push(utmRoute, {
        data: {
          leadSignupEmail: details ? details.email : '',
          propertyStrategyLink: generatePropertyStrategyURL(),
        },
      });
    } catch (error) {
      history.push('/login');
    }
  };

  const check = () => {
    const parsed = queryString.parse(history.location.search);
    const validCampaignId =
      parsed?.CampaignId &&
      marketingCampaigns &&
      marketingCampaigns.find((campaign) => campaign.key === parsed.CampaignId);

    if (
      validCampaignId &&
      parsed?.Email &&
      parsed?.FirstName &&
      parsed?.Mobile &&
      typeof parsed?.Mobile === 'string'
    ) {
      const data = {
        marketingCampaign: parsed.CampaignId,
        email: parsed.Email,
        firstName: parsed.FirstName,
        phone: parsed.Mobile.replace(/^\s+/, '+'),
        inviteId: env_constants.DEFAULT_INVITE_ID,
      };

      setDetails(
        !parsed?.LastName
          ? data
          : {
              ...data,
              lastName: parsed.LastName,
            },
      );
    } else {
      history.push('/login');
    }
  };

  useEffect(() => {
    if (clientIp) {
      check();
    }
  }, [clientIp, history.location.search]);

  useEffect(() => {
    if (clientIp && details) {
      proceedSignUp();
    }
  }, [clientIp, details]);

  return (
    <div style={{ position: 'relative', height: '100%' }}>
      <FullPageLoader classNames="h-fixed" message={'Signing up...'} />
    </div>
  );
};

export default AgencyLeadAutoSignup;
