import { Button, Input, Modal } from 'antd';
import { FieldArray, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import Select from '../../../../foundation/components/select/Select';
import { useAppDispatch } from '../../../../store/hooks';
import { inviteUsers } from '../../../agency/redux/async_thunks';
import {
  selectRoleOptions,
  selectUser,
} from '../../../authentication/redux/selectors';
import { selectEnterprise } from '../../redux/selectors';
import { EnterpriseAdmin } from '../../redux/types';
import { inviteFormValidationSchema } from '../../validation_schemas/invite_form_validation';

type EditInviteModalProps = {
  closeModal: () => void;
  selectedUser: EnterpriseAdmin;
  setInviteSuccessModalVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setInvitedEmails: React.Dispatch<React.SetStateAction<string[]>>;
};

const EditInviteModal = ({
  closeModal,
  setInviteSuccessModalVisibility,
  setInvitedEmails,
  selectedUser,
}: EditInviteModalProps) => {
  const ROLE_IDS = useSelector(selectRoleOptions);

  const ROLE_ID = useMemo(() => ROLE_IDS?.enterpriseAdmin, [ROLE_IDS]);

  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const enterprise = useSelector(selectEnterprise);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const userRoles = useMemo(() => {
    if (ROLE_ID) {
      return [
        {
          key: 'Enterprise Admin',
          value: ROLE_ID.toString(),
        },
      ];
    }
    return [];
  }, [ROLE_ID]);

  const modalOpts = {
    title: 'Edit Enterprise Admins',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
    width: 560,
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    try {
      if (user && enterprise) {
        setIsLoading(true);
        const data = {
          userId: user?.user_id,
          inviteeEmailsRoleIds: values.emails.map((email) => ({
            email,
            roleId: ROLE_ID,
          })),
          agencyId: user?.agency_id,
        };

        const response = await dispatch(
          inviteUsers({
            token: user?.token,
            data: data,
          }),
        ).unwrap();

        setInvitedEmails(response.allInvitees);

        setIsLoading(false);
        closeModal();
        setInviteSuccessModalVisibility(true);
      }
    } catch (error) {
      handleModalClose();
    }
  };

  return (
    <Modal {...modalOpts} className="h-invite-modal c-form-modal">
      {isLoading && <FullPageLoader />}
      <div className="h-invite-modal__information">
        <strong>Enterprise admins</strong>{' '}
        {`can edit enterprise profile, manage enterprise admins, create/edit/delete agencies and agency admins/users`}
      </div>
      <Formik
        initialValues={{ emails: [selectedUser.email] }}
        validationSchema={inviteFormValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray name="emails">
                {() => (
                  <div>
                    {values.emails.map((email, index) => (
                      <div
                        className="h-invite-modal__dual-fields-wrapper"
                        key={index}
                      >
                        <div className="c-form-field">
                          <div className="c-form-field__label">
                            Email address
                          </div>
                          <div className="c-form-field__wrapper">
                            <Input
                              onChange={handleChange}
                              type="text"
                              name={`emails[${index}]`}
                              placeholder={`User ${index + 1}`}
                              value={values.emails[index]}
                              onBlur={handleBlur}
                              disabled
                            />
                            <div className="c-form-field__error">
                              {errors.emails && errors.emails[index]
                                ? errors.emails[index]
                                : undefined}
                            </div>
                          </div>
                        </div>

                        <div className="c-form-field">
                          <div className="c-form-field__label">Permissions</div>
                          <div className="c-form-field__wrapper">
                            <Select
                              options={userRoles}
                              value={ROLE_ID ? ROLE_ID.toString() : undefined}
                              onChange={(v: any) => setFieldValue('state', v)}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <div
                className="h-invite-modal__submit-btn-wrapper"
                style={{ paddingTop: 20 }}
              >
                <Button
                  type="primary"
                  className="h-invite-modal__submit-btn"
                  htmlType="submit"
                >
                  Re-send Invite
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditInviteModal;
