import { Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import useRole from '../../../../foundation/cutom_hooks/useRole';
import useUTM from '../../../../foundation/cutom_hooks/useUTM';
import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import { removeItemToStorage } from '../../../../foundation/utils/storageHandler';
import { setClient } from '../../../client/redux/slice';
import { setPlan } from '../../../plan/redux/slice';
import { setNavIndex } from '../../../profile/redux/slice';
import { resetSearch } from '../../../suburb_scoring/redux/slice';

const StickyFooter = () => {
  const { isMobileViewport } = useViewport();

  const [isClientView] = useRole();

  const ROUTE = '/free-pgp';
  const utmRoute = useUTM(ROUTE);

  const dispatch = useDispatch();
  const history = useHistory();

  const handlePropertyStrategyNavClick = (e: any) => {
    e.preventDefault();

    if (history?.location?.pathname !== ROUTE) {
      if (!isClientView) {
        removeItemToStorage('client');
        removeItemToStorage('plan');

        dispatch(setClient(undefined));
        dispatch(setPlan(undefined));
        dispatch(resetSearch());
      }
    }

    dispatch(setNavIndex(0));
    history.push(utmRoute);
  };

  return (
    <div className="l-sticky-footer">
      <p>
        Ready to plan your {isMobileViewport && <br />}
        property journey?
      </p>
      <Button type="primary" onClick={handlePropertyStrategyNavClick}>
        Get Free Growth Plan
      </Button>
    </div>
  );
};

export default StickyFooter;
