import React from 'react';

function UserIconCustom({ color, style }: { color?: string; style?: any }) {
  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style || {}}
    >
      <path
        d="M9 10.5V9.5C9 8.96957 8.78929 8.46086 8.41421 8.08579C8.03914 7.71071 7.53043 7.5 7 7.5H3C2.46957 7.5 1.96086 7.71071 1.58579 8.08579C1.21071 8.46086 1 8.96957 1 9.5V10.5M7 3.5C7 4.60457 6.10457 5.5 5 5.5C3.89543 5.5 3 4.60457 3 3.5C3 2.39543 3.89543 1.5 5 1.5C6.10457 1.5 7 2.39543 7 3.5Z"
        stroke={color || '#3538CD'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default UserIconCustom;
