import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  fetchGraphData,
  fetchGraphsData,
  fetchReportData,
  fetchSharedReportData,
  updateReportHtml,
} from './async_thunks';
import { AiReportState } from './types';

const initialState: AiReportState = {
  suburb: undefined,
  reportHtml: {},
  reportData: {},
  template: 'investment-summary',
  isDisclaimerModalActive: false,
  isReportLoading: false,
  graphDisplaySettings: {
    // All enabled by default
    SupplyDemandIndicator: true,
    SupplyDemandRatio: true,
    RentalVacancyRatio: true,
    VolumeRentalListings: true,
    MedianSalePrice: true,
    GrossRentalYield: true,
    MedianWeeklyAskingRent: true,
    MedianMarketTime: true,
    LgaWeightedInvCapita: true,
    MedianSalesPricePrediction: true,
    MedianWeeklyAskingRentPrediction: true,
  },
  isGraphLoading: {},
  timelineType: '10',
  forecastTimelineType: '10',
  timelineOptions: [
    {
      key: '3 Years',
      value: '3',
    },
    {
      key: '5 Years',
      value: '5',
    },
    {
      key: '10 Years',
      value: '10',
    },
    {
      key: '15 Years',
      value: '15',
    },
    {
      key: '20 Years',
      value: '20',
    },
  ],
  projectSettings: {
    orderType: 'Stage',
    sortKey: 'realValue',
    orderByKey: 'descending',
    kmRadius: 50,
  },
  projectsValues: {
    distance: {
      min: 5,
      max: 100,
      interval: 5,
    },
    orderTypes: ['Industry', 'Stage', 'PostCode'],
  },
  isReportToggleEnabled: false,
  isEditedVersion: false,
  isFavorite: false,
  favoriteReports: [],
  sharedReports: [],
  isGoingBack: false,
};

export const aiReportSlice = createSlice({
  name: 'ai_report',
  initialState,
  reducers: {
    setSuburb: (state, action) => {
      state.suburb = action.payload;
    },
    resetSuburb: (state) => {
      state.suburb = initialState.suburb;
    },
    resetReport: (state) => {
      state.reportHtml = initialState.reportHtml;
      state.reportData = initialState.reportData;
      state.isFavorite = false;
    },
    setTemplate: (state, action) => {
      state.template = action.payload;
    },
    resetTemplate: (state) => {
      state.template = initialState.template;
    },
    setIsDisclaimerModalActive: (state, action) => {
      state.isDisclaimerModalActive = action.payload;
    },
    setTimelineType: (state: any, action: any) => {
      state.timelineType = action.payload;
    },
    setForecastTimelineType: (state: any, action: any) => {
      state.forecastTimelineType = action.payload;
    },
    setIsGraphLoading: (state, action: any) => {
      state.isGraphLoading = {
        ...state.isGraphLoading,
        [action.payload.key]: action.payload.status,
      };
    },
    setProjectSettings: (state, action: any) => {
      state.projectSettings = action.payload;
    },
    setIsReportLoading: (state, action: PayloadAction<boolean>) => {
      state.isReportLoading = action.payload;
    },
    setIsReportToggleEnabled: (state, action) => {
      state.isReportToggleEnabled = action.payload;
    },
    setIsEditedVersion: (state, action) => {
      state.isEditedVersion = action.payload;
    },
    setIsFavorite: (state, action) => {
      state.isFavorite = action.payload;
    },
    setFavoriteReports: (state, action) => {
      state.favoriteReports = action.payload;
    },
    setSharedReports: (state, action) => {
      state.sharedReports = action.payload;
    },
    setIsGoingBack: (state, action) => {
      state.isGoingBack = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchReportData.fulfilled, (state, action) => {
      if (action.payload) {
        state.suburb = {
          postCode: action.payload.postCode,
          sscCode: action.payload.sscCode,
          state: action.payload.state,
          suburbCode: action.payload.suburbCode,
          suburbName: action.payload.suburbName,
        };

        state.reportHtml[state.template] = action.payload.reportHtml;

        if (action.payload.pageIndex && action.payload.pageIndex > 1) {
          state.reportData[state.template].projects =
            action.payload.reportData.projects;
        } else {
          state.reportData[state.template] = action.payload.reportData;
        }

        state.isFavorite = action.payload.isFavorite;
      } else {
        state.suburb = undefined;
        state.reportHtml[state.template] = undefined;
        state.reportData[state.template] = undefined;
        state.isFavorite = false;
      }
    });

    builder.addCase(fetchSharedReportData.fulfilled, (state, action) => {
      if (action.payload) {
        state.suburb = {
          postCode: action.payload.postCode,
          sscCode: action.payload.sscCode,
          state: action.payload.state,
          suburbCode: action.payload.suburbCode,
          suburbName: action.payload.suburbName,
        };

        state.reportHtml[state.template] = action.payload.reportHtml;
        state.reportData[state.template] = action.payload.reportData;
      } else {
        state.suburb = undefined;
        state.reportHtml[state.template] = undefined;
        state.reportData[state.template] = undefined;
      }
    });

    builder.addCase(fetchGraphsData.fulfilled, (state, action) => {
      if (action.payload) {
        state.reportData[state.template].allGraphs = action.payload.allGraphs;
      } else {
        state.reportData[state.template].allGraphs = [];
      }
    });

    builder.addCase(fetchGraphData.fulfilled, (state, action) => {
      if (action.payload) {
        const foundIndex = state.reportData[state.template].allGraphs.findIndex(
          (g: any) => g.graphType === action.payload.graphType,
        );

        if (foundIndex !== -1) {
          state.reportData[state.template].allGraphs[foundIndex] = {
            graphType: action.payload.graphType,
            graphs: action.payload.graphs,
          };
        }
      }
    });

    builder.addCase(updateReportHtml.fulfilled, (state, action) => {
      if (action.payload?.reportHtml) {
        // Reset the other tabs since the toggle has changed in this routine
        state.reportData = {
          [state.template]: state.reportData[state.template],
        };
        state.reportHtml = {
          [state.template]: action.payload.reportHtml,
        };
      }
    });
  },
});

export const {
  setSuburb,
  resetSuburb,
  resetReport,
  setTemplate,
  resetTemplate,
  setIsDisclaimerModalActive,
  setTimelineType,
  setForecastTimelineType,
  setIsGraphLoading,
  setProjectSettings,
  setIsReportLoading,
  setIsReportToggleEnabled,
  setIsEditedVersion,
  setIsFavorite,
  setFavoriteReports,
  setSharedReports,
  setIsGoingBack,
} = aiReportSlice.actions;

export default aiReportSlice.reducer;
