import * as Yup from 'yup';

import { passwordSchema } from '../../../foundation/utils/getValidationSchemas';

const requiredMessage = `This field is required`;

export const loginFormValidationSchema = (isStrongPassword?: boolean) => {
  const fields: any = {
    email: Yup.string()
      .email('Please enter a valid email.')
      .required(requiredMessage)
      .nullable(),
    passCode: passwordSchema(undefined, false, isStrongPassword),
  };

  return Yup.object().shape(fields);
};
