// import './styles/antd.style.less';
import './styles/styles.scss';

import { ConfigProvider } from 'antd';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import ReportAccess from './features/ai_report/report_access/ReportAccess';
import DeleteAccount from './features/authentication/delete_account/DeleteAccount';
import Login from './features/authentication/login/Login';
import ResetPassword from './features/authentication/reset_password/ResetPassword';
import Signup from './features/authentication/signup/Signup';
import AgencyLeadAccess from './features/property_strategy/agency_lead_access/AgencyLeadAccess';
import AgencyLeadAutoSignup from './features/property_strategy/agency_lead_auto_signup/AgencyLeadAutoSignup';
import AutoSignupSuccess from './features/property_strategy/agency_lead_auto_signup/auto_signup_success/AutoSignupSuccess';
import PublicRoute from './features/startup/public_route/PublicRoute';
import Startup from './features/startup/Startup';
import PrivacyPolicy from './features/static_content/privacy_policy/PrivacyPolicy';
import TermsConditions from './features/static_content/terms_conditions/TermsConditions';
import NotFound from './foundation/components/not_found/NotFound';
import { injectStore } from './foundation/config/axios';
import { useClientIP } from './foundation/cutom_hooks/useClientIP';
import env_constants from './internals/env/env_constants.json';
import reportWebVitals from './reportWebVitals';
import { store } from './store';

injectStore(store);

const Root = () => {
  sessionStorage.setItem(
    'maze-allow-embed',
    env_constants.ALLOW_MAZE_SNIPPET_EMBED.toString(),
  );
  /**
   * We need client ip on almost every route public and private both. That's why fetching it here.
   */
  useClientIP();

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#00b2a3',
        },
      }}
    >
      <Switch>
        <PublicRoute path="/login" strict exact component={Login} />
        <PublicRoute path="/invite" strict exact component={Signup} />
        <PublicRoute
          path="/reset-password"
          strict
          exact
          component={ResetPassword}
        />
        <PublicRoute
          path="/suburb-scoring/nonclient/report"
          strict
          exact
          component={ReportAccess}
        />
        <PublicRoute
          path="/agency-lead"
          strict
          exact
          component={AgencyLeadAccess}
        />
        <PublicRoute
          path="/invite-lead"
          strict
          exact
          component={AgencyLeadAutoSignup}
        />
        <PublicRoute
          path="/invite-lead-success"
          strict
          exact
          component={AutoSignupSuccess}
        />
        <PublicRoute
          path="/delete-profile/:deleteCode"
          strict
          exact
          component={DeleteAccount}
        />
        <Route path="/privacy-policy" strict exact component={PrivacyPolicy} />
        <Route
          path="/terms-and-conditions"
          strict
          exact
          component={TermsConditions}
        />
        <Startup />
        <PublicRoute path="*" component={NotFound} />
      </Switch>
    </ConfigProvider>
  );
};

const container = document.getElementById('root') as HTMLElement;

const root = ReactDOM.createRoot(container);

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <Root />
    </BrowserRouter>
  </Provider>,
);

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
