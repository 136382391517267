import React, { useEffect, useState } from 'react';

import Mail from '../../../foundation/assets/svgs/Mail.svg';

const AuthenticationFooter = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);

  return (
    <div className="client-dash-login__footer-container">
      <div>© Property Pathfinder {currentYear}</div>
      <div>
        <Mail style={{ marginRight: 10, display: 'inline-block' }} />
        <a
          className="client-dash-login__footer-email-link"
          href="mailto:help@propertypathfinder.io"
        >
          help@propertypathfinder.io
        </a>
      </div>
    </div>
  );
};

export default AuthenticationFooter;
