import { Alert, Col, Popover, Row } from 'antd';
// eslint-disable-next-line
import { Chart as ChartJS, defaults } from 'chart.js';
import { map as pluck } from 'lodash';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import Info from '../../../../foundation/assets/svgs/Info';
import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import DashboardCard from '../../dashboard_card/DashboardCard';
import {
  selectReportDataByCurrentTemplate,
  selectSuburb,
  selectTemplate,
} from '../../redux/selectors';

const Population = () => {
  const population = useSelector(selectReportDataByCurrentTemplate);
  const suburb = useSelector(selectSuburb);
  const template = useSelector(selectTemplate);

  const { isMobileViewport } = useViewport();

  const renderPeople = () => {
    const { previousCensusYear, currentCensusYear, descriptor } = population;
    const people = population.people ? population.people : {};

    return (
      <DashboardCard
        title={`${currentCensusYear} ${suburb.suburbName} Population`}
        descriptor={
          people.displayLgaForecast
            ? descriptor.censusPopulation
            : descriptor.dotIdPopulation
        }
        isDataAvailable={!!population.people}
        value={people.currentPopulation}
        valueType={0}
        changeValue={people.percentageChange}
        changeIndicator={people.currentChange}
        censusYear={previousCensusYear}
        censusSame={currentCensusYear === previousCensusYear}
        censusPreviousValue={people.previousPopulation}
        censusNoChange={people.currentPopulation === people.previousPopulation}
        source={people.displayLgaForecast ? null : people.dataSource}
      ></DashboardCard>
    );
  };

  const renderPeopleForecast = () => {
    const { descriptor } = population;
    const people = population.people ? population.people : {};
    const forecast = people.forecast ? people.forecast : {};

    const title = `${forecast.forecastYear} ${
      people.displayLgaForecast ? 'LGA ' : ' '
    }Population Forecast`;

    return (
      <DashboardCard
        title={title}
        descriptor={
          people.displayLgaForecast
            ? descriptor.lgaPopulationForecast
            : descriptor.suburbPopulationForecast
        }
        isDataAvailable={!!population.people && !!population.people.forecast}
        value={forecast.forecastPopulation}
        valueType={0}
        changeValue={forecast.forecastPercentageChange}
        changeIndicator={forecast.forecastChange}
        forecastYear={forecast.forecastYear}
        population={forecast.forecastPopulation}
        populationCurrentValue={forecast.forecastCurrentPopulation}
        populationNoChange={
          forecast.forecastCurrentPopulation === forecast.forecastPopulation
        }
        source={people.displayLgaForecast ? null : people.dataSource}
        populationForecastComparisonYear={population.currentCensusYear}
      ></DashboardCard>
    );
  };

  const renderUnemployment = () => {
    const { previousCensusYear, descriptor } = population;
    const unemployment = population.unemployment ? population.unemployment : {};

    return (
      <DashboardCard
        title="Unemployment"
        descriptor={descriptor.unemployment}
        isDataAvailable={!!population.unemployment}
        value={unemployment.unemploymentRate}
        valueType={0}
        changeValue={unemployment.percentageChange}
        changeIndicator={unemployment.change}
        stateCode={suburb.state}
        stateValue={unemployment.stateUnemploymentRate}
        stateValueType={1}
        censusYear={previousCensusYear}
      ></DashboardCard>
    );
  };

  const renderMedianAge = () => {
    const { previousCensusYear, descriptor } = population;
    const medianAge = population.medianAge ? population.medianAge : {};

    return (
      <DashboardCard
        title="Median Age"
        descriptor={descriptor.medianAge}
        isDataAvailable={!!population.unemployment}
        value={medianAge.medianAge}
        valueType={0}
        changeValue={medianAge.medianAgeChange}
        changeIndicator={medianAge.change}
        stateCode={suburb.state}
        stateValue={medianAge.stateMedianAge}
        stateValueType={0}
        censusYear={previousCensusYear}
        censusNoChange={
          medianAge.medianAge > 0 && medianAge.medianAgeChange === 0
        }
      ></DashboardCard>
    );
  };

  const renderMedianHouseholdIncome = () => {
    const { previousCensusYear, descriptor } = population;

    const medianHouseholdIncome = population.medianHouseholdIncome
      ? population.medianHouseholdIncome
      : {};

    return (
      <DashboardCard
        title="Median Weekly Household Income"
        descriptor={descriptor.medianWeeklyHouseholdIncome}
        isDataAvailable={!!population.medianHouseholdIncome}
        value={medianHouseholdIncome.householdIncome}
        valueType={2}
        changeValue={medianHouseholdIncome.percentageChange}
        changeIndicator={medianHouseholdIncome.change}
        stateCode={suburb.state}
        stateValue={medianHouseholdIncome.stateHouseholdIncome}
        stateValueType={2}
        censusYear={previousCensusYear}
      ></DashboardCard>
    );
  };

  const renderMainIndustries = () => {
    const { employment, otherIndustriesDetails } = population;

    if (!employment?.length) {
      return <Alert message="No data available" type="warning" showIcon />;
    }

    const data = {
      datasets: [
        {
          data: pluck(employment, 'percentageShare').map((p) => {
            return parseFloat(p);
          }),
        },
      ],
      labels: pluck(employment, 'industry'),
    };

    const options = {
      cutout: '92%',
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              return `${context.label}: ${context.parsed}%`;
            },
          },
        },
      },
    };

    const highest = employment[0];

    const shade = (percentage: any, RGB: any) => {
      const RGBArray = RGB.split(',');

      const red = RGBArray[0];
      const green = RGBArray[1];
      const blue = RGBArray[2];

      const isDarken = percentage < 0;

      const tint = isDarken ? 0 : 255 * percentage;
      const step = isDarken ? 1 + percentage : 1 - percentage;

      return `rgb(${Math.round(
        parseInt(red.slice(4), 10) * step + tint,
      )}, ${Math.round(parseInt(green, 10) * step + tint)}, ${Math.round(
        parseInt(blue, 10) * step + tint,
      )})`;
    };

    const backgroundColors = Object.assign([], defaults.backgroundColor);

    for (let ctr = 0; ctr < employment.length; ctr++) {
      // @ts-ignore
      if (ctr === backgroundColors.length) {
        const newColor = shade(
          -0.15, // Shade percentage
          // @ts-ignore
          backgroundColors[ctr - defaults.backgroundColor.length],
        );

        // @ts-ignore
        backgroundColors.push(newColor);
      }
    }

    let popoverContent: any = null;

    if (otherIndustriesDetails?.length) {
      popoverContent = (
        <ul className="l-other-industries">
          {otherIndustriesDetails.map((e: any, i: number) => {
            return (
              <li className="l-other-industries__item" key={e.industry}>
                <span className="l-other-industries__color"></span>
                <span className="l-other-industries__key">{e.industry}</span>
                <span className="l-other-industries__value">
                  {e.percentageShare}
                </span>
              </li>
            );
          })}
        </ul>
      );
    }

    return (
      <Row gutter={[50, 0]} justify="center">
        <Col span={24}>
          <div className="l-main-industries">
            <div className="l-main-industries__highest">
              <h5 className="l-main-industries__value">
                {highest.percentageShare}
              </h5>
              <span className="l-main-industries__industry">
                {highest.industry}
              </span>
            </div>
            <div className="l-main-industries__chart">
              <Doughnut data={data} options={options} />
            </div>
          </div>
        </Col>
        <Col span={24}>
          <ul className="l-legend">
            {employment.map((e: any, i: number) => {
              return (
                <li className="l-legend__item" key={e.industry}>
                  <span
                    className="l-legend__color"
                    // @ts-ignore
                    style={{ background: backgroundColors[i] }}
                  ></span>
                  <span className="l-legend__key">{e.industry}</span>
                  <span className="l-legend__value">
                    {e.percentageShare}
                    {e.industry === 'Other Industries' && (
                      <Popover
                        content={popoverContent}
                        title="Other Industries Details"
                        trigger="click"
                        overlayClassName="no-override"
                      >
                        <span className="l-legend__info">
                          <Info />
                        </span>
                      </Popover>
                    )}
                  </span>
                </li>
              );
            })}
          </ul>
        </Col>
      </Row>
    );
  };

  if (template !== 'population') {
    return <></>;
  }

  if (suburb && !population) {
    return (
      <Col span={24}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Alert
              message="No data available"
              description={<></>}
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      </Col>
    );
  }

  let infoPopoverContent: any = null;

  if (population?.descriptor?.mainIndustryEmployment) {
    infoPopoverContent = (
      <p className="l-descriptor">
        {population.descriptor.mainIndustryEmployment}
      </p>
    );
  }

  return (
    <div className="about">
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col span={isMobileViewport ? 24 : 12}>
              <Row gutter={[0, 15]} style={{ height: '100%' }}>
                <Col span={24}>
                  <div className="l-dashboard-card">
                    <div className="l-dashboard-card__body l-dashboard-card__body--justify-start">
                      <h5 className="l-dashboard-card__title">
                        Main Industries Of Employment
                        {population?.descriptor?.mainIndustryEmployment && (
                          <Popover
                            content={infoPopoverContent}
                            title={null}
                            placement="top"
                            trigger="click"
                            overlayClassName="no-override"
                          >
                            <span className="l-descriptor-trigger">
                              <Info />
                            </span>
                          </Popover>
                        )}
                      </h5>
                      {renderMainIndustries()}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col span={isMobileViewport ? 24 : 12}>
              <Row gutter={[0, 15]}>
                <Col span={24}>{renderPeople()}</Col>
                <Col span={24}>{renderPeopleForecast()}</Col>
                <Col span={24}>{renderMedianAge()}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col span={isMobileViewport ? 24 : 12}>
              <Row gutter={[0, 15]} style={{ height: '100%' }}>
                <Col span={24}>{renderUnemployment()}</Col>
              </Row>
            </Col>
            <Col span={isMobileViewport ? 24 : 12}>
              <Row gutter={[0, 15]} style={{ height: '100%' }}>
                <Col span={24}>{renderMedianHouseholdIncome()}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Population;
