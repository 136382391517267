import { MailOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, Modal } from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { selectUser } from '../../authentication/redux/selectors';
import { deleteAccountRequest } from '../redux/async_thunks';

const DeleteAccountModal = ({ closeHandler, logoutHandler }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [email, setEmail] = useState<string>();
  const [isDeleteData, setIsDeleteData] = useState(false);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const deleteAccount = async () => {
    try {
      setIsLoading(true);

      if (user) {
        const data = {
          userId: user.user_id,
          email: user.email,
          deleteData: isDeleteData,
        };

        await dispatch(
          deleteAccountRequest({
            data,
            token: user.token,
          }),
          // @ts-ignore
        ).unwrap();

        sessionStorage.setItem('delete_account', 'true');
        logoutHandler();
      }
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const validateEmail = (email: string) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValid = emailRegex.test(email);

    setIsEmailValid(isValid);
  };

  const handleChange = (e: any) => {
    setIsDeleteData(e.target.checked);
  };

  const errorMessageStyle = {
    color: '#eb5757',
    marginTop: '-5px',
    marginBottom: '10px',
  };

  return (
    <Modal
      title={'Confirm Account Deletion'}
      open={true}
      footer={false}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      width={480}
      closable={!isLoading}
    >
      {isLoading && <FullPageLoader classNames="l-filter-modal__page-loader" />}
      <div className="client-view l-report__client-view">
        <div style={{ width: '100%' }}>
          <p style={{ marginBottom: '15px' }}>
            Once you select proceed, you&apos;ll be logged out of your current
            session, and an email contianing the next steps to delete your
            account will be sent to you.
          </p>
          <p style={{ marginBottom: '15px' }}>
            To proceed, please enter your account email address:
          </p>
          <div>
            <Input
              value={email}
              className="client-view__search-input"
              prefix={<MailOutlined />}
              placeholder={`Email Address`}
              onChange={(e) => {
                setEmail(e.target.value);
                validateEmail(e.target.value);
              }}
            />
            {!!email && !isEmailValid && (
              <div style={errorMessageStyle}>Email address is invalid</div>
            )}
            {!!email && isEmailValid && email !== user?.email && (
              <div style={errorMessageStyle}>Email address is incorrect</div>
            )}
          </div>
          <Checkbox onChange={handleChange}>
            Do you also want to unsubscribe from marketing emails?
          </Checkbox>
        </div>
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            className="l-report__client-view-button"
            onClick={() => {
              closeHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            className="l-report__client-view-button"
            type="primary"
            htmlType="submit"
            disabled={isLoading || !email || !isEmailValid}
            loading={isLoading}
            onClick={() => {
              deleteAccount();
            }}
          >
            Proceed
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteAccountModal;
