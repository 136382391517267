import {
  Avatar,
  Button,
  Divider,
  Input,
  Modal,
  notification,
  Upload,
} from 'antd';
import { Formik } from 'formik';
import { compressAccurately } from 'image-conversion';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../../foundation/assets/svgs/Close';
import UploadIcon from '../../../../foundation/assets/svgs/Upload';
import FormSelectField from '../../../../foundation/components/form_select_field/FormSelectField';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { KeyValue } from '../../../../foundation/types';
import getBase64Image from '../../../../foundation/utils/getBase64Image';
import envConstant from '../../../../internals/env/env_constants.json';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { fetchPropertyAddresses } from '../../../property/redux/async_thunks';
import { createAgency, updateAgency } from '../../redux/async_thunks';
import { selectAgencies, selectAgency } from '../../redux/selectors';
import { setAgencies, setAgency } from '../../redux/slice';
import { agencyFormValidationSchema } from '../../validation_schemas/agency_form_validation';
type CreateAgencyModalProps = {
  closeModal: () => void;
  setInviteModalVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};
const AgencyModal = ({
  closeModal,
  setInviteModalVisibility,
}: CreateAgencyModalProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [fileList, setFileList] = useState<any>([]);

  const [imageUrl, setImageURL] = useState<string | undefined>(undefined);

  const [formType, setFormType] = useState<'create' | 'update'>('create');

  const agency = useSelector(selectAgency);

  const agencies = useSelector(selectAgencies);

  const user = useSelector(selectUser);

  // @ts-ignore
  const fetchAddresses = (name: string): Promise<KeyValue[]> => {
    if (user) {
      return fetchPropertyAddresses(user?.token, name, user.user_id)
        .then((res) => {
          const addresses = res.addresses;
          const xyz: KeyValue[] = addresses
            ? addresses.map((item) => ({
                label: item,
                value: item,
              }))
            : [];
          return xyz;
        })
        .catch((error) => {
          console.log(error);
          return [];
        });
    }
  };

  useEffect(() => {
    if (agency?.agencyId) {
      setFormType('update');
    }

    if (agency?.agencyPicture) {
      setImageURL(agency.agencyPicture);
    }
  }, [agency]);

  const initialFormValues = useMemo(() => {
    if (agency?.agencyId) {
      return {
        agencyName: agency.agencyName,
        agencyAddress: agency.agencyAddress,
        picture: agency.agencyPicture,
      };
    } else {
      return {
        agencyName: undefined,
        agencyAddress: undefined,
        picture: undefined,
      };
    }
  }, [agency]);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: formType === 'create' ? 'Create Agency' : 'Agency Profile',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
  };

  const handleAgencyCreate = async (values: any) => {
    try {
      const data: any = {
        agencyName: values.agencyName,
        userId: user?.user_id,
        agencyAddress: values.agencyAddress.trim(),
        enterpriseId: user?.enterprise_id,
      };

      if (fileList[0]) {
        data.picture = fileList[0];
      }

      if (user) {
        let updatedList: any[] = [];

        if (agencies) {
          updatedList = [...agencies];
        }

        const createdAgency: any = await dispatch(
          createAgency({
            token: user?.token,
            data: data,
            picture: data.picture,
          }),
        ).unwrap();

        updatedList.push(createdAgency);

        dispatch(setAgencies(updatedList));

        if (formType === 'create') {
          // Set agency data for the user invite modal
          dispatch(setAgency(createdAgency));
        }

        setInviteModalVisibility(true);
      }

      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleAgencyUpdate = async (values: any) => {
    try {
      // Create data
      const data: any = {
        agencyName: values.agencyName,
        agencyId: agency?.agencyId,
        userId: user?.user_id,
        agencyAddress: values.agencyAddress.trim(),
      };

      // Handle picture logic.
      if (agency?.agencyPicture && !fileList[0] && !imageUrl) {
        data.removeAgencyPicture = true;
      } else if (fileList[0]) {
        data.picture = fileList[0];
      }

      if (user) {
        let updatedList: any[] = [];

        // API call
        const updatedAgency: any = await dispatch(
          updateAgency({
            token: user?.token,
            data: data,
            picture: data.picture,
          }),
        ).unwrap();

        // Set agency in redux
        dispatch(setAgency(updatedAgency));

        // Update agencies list in redux
        if (agencies) {
          updatedList = agencies?.map((item) => {
            if (item.agencyId === updatedAgency?.agencyId) {
              return { ...updatedAgency };
            }
            return item;
          });

          dispatch(setAgencies(updatedList));
        }

        notification.success({
          message: 'Success!',
          description: 'Agency profile updated successfully.',
        });
      }

      // Close this modal.
      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    if (formType === 'create') {
      handleAgencyCreate(values);
    } else {
      handleAgencyUpdate(values);
    }

    setIsLoading(true);
  };

  const beforeUpload = async (file: any) => {
    const minSizeKB = envConstant.MIN_PICTURE_FILESIZE_UPLOAD_IN_KB;
    const maxSizeKB = envConstant.MAX_PICTURE_FILESIZE_UPLOAD_IN_KB;

    let maxSizeMessage: string;
    let isCompressionFailed = false;

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    const fileSizeKB = file.size / 1024;
    const isFileSizeAllowed =
      fileSizeKB >= minSizeKB && fileSizeKB <= maxSizeKB;

    if (!isJpgOrPng) {
      notification.warning({
        message: 'You can only upload a JPG/PNG file!',
      });
    }

    if (fileSizeKB < minSizeKB) {
      notification.warning({
        message: `Image should be at least ${minSizeKB}KB!`,
      });
    }

    if (maxSizeKB < 1024) {
      maxSizeMessage = `${maxSizeKB}KB`;
    } else {
      const maxSizeMB = maxSizeKB / 1024;

      maxSizeMessage = `${maxSizeMB}MB`;
    }

    if (fileSizeKB > maxSizeKB) {
      notification.warning({
        message: `Image should be no more than ${maxSizeMessage}!`,
      });
    }

    if (isJpgOrPng && isFileSizeAllowed) {
      try {
        const compressedBlob = await compressAccurately(file, 900); // Down to 900KB
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });

        file = compressedFile;

        setFileList([...fileList, file]);
      } catch (error) {
        console.error('Image compression error:', error);
        notification.error({
          message: `Something went wrong. Please try again or try a different picture.`,
        });

        isCompressionFailed = true;
      }
    }

    return (
      (isJpgOrPng && isFileSizeAllowed && !isCompressionFailed) ||
      Upload.LIST_IGNORE
    );
  };

  const handleImageChange =
    (setFieldValue) =>
    async ({ file }) => {
      if (file.status === 'uploading') {
        return;
      }

      if (file.status === 'done') {
        const preview: any = await getBase64Image(file.originFileObj);
        setImageURL(preview);
        setFieldValue('picture', file.originFileObj);
      }
    };

  const removePhotoClickHandler = (setFieldValue) => (e) => {
    e.preventDefault();
    setImageURL(undefined);
    setFileList([]);
    setFieldValue('picture', undefined);
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <Modal {...modalOpts} className="h-agency-modal c-form-modal">
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleFormSubmit}
        validationSchema={agencyFormValidationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="c-form-field">
                <div className="c-form-field__label">Agency name:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="agencyName"
                    onChange={handleChange}
                    type="text"
                    value={values.agencyName}
                    onBlur={handleBlur}
                  />
                  <div className="c-form-field__error">
                    {errors.agencyName ? errors.agencyName : undefined}
                  </div>
                </div>
              </div>
              <FormSelectField
                label="Agency address"
                onChange={(v: string) => setFieldValue('agencyAddress', v)}
                value={values.agencyAddress}
                error={errors.agencyAddress}
                isDebounce
                fetchOptions={fetchAddresses}
              />
              <Divider />
              <div className="h-agency-modal__picture-section">
                {imageUrl && (
                  <>
                    <Avatar
                      className="h-agency-modal__image-avatar"
                      src={imageUrl}
                      alt="avatar"
                      shape="square"
                    />
                    <Button
                      type="text"
                      onClick={removePhotoClickHandler(setFieldValue)}
                    >
                      Remove or Edit
                    </Button>
                  </>
                )}
                {!imageUrl && (
                  <div className="h-agency-modal__picture-controllers">
                    <Upload
                      name="avatar"
                      //   listType="picture-circle"
                      className="h-agency-modal__change-photo-btn"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleImageChange(setFieldValue)}
                      customRequest={dummyRequest}
                      accept=".png,.jpg,.jpeg"
                      maxCount={1}
                      multiple={false}
                    >
                      <div className="h-agency-modal__picture-uploader">
                        <div>
                          <UploadIcon />
                        </div>
                        <div className="h-agency-modal__picture-uploader-text">
                          Upload Logo
                        </div>
                        <Button
                          type="primary"
                          className="h-agency-modal__picture-uploader-btn"
                        >
                          Browse to upload
                        </Button>
                      </div>
                    </Upload>
                  </div>
                )}
              </div>

              <div className="c-form__btn-wrapper h-agency-modal__form-btn-wrapper">
                <Button
                  className="h-agency-modal__form-cancel-btn"
                  disabled={isLoading}
                  onClick={handleModalClose}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="h-agency-modal__form-submit-btn"
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Save & Continue
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AgencyModal;
