import { Chart as ChartJS, registerables } from 'chart.js';
import moment from 'moment';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

import { numberFormatter } from '../../utils/helperFunctions';

type Props = {
  yAxesLabel: string;
  xAxesLabel: string;
  y1AxesLabel?: string;
  data: any;
  graphTitle: string;
  areaAboveFill?: string;
  displayLegend?: boolean;
  xAxesType?: string;
  xAxesTimeUnit?: string;
};
/**
 * NOTE: This graph component is not totally dynamic because mostly we need to plot
 * amount against time in the Portfolio Planner. In the future if we want to plot other type of values
 * we can make it dynamic.
 */
const LineGraph = ({
  yAxesLabel,
  xAxesLabel,
  y1AxesLabel,
  data,
  graphTitle,
  areaAboveFill,
  displayLegend,
  xAxesType,
  xAxesTimeUnit,
}: Props) => {
  ChartJS.register(...registerables);

  const y1Axis = y1AxesLabel
    ? {
        y1: {
          title: {
            text: y1AxesLabel,
            display: true,
            padding: {
              bottom: 10,
            },
          },
          position: 'right',
          ticks: {
            callback: (value: any) => {
              return numberFormatter(value, '$');
            },
          },
        },
      }
    : {};

  const xAxisType = useMemo(() => {
    if (xAxesType === 'string') {
      return {};
    } else {
      return {
        type: xAxesType ? xAxesType : 'time',
        time: {
          unit: xAxesTimeUnit ? xAxesTimeUnit : 'month',
        },
      };
    }
  }, [xAxesType, xAxesTimeUnit]);
  /**
   * Graph settings
   */
  const options: any = {
    responsive: true,
    maintainAspectRatio: false,
    fill: {
      target: 'origin',
      below: 'rgba(255, 77, 79, 0.2)', // Area will be red above the origin
      above: areaAboveFill,
    },

    plugins: {
      legend: {
        position: 'top',
        display: displayLegend ? displayLegend : false,
      },
      title: {
        display: false,
        text: graphTitle,
      },
      tooltip: {
        callbacks: {
          title: (context: any) => {
            return `${context[0].dataset.label}\n${
              xAxesType !== 'string'
                ? moment(new Date(context[0].parsed.x)).format('MMMM DD, YYYY')
                : context[0].raw.x
            }`;
          },
          label: (context: any) => {
            const value = context.parsed.y;
            return numberFormatter(value, '$');
          },
        },
      },
    },
    scales: {
      xAxes: {
        title: {
          text: xAxesLabel,
          display: true,
          padding: {
            bottom: 10,
          },
        },
        ...xAxisType,
      },
      y: {
        title: {
          text: yAxesLabel,
          display: true,
          padding: {
            bottom: 10,
          },
        },
        ticks: {
          callback: (value: any) => {
            return numberFormatter(value, '$');
          },
        },
      },
      ...y1Axis,
    },
  };

  return <Line options={options} data={data} />;
};

export default LineGraph;
