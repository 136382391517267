import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const profileFormValidationSchema = () => {
  const fields: any = {
    firstName: Yup.string().required(requiredMessage).nullable(),
    lastName: Yup.string().required(requiredMessage).nullable(),
  };

  return Yup.object().shape(fields);
};
