import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const inviteFormValidationSchema = () => {
  const fields: any = {
    emails: Yup.array()
      .required(requiredMessage)
      .of(
        Yup.string().required(requiredMessage).email('Must be a valid email'),
      ),
  };

  return Yup.object().shape(fields);
};
