import { Buffer } from 'buffer';
import CryptoES from 'crypto-es';

import envConstant from '../../internals/env/env_constants.json';
import { getItemFromStorage } from './storageHandler';

export const getPassKeyAndIV = () => {
  const key = Buffer.from(envConstant.DEFAULT_PASS_KEY, 'base64').toString(
    'utf8',
  );

  const iv = Buffer.from(envConstant.DEFAULT_PASS_IV, 'base64').toString(
    'utf8',
  );

  return [key, iv];
};

export const decryptData = (data: any) => {
  const passKeyIV = getPassKeyAndIV();
  const decrypted = CryptoES.AES.decrypt(
    data,
    CryptoES.enc.Utf8.parse(passKeyIV[0]),
    {
      iv: CryptoES.enc.Utf8.parse(passKeyIV[1]),
      mode: CryptoES.mode.CBC,
    },
  );

  return JSON.parse(decrypted.toString(CryptoES.enc.Utf8));
};

export const encryptData = (data: any) => {
  const passKeyIV = getPassKeyAndIV();
  const encrypted = CryptoES.AES.encrypt(
    typeof data === 'string' ? data : JSON.stringify(data),
    CryptoES.enc.Utf8.parse(passKeyIV[0]),
    {
      iv: CryptoES.enc.Utf8.parse(passKeyIV[1]),
      mode: CryptoES.mode.CBC,
    },
  );

  return encrypted.toString();
};

export const getClientId = (clientId?: string | undefined) => {
  return clientId || getSessionClientID();
};

export const getSessionClientID = () => {
  const userAgent = window.navigator.userAgent;
  const clientIp = getItemFromStorage('client_ip');

  return encryptData(`${userAgent}${clientIp ? '/' + clientIp : ''}`);
};

export const decryptResponseMessage = (responseData: string, key: string) => {
  if (responseData) {
    const decryptedRes = decryptData(responseData);
    if (decryptedRes && (decryptedRes[key] || decryptedRes.responseMessage)) {
      return decryptedRes[key] || decryptedRes.responseMessage;
    }
    return '';
  }
};
