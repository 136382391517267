import React from 'react';

import Logo from '../../../foundation/assets/svgs/Logo.svg';

const PrivacyPolicy = () => {
  return (
    <div className="l-static-content">
      <div className="l-static-content__header">
        <a
          href="/login"
          aria-label="Go to login page"
          className="l-static-content__logo-wrapper"
        >
          <Logo aria-hidden="true" />
        </a>
      </div>
      <div className="l-static-content__body">
        <div className="l-static-content__copy">
          <h1 className="l-static-content__heading">
            DASHDOT PTY LTD &ndash; PRIVACY POLICY
          </h1>
          <p className="l-static-content__paragraph">
            Dashdot Pty Ltd ABN 83 631 524 797 (<strong>we</strong>,{' '}
            <strong>us</strong> or <strong>our</strong>), understands that
            protecting your personal information is important. This Privacy
            Policy sets out our commitment to protecting the privacy of personal
            information provided to us, or otherwise collected by us when
            providing:
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              Access and use of our website at{' '}
              <a
                href="https://dashdot.com.au/"
                target="_blank"
                rel="noreferrer"
              >
                https://dashdot.com.au/
              </a>{' '}
              (<strong>Website</strong>); and/ or
            </li>
            <li className="l-static-content__list-item">
              Access and use of our other domains and subdomains which include
              but are not limited to the following websites (Collectively known
              as <strong>Subsidiary Websites</strong>):
              <ul className="l-static-content__sub-list">
                <li className="l-static-content__sub-list-item">
                  dashdot.info
                </li>
                <li className="l-static-content__sub-list-item">
                  dashdotreport.com
                </li>
                <li className="l-static-content__sub-list-item">
                  dashdotguide.com
                </li>
                <li className="l-static-content__sub-list-item">
                  go.dashdot.com.au
                </li>
                <li className="l-static-content__sub-list-item">
                  dashdotwebinar.com
                </li>
                <li className="l-static-content__sub-list-item">
                  renegadespropertybook.com
                </li>
                <li className="l-static-content__sub-list-item">
                  theinvestorlab.com.au
                </li>
                <li className="l-static-content__sub-list-item">
                  membership.theinvestorlab.com.au
                </li>
                <li className="l-static-content__sub-list-item">
                  propertypathfinder.io
                </li>
              </ul>
            </li>
            <li className="l-static-content__list-item">
              Access and use of the Property Pathfinder website application
              platform (propertypathfinder.io) (<strong>Platform</strong>).
              collectively known as <strong>Services</strong> or when otherwise
              interacting with you.
            </li>
          </ul>
          <h2 className="l-static-content__sub-heading">
            The information we collect
          </h2>
          <p className="l-static-content__paragraph">
            <strong>Personal information</strong>: is information or an opinion,
            whether true or not and whether recorded in a material form or not,
            about an individual who is identified or reasonably identifiable.
          </p>
          <p className="l-static-content__paragraph">
            The types of personal information we may collect about you include:
          </p>
          <p className="l-static-content__paragraph">
            <u>When you visit our Website and/or Subsidiary Websites:</u>
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              your browser session and geo-location data, device and network
              information, statistics on page views and sessions, acquisition
              sources, search queries and/or browsing behaviour;
            </li>
            <li className="l-static-content__list-item">your name;</li>
            <li className="l-static-content__list-item">
              your demographic information, including postcode;
            </li>
            <li className="l-static-content__list-item">
              your contact details, including email address, street address
              and/or telephone number;
            </li>
          </ul>
          <p className="l-static-content__paragraph">
            <u>As a user of our Platform:</u>
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              your contact details, including email address and/or telephone
              number;
            </li>
            <li className="l-static-content__list-item">
              your demographic information, including postcode;
            </li>
            <li className="l-static-content__list-item">
              your username and password; and
            </li>
            <li className="l-static-content__list-item">
              your browser session and geo-location data, device and network
              information, statistics on page views and sessions, acquisition
              sources, search queries and/or browsing behaviour.
            </li>
          </ul>
          <p className="l-static-content__paragraph">
            <strong>Sensitive information</strong>: is a subset of personal
            information that is given a higher level of protection. Sensitive
            information means information relating to your racial or ethnic
            origin, political opinions, religion, trade union or other
            professional associations or memberships, philosophical beliefs,
            sexual orientation or practices, criminal records, health
            information or biometric information.
          </p>
          <p className="l-static-content__paragraph">
            <strong>Sensitive information</strong>: We do not actively request
            sensitive information about you. If at any time we need to collect
            sensitive information about you, unless otherwise permitted by law,
            we will first obtain your consent and we will only use it as
            required or authorised by law.
          </p>
          <h2 className="l-static-content__sub-heading">
            How we collect personal information
          </h2>
          <p className="l-static-content__paragraph">
            We collect personal information in a variety of ways, including:
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              <strong>Directly</strong>: We collect personal information which
              you directly provide to us, including:
              <ul className="l-static-content__sub-list">
                <li className="l-static-content__sub-list-item">
                  when you register for an account (with either or all of the
                  Website, Subsidiary Websites or Platform);
                </li>
                <li className="l-static-content__sub-list-item">
                  through the &lsquo;book a call&rsquo; form on our Website
                  and/or Subsidiary Website; or
                </li>
                <li className="l-static-content__sub-list-item">
                  when you request our assistance via email; or
                </li>
                <li className="l-static-content__sub-list-item">
                  when you request our assistance over the telephone.
                </li>
              </ul>
            </li>
            <li className="l-static-content__list-item">
              <strong>Indirectly</strong>: We may collect personal information
              which you indirectly provide to us while interacting with us, such
              as when you use our Services, in emails, over the telephone and in
              your online enquiries.
            </li>
            <li className="l-static-content__list-item">
              <strong>From third parties</strong>: We collect personal
              information from third parties, such as details of your use of our
              Website from our analytics and cookie providers and marketing
              providers. See the “Cookies” section below for more detail on the
              use of cookies.
            </li>
          </ul>
          <h2 className="l-static-content__sub-heading">
            Why we collect, hold, use and disclose personal information
          </h2>
          <p className="l-static-content__paragraph">
            <u>As a user of our Platform:</u>
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              to enable you to access and use our Platform, including to provide
              you with a login;
            </li>
            <li className="l-static-content__list-item">
              to contact and communicate with you about our Services, including
              in response to any support requests you lodge with us or other
              enquiries you make with us;
            </li>
            <li className="l-static-content__list-item">
              for internal record keeping, administrative, invoicing and billing
              purposes;
            </li>
            <li className="l-static-content__list-item">
              for analytics, market research and business development, including
              to operate and improve our Services and associated applications;{' '}
            </li>
            <li className="l-static-content__list-item">
              to comply with our legal obligations and resolve any disputes that
              we may have; and/or
            </li>
            <li className="l-static-content__list-item">
              if otherwise required or authorised by law.
            </li>
          </ul>
          <p className="l-static-content__paragraph">
            <u>When you visit our Website and/or Subsidiary Websites:</u>
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              to provide our Website and/or Subsidiary Websites to you;
            </li>
            <li className="l-static-content__list-item">
              to contact and communicate with you about any enquiries you make
              with us via our Website and/or Subsidiary Websites;
            </li>
            <li className="l-static-content__list-item">
              to comply with our legal obligations and resolve any disputes that
              we may have; and/or
            </li>
            <li className="l-static-content__list-item">
              if otherwise required or authorised by law.
            </li>
          </ul>
          <p className="l-static-content__paragraph">
            <strong>Personal information</strong>: We may collect, hold, use and
            disclose personal information for the following purposes:
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              to enable you to access and use our Services, including to provide
              you with a login;{' '}
            </li>
            <li className="l-static-content__list-item">
              to enable you to access and use our Platform;
            </li>
            <li className="l-static-content__list-item">
              to contact and communicate with you about our Services, including
              in response to any support requests you lodge with us or other
              enquiries you make with us;
            </li>
            <li className="l-static-content__list-item">
              for internal record keeping, administrative, invoicing and billing
              purposes;
            </li>
            <li className="l-static-content__list-item">
              for analytics, market research and business development, including
              to operate and improve our Services;
            </li>
            <li className="l-static-content__list-item">
              for advertising and marketing, including to send you promotional
              information about our products and services and other information
              that we consider may be of interest to you;
            </li>
            <li className="l-static-content__list-item">
              to comply with our legal obligations and resolve any disputes that
              we may have; and
            </li>
            <li className="l-static-content__list-item">
              if otherwise required or authorised by law.
            </li>
          </ul>
          <h2 className="l-static-content__sub-heading">
            Our disclosures of personal information to third parties
          </h2>
          <p className="l-static-content__paragraph">
            We may disclose personal information to:
          </p>
          <ul className="l-static-content__list">
            <li className="l-static-content__list-item">
              third party service providers for the purpose of enabling them to
              provide their services, to us, including (without limitation) IT
              service providers, data storage, web-hosting and server providers,
              email marketing providers, debt collectors, couriers, maintenance
              or problem-solving providers, marketing or advertising providers,
              professional advisors, and data providers;
            </li>
            <li className="l-static-content__list-item">
              our employees, contractors and/or related entities;
            </li>
            <li className="l-static-content__list-item">
              our existing or potential agents or business partners;
            </li>
            <li className="l-static-content__list-item">
              anyone to whom our business or assets (or any part of them) are,
              or may (in good faith) be, transferred;
            </li>
            <li className="l-static-content__list-item">
              courts, tribunals and regulatory authorities, in the event you
              fail to pay for goods or services we have provided to you;
            </li>
            <li className="l-static-content__list-item">
              courts, tribunals, regulatory authorities and law enforcement
              officers, as required or authorised by law, in connection with any
              actual or prospective legal proceedings, or in order to establish,
              exercise or defend our legal rights; and
            </li>
            <li className="l-static-content__list-item">
              third parties to collect and process data, such as Google
              Analytics (To find out how Google uses data when you use third
              party websites or applications, please see
              www.google.com/policies/privacy/partners/ or any other URL Google
              may use from time to time), Facebook Pixel or other relevant
              analytics businesses; and
            </li>
            <li className="l-static-content__list-item">
              any other third parties as required or permitted by law, such as
              where we receive a subpoena.
            </li>
          </ul>
          <p className="l-static-content__paragraph">
            <strong>Google Analytics</strong>: We have enabled Google Analytics
            Advertising Features including Remarketing Features, Advertising
            Reporting Features, Demographics and Interest Reports, Store Visits,
            Google Display Network Impression reporting etc. We and third-party
            vendors use first-party cookies (such as the Google Analytics
            cookie) or other first-party identifiers, and third-party cookies
            (such as Google advertising cookies) or other third-party
            identifiers together.
          </p>
          <p className="l-static-content__paragraph">
            You can opt-out of Google Analytics Advertising Features including
            using a Google Analytics Opt-out Browser add-on found{' '}
            <a
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . To opt-out of personalised ad delivery on the Google content
            network, please visit Google&apos;s Ads Preferences Manager{' '}
            <a
              href="http://www.google.com/ads/preferences"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            or if you wish to opt-out permanently even when all cookies are
            deleted from your browser you can install their plugin{' '}
            <a
              href="https://support.google.com/ads/answer/7395996"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>
            . To opt out of interest-based ads on mobile devices, please follow
            these instructions for your mobile device: On android open the
            Google Settings app on your device and select “ads” to control the
            settings. On iOS devices with iOS 6 and above use Apple&apos;s
            advertising identifier. To learn more about limiting ad tracking
            using this identifier, visit the settings menu on your device.
          </p>
          <h2 className="l-static-content__sub-heading">Overseas disclosure</h2>
          <p className="l-static-content__paragraph">
            While we store personal information in Australia, where we disclose
            your personal information to the third parties listed above, these
            third parties may store, transfer or access personal information
            outside of Australia, including but not limited to, the United
            States.
          </p>
          <p className="l-static-content__paragraph">
            Unless we seek and receive your consent to an overseas disclosure of
            your personal information, we will only disclose your personal
            information to countries with laws which protect your personal
            information in a way which is substantially similar to the
            Australian Privacy Principles and/or we will take such steps as are
            reasonable in the circumstances to require that overseas recipients
            protect your personal information in accordance with the Australian
            Privacy Principles.
          </p>
          <h2 className="l-static-content__sub-heading">
            Your rights and controlling your personal information
          </h2>
          <p className="l-static-content__paragraph">
            <strong>Your choice</strong>: Please read this Privacy Policy
            carefully. If you provide personal information to us, you understand
            we will collect, hold, use and disclose your personal information in
            accordance with this Privacy Policy. You do not have to provide
            personal information to us, however, if you do not, it may affect
            our ability to provide our Services to you and your use of our
            Services.
          </p>
          <p className="l-static-content__paragraph">
            <strong>Information from third parties</strong>: If we receive
            personal information about you from a third party, we will protect
            it as set out in this Privacy Policy. If you are a third party
            providing personal information about somebody else, you represent
            and warrant that you have such person&apos;s consent to provide the
            personal information to us.
          </p>
          <p className="l-static-content__paragraph">
            <strong>Restrict and unsubscribe</strong>: To object to processing
            for direct marketing/unsubscribe from our email database or opt-out
            of communications (including marketing communications), please
            contact us using the details below or opt-out using the opt-out
            facilities provided in the communication.
          </p>
          <p className="l-static-content__paragraph">
            <strong>Access</strong>: You may request access to the personal
            information that we hold about you. An administrative fee may be
            payable for the provision of such information. Please note, in some
            situations, we may be legally permitted to withhold access to your
            personal information. If we cannot provide access to your
            information, we will advise you as soon as reasonably possible and
            provide you with the reasons for our refusal and any mechanism
            available to complain about the refusal. If we can provide access to
            your information in another form that still meets your needs, then
            we will take reasonable steps to give you such access.
          </p>
          <p className="l-static-content__paragraph">
            <strong>Correction</strong>: If you believe that any information we
            hold about you is inaccurate, out of date, incomplete, irrelevant or
            misleading, please contact us using the details below. We will take
            reasonable steps to promptly correct any information found to be
            inaccurate, out of date, incomplete, irrelevant or misleading.
            Please note, in some situations, we may be legally permitted to not
            correct your personal information. If we cannot correct your
            information, we will advise you as soon as reasonably possible and
            provide you with the reasons for our refusal and any mechanism
            available to complain about the refusal.
          </p>
          <p className="l-static-content__paragraph">
            <strong>Complaints</strong>: If you wish to make a complaint, please
            contact us using the details below and provide us with full details
            of the complaint. We will promptly investigate your complaint and
            respond to you, in writing, setting out the outcome of our
            investigation and the steps we will take in response to your
            complaint. If you are not satisfied with our response, you also have
            the right to contact the Office of the Australian Information
            Commissioner.
          </p>
          <h2 className="l-static-content__sub-heading">
            Storage and security
          </h2>
          <p className="l-static-content__paragraph">
            We are committed to ensuring that the personal information we
            collect is secure. In order to prevent unauthorised access or
            disclosure, we have put in place suitable physical, electronic and
            managerial procedures, to safeguard and secure personal information
            and protect it from misuse, interference, loss and unauthorised
            access, modification and disclosure.
          </p>
          <p className="l-static-content__paragraph">
            While we are committed to security, we cannot guarantee the security
            of any information that is transmitted to or by us over the
            Internet. The transmission and exchange of information is carried
            out at your own risk.{' '}
          </p>
          <h2 className="l-static-content__sub-heading">Cookies</h2>
          <p className="l-static-content__paragraph">
            We may use cookies on our Website from time to time. Cookies are
            text files placed in your computer&apos;s browser to store your
            preferences. Cookies, by themselves, do not tell us your email
            address or other personally identifiable information. If and when
            you choose to provide our online Website with personal information,
            this information may be linked to the data stored in the cookie.
          </p>
          <p className="l-static-content__paragraph">
            You can block cookies by activating the setting on your browser that
            allows you to refuse the setting of all or some cookies. However, if
            you use your browser settings to block all cookies (including
            essential cookies) you may not be able to access all or parts of our
            Website.
          </p>
          <h2 className="l-static-content__sub-heading">
            Links to other websites
          </h2>
          <p className="l-static-content__paragraph">
            Our website may contain links to other party&apos;s websites. We do
            not have any control over those websites and we are not responsible
            for the protection and privacy of any personal information which you
            provide whilst visiting those websites. Those websites are not
            governed by this Privacy Policy.
          </p>
          <h2 className="l-static-content__sub-heading">
            Personal information from social network accounts
          </h2>
          <p className="l-static-content__paragraph">
            If you connect your account with us to a social network account,
            such as Facebook, Google, TikTok and LinkedIn we will collect your
            personal information from the social network. We will do this in
            accordance with the privacy settings you have chosen on that social
            network.
          </p>
          <p className="l-static-content__paragraph">
            The personal information that we may receive includes your name, ID,
            user name, handle, profile picture, gender, age, language, list of
            friends or followers and any other personal information you choose
            to share.
          </p>
          <p className="l-static-content__paragraph">
            We use the personal information we receive from the social network
            to create a profile for you on our Platform.
          </p>
          <p className="l-static-content__paragraph">
            Where we have accessed your personal information through your
            Facebook account, you have the right to request the deletion of
            personal information that we have been provided by Facebook. To
            submit a request for the deletion of personal information we
            acquired from Facebook, please send us an email at the address at
            the end of this Privacy Policy and specify in your request which
            personal information you would like deleted. If we deny your request
            for the deletion of personal information, we will explain why.
          </p>
          <h2 className="l-static-content__sub-heading">Amendments</h2>
          <p className="l-static-content__paragraph">
            We may, at any time and at our discretion, vary this Privacy Policy
            by publishing the amended Privacy Policy on our Website and
            Platform. We recommend you check our Website regularly to ensure you
            are aware of our current Privacy Policy.
          </p>
          <br />
          <p className="l-static-content__paragraph">
            <strong>For any questions or notices, please contact us at:</strong>
          </p>
          <p className="l-static-content__paragraph">
            Dashdot Pty Ltd ABN 83 631 524 797
          </p>
          <p className="l-static-content__paragraph">
            Email: hello@dashdot.com.au{' '}
          </p>
          <p className="l-static-content__paragraph">
            <strong>Last update</strong>: 24 June 2024
          </p>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
