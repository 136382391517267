import React from 'react';

function Mail({ style }: { style?: React.CSSProperties }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="12"
      fill="none"
      viewBox="0 0 16 12"
      style={style}
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.333"
        d="M14.667 2c0-.733-.6-1.333-1.334-1.333H2.667c-.734 0-1.334.6-1.334 1.333m13.334 0v8c0 .733-.6 1.333-1.334 1.333H2.667c-.734 0-1.334-.6-1.334-1.333V2m13.334 0L8 6.667 1.333 2"
      ></path>
    </svg>
  );
}

export default Mail;
