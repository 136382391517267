import 'chartjs-adapter-moment';

import { Alert } from 'antd';
// eslint-disable-next-line
import {
  Chart as ChartJS,
  ChartOptions,
  LayoutPosition,
  ScaleType,
  TimeUnit,
} from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import { forEach } from 'lodash';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { selectGraphByType } from '../../../redux/selectors';

ChartJS.register(Annotation);

type Props = {
  graphType: string;
  isXUnitsInMonths?: boolean;
  yMaxTicksLimit?: number;
  style?: any;
  isProjects?: boolean;
};

interface CustomizeChartOptions extends ChartOptions {
  annotation?: any;
}

const LineGraph = ({
  graphType,
  isXUnitsInMonths,
  yMaxTicksLimit,
  style,
  isProjects,
}: Props) => {
  const chartRef = useRef(null);
  const graphData = useSelector(selectGraphByType(graphType));

  const [chartData, setChartData] = useState<any>({
    datasets: [],
  });

  const datasets: any[] = [];

  const formatDate = (date: string) => {
    return moment(new Date(date)).format('MMM DD YYYY');
  };

  const formatAllDates = (array: any[]) => {
    return array.map((d: any) => {
      return {
        ...d,
        date: formatDate(d.date),
      };
    });
  };

  const convertZerosToNull = (array: any[]) => {
    if (
      graphType === 'GrossRentalYield' ||
      graphType === 'RentalVacancyRatio' ||
      graphType === 'SupplyDemandRatio'
    ) {
      return array.map((m: any) => {
        const result: any = {};

        if (typeof m.ratio === 'number' && m.ratio === 0) {
          return { ...m, ratio: null };
        }

        if (typeof m.rentalYield1 === 'number' && m.rentalYield1 === 0) {
          result.rentalYield1 = null;
        }

        if (typeof m.rentalYield12 === 'number' && m.rentalYield12 === 0) {
          result.rentalYield12 = null;
        }

        return {
          ...m,
          ...result,
        };
      });
    }

    return array;
  };

  const defaultColors = ['#00B2A3', '#E29E15'];
  const reversedColors = ['#E29E15', '#00B2A3'];
  const faintColors = ['#00B2A3', '#E29E156f'];
  const faintReversedColors = ['#E29E156f', '#00B2A3'];
  const gradientColors = ['rgba(0, 178, 163, .4)', 'rgba(226, 158, 21, .6)'];
  const gradientColorsBeta = [
    'rgba(0, 178, 163, 0)',
    'rgba(250, 253, 247, .5)',
  ];

  const gradientReversedColors = [
    'rgba(226, 158, 21, 1)',
    'rgba(0, 178, 163, .75)',
  ];

  const colors: any = {
    SupplyDemandIndicator: reversedColors,
    SupplyDemandRatio: defaultColors,
    RentalVacancyRatio: gradientReversedColors,
    VolumeRentalListings: gradientColors,
    MedianSalePrice: faintColors,
    GrossRentalYield: faintReversedColors,
    MedianWeeklyAskingRent: faintColors,
    LgaWeightedInvCapita: reversedColors,
    SuburbTotalWeightedInvesmentPerCapita: reversedColors,
    MedianMarketTime: faintReversedColors,
    VendorDiscount: faintColors,
    MedianSalesPricePrediction: defaultColors,
    MedianWeeklyAskingRentPrediction: defaultColors,
  };

  const valueUnits: any = {
    SupplyDemandIndicator: '',
    SupplyDemandRatio: '',
    RentalVacancyRatio: '%',
    VolumeRentalListings: '',
    MedianSalePrice: '$',
    GrossRentalYield: '%',
    MedianWeeklyAskingRent: '$',
    LgaWeightedInvCapita: '$',
    SuburbTotalWeightedInvesmentPerCapita: '$',
    MedianMarketTime: '',
    VendorDiscount: '%',
    MedianSalesPricePrediction: '$',
    MedianWeeklyAskingRentPrediction: '$',
  };

  const trendlineColors = 'rgba(13, 34, 56, 1)';

  useEffect(() => {
    if (chartRef?.current) {
      const chart: any = chartRef.current;

      // Apply area gradients if "Volume Of Rental Listings" graph
      if (graphType === 'VolumeRentalListings') {
        setChartData({
          datasets: datasets.map((d: any, i: number) => {
            const gradient = chart.ctx.createLinearGradient(0, 0, 0, 800);

            gradient.addColorStop(0, gradientColors[i]);
            gradient.addColorStop(1, gradientColorsBeta[i]);

            return {
              ...d,
              fill: 'start',
              borderWidth: 2,
              backgroundColor: gradient,
              strokeColor: gradientColors[i],
              pointColor: gradientColors[i],
              pointStrokeColor: gradientColors[i],
              pointHighlightFill: gradientColors[i],
              pointHighlightStroke: gradientColors[i],
            };
          }),
        });
      } else {
        setChartData({ datasets: datasets });
      }
    }
  }, [graphData, chartRef.current]);

  if (!graphData) {
    return (
      <div className="misc">
        <Alert message="No data available" type="warning" showIcon />
      </div>
    );
  }

  const timelineData = graphData.graphs.filter((g: any) => {
    return g.name.indexOf('Timeline') !== -1;
  });

  const forecastData = graphData.graphs.filter((g: any) => {
    return g.name.indexOf('Forecast') !== -1;
  });

  const regressionData = graphData.graphs.filter((g: any) => {
    return g.name.indexOf('Regression') !== -1;
  });

  const xType: ScaleType = 'time';
  const positionType: LayoutPosition = 'right';
  const xTimeUnit: TimeUnit = 'month';

  let yAxisLabel = 'Quantity';
  let isSecondYAxisDisplayed = false;
  const legend: any = [];
  let annotationOptions: CustomizeChartOptions = {};
  let forecastDate: any;

  const setYAxisLabel = (dataName: string) => {
    switch (dataName) {
      case 'Ratio':
        yAxisLabel = 'Ratio';
        break;
      case 'Supply':
      case 'RentalListing':
        yAxisLabel = 'Volume';
        break;
      case 'Msp12':
        yAxisLabel = 'Sales Price';
        break;
      case 'RentalYield1':
        yAxisLabel = 'Percentage';
        break;
      case 'Mmt12':
        yAxisLabel = 'Days';
        break;
      case 'Nat':
      case 'Sub':
        yAxisLabel = 'Investment';
        break;
      case 'Vd12':
        yAxisLabel = 'Discount';
        break;
      default:
        yAxisLabel = 'Quantity';
    }
  };

  const getLineOrder = (index: number) => {
    if (
      (graphType === 'GrossRentalYield' ||
        graphType === 'VolumeRentalListings' ||
        graphType === 'MedianMarketTime') &&
      index > 1
    ) {
      // Also apply to legend ordering, lowest comes first
      legend.reverse();
      return 1;
    }

    return 2;
  };

  const createAnnotations = (dataObj: any) => {
    if (!dataObj.configuration || graphType === 'GrossRentalYield') {
      return false;
    }

    const { configuration } = dataObj;
    const list = dataObj.data;

    let yLowerLimit;
    let yUpperLimit;

    if (graphType === 'SupplyDemandRatio') {
      yLowerLimit = parseFloat(
        configuration.filter((i: any) => i.key === 'LowerBand')[0].value,
      );

      yUpperLimit = parseFloat(
        configuration.filter((i: any) => i.key === 'UpperBand')[0].value,
      );
    } else {
      const middleIndex = Math.ceil(configuration.length / 2);

      yLowerLimit = parseFloat(configuration[middleIndex - 2].value);
      yUpperLimit = parseFloat(configuration[middleIndex - 1].value);
    }

    const middleLine = (yLowerLimit + yUpperLimit) / 2;

    const xLowerLimit = list[0].date;
    const xUpperLimit = list[list.length - 1].date;

    annotationOptions = {
      annotation: {
        annotations: {
          box: {
            type: 'box',
            xMin: xLowerLimit,
            xMax: xUpperLimit,
            yMin: yLowerLimit,
            yMax: yUpperLimit,
            backgroundColor: '#c8c8c815',
            borderColor: 'transparent',
            drawTime: 'beforeDraw',
          },
          line: {
            type: 'line',
            scaleID: 'y',
            value: middleLine,
            borderColor: '#292929bb',
            borderWidth: 2,
            drawTime: 'beforeDatasetsDraw',
            borderDash: [3, 3],
            label: {
              content: 'Neutral Market',
              enabled: true,
              position: 'start',
              backgroundColor: '#fbfbfb',
              xPadding: 9,
              yPadding: 6,
              color: '#292929bb',
              textAlign: 'center',
              fontStyle: 'normal',
            },
          },
        },
      },
    };
  };

  const createForecastAnnotations = (dataObj: any) => {
    const { data } = dataObj;

    const forecastStartIndex = data.findIndex((entry: any) => {
      return entry.conf70High !== null && entry.conf70High !== undefined;
    });

    const forecastData = data.slice(forecastStartIndex, data.length);

    const xLowerLimit = forecastData[0].date;
    const xUpperLimit = forecastData[forecastData.length - 1].date;

    forecastDate = formatDate(xLowerLimit);

    annotationOptions = {
      annotation: {
        annotations: {
          ...{
            box: {
              type: 'box',
              xMin: xLowerLimit,
              xMax: xUpperLimit,
              backgroundColor: '#c8c8c815',
              borderColor: 'transparent',
              drawTime: 'beforeDraw',
            },
          },
          // ...lines,
        },
      },
    };
  };

  const getLabel = (param: string) => {
    let label = param;

    if (param === 'Volume of Sales (1 Month)') {
      label = 'Sales Volume';
    }

    if (param === 'Stock on Market (1 Month)') {
      label = 'Stock on Market';
    }
    return label;
  };

  if (timelineData.length) {
    createAnnotations(timelineData[0]);

    forEach(timelineData[0].axis, (a, i: number) => {
      if (a.dataName.toLowerCase() !== 'date') {
        if (a.position === 'Y') {
          setYAxisLabel(a.dataName);
        }

        const label = getLabel(a.label);

        const yAxisKey = a.dataName[0].toLowerCase() + a.dataName.slice(1);
        const isStockMarketOrMar3 =
          yAxisKey === 'stockMarket' ||
          (yAxisKey === 'mar3' && graphType === 'MedianWeeklyAskingRent');

        // If graph type is "Stock On Market & Sales Volume"
        // or "Median Weekly Asking Rent", display 2nd Y-axis
        isSecondYAxisDisplayed = isSecondYAxisDisplayed || isStockMarketOrMar3;

        legend.push({
          label: label,
          backgroundColor: colors[graphType][i - 1],
        });

        datasets.push({
          label: label,
          data: formatAllDates(convertZerosToNull(timelineData[0].data)),
          parsing: {
            yAxisKey: yAxisKey,
          },
          backgroundColor: colors[graphType][i - 1],
          borderColor: colors[graphType][i - 1],
          yAxisID: isStockMarketOrMar3 ? 'y1' : 'y',
          order: getLineOrder(i),
        });
      }
    });
  }

  // Forecast serialization is specific to graph types
  // MedianSalesPricePrediction & MedianWeeklyAskingRentPrediction
  if (forecastData.length) {
    createForecastAnnotations(forecastData[0]);

    const isMedianSales = graphType === 'MedianSalesPricePrediction';

    const legendConf70 = '70% Confidence Zone';
    const legendConf95 = '95% Confidence Zone';

    const lowerLimitAppendText = 'Lower Limit';
    const upperLimitAppendText = 'Upper Limit';

    const foreCastBgColors = ['#E29E1580', '#E29E154D'];

    yAxisLabel = isMedianSales ? 'Price' : 'Rent';

    legend.push({
      label: legendConf70,
      backgroundColor: foreCastBgColors[0],
    });

    legend.push({
      label: legendConf95,
      backgroundColor: foreCastBgColors[1],
    });

    // Value
    datasets.push({
      label: isMedianSales ? 'Median Sales Price' : 'Median Weekly Asking Rent',
      data: formatAllDates(convertZerosToNull(forecastData[0].data)),
      parsing: {
        yAxisKey: 'value',
      },
      backgroundColor: colors[graphType][0],
      borderColor: colors[graphType][0],
      yAxisID: 'y',
      order: 1,
    });

    // 70% Confidence Zone
    datasets.push({
      label: `${legendConf70} ${lowerLimitAppendText}`,
      data: formatAllDates(convertZerosToNull(forecastData[0].data)),
      parsing: {
        yAxisKey: 'conf70Low',
      },
      backgroundColor: foreCastBgColors[0],
      borderColor: 'transparent',
      fill: false,
      order: 2,
    });

    datasets.push({
      label: `${legendConf70} ${upperLimitAppendText}`,
      data: formatAllDates(convertZerosToNull(forecastData[0].data)),
      parsing: {
        yAxisKey: 'conf70High',
      },
      backgroundColor: foreCastBgColors[0],
      borderColor: 'transparent',
      fill: '-1',
      order: 2,
    });

    // 90% Confidence Zone
    datasets.push({
      label: `${legendConf95} ${lowerLimitAppendText}`,
      data: formatAllDates(convertZerosToNull(forecastData[0].data)),
      parsing: {
        yAxisKey: 'conf95Low',
      },
      backgroundColor: foreCastBgColors[1],
      borderColor: 'transparent',
      fill: false,
      order: 3,
    });

    datasets.push({
      label: `${legendConf95} ${upperLimitAppendText}`,
      data: formatAllDates(convertZerosToNull(forecastData[0].data)),
      parsing: {
        yAxisKey: 'conf95High',
      },
      backgroundColor: foreCastBgColors[1],
      borderColor: 'transparent',
      fill: '-1',
      order: 3,
    });
  }

  if (regressionData.length) {
    const regressionDataMapped = regressionData[0].data.map((d: any) => {
      return {
        ...d,
        ratio: parseFloat(d.ratio),
      };
    });

    const axis = timelineData[0].axis;

    forEach(axis, (a, i) => {
      const yAxisKey = a.dataName[0].toLowerCase() + a.dataName.slice(1);
      const isStockMarketOrMar3 =
        yAxisKey === 'stockMarket' ||
        (yAxisKey === 'mar3' && graphType === 'MedianWeeklyAskingRent');

      if (a.dataName.toLowerCase() !== 'date') {
        datasets.push({
          label: `Trendline Ratio ${i}`,
          data: formatAllDates(regressionDataMapped),
          parsing: {
            yAxisKey: a.dataName[0].toLowerCase() + a.dataName.slice(1),
          },
          backgroundColor: trendlineColors,
          borderColor: trendlineColors,
          yAxisID: isStockMarketOrMar3 ? 'y1' : 'y',
        });
      }
    });
  }

  let scaleXOptions = {
    ticks: {
      autoSkip: true,
    },
    type: xType,
    grid: {
      color: 'rgba(0, 0, 0, 0)',
    },
  };

  if (isXUnitsInMonths) {
    scaleXOptions = {
      ...scaleXOptions,
      ...{
        time: {
          unit: xTimeUnit,
        },
      },
    };
  }

  const options = {
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
    parsing: {
      xAxisKey: 'date',
    },
    layout: {
      padding: {
        right: 12,
        left: 12,
        top: 20,
        bottom: 0,
      },
    },
    plugins: {
      filler: {
        propagate: true,
      },
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          labelColor: () => {
            return {
              borderColor: 'rgb(0, 0, 255)',
              backgroundColor: 'rgb(255, 0, 0)',
              borderWidth: 2,
              borderRadius: 2,
            };
          },
          title: (context: any) => {
            return moment(new Date(context[0].parsed.x)).format(
              'MMMM DD, YYYY',
            );
          },
          label: (context: any) => {
            if (
              graphType === 'MedianSalePrice' ||
              graphType === 'MedianWeeklyAskingRent' ||
              graphType === 'LgaWeightedInvCapita'
            ) {
              return `${
                context.dataset.label
              }: $${context.parsed.y.toLocaleString()}`;
            }

            if (
              graphType === 'RentalVacancyRatio' ||
              graphType === 'GrossRentalYield' ||
              graphType === 'VendorDiscount'
            ) {
              return `${
                context.dataset.label
              }: ${context.parsed.y.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 1,
              })}${valueUnits[graphType]}`;
            }

            if (
              graphType === 'MedianSalesPricePrediction' ||
              graphType === 'MedianWeeklyAskingRentPrediction'
            ) {
              const target = context.dataset.data[context.dataIndex];
              const valueRate = target?.valueRate ? target.valueRate : 'N/a';

              const forDisplay = [
                `${
                  context.dataset.label
                }: $${context.parsed.y.toLocaleString()}`,
              ];

              if (
                context.dataset.label === 'Median Sales Price' ||
                context.dataset.label === 'Median Weekly Asking Rent'
              ) {
                forDisplay.push(`Forecasted Growth: ${valueRate}`);
              }

              return forDisplay;
            }

            if (graphType === 'SuburbTotalWeightedInvesmentPerCapita') {
              return [
                'Suburb Total Weighted Investment',
                'Per Capita (within defined radius):',
                `$${context.parsed.y.toLocaleString()}`,
              ];
            }

            return `${
              context.dataset.label
            }: ${context.parsed.y.toLocaleString()}${valueUnits[graphType]}`;
          },
        },
      },
      ...annotationOptions,
    },
    elements: {
      point: {
        radius: 0,
        pointHitRadius: 10,
      },
      line: {
        borderWidth: 2,
        lineTension: 0.15,
      },
    },
    scales: {
      x: scaleXOptions,
      y: {
        title: {
          text: yAxisLabel,
          display: true,
          padding: {
            bottom: 10,
          },
        },
        ticks: {
          maxTicksLimit: yMaxTicksLimit,
          callback: (value: any) => {
            if (
              graphType === 'MedianSalePrice' ||
              graphType === 'MedianWeeklyAskingRent' ||
              graphType === 'LgaWeightedInvCapita' ||
              graphType === 'SuburbTotalWeightedInvesmentPerCapita' ||
              graphType === 'MedianSalesPricePrediction' ||
              graphType === 'MedianWeeklyAskingRentPrediction'
            ) {
              return `${valueUnits[graphType]}${value.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
              )}`;
            }

            return (
              value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }) + valueUnits[graphType]
            );
          },
        },
      },
      y1: {
        display: isSecondYAxisDisplayed,
        position: positionType,
        grid: {
          drawOnChartArea: false,
        },
        title: {
          text:
            graphType === 'MedianWeeklyAskingRent'
              ? 'Asking Rent'
              : 'Percentage',
          display: true,
        },
        ticks: {
          callback: (value: any) => {
            if (graphType === 'MedianWeeklyAskingRent') {
              return `${valueUnits[graphType]}${value.toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                },
              )}`;
            }

            return (
              value.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              }) + valueUnits[graphType]
            );
          },
        },
      },
    },
  };

  return (
    <div className="l-chart-wrapper" style={style || {}}>
      {forecastDate && (
        <p style={{ marginBottom: '18px', fontSize: '13px' }}>
          Forecast from{' '}
          <strong style={{ color: '#555' }}>{forecastDate}</strong>
        </p>
      )}
      <section style={isProjects ? { height: '66%', paddingBottom: '0' } : {}}>
        <Line data={chartData} ref={chartRef} options={options} />
      </section>
      {graphType !== 'SupplyDemandRatio' && (
        <ul
          className={`l-legend l-legend--horizontal${
            isProjects ? ' l-legend--projects' : ''
          }`}
        >
          {legend.map((e: any) => {
            return (
              <li className="l-legend__item" key={e.label}>
                <span
                  className="l-legend__color"
                  // @ts-ignore
                  style={{ background: e.backgroundColor }}
                ></span>
                <span className="l-legend__key">{e.label}</span>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
};

export default LineGraph;
