import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const inviteFormValidationSchema = () => {
  const fields: any = {
    users: Yup.array().of(
      Yup.object().shape({
        email: Yup.string()
          .email('Must be a valid email')
          .required(requiredMessage),
      }),
    ),
  };

  return Yup.object().shape(fields);
};
