import React from 'react';

function ArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#00766C"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.67"
        d="M12.5 15l-5-5 5-5"
      ></path>
    </svg>
  );
}

export default ArrowLeft;
