import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useHistory } from 'react-router-dom';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import NotFound from '../../foundation/components/not_found/NotFound';
import { RouteType, useRoutes } from '../../foundation/config/routes';
import { useClientIP } from '../../foundation/cutom_hooks/useClientIP';
import { useRole } from '../../foundation/cutom_hooks/useRole';
import {
  clearStorage,
  getItemFromStorage,
} from '../../foundation/utils/storageHandler';
import { useAppDispatch } from '../../store/hooks';
import { refreshToken } from '../authentication/redux/async_thunks';
import {
  selectRoleOptions,
  selectUser,
} from '../authentication/redux/selectors';
import { User } from '../authentication/redux/types';
import { setClient } from '../client/redux/slice';
import AppLayout from '../layout/Layout';
import { setPlan } from '../plan/redux/slice';
import { fetchProfile } from '../profile/redux/async_thunks';
import {
  fetchFormValues,
  fetchPlanProperties,
} from '../property/redux/async_thunks';
import {
  selectedPlanOffsetAccounts,
  selectedPlanRecords,
  selectPlanProperties,
} from '../property/redux/selectors';
import PrivateRoute from './private_route/PrivateRoute';

function Startup() {
  const roleIds = useSelector(selectRoleOptions);

  const [isLoading, setIsLoading] = useState(true);

  const clientIp = useClientIP();

  const user = useSelector(selectUser);

  const [isClientView] = useRole();

  const dispatch = useAppDispatch();

  const history = useHistory();

  const planProperties = useSelector(selectPlanProperties);

  const planOffsetAccounts = useSelector(selectedPlanOffsetAccounts);

  const planRecords = useSelector(selectedPlanRecords);

  const routes = useRoutes(
    planProperties,
    isClientView,
    planOffsetAccounts,
    planRecords,
  );

  const getAPIParams = () => {
    type refreshTokenParamsType = {
      userId: string;
      token: string;
    };

    let apiParams: refreshTokenParamsType | undefined = undefined;

    const currentUserData = getItemFromStorage('user');

    if (currentUserData) {
      const data = JSON.parse(currentUserData);

      apiParams = {
        userId: data.user_id,
        token: data.token,
      };
    }

    return apiParams;
  };

  const createNetworkCallPromisesArray = (
    updatedUserData: User,
    currentPlanData: string,
  ) => {
    const $promises: any[] = [];

    const isSuperAdmin =
      updatedUserData && updatedUserData.role_id === roleIds?.superAdmin;
    const isEnterpriseAdmin =
      updatedUserData && updatedUserData.role_id === roleIds?.enterpriseAdmin;

    if (currentPlanData && !isSuperAdmin && !isEnterpriseAdmin) {
      const plan = JSON.parse(currentPlanData);
      dispatch(setPlan(plan));
      if (updatedUserData && updatedUserData.token) {
        $promises.push(
          dispatch(
            fetchPlanProperties({
              token: updatedUserData.token,
              planId: plan.planId,
            }),
            // @ts-ignore
          ).unwrap(),
        );
      }
    }

    /**
     * Fetch form values
     */
    if (
      updatedUserData &&
      updatedUserData.token
      // updatedUserData.role_id !== 0
    ) {
      $promises.push(
        dispatch(
          fetchProfile({
            token: updatedUserData.token,
            email: updatedUserData.email,
            agencyId: updatedUserData.agency_id,
          }),
        ).unwrap(),
      );
    }

    return $promises;
  };

  const checkRedirect = () => {
    const reportCode = sessionStorage.getItem('report_code');

    if (reportCode) {
      history.push(`/suburb-scoring/shared-report/${reportCode}`);
    }
  };

  const checkValuesEpRolePermission = (user: User) => {
    return {
      isAllowed:
        user?.role_id !== roleIds?.superAdmin &&
        user?.role_id !== roleIds?.enterpriseAdmin &&
        user?.role_id !== roleIds?.nonClient,
    };
  };

  const setAuthData = async (updatedUserData: any) => {
    try {
      /**
       * Fetch the values from browser's storage.
       */

      const currentClientData = getItemFromStorage('client');

      const currentPlanData = getItemFromStorage('plan');

      const isSuperAdmin =
        updatedUserData && updatedUserData.role_id === roleIds?.superAdmin;
      const isEnterpriseAdmin =
        updatedUserData && updatedUserData.role_id === roleIds?.enterpriseAdmin;
      const isClient =
        updatedUserData && updatedUserData.role_id === roleIds?.client;
      const isAgencyLead =
        updatedUserData && updatedUserData.role_id === roleIds?.agencyLead;

      /**
       * Set the client and plan data in redux based on their avaialabilities
       * only for the non-admin users. We can't use useRoles here because that's not available
       * at the time this function is invoked.
       */
      if (currentClientData && !isEnterpriseAdmin && !isSuperAdmin) {
        dispatch(setClient(JSON.parse(currentClientData)));
      }

      const $promises = createNetworkCallPromisesArray(
        updatedUserData,
        currentPlanData ? currentPlanData : '',
      );

      await Promise.all($promises);

      const path = history.location.pathname;

      /**
       * Handle browser routing based on the avaialble values.
       */
      if (isAgencyLead) {
        history.push('/suburb-scoring');
      } else if (!isEnterpriseAdmin && !isSuperAdmin) {
        // If admin user
        history.push(path);
      } else if (isClient) {
        // If client account
        if (currentClientData && currentPlanData) {
          if (path === '/') {
            history.push('/dashboard');
          } else {
            history.push(path);
          }
        } else if (currentClientData && !currentPlanData) {
          history.push('/plan');
        } else {
          history.push(`/login`);
        }
      } else {
        // If normal user
        if (currentClientData && currentPlanData) {
          if (path === '/') {
            history.push('/dashboard');
          } else {
            history.push(path);
          }
        } else if (!currentPlanData && currentClientData) {
          history.push('/plan');
        } else {
          history.push(`/`);
        }
      }

      setIsLoading(false);
    } catch (error) {
      clearStorage();
      history.push('/login');
      console.log(error);
    }
  };

  const fetchData = async () => {
    try {
      if (user) {
        checkRedirect();

        const currentPlanData = getItemFromStorage('plan');

        const $promises = createNetworkCallPromisesArray(
          user,
          currentPlanData ? currentPlanData : '',
        );

        await Promise.all($promises);
      }
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const initiateAuth = async () => {
    try {
      if (!user) {
        const apiParams = getAPIParams();

        /**
         * Call the refreshToken API to get the updated values.
         */
        let updatedUserData: any = undefined;
        if (apiParams) {
          updatedUserData = await dispatch(
            refreshToken({
              data: { userId: apiParams.userId },
              token: apiParams.token,
            }),
            // @ts-ignore
          ).unwrap();
        }

        if (updatedUserData?.token) {
          if (checkValuesEpRolePermission(updatedUserData).isAllowed) {
            await dispatch(
              fetchFormValues({
                token: updatedUserData.token,
              }),
              // @ts-ignore
            ).unwrap();
          }

          setAuthData(updatedUserData);
        } else {
          clearStorage();
          history.push('/login');
        }
      } else if (user.token && checkValuesEpRolePermission(user).isAllowed) {
        dispatch(
          fetchFormValues({
            token: user.token,
          }),
          // @ts-ignore
        ).unwrap();
      }
    } catch (error) {
      clearStorage();
      history.push('/login');
    }
  };

  useEffect(() => {
    if (clientIp) {
      initiateAuth();
    }
  }, [clientIp]);

  useEffect(() => {
    if (roleIds && user) {
      fetchData();
    }
  }, [user]);

  const routeRenderer: any = (item: RouteType) => {
    if (!item.isSubMenu) {
      return <PrivateRoute {...item} key={item.path} />;
    } else {
      const items: any[] = [];
      if (item.routes) {
        for (const route of item.routes) {
          items.push(routeRenderer(route));
        }
      }
      return items;
    }
  };

  const privateRoutes = useMemo(() => {
    const items: any[] = [];

    for (const route of routes) {
      items.push(routeRenderer(route));
    }
    return items;
  }, [routes]);

  if (isLoading) {
    return <FullPageLoader />;
  }

  return (
    <AppLayout>
      <Switch>
        {privateRoutes}
        <PrivateRoute path="*" component={NotFound} />
      </Switch>
    </AppLayout>
  );
}

export default Startup;
