import { Col, Row } from 'antd';
import { Buffer } from 'buffer';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { selectUser } from '../../../authentication/redux/selectors';
import { fetchReportData } from '../../redux/async_thunks';
import {
  selectForecastTimelineType,
  selectSuburb,
  selectTemplate,
  selectTimelineOptions,
} from '../../redux/selectors';
import { setForecastTimelineType, setIsReportLoading } from '../../redux/slice';
import LineGraph from '../key_indicators/line_graph/LineGraph';

const PriceRentForecast = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const suburb = useSelector(selectSuburb);
  const forecastTimelineType = useSelector(selectForecastTimelineType);
  const user = useSelector(selectUser);
  const template = useSelector(selectTemplate);

  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);

  const { r, t, u } = queryString.parse(history.location.search);
  const isPublicView = !!r && !!t && !!u;

  const graphTypes = [
    {
      key: 'Median Sales Price Prediction Forecast',
      value: 'MedianSalesPricePrediction',
    },
    {
      key: 'Median Weekly Asking Rent Prediction Forecast',
      value: 'MedianWeeklyAskingRentPrediction',
    },
  ];

  const timelineOptions = useSelector(selectTimelineOptions);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchForecastData = async () => {
    dispatch(setIsReportLoading(true));

    const data: any = {
      suburbCode: suburb?.suburbCode,
      userId: !isPublicView
        ? user?.user_id
        : Buffer.from(u as string, 'base64').toString('utf-8'),
      timeLineYears: forecastTimelineType,
    };

    try {
      await dispatch(
        fetchReportData({
          data: data,
          template: template,
          token: !isPublicView
            ? user?.token
            : Buffer.from(t as string, 'base64').toString('utf-8'),
        }),
      )
        // @ts-ignore
        .unwrap();
    } catch (error) {
      errorHandler(error);

      if (isPublicView) {
        history.push('/');
      }
    }

    dispatch(setIsReportLoading(false));
  };

  const groupFetch = () => {
    if (forecastTimelineType) {
      fetchForecastData();
    }
  };

  useEffect(() => {
    if (!isFirstLoad) {
      groupFetch();
    } else {
      setIsFirstLoad(false);
    }
  }, [forecastTimelineType]);

  if (!suburb) {
    return null;
  }

  const renderTimelineInput = () => {
    const containerStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      borderRadius: '16px',
      border: '1px solid #d9dbe9',
      padding: '16px',
      maxWidth: '430px',
      margin: '0 auto',
    };

    const labelStyle = {
      fontWeight: 'bold',
      fontSize: '16px',
      marginBottom: '9px',
    };

    return (
      <div style={{ ...containerStyle, flexDirection: 'column' }}>
        <p style={labelStyle}>Graph Timeline</p>
        <ul className="l-select-year">
          {timelineOptions.map((option: any, i: any) => {
            return (
              <li
                className={`l-select-year__option${
                  option.value === forecastTimelineType
                    ? ' l-select-year__option--active'
                    : ''
                }`}
                key={option.value}
              >
                <a
                  href="#"
                  data-value={option.value}
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(
                      // @ts-ignore
                      setForecastTimelineType(e.currentTarget.dataset.value),
                    );
                  }}
                >
                  {option.value}Y
                </a>
              </li>
            );
          })}
        </ul>
      </div>
    );
  };

  return (
    <Col span={24}>
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row gutter={[0, 15]}>
            {renderTimelineInput()}
            {graphTypes.map((g: any, i: number) => {
              const cardStyle = { marginBottom: '0', paddingBottom: '0' };

              const graphName = g.key;

              return (
                <Col span={24} key={g.value}>
                  <div className="l-dashboard-card">
                    <div className="l-dashboard-card__body">
                      <div
                        className="l-dashboard-card__title"
                        style={cardStyle}
                      >
                        {graphName}
                      </div>
                      <LineGraph graphType={g.value} />
                    </div>
                  </div>
                </Col>
              );
            })}
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default PriceRentForecast;
