import type { MenuProps } from 'antd';
import { Button, Col, Dropdown } from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';

import AgencyIcon from '../../../foundation/assets/svgs/AgencyIcon';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../../store/hooks';
import DeleteAgencyModal from '../modals/delete_agency_modal/DeleteAgencyModal';
import InviteModal from '../modals/invite_modal/InviteModal';
import InviteSuccessModal from '../modals/invite_success_modal/InviteSuccessModal';
import { setAgency } from '../redux/slice';
import { Agency } from '../redux/types';

type AgencyCardProps = {
  agency: Agency;
  fetchAgencyResults: (searchValue?: string) => Promise<void>;
};

const AgencyCard = ({ agency, fetchAgencyResults }: AgencyCardProps) => {
  const { isMobileViewport } = useViewport();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isInviteModalVisible, setInviteModalVisibility] = useState(false);

  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

  const [isInviteSuccessModalVisible, setInviteSuccessModalVisibility] =
    useState(false);

  const [isDeleteAgencyModalVisible, setDeleteAgencyModalVisibility] =
    useState(false);

  const handleSettingsClick = () => {
    history.push(`/agency/${agency.agencyId}`);
  };

  const handleInviteUserClick = () => {
    dispatch(setAgency(agency));
    setInviteModalVisibility(true);
  };

  const handleArchiveAgencyClick = () => {
    dispatch(setAgency(agency));
    setDeleteAgencyModalVisibility(true);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <span
          className="l-agency__card-control-item"
          onClick={handleSettingsClick}
        >
          Settings
        </span>
      ),
    },
    {
      key: '2',
      label: (
        <span
          className="l-agency__card-control-item l-agency__card-control-item--has-separator"
          onClick={handleInviteUserClick}
        >
          Invite users
        </span>
      ),
    },
    {
      key: '3',
      label: (
        <span
          className="l-agency__card-control-item"
          style={{ color: '#D92D20' }}
          onClick={handleArchiveAgencyClick}
        >
          Archive agency
        </span>
      ),
    },
  ];

  const handleInviteModalClose = () => {
    setInviteModalVisibility(false);
  };

  const handleInviteSuccessModalClose = () => {
    setInviteSuccessModalVisibility(false);
  };

  const handleDeleteAgencyModalClose = () => {
    setDeleteAgencyModalVisibility(false);
  };

  return (
    <Col xs={24} xl={12} xxl={8}>
      <div className="l-agency__card">
        {isInviteModalVisible && (
          <InviteModal
            closeModal={handleInviteModalClose}
            setInviteSuccessModalVisibility={setInviteSuccessModalVisibility}
            setInvitedEmails={setInvitedEmails}
          />
        )}

        {isDeleteAgencyModalVisible && (
          <DeleteAgencyModal
            closeModal={handleDeleteAgencyModalClose}
            fetchAgencyResults={fetchAgencyResults}
          />
        )}

        {isInviteSuccessModalVisible && (
          <InviteSuccessModal
            closeModal={handleInviteSuccessModalClose}
            emails={invitedEmails}
            inviteType={'user'}
          />
        )}
        <div className="l-agency__card-icon">
          <AgencyIcon />
        </div>
        <div className="l-agency__card-content">
          <div className="l-agency__card-content-wrapper">
            <div className="l-agency__card-entrprise-name">
              {agency.enterpriseName}
            </div>
            <div className="l-agency__card-agency-name">
              {agency.agencyName}
            </div>
            <div className="l-agency__card-agency-address">
              {agency.agencyAddress}
            </div>
          </div>
          <div className="l-agency__card-controls">
            <Dropdown
              placement={isMobileViewport ? 'topRight' : 'topCenter'}
              menu={{ items }}
            >
              <Button shape="round" className="l-agency__card-control-btn">
                :
              </Button>
            </Dropdown>
          </div>
        </div>
      </div>
    </Col>
  );
};

export default AgencyCard;
