import { Button, Col } from 'antd';
import React from 'react';

import AgencyIcon from '../../../foundation/assets/svgs/AgencyIcon';

type CreateAgencyCardProps = {
  onClick: () => void;
};

const CreateAgencyCard = ({ onClick }: CreateAgencyCardProps) => {
  return (
    <Col xs={24} xl={12} xxl={8}>
      <div className="l-agency__card">
        <div className="l-agency__card-icon l-agency__card-icon--create">
          <AgencyIcon />
        </div>
        <div className="l-agency__card-content l-agency__card-content--create">
          <Button onClick={onClick}>Create new agency</Button>
        </div>
      </div>
    </Col>
  );
};

export default CreateAgencyCard;
