import { DeleteOutlined } from '@ant-design/icons';
import { Button, Input, Modal } from 'antd';
import { FieldArray, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import Select from '../../../../foundation/components/select/Select';
import { useAppDispatch } from '../../../../store/hooks';
import {
  selectRoleOptions,
  selectUser,
} from '../../../authentication/redux/selectors';
import { inviteUsers } from '../../redux/async_thunks';
import { selectAgency } from '../../redux/selectors';
import { inviteFormValidationSchema } from '../../validation_schemas/invite_form_validation';

type InviteModalProps = {
  closeModal: () => void;
  setInviteSuccessModalVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setInvitedEmails: React.Dispatch<React.SetStateAction<string[]>>;
};

const InviteModal = ({
  closeModal,
  setInviteSuccessModalVisibility,
  setInvitedEmails,
}: InviteModalProps) => {
  const ROLE_IDS = useSelector(selectRoleOptions);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const agency = useSelector(selectAgency);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: 'Invite Agency Users',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
  };

  const userRoles = useMemo(() => {
    if (ROLE_IDS) {
      return [
        {
          key: 'Agency User',
          value: ROLE_IDS?.agencyUser.toString(),
        },
        {
          key: 'Agency Admin',
          value: ROLE_IDS?.agencyAdmin.toString(),
        },
      ];
    }
    return [];
  }, [ROLE_IDS]);

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    try {
      if (user && agency) {
        setIsLoading(true);
        const data = {
          userId: user?.user_id,
          inviteeEmailsRoleIds: values.users.map((user) => {
            return { email: user.email, roleId: parseInt(user.role, 10) };
          }),
          agencyId: agency?.agencyId,
        };

        const response = await dispatch(
          inviteUsers({
            token: user?.token,
            data: data,
          }),
        ).unwrap();

        setInvitedEmails(response.allInvitees);

        setIsLoading(false);
        closeModal();
        setInviteSuccessModalVisibility(true);
      }
    } catch (error) {
      handleModalClose();
    }
  };

  return (
    <Modal {...modalOpts} className="h-invite-modal c-form-modal">
      {isLoading && <FullPageLoader />}
      <div className="h-invite-modal__information">
        <strong>Agency users</strong>{' '}
        {`can add/edit/delete agency clients and client's plans.`}
      </div>
      <div className="h-invite-modal__information">
        <strong>Agency admins</strong>{' '}
        {`can edit an agency profile, manage agency admins/users/clients, and manage client's plans.`}
      </div>
      <Formik
        initialValues={{
          users: [
            {
              email: '',
              role: userRoles[0].value,
            },
          ],
        }}
        validationSchema={inviteFormValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray name="users">
                {({ push, remove }) => (
                  <div>
                    {values.users.map((user, index) => {
                      return (
                        <div
                          className="h-invite-modal__dual-fields-wrapper"
                          key={index}
                        >
                          <div className="c-form-field">
                            <div className="c-form-field__label">
                              Email address
                            </div>
                            <div className="c-form-field__wrapper">
                              <Input
                                onChange={handleChange}
                                type="text"
                                name={`users[${index}].email`}
                                placeholder={`User ${index + 1}`}
                                value={values.users[index].email}
                                onBlur={handleBlur}
                              />
                              <div className="c-form-field__error">
                                {errors.users && errors.users[index]
                                  ? // @ts-ignore
                                    errors.users[index].email
                                  : undefined}
                              </div>
                            </div>
                          </div>

                          <div className="c-form-field">
                            <div className="c-form-field__label">
                              Permissions
                            </div>
                            <div className="c-form-field__wrapper">
                              <Select
                                options={userRoles}
                                value={values.users[index].role}
                                onChange={(v: any) => {
                                  return setFieldValue(
                                    `users[${index}].role`,
                                    v,
                                  );
                                }}
                              />
                            </div>
                          </div>

                          {index > 0 ? (
                            <Button
                              onClick={() => remove(index)}
                              icon={<DeleteOutlined />}
                              className="h-invite-modal__del-btn"
                              danger
                            />
                          ) : (
                            <div className="h-invite-modal__del-btn" />
                          )}
                        </div>
                      );
                    })}
                    <Button
                      className="h-invite-modal__add-user-btn"
                      onClick={() =>
                        push({ email: '', role: userRoles[0].value })
                      }
                    >
                      Add User
                    </Button>
                  </div>
                )}
              </FieldArray>
              <div className="h-invite-modal__submit-btn-wrapper">
                <Button
                  type="primary"
                  className="h-invite-modal__submit-btn"
                  htmlType="submit"
                >
                  Send Invite
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default InviteModal;
