import { notification } from 'antd';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useClientIP } from '../../../foundation/cutom_hooks/useClientIP';
import env_constants from '../../../internals/env/env_constants.json';
import { useAppDispatch } from '../../../store/hooks';
import AuthenticationPageImage from '../elements/AuthenticationPageImage';
import ClientAuthenticationForm from '../forms/ClientAuthenticationForm';
import LeadSignupModalSuccess from '../modals/LeadSignupSuccessModal';
import { getInviteInfo, signup } from '../redux/async_thunks';
import { selectAuthStrongPassword } from '../redux/selectors';

const Signup = () => {
  const dispatch = useAppDispatch();
  const [isSignupFormLoading, setIsSignupFormLoading] = useState(true);

  const [inviteId, setInviteId] = useState<any>(undefined);

  const [inviteInfo, setInviteInfo] = useState<any>(undefined);

  const [isLeadSignup, setIsLeadSignup] = useState(false);

  const [leadSignupEmail, setLeadSignupEmail] = useState(null);

  const clientIp = useClientIP();

  const history = useHistory();

  const shouldImplementStrongPassword = useSelector(selectAuthStrongPassword);

  useEffect(() => {
    const parsed = queryString.parse(history.location.search);

    if (!Array.isArray(parsed.r) && parsed.r) {
      sessionStorage.setItem('report_code', parsed.r);
    }

    if (parsed.t) {
      setInviteId(parsed.t);
    } else {
      setIsLeadSignup(true);
      setInviteId(env_constants.DEFAULT_INVITE_ID);
    }
  }, [history.location.search]);

  const verifyInvitation = async () => {
    try {
      const resp = await dispatch(getInviteInfo({ inviteId })).unwrap();

      if (resp) {
        setInviteInfo({ ...resp });

        if (resp.emailAlreadyRegistered) {
          history.push('/login');
        } else {
          setIsSignupFormLoading(false);
        }
      } else {
        history.push('/login');
      }
    } catch (error) {
      console.log(error);
      setIsSignupFormLoading(false);
      history.push('/login');
    }
  };

  useEffect(() => {
    if (inviteId && clientIp) {
      verifyInvitation();
    }
  }, [inviteId, clientIp]);

  const leadSignupCloseHandler = () => {
    setLeadSignupEmail(null);
  };

  const handleFormSubmit = async (values) => {
    if (isSignupFormLoading) {
      return;
    }
    try {
      setIsSignupFormLoading(true);

      // Remove leading zeros using the JavaScript string method
      const phone = values.phone.replace(/^0+/, '');

      const data = {
        ...values,
        phone: `+61${phone}`,
        inviteId: inviteInfo.inviteId,
      };

      await dispatch(
        signup({ values: data, isLeadSignup: isLeadSignup }),
        // @ts-ignore
      ).unwrap();

      if (!isLeadSignup) {
        notification.success({
          message: 'Success!',
          placement: 'top',
          description: 'Successfully registered! You are now logged in.',
        });

        history.push('/');
      } else {
        setIsSignupFormLoading(false);
        setLeadSignupEmail(data.email);
      }
    } catch (error) {
      setIsSignupFormLoading(false);
    }
  };

  return (
    <>
      {leadSignupEmail && (
        <LeadSignupModalSuccess
          email={leadSignupEmail}
          closeHandler={leadSignupCloseHandler}
        />
      )}
      <div className="client-dash-login">
        {isSignupFormLoading && <FullPageLoader />}
        <ClientAuthenticationForm
          handleFormSubmit={handleFormSubmit}
          isFormLoading={isSignupFormLoading}
          formType="signup"
          redactedEmail={isLeadSignup ? undefined : inviteInfo?.redactedEmail}
          email={isLeadSignup ? undefined : inviteInfo?.email}
          isLeadSignup={isLeadSignup}
          isStrongPassword={shouldImplementStrongPassword}
        />
        <AuthenticationPageImage />
      </div>
    </>
  );
};

export default Signup;
