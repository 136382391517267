import { Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import { selectOptions, selectSearchMetricValues } from '../../redux/selectors';

const FilterFieldDropDown = ({
  metricChangeHandler,
  metricKey,
  index,
  metricKeyValues,
}: any) => {
  const metricValues = useSelector(selectSearchMetricValues);

  const options = useSelector(selectOptions);

  const keyValue = metricKeyValues.filter((metric: any) => {
    return metric.value === metricKey;
  })[0];

  const getDropdownOptions = () => {
    const dropdownOptions = metricValues?.filters[metricKey]?.map(
      (filter: any) => {
        return {
          label: filter.key || '0',
          value: filter.value,
        };
      },
    );

    return dropdownOptions
      ? [{ label: 'Any', value: null }, ...dropdownOptions]
      : [];
  };

  const checkUndefined = (value: any) => {
    return value === undefined ? null : value;
  };

  return (
    <div className="l-filter-field" key={index}>
      <label className="l-filter-field__label">
        <span>{keyValue.key}</span>
      </label>
      <div className="l-filter-field__input">
        <div className="l-filter-field__select">
          <p>Min</p>
          <Select
            options={getDropdownOptions()}
            // @ts-ignore
            value={checkUndefined(options?.searchMetrics[metricKey]?.min)}
            onChange={(value: any) => {
              metricChangeHandler(
                // @ts-ignore
                [value, options.searchMetrics[metricKey].max],
                metricKey,
              );
            }}
          />
        </div>
        <div className="l-filter-field__select">
          <p>Max</p>
          <Select
            options={getDropdownOptions()}
            // @ts-ignore
            value={checkUndefined(options?.searchMetrics[metricKey]?.max)}
            onChange={(value: any) => {
              metricChangeHandler(
                // @ts-ignore
                [options.searchMetrics[metricKey].min, value],
                metricKey,
              );
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterFieldDropDown;
