import { Button, Input, Modal, Tooltip } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';

import InviteSentIcon from '../../../foundation/assets/svgs/InviteSentIcon';
import { selectSuburb } from '../redux/selectors';

type InviteSuccessModalProps = {
  closeHandler: () => void;
  clientName?: string;
  editedReport: boolean;
  isEmailNotified: boolean;
  isNotify: boolean;
  isClientRecipient: boolean;
  email?: string;
  reportLink?: string;
};
const ShareSuburbReportSuccessModal = ({
  closeHandler,
  clientName,
  editedReport,
  isEmailNotified,
  isNotify,
  isClientRecipient,
  email,
  reportLink,
}: InviteSuccessModalProps) => {
  const handleModalClose = () => {
    closeHandler();
  };

  const suburb = useSelector(selectSuburb);

  const handleInputClick = (e: any) => {
    e.target.select();
  };

  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <InviteSentIcon />
        </span>
        <span>Suburb Report {isClientRecipient ? 'Shared' : 'Link'}</span>
      </div>
    ),
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: false,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal">
      {isClientRecipient && (
        <>
          <div className="h-invite-success-modal__text">
            <strong>{clientName}</strong> now has access to{' '}
            {editedReport ? 'the edited version of' : ''} the{' '}
            <strong>{suburb.suburbName}</strong> suburb report once they sign up
            or log in.
          </div>
          {!isNotify && (
            <div className="h-invite-success-modal__text">
              The client can find the shared suburb report in the dashboard.
            </div>
          )}
          {isNotify && (
            <div className="h-invite-success-modal__text">
              {`${
                isEmailNotified
                  ? 'An email notification with the link has been sent. The client can also access the report in the dashboard.'
                  : "No email is found for this client, therefore the notification won't be sent this time. However, the client can still access the report in the dashboard."
              }`}
            </div>
          )}
        </>
      )}
      {!isClientRecipient && (
        <>
          {email && (
            <>
              <div className="h-invite-success-modal__text">
                An email containing the link to{' '}
                {editedReport ? 'the edited version of' : ''} the{' '}
                <strong>{suburb.suburbName}</strong> suburb report has been sent
                to <strong>{email}</strong>.
              </div>
              <div className="h-invite-success-modal__text">
                You may also copy the link below:
              </div>
            </>
          )}
          {!email && (
            <div className="h-invite-success-modal__text">
              Here&apos;s the link to{' '}
              {editedReport ? 'the edited version of' : ''} the{' '}
              <strong>{suburb.suburbName}</strong> suburb report:
            </div>
          )}
          <Input
            style={{ background: '#f1f5f7' }}
            value={reportLink}
            onClick={handleInputClick}
          />
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Tooltip placement="bottom" trigger="click" title="Copied!">
              <span
                style={{
                  cursor: 'pointer',
                  color: '#008491',
                  textDecoration: 'underline',
                  margin: '5px auto 20px',
                  display: 'inline-block',
                }}
                onClick={() => {
                  navigator.clipboard.writeText(reportLink ?? '');
                }}
              >
                Copy to clipboard
              </span>
            </Tooltip>
          </div>
        </>
      )}
      <div className="h-invite-success-modal__btn-wrapper">
        <Button
          type="primary"
          className="h-invite-success-modal__btn"
          onClick={handleModalClose}
          style={{ width: '130px' }}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default ShareSuburbReportSuccessModal;
