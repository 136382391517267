import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const agencyFormValidationSchema = () => {
  const fields: any = {
    agencyName: Yup.string().trim().required(requiredMessage).nullable(),
    agencyAddress: Yup.string().trim().required(requiredMessage).nullable(),
  };

  return Yup.object().shape(fields);
};
