import { MailOutlined, SearchOutlined, UserOutlined } from '@ant-design/icons';
import {
  Avatar,
  Button,
  Checkbox,
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
} from 'antd';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { selectUser } from '../../authentication/redux/selectors';
import { fetchClients } from '../../client/redux/async_thunks';
import { selectClients } from '../../client/redux/selectors';
import { setClients } from '../../client/redux/slice';
import { shareSuburbReport } from '../redux/async_thunks';
import { selectSuburb } from '../redux/selectors';

const ShareSuburbReportModal = ({
  closeHandler,
  editedReport,
  setSelectedClientName,
  setIsEmailNotified,
  isNotify,
  setIsNotify,
  isClientRecipient,
  setIsClientRecipient,
  email,
  setEmail,
  setReportLink,
}: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState<string | undefined>(undefined);
  const [selectedClientId, setSelectedClientId] = useState<any>(null);
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [isSearchErrorMessageVisible, setIsSearchErrorMessageVisible] =
    useState(false);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const clients = useSelector(selectClients);
  const suburb = useSelector(selectSuburb);

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchClientsResults = async (searchValue: string) => {
    setIsSearchErrorMessageVisible(false);

    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      if (user?.token) {
        await dispatch(
          fetchClients({
            token: user.token,
            searchValue: searchValue,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      dispatch(setClients([]));

      if (error.message.includes('404')) {
        setIsSearchErrorMessageVisible(true);
      }
    }
  };

  const handleClientSearch = () => {
    if (searchText) {
      fetchClientsResults(searchText);
    }
  };

  const sendShareRequest = async (optionsParam?: any) => {
    setIsLoading(true);

    try {
      const response = await dispatch(
        shareSuburbReport({
          token: user?.token,
          data: {
            suburbCode: suburb.suburbCode,
            editedReport: editedReport,
            clientId: isClientRecipient ? selectedClientId : null,
            notify: isClientRecipient ? isNotify : null,
            userId: user?.user_id,
            email: !isClientRecipient ? email : null,
          },
        }),
      )
        // @ts-ignore
        .unwrap();

      if (isClientRecipient) {
        setIsEmailNotified(
          response?.responseMessage !==
            'Report shared, but client has no email address yet.',
        );
      } else {
        setReportLink(response.reportLink);
      }

      setIsLoading(false);
      closeHandler(true);
    } catch (error) {
      errorHandler(error);
      closeHandler();
    }
  };

  const validateEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    const isValid = emailRegex.test(email);
    setIsEmailValid(isValid);
  };

  const handleChange = (e: any) => {
    setIsNotify(e.target.checked);
  };

  const handleRadioChange = (e: RadioChangeEvent) => {
    setIsClientRecipient(e.target.value);
  };

  return (
    <Modal
      title={'Share Suburb Report'}
      open={true}
      footer={false}
      onCancel={closeHandler}
      centered
      maskClosable={false}
      width={450}
      styles={{
        body: {
          padding: '0',
        },
      }}
      closable={!isLoading}
    >
      {isLoading && <FullPageLoader classNames="l-filter-modal__page-loader" />}
      <div className="client-view l-report__client-view">
        <p style={{ width: '100%', marginBottom: '10px' }}>
          You&lsquo;re about to share the {editedReport ? 'edited' : ''} suburb
          report for <strong>{suburb.suburbName}</strong>.
        </p>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            width: '100%',
            padding: '0 0 20px',
            borderBottom: '1px solid #d9dbe9',
            marginBottom: '15px',
          }}
        >
          <p style={{ margin: '0 12px 0 0' }}>Please select recipient:</p>
          <div className="l-report__button-group-wrap">
            <Radio.Group onChange={handleRadioChange} value={isClientRecipient}>
              <Radio.Button value={true}>Client</Radio.Button>
              <Radio.Button value={false}>Non-client</Radio.Button>
            </Radio.Group>
          </div>
        </div>
        {isClientRecipient && (
          <div style={{ width: '100%' }}>
            <p style={{ width: '100%', marginBottom: '10px' }}>
              Please search and select a client:
            </p>
            <div className="client-view__search-wrapper">
              <Input
                value={searchText}
                className="client-view__search-input"
                prefix={<SearchOutlined />}
                placeholder={`Search Clients`}
                onChange={(e) => setSearchText(e.target.value)}
                onPressEnter={handleClientSearch}
              />
              <Button
                type="primary"
                className="client-view__search-btn"
                onClick={handleClientSearch}
                disabled={!searchText}
              >
                Search
              </Button>
            </div>
            <div className="client-view__search-results-wrapper">
              <ul className="l-report__client-view-list">
                {isSearchErrorMessageVisible && (
                  <p className="client-view__search-no-result">
                    No clients found.
                  </p>
                )}
                {clients &&
                  clients?.map((client) => (
                    <li
                      className={`l-report__client-view-list-item${
                        selectedClientId === client?.clientId
                          ? ' l-report__client-view-list-item--selected'
                          : ''
                      }`}
                      key={client?.clientId}
                    >
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setSelectedClientId(client?.clientId);
                          setSelectedClientName(client?.clientName);
                        }}
                      >
                        <Avatar
                          size={30}
                          className="client-view__card-avatar"
                          src={client.clientPhoto}
                          style={{ backgroundColor: '#ddd', color: '#555' }}
                          icon={<UserOutlined />}
                        />
                        <span>{client.clientName}</span>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
            <Checkbox onChange={handleChange}>
              Send an email notification
            </Checkbox>
          </div>
        )}
        {!isClientRecipient && (
          <div style={{ width: '100%' }}>
            <p style={{ width: '100%', marginBottom: '10px' }}>
              Once you proceed, the report link will be generated. If you
              prefer, you can also provide an email address to share the link
              via email:
            </p>
            <div>
              <Input
                value={email}
                className="client-view__search-input"
                prefix={<MailOutlined />}
                placeholder={`Email Address (Optional)`}
                onChange={(e) => {
                  setEmail(e.target.value);
                  validateEmail(e.target.value);
                }}
              />
              {!!email && !isEmailValid && (
                <div style={{ color: '#eb5757', marginTop: '-5px' }}>
                  Email address is invalid
                </div>
              )}
            </div>
          </div>
        )}
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          <Button
            className="l-report__client-view-button"
            onClick={() => {
              closeHandler();
            }}
          >
            Cancel
          </Button>
          <Button
            className="l-report__client-view-button"
            type="primary"
            htmlType="submit"
            disabled={
              isLoading ||
              (isClientRecipient && !selectedClientId) ||
              (!isClientRecipient && !!email && !isEmailValid)
            }
            loading={isLoading}
            onClick={() => {
              sendShareRequest();
            }}
          >
            {isClientRecipient && 'Share'}
            {!isClientRecipient && (
              <>
                {!!email && 'Share Via Email'}
                {!email && 'Get Report Link'}
              </>
            )}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareSuburbReportModal;
