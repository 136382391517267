import React from 'react';

function CheckCircle() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      fill="none"
      viewBox="0 0 70 70"
    >
      <path
        stroke="#00B2A3"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M64.166 32.317V35A29.167 29.167 0 1146.871 8.342m17.295 3.325L35 40.862l-8.75-8.75"
      ></path>
    </svg>
  );
}

export default CheckCircle;
