import {
  ArrowRightOutlined,
  CaretDownOutlined,
  CaretUpOutlined,
  CloseOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  notification,
  Popover,
  Spin,
  Switch,
  Table,
  Tooltip,
} from 'antd';
import { ColumnsType } from 'antd/es/table';
import { each, isEmpty, map as pluck } from 'lodash';
import debounce from 'lodash/debounce';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import reactStringReplace from 'react-string-replace';

import {
  selectEnabledSearchMetrics,
  selectIsSearchFeatureLoading,
  selectKeyword,
  selectLastSearchCategoryIdSetting,
  selectOptions,
  selectQueryValues,
  selectResults,
  selectSearchMetricValues,
  selectSuggestions,
} from '../../features/suburb_scoring/redux/selectors';
import {
  resetResults,
  resetSuggestions,
  searchMetricsDefaults,
  setEnabledSearchMetrics,
  setIsSearchFeatureLoading,
  setKeyword,
  setLastSearchCategoryIdSetting,
  setOptions,
  setQueryValues,
} from '../../features/suburb_scoring/redux/slice';
import Close from '../../foundation/assets/svgs/Close';
import Filter from '../../foundation/assets/svgs/Filter';
import Search from '../../foundation/assets/svgs/Search';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import useRole from '../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import envConstant from '../../internals/env/env_constants.json';
import {
  resetReport,
  resetSuburb,
  resetTemplate,
  setIsEditedVersion,
  setIsReportToggleEnabled,
} from '../ai_report/redux/slice';
import { selectUser } from '../authentication/redux/selectors';
import StickyFooter from '../property_strategy/agency_lead_access/sticky_footer/StickyFooter';
import FilterModal from './filter/filter_modal/FilterModal';
import Pagination from './pagination/Pagination';
import {
  suburbScoreSearch,
  suburbScoreSuggestions,
} from './redux/async_thunks';

const SuburbScoring = () => {
  const COLUMN_MIN_WIDTH = '80px';
  const keywordMinLength = envConstant.SUBURB_SCORE_KEYWORD_MIN_LENGTH;

  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector(selectUser);
  const suggestions = useSelector(selectSuggestions);
  const queryValues = useSelector(selectQueryValues);
  const keyword = useSelector(selectKeyword);
  const results = useSelector(selectResults);
  const options = useSelector(selectOptions);
  const enabledSearchMetrics = useSelector(selectEnabledSearchMetrics);
  const searchMetricValues = useSelector(selectSearchMetricValues);
  const isSearchFeatureLoading = useSelector(selectIsSearchFeatureLoading);
  const lastSearchCategoryIdSetting = useSelector(
    selectLastSearchCategoryIdSetting,
  );

  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);

  const [searchKeywordDisplay, setSearchKeywordDisplay] = useState('');

  const [width, setWidth] = useState<any>(0);

  const [isFilterModalActive, setIsFilterModalActive] = useState(false);

  const [showScrollButtons, setShowScrollButtons] = useState(false);
  const [isScrollRightButtonVisible, setIsScrollRightButtonVisible] =
    useState(true);

  const [isScrollButtonSticky, setIsScrollButtonSticky] = useState(true);

  const [isPopoverOpen, setIsPopoverOpen] = useState({});

  const { isDesktopViewport, isMobileViewport } = useViewport();

  const [, , , , , isAgencyLeadView] = useRole();

  const phantomTextRef = useRef();
  const inputRef = useRef(null);
  const selectedRef = useRef();

  const tableRef: any = useRef(null);

  const metricKeyValues = [
    {
      key: 'Growth Potential Score',
      value: 'growthScore',
    },
    {
      key: 'Median Sale Price',
      value: 'medianSalePrice',
    },
    {
      key: 'Median Sale Price Growth Forecast (15m)',
      value: 'medianSalePriceGrowthForecast15Mo',
    },
    {
      key: 'Cashflow Score',
      value: 'cashflowScore',
    },
    {
      key: 'Gross Rental Yield',
      value: 'grossRentalYield',
    },
    {
      key: 'Est. Net Rental Yield',
      value: 'estNetRentalYield',
    },
    {
      key: 'Median Rental Growth Forecast (15m)',
      value: 'medianRentalGrowthForecast15Mo',
    },
    {
      key: 'Affordability Score',
      value: 'affordabilityScore',
    },
    {
      key: 'Socio-economic Score',
      value: 'socioEconomicScore',
    },
    {
      key: 'Population',
      value: 'population',
    },
    {
      key: 'Suburb Population Growth Forecast (5yrs)',
      value: 'populationGrowthForecast5Yr',
    },
    {
      key: 'LGA Population Growth Forecast (5yrs)',
      value: 'lgaPopulationGrowthForecast5Yr',
    },
  ];

  const scrollToBottom = (ref: any, scrollHeight = 0) => {
    if (ref?.current) {
      ref.current.scrollTop = ref?.current?.scrollHeight - scrollHeight;
    }
  };

  const scrollToTop = () => {
    const scrollToElement = document.getElementById('h2-anchor');

    if (scrollToElement) {
      scrollToElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  const prepareSearchMetrics = (
    options: any,
    enabledSearchMetrics: any,
    searchMetricValues: any,
  ) => {
    let searchMetricsChecked: any = {};

    const assignValues = (metricValue: any, metricKey: string) => {
      const minMaxLimits = searchMetricValues?.filters[metricKey];
      searchMetricsChecked[metricKey] = {};

      if (metricValue.min !== null && metricValue.min !== minMaxLimits?.min) {
        searchMetricsChecked[metricKey].min = metricValue.min;
      }

      if (metricValue.max !== null && metricValue.max !== minMaxLimits?.max) {
        searchMetricsChecked[metricKey].max = metricValue.max;
      }
    };

    if (!enabledSearchMetrics?.length) {
      return null;
    }

    each(options.searchMetrics, (metricValue: any, metricKey: string) => {
      if (enabledSearchMetrics.includes(metricKey)) {
        assignValues(metricValue, metricKey);
      }
    });

    // Remove empty objects
    searchMetricsChecked = Object.keys(searchMetricsChecked)
      .filter((key) => {
        return (
          !!searchMetricsChecked[key] || !isEmpty(searchMetricsChecked[key])
        );
      })
      .reduce((obj: any, key) => {
        obj[key] = searchMetricsChecked[key];
        return obj;
      }, {});

    return isEmpty(searchMetricsChecked) ? null : searchMetricsChecked;
  };

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const fetchResults = async (optionsParam?: any, isClearFilter?: boolean) => {
    dispatch(setIsSearchFeatureLoading(true));

    try {
      const searchMetricsChecked = prepareSearchMetrics(
        options,
        enabledSearchMetrics,
        searchMetricValues,
      );

      const searchValues = queryValues.length
        ? pluck(queryValues, 'value')
        : null;

      const searchOptions = optionsParam?.pageIndex
        ? {
            ...options,
            ...optionsParam,
          }
        : {
            ...options,
            ...optionsParam,
            pageIndex: 1,
          };

      const data = isClearFilter
        ? {
            userId: user?.user_id,
            categoryId: 0,
            orderAsc: false,
            searchMetrics: null,
            searchValues: null,
            pageIndex: 1,
          }
        : {
            userId: user?.user_id,
            searchValues: searchValues,
            ...searchOptions,
            ...{
              searchMetrics: searchMetricsChecked,
            },
          };

      const response: any = await dispatch(
        suburbScoreSearch({
          token: user?.token,
          data: data,
        }),
      )
        // @ts-ignore
        .unwrap();

      const hasNoResults =
        response &&
        !response?.pageCount &&
        response?.pageIndex &&
        response?.pageIndex === 1;

      if (hasNoResults) {
        notification.warning({
          message: 'No results found',
          description:
            'Sorry, the search and filter combination yielded no results.',
          placement: 'topRight',
        });

        dispatch(resetResults());
      }

      dispatch(setLastSearchCategoryIdSetting(data.categoryId));

      if (!hasNoResults && optionsParam?.pageIndex) {
        scrollToTop();
      }
    } catch (error) {
      errorHandler(error);
      dispatch(setLastSearchCategoryIdSetting(0));
    }

    setIsFilterModalActive(false);
    dispatch(setKeyword(undefined));
    dispatch(resetSuggestions());
    dispatch(setIsSearchFeatureLoading(false));
  };

  const fetchSuggestions = async (searchKeyword: string) => {
    setIsSuggestionsLoading(true);

    try {
      await dispatch(
        suburbScoreSuggestions({
          data: {
            userId: user?.user_id,
            searchKeyword: searchKeyword,
            selectedValues: queryValues.length
              ? pluck(queryValues, 'value')
              : null,
          },
          token: user?.token,
        }),
      );
    } catch (error) {
      errorHandler(error);
    }

    dispatch(setKeyword(searchKeyword));
    setIsSuggestionsLoading(false);
  };

  const setSearchKeywordRealValue = (e: any) => {
    const value = e.target.value;

    if (!value.length) {
      setSearchKeywordDisplay('');
    }

    if (value.length < keywordMinLength) {
      dispatch(setKeyword(undefined));
      dispatch(resetSuggestions());
    } else {
      fetchSuggestions(value);
    }
  };

  const isAllSelected =
    suggestions.filter((suggestion: any) => {
      return queryValues.some((queryValue: any) => {
        return queryValue.value === suggestion.value;
      });
    }).length === suggestions.length;

  const handleFilterModalClose = () => {
    setIsFilterModalActive(false);
  };

  // Override property names to match the sort type
  // Temp
  const overridePropertyName = (sortType: string) => {
    let dataIndex: string;

    switch (sortType) {
      case 'socioEconomicScore':
        dataIndex = 'socioEcoScore';
        break;
      case 'affordabilityScore':
        dataIndex = 'affordScore';
        break;
      default:
        dataIndex = sortType;
    }

    return dataIndex;
  };

  const handleHeaderCell = (record: any) => {
    const dataIndex = overridePropertyName(record.dataIndex);

    return {
      onClick: () => {
        if (dataIndex === options.sortType) {
          dispatch(
            setOptions({
              ...options,
              sortType: dataIndex,
              orderAsc: !options.orderAsc,
            }),
          );
        } else {
          dispatch(
            setOptions({
              ...options,
              sortType: dataIndex,
              orderAsc: true,
            }),
          );
        }

        fetchResults({
          sortType: dataIndex,
          orderAsc: dataIndex === options.sortType ? !options.orderAsc : true,
        });
      },
    };
  };

  const handleLayoutScroll = () => {
    const layoutElement = document.querySelector('.ant-layout');

    if (layoutElement && layoutElement.scrollTop > 200) {
      setShowScrollButtons(true);
    } else {
      setShowScrollButtons(false);
    }

    if (tableRef?.current?.nativeElement) {
      const tableBottom =
        tableRef.current.nativeElement.getBoundingClientRect().bottom;

      const viewportHeight = window.innerHeight;

      if (tableBottom <= viewportHeight) {
        setIsScrollButtonSticky(true);
      } else {
        setIsScrollButtonSticky(false);
      }
    }
  };

  const handleScrollRight = () => {
    if (tableRef?.current?.nativeElement) {
      if (!isDesktopViewport) {
        setIsPopoverOpen({});
      }

      const tableBody =
        tableRef.current.nativeElement.getElementsByClassName(
          'ant-table-body',
        )[0];
      const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

      tableBody.scrollTo({
        left: maxScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const handleTableScroll = (event: any) => {
    const tableBody = event.target;
    const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

    if (tableBody.scrollLeft <= maxScrollLeft - 1) {
      setIsScrollRightButtonVisible(true);
    } else {
      setIsScrollRightButtonVisible(false);
    }
  };

  const handleResize = () => {
    if (tableRef?.current?.nativeElement) {
      const tableBody =
        tableRef.current.nativeElement.getElementsByClassName(
          'ant-table-body',
        )[0];
      const maxScrollLeft = tableBody.scrollWidth - tableBody.clientWidth;

      if (tableBody.scrollLeft <= maxScrollLeft - 1) {
        setIsScrollRightButtonVisible(true);
      } else {
        setIsScrollRightButtonVisible(false);
      }
    }
  };

  const renderBadge = (value?: string, color?: string, classNames?: string) => {
    if (color) {
      const className = color === '#FF0000' ? 'negative' : 'positive';

      return (
        <div
          className={`l-suburb-scoring__badge l-suburb-scoring__badge--${className} ${classNames}`}
        >
          {value}
        </div>
      );
    }

    return value ? (
      <div className="l-suburb-scoring__badge">{value}</div>
    ) : (
      <></>
    );
  };

  const renderTextWithIcon = (text: any, icon: any) => {
    const style = { whiteSpace: 'nowrap' };
    if (!icon) {
      return <span>{text}</span>;
    }

    const words = text.split(' ');

    if (words.length === 1) {
      return (
        <span style={style}>
          {text} {icon}
        </span>
      );
    }

    const lastWord = words.pop();

    return (
      <>
        {words.join(' ')}{' '}
        <span style={style}>
          {lastWord} {icon}
        </span>
      </>
    );
  };

  const renderColumnHeader = (
    dataIndexParam: string,
    titleParam?: string,
    opt?: any,
  ) => {
    const dataIndex = overridePropertyName(dataIndexParam);

    const popoverContent = (
      <button
        className="l-descriptor"
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        {!isDesktopViewport && (
          <button
            style={{
              position: 'absolute',
              top: 0,
              right: '4px',
              cursor: 'pointer',
            }}
            onClick={() => {
              if (!isDesktopViewport) {
                setIsPopoverOpen({});
              }
            }}
          >
            <CloseOutlined />
          </button>
        )}
        <div
          style={{ textAlign: 'left' }}
          dangerouslySetInnerHTML={{
            __html: results?.descriptors ? results.descriptors[dataIndex] : '',
          }}
        />
      </button>
    );

    const popover = (
      <>
        {!opt?.noDescriptor && (
          <Popover
            content={popoverContent}
            title={null}
            placement="top"
            trigger={isDesktopViewport ? 'hover' : 'click'}
            overlayClassName="no-override"
            open={isPopoverOpen[dataIndex]}
          >
            <button
              style={{ display: 'inline-block' }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (!isDesktopViewport) {
                  setIsPopoverOpen({
                    [dataIndex]: !isPopoverOpen[dataIndex],
                  });
                }
              }}
            >
              <InfoCircleOutlined
                style={{
                  color: '#888',
                  fontSize: '.8em',
                  cursor: isDesktopViewport ? 'default' : 'pointer',
                }}
              />
            </button>
          </Popover>
        )}
      </>
    );

    const metric = metricKeyValues.find((metric) => {
      return dataIndexParam === metric.value;
    });

    const title = titleParam ?? metric?.key;

    let classNames = title?.includes('Score')
      ? 'l-suburb-scoring__column-header--bold'
      : '';

    if (opt?.noSort) {
      classNames += ' l-suburb-scoring__column-header--default';
    }

    const caretStyle = {
      fontSize: '17px',
      color: '#18362e',
    };

    return (
      <span className={`l-suburb-scoring__column-header ${classNames}`}>
        <span style={{ marginRight: '5px' }}>
          <span style={{ marginRight: '5px' }}>
            {renderTextWithIcon(title, popover)}
          </span>
        </span>
        {dataIndex && options.sortType === dataIndex && !opt?.noSort && (
          <span
            style={{
              display: 'inline-block',
              textAlign: 'center',
              marginTop: '5px',
              width: '100%',
            }}
          >
            {options.orderAsc ? (
              <CaretUpOutlined style={caretStyle} />
            ) : (
              <CaretDownOutlined style={caretStyle} />
            )}
          </span>
        )}
      </span>
    );
  };

  const renderScore = (value?: number, color?: string) => {
    if (!value) {
      return <></>;
    }

    let colorMatch: string;

    switch (value) {
      case 5:
        colorMatch = '#1cb002';
        break;
      case 4:
        colorMatch = '#82e003';
        break;
      case 3:
        colorMatch = '#ffd301';
        break;
      case 2:
        colorMatch = '#fc950c';
        break;
      default:
        colorMatch = '#f50603';
    }

    return (
      <div
        className="l-suburb-scoring__percent-circle"
        style={{
          background: `radial-gradient(closest-side, white 79%, transparent 84% 100%), conic-gradient(${colorMatch} ${
            value * 20
          }%, white 0)`,
        }}
      >
        <span className="l-suburb-scoring__percent-circle-value">{value}</span>
      </div>
    );
  };

  const columns: ColumnsType<any> = [
    {
      title: renderColumnHeader('suburbName', 'Suburb'),
      dataIndex: 'suburbName',
      key: 'suburbName',
      fixed: 'left',
      width: '100px',
      onHeaderCell: handleHeaderCell,
      render: (data, row) => {
        return (
          <>
            {/* @ts-ignore */}
            <Tooltip
              placement="top"
              title={!!row.categoryId && results?.suburbWarning}
            >
              <button
                className="l-suburb-scoring__suburb-name"
                onClick={() => {
                  dispatch(resetSuburb());
                  dispatch(resetReport());
                  dispatch(resetTemplate());

                  dispatch(setIsReportToggleEnabled(false));
                  dispatch(setIsEditedVersion(false));

                  history.push(
                    `/suburb-scoring${!isAgencyLeadView ? '-internal' : ''}/report/${row.suburbCode}`,
                  );
                }}
              >
                {renderTextWithIcon(
                  data,
                  <>
                    {row.categoryId ? (
                      <WarningOutlined style={{ color: '#f00' }} />
                    ) : null}
                  </>,
                )}
              </button>
            </Tooltip>
            <span>
              {row.postCode}, {row.state}
            </span>
          </>
        );
      },
    },
    {
      title: renderColumnHeader('growthScore'),
      dataIndex: 'growthScore',
      key: 'growthScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('medianSalePrice'),
      dataIndex: 'medianSalePrice',
      key: 'medianSalePrice',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data);
      },
    },
    {
      title: renderColumnHeader(
        'medianSalePriceForecast15Mo',
        'Median Sale Price Forecast (15m)',
      ),
      dataIndex: 'medianSalePriceForecast15Mo',
      key: 'medianSalePriceForecast15Mo',
      onHeaderCell: handleHeaderCell,
      children: [
        {
          title: renderColumnHeader('medianSalePriceForecast15MoLow', 'Low', {
            noDescriptor: true,
            noSort: true,
          }),
          dataIndex: 'medianSalePriceForecast15MoLow',
          key: 'medianSalePriceForecast15MoLow',
          width: COLUMN_MIN_WIDTH,
          render: (data, row) => {
            return (
              <div>
                {renderBadge(data)}
                <br />
                {renderBadge(
                  row?.medianSalePriceGrowthForecast15MoLow?.value,
                  row?.medianSalePriceGrowthForecast15MoLow?.key,
                  'l-suburb-scoring__badge--margin',
                )}
              </div>
            );
          },
        },
        {
          title: renderColumnHeader(
            'medianSalePriceForecast15Mo',
            'Best Estimate',
            {
              noDescriptor: true,
              noSort: true,
            },
          ),
          dataIndex: 'medianSalePriceForecast15Mo',
          key: 'medianSalePriceForecast15Mo',
          width: COLUMN_MIN_WIDTH,
          render: (data, row) => {
            return (
              <div>
                {renderBadge(data)}
                <br />
                {renderBadge(
                  row?.medianSalePriceGrowthForecast15Mo?.value,
                  row?.medianSalePriceGrowthForecast15Mo?.key,
                  'l-suburb-scoring__badge--margin',
                )}
              </div>
            );
          },
        },
        {
          title: renderColumnHeader('medianSalePriceForecast15MoHigh', 'High', {
            noDescriptor: true,
            noSort: true,
          }),
          dataIndex: 'medianSalePriceForecast15MoHigh',
          key: 'medianSalePriceForecast15MoHigh',
          width: COLUMN_MIN_WIDTH,
          render: (data, row) => {
            return (
              <div>
                {renderBadge(data)}
                <br />
                {renderBadge(
                  row?.medianSalePriceGrowthForecast15MoHigh?.value,
                  row?.medianSalePriceGrowthForecast15MoHigh?.key,
                  'l-suburb-scoring__badge--margin',
                )}
              </div>
            );
          },
        },
      ],
    },
    {
      title: renderColumnHeader('cashFlowScore', 'Cash Flow Score'),
      dataIndex: 'cashFlowScore',
      key: 'cashFlowScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('estNetRentalYield'),
      dataIndex: 'estNetRentalYield',
      key: 'estNetRentalYield',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('grossRentalYield', 'Gross Rental Yield'),
      dataIndex: 'grossRentalYield',
      key: 'grossRentalYield',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('medianRentalGrowthForecast15Mo'),
      dataIndex: 'medianRentalGrowthForecast15Mo',
      key: 'medianRentalGrowthForecast15Mo',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('affordabilityScore'),
      dataIndex: 'affordabilityScore',
      key: 'affordabilityScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('socioEconomicScore'),
      dataIndex: 'socioEconomicScore',
      key: 'socioEconomicScore',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderScore(data?.value);
      },
    },
    {
      title: renderColumnHeader('population'),
      dataIndex: 'population',
      key: 'population',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data);
      },
    },
    /*
    {
      title: renderColumnHeader(
        'populationForecast5Yr',
        'Population Forecast (5Yrs)',
      ),
      dataIndex: 'populationForecast5Yr',
      key: 'populationForecast5Yr',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data);
      },
    },
    */
    {
      title: renderColumnHeader('populationGrowthForecast5Yr'),
      dataIndex: 'populationGrowthForecast5Yr',
      key: 'populationGrowthForecast5Yr',
      width: COLUMN_MIN_WIDTH,
      onHeaderCell: handleHeaderCell,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
    {
      title: renderColumnHeader('lgaPopulationGrowthForecast5Yr'),
      dataIndex: 'lgaPopulationGrowthForecast5Yr',
      key: 'lgaPopulationGrowthForecast5Yr',
      onHeaderCell: handleHeaderCell,
      width: COLUMN_MIN_WIDTH,
      render: (data) => {
        return renderBadge(data?.value, data?.key);
      },
    },
  ];

  useEffect(() => {
    if (user) {
      if (searchKeywordDisplay) {
        // @ts-ignore
        setWidth(phantomTextRef.current.offsetWidth + 100);
      } else {
        setWidth('100%');
      }
    }
  }, [searchKeywordDisplay]);

  useEffect(() => {
    if (user) {
      scrollToBottom(selectedRef);
    }
  }, [queryValues]);

  useEffect(() => {
    if (user) {
      const showMessageKey = 'signup_success';

      if (!results) {
        fetchResults();
      }

      if (sessionStorage.getItem(showMessageKey) === 'yes') {
        notification.success({
          message: 'Success!',
          placement: 'top',
          description: 'Registration completed! You are now logged in.',
        });

        sessionStorage.removeItem(showMessageKey);
      }
    }
  }, []);

  useEffect(() => {
    const layoutElement = document.querySelector('.ant-layout');

    if (layoutElement) {
      layoutElement.addEventListener('scroll', handleLayoutScroll);
    }

    return () => {
      if (layoutElement) {
        layoutElement.removeEventListener('scroll', handleLayoutScroll);
      }
    };
  }, []);

  useEffect(() => {
    const tableBody =
      tableRef?.current?.nativeElement?.getElementsByClassName(
        'ant-table-body',
      )[0];

    if (tableBody) {
      tableBody.removeEventListener('scroll', handleTableScroll);
      tableBody.addEventListener('scroll', handleTableScroll);
    }

    return () => {
      if (tableBody) {
        tableBody.removeEventListener('scroll', handleTableScroll);
      }
    };
  }, [tableRef?.current]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const widerSearchValue = useMemo(() => {
    if (options.categoryId === 0) {
      return false;
    } else {
      return true;
    }
  }, [options.categoryId]);

  const onWiderSearchChange = () => {
    let categoryId = 0;
    if (!widerSearchValue) {
      categoryId = 1;
      dispatch(
        setEnabledSearchMetrics([...enabledSearchMetrics, 'categoryId']),
      );
    } else {
      dispatch(
        setEnabledSearchMetrics(
          enabledSearchMetrics.filter((metric) => {
            return metric !== 'categoryId';
          }),
        ),
      );
    }

    dispatch(
      setOptions({
        ...options,
        categoryId,
      }),
    );
  };

  const renderForm = () => {
    return (
      <div className="l-suburb-scoring__form-wrapper">
        {isFilterModalActive && (
          <FilterModal
            widerSearchValue={widerSearchValue}
            onWiderSearchChange={onWiderSearchChange}
            fetchResults={fetchResults}
            closeHandler={handleFilterModalClose}
            metricKeyValues={metricKeyValues}
          />
        )}
        <div className="l-suburb-scoring__form">
          <div className="l-suburb-scoring__input">
            <div className="l-suburb-scoring__input-content">
              <div className="l-suburb-scoring__input-field-wrapper">
                <div className="l-suburb-scoring__search-icon">
                  {isSuggestionsLoading ? (
                    <Spin
                      size={'small'}
                      style={{
                        height: '18px',
                        width: '18px',
                        marginBottom: '3px',
                      }}
                    />
                  ) : (
                    <Search />
                  )}
                </div>
                <div
                  className="l-suburb-scoring__selected"
                  // @ts-ignore
                  ref={selectedRef}
                  onClick={() => {
                    // @ts-ignore
                    inputRef.current.focus();
                  }}
                  role="button"
                >
                  {queryValues.map((queryValue: any) => {
                    return (
                      <div
                        className="l-suburb-scoring__overflow-item-suggestion"
                        key={queryValue.value}
                      >
                        <span>{queryValue.key}</span>
                        <strong
                          onClick={(e) => {
                            e.stopPropagation();

                            dispatch(
                              setQueryValues(
                                queryValues.filter((queryValueCurrent: any) => {
                                  return (
                                    queryValueCurrent.value !== queryValue.value
                                  );
                                }),
                              ),
                            );
                            // @ts-ignore
                            inputRef.current.focus();
                          }}
                          className="l-suburb-scoring__overflow-item-remove"
                        >
                          <Close />
                        </strong>
                      </div>
                    );
                  })}
                  <div className="l-suburb-scoring__overflow-item">
                    <span
                      className="l-suburb-scoring__phantom-text"
                      // @ts-ignore
                      ref={phantomTextRef}
                    >
                      <span>{searchKeywordDisplay}</span>
                    </span>
                    <input
                      placeholder={'State, LGA, Postcode, or Suburb'}
                      onChange={debounce(setSearchKeywordRealValue, 200)}
                      onKeyUp={() => {
                        // @ts-ignore
                        setSearchKeywordDisplay(inputRef.current.value);
                      }}
                      style={{
                        width,
                      }}
                      defaultValue={keyword}
                      ref={inputRef}
                    />
                  </div>
                </div>
              </div>

              <div className="l-suburb-scoring__button-group">
                <Button
                  type="primary"
                  className="l-suburb-scoring__primary-button"
                  onClick={(e) => {
                    e.stopPropagation();
                    fetchResults();
                  }}
                >
                  Search
                </Button>
                <Button
                  className="l-suburb-scoring__button"
                  onClick={() => {
                    setIsFilterModalActive(true);
                  }}
                >
                  <Filter />
                  {!!enabledSearchMetrics?.length && (
                    <span>{enabledSearchMetrics.length}</span>
                  )}
                </Button>
              </div>
            </div>
          </div>
          {queryValues?.length > 0 && (
            <a
              className="l-suburb-scoring__clear-search-btn"
              onClick={() => {
                dispatch(setQueryValues([]));
                dispatch(
                  setOptions({
                    ...options,
                    searchMetrics: searchMetricsDefaults,
                    categoryId: 0,
                  }),
                );

                dispatch(setEnabledSearchMetrics([]));

                dispatch(setIsSearchFeatureLoading(true));
                fetchResults(null, true);
              }}
            >
              Clear Search
            </a>
          )}
          <div className="l-suburb-scoring__suggestions-anchor">
            {suggestions && !!suggestions.length && !isAllSelected && (
              <div className="l-suburb-scoring__suggestions">
                <ul className="l-suburb-scoring__suggestions-list">
                  {suggestions.map((suggestion: any) => {
                    if (
                      queryValues.some((queryValue: any) => {
                        return queryValue.value === suggestion.value;
                      })
                    ) {
                      return null;
                    }

                    return (
                      <div
                        className="l-suburb-scoring__suggestions-item"
                        key={suggestion.value}
                        onClick={() => {
                          dispatch(
                            setQueryValues([...queryValues, ...[suggestion]]),
                          );
                          dispatch(resetSuggestions());
                          dispatch(setKeyword(undefined));
                          // @ts-ignore
                          inputRef.current.focus();
                          // @ts-ignore
                          inputRef.current.value = '';
                        }}
                      >
                        <span>
                          {reactStringReplace(
                            suggestion.key,
                            keyword,
                            (match) => {
                              return <strong key={match}>{match}</strong>;
                            },
                          )}
                        </span>
                        <small className="l-suburb-scoring__bullet"></small>
                        <span className="l-suburb-scoring__desc">
                          {suggestion.desc}
                        </span>
                      </div>
                    );
                  })}
                </ul>
              </div>
            )}
            {!suggestions.length && keyword && (
              <div className="l-suburb-scoring__suggestions">
                <div className="l-suburb-scoring__suggestions-empty">
                  No suggestions found
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderWiderSearchHint = () => {
    if (lastSearchCategoryIdSetting) {
      return <></>;
    }

    return (
      <>
        {' '}
        <WarningOutlined style={{ color: '#f00' }} /> For more accurate
        insights, results only includes suburbs with a minimum of 30 sales in
        the last 12 months and with sufficient data. These filters can be
        removed by selecting &quot;Wider Search&quot; in the search filters.
      </>
    );
  };

  const renderResults = () => {
    const totalCount = results.resultCount;
    const lowerCount =
      ((options.pageIndex || 0) - 1) * results.itemsPerPage + 1;
    const upperCount = (options.pageIndex || 0) * results.itemsPerPage;

    return (
      <div className="l-suburb-scoring__results-wrapper">
        {results.pageCount > 1 ? (
          <div className="l-suburb-scoring__numbers">
            Showing <span>{lowerCount}</span> -{' '}
            <span>{upperCount > totalCount ? totalCount : upperCount}</span> of{' '}
            <span>{totalCount}</span> results.
            {renderWiderSearchHint()}
          </div>
        ) : (
          <div className="l-suburb-scoring__numbers">
            Showing <span>{totalCount}</span> result
            {totalCount > 1 ? 's.' : '.'}
            {renderWiderSearchHint()}
          </div>
        )}
        <Table
          columns={columns}
          pagination={false}
          scroll={{ x: 1400 }}
          size={'small'}
          dataSource={results.suburbs}
          style={{ width: '100%' }}
          sticky={true}
          ref={tableRef}
        />
        {showScrollButtons && !isDesktopViewport && (
          <div
            className="l-table-scroll-buttons"
            style={
              isScrollButtonSticky
                ? {
                    position: 'absolute',
                    bottom: isAgencyLeadView ? '155px' : '99px',
                    right: '10px',
                  }
                : {
                    bottom: isAgencyLeadView ? '56px' : '0',
                  }
            }
          >
            {isScrollRightButtonVisible && (
              <Button
                onClick={handleScrollRight}
                size="small"
                className="l-table-scroll-buttons__item"
                type="primary"
              >
                <ArrowRightOutlined />
              </Button>
            )}
          </div>
        )}
        <Pagination fetchResults={fetchResults} />
      </div>
    );
  };

  return (
    <>
      <div className="l-suburb-scoring">
        <div className="l-suburb-scoring__header-text">
          <h2 id="h2-anchor">Suburb Scoring</h2>
          <h6>
            Ready to hunt for the next hotspot?
            {!isMobileViewport ? (
              <>
                <br />
                Simply type in a suburb or LGA below, or explore the filters.
                <br />
              </>
            ) : (
              <> Simply use the field below. </>
            )}
            For the full Suburb Report, click on a Suburb Name.
          </h6>
          <p>
            {!isMobileViewport ? (
              <em>
                Note: at this stage, Pathfinder covers the residential housing
                market ONLY (ie. no units, no commercial)
              </em>
            ) : (
              <em>
                Note: Currently, Pathfinder covers the residential market only
                and it&rsquo;s best viewed on desktop.
              </em>
            )}
          </p>
        </div>
        {isSearchFeatureLoading && (
          <FullPageLoader classNames="l-suburb-scoring__page-loader" />
        )}
        {renderForm()}
        {results && <>{renderResults()}</>}

        {!isSearchFeatureLoading && !results && options.categoryId === 1 && (
          <div className="l-suburb-scoring__no-result-container">
            <h4 style={{ paddingBottom: 10 }}>No Results Found</h4>
          </div>
        )}

        {!isSearchFeatureLoading && !results && options.categoryId === 0 && (
          <div className="l-suburb-scoring__no-result-container">
            <h4 style={{ paddingBottom: 10 }}>No Results Found</h4>

            <p style={{ fontSize: 16 }}>
              Default filters show results for locations with a minimum of 30
              sales over the last 12 months and exclude suburbs with insiffcient
              data to be statistically vibable. To possibly find this location,
              would you like to widen the search by removing these default
              filters?
            </p>

            <div className="l-suburb-scoring__no-result-wider-container">
              <span className="l-suburb-scoring__no-result-wider-label">
                Wider Search
              </span>
              <Switch
                checked={widerSearchValue}
                onChange={() => {
                  onWiderSearchChange();
                  fetchResults({ categoryId: 1 });
                }}
              />
            </div>
          </div>
        )}
      </div>
      {isAgencyLeadView && !isDesktopViewport && <StickyFooter />}
    </>
  );
};

export default SuburbScoring;
