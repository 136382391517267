import { Button, Input } from 'antd';
import { Formik } from 'formik';
import React from 'react';
import { useSelector } from 'react-redux';

import FormTextArea from '../../../foundation/components/form_text_area/FormTextArea';
import { selectUser } from '../../authentication/redux/selectors';
import { contactUsFormValidationSchema } from '../validations/contact_us_form_validation';

type ContactUsFormProps = {
  handleFormSubmit: (values: any) => void;
  isFormLoading: boolean;
};

const ContactUsForm = ({
  handleFormSubmit,
  isFormLoading,
}: ContactUsFormProps) => {
  const user = useSelector(selectUser);

  const formSubmitHandler = (values: any) => {
    const data: any = {
      contactNumber: values.contactNumber.trim(),
      message: values.message.trim(),
      userId: user?.user_id,
    };

    handleFormSubmit({
      data: data,
      token: user?.token,
    });
  };

  return (
    <div className="contact-us__form-wrapper">
      <Formik
        initialValues={{
          contactNumber: '',
          message: '',
          firstName: user?.first_name,
          lastName: user?.last_name,
          email: user?.email,
          // privacyPolicyAgree: false,
        }}
        enableReinitialize
        onSubmit={formSubmitHandler}
        validationSchema={contactUsFormValidationSchema}
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="contact-us__form">
              <div className="contact-us__horizontal-fields">
                <div className="c-form-field">
                  <div className="c-form-field__label">First name</div>
                  <div className="c-form-field__wrapper">
                    <Input
                      name="firstName"
                      type="text"
                      value={values.firstName}
                      disabled
                    />
                  </div>
                </div>
                <div className="c-form-field">
                  <div className="c-form-field__label">Last name</div>
                  <div className="c-form-field__wrapper">
                    <Input
                      name="lastName"
                      type="text"
                      value={values.lastName}
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">Email</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="email"
                    type="text"
                    value={values.email}
                    disabled
                  />
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">Phone number</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="contactNumber"
                    onChange={handleChange}
                    type="text"
                    value={values.contactNumber}
                    onBlur={handleBlur}
                    placeholder={'+61 0400 000 000'}
                  />
                  <div className="c-form-field__error">
                    {errors.contactNumber ? errors.contactNumber : undefined}
                  </div>
                </div>
              </div>
              <FormTextArea
                label="Message"
                name="message"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.message}
                error={errors.message}
              />
              {/* <div className="c-form-field">
                <div className="c-form-field__wrapper">
                  <Checkbox
                    name="privacyPolicyAgree"
                    checked={values.privacyPolicyAgree}
                    onChange={handleChange}
                  >
                    You agree to our friendly <a href="#">privacy policy</a>
                  </Checkbox>
                  <div className="c-form-field__error">
                    {errors.privacyPolicyAgree
                      ? errors.privacyPolicyAgree
                      : undefined}
                  </div>
                </div>
              </div> */}
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="client-dash-login__form-btn"
                  disabled={!(isValid && dirty)}
                  loading={isFormLoading}
                >
                  Send message
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ContactUsForm;
