import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Button, Input } from 'antd';
import { Formik } from 'formik';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';

import Logo from '../../../foundation/assets/svgs/Logo.svg';
import { forgotPassFormValidationSchema } from '../validations/forgot_form_validation';
import { resetPasswordFormValidationSchema } from '../validations/reset_pass_form_validation';

type ResetPasswordFormProps = {
  handleFormSubmit: (values: any) => void;
  isFormLoading: boolean;
  formType: 'forgot' | 'reset';
  isStrongPassword?: boolean;
};

const ResetPasswordForm = ({
  handleFormSubmit,
  isFormLoading,
  formType,
  isStrongPassword,
}: ResetPasswordFormProps) => {
  const history = useHistory();
  const formTitle = useMemo(() => {
    if (formType === 'reset') {
      return `Reset password`;
    } else {
      return `Forgot your password?`;
    }
  }, [formType]);

  const handleLoginClick = () => {
    history.push('/login');
  };

  return (
    <div className="l-reset-pass__form-wrapper">
      <div className="l-reset-pass__logo-container">
        <Logo />
      </div>
      <Formik
        initialValues={{
          email: undefined,
          newPassCode: undefined,
          comfirmPassCode: undefined,
        }}
        enableReinitialize
        onSubmit={handleFormSubmit}
        validationSchema={
          formType === 'forgot'
            ? forgotPassFormValidationSchema
            : () => resetPasswordFormValidationSchema(isStrongPassword)
        }
      >
        {({
          errors,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
        }) => {
          return (
            <form onSubmit={handleSubmit} className="l-reset-pass__form">
              <div className="l-reset-pass__reset-form-heading">
                {formTitle}
              </div>

              {formType === 'forgot' && (
                <div className="c-form-field">
                  <div className="c-form-field__label">Email:</div>
                  <div className="c-form-field__wrapper">
                    <Input
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      placeholder={'Enter your email'}
                    />
                    <div className="c-form-field__error">
                      {errors.email ? errors.email : undefined}
                    </div>
                  </div>
                </div>
              )}

              {formType === 'reset' && (
                <>
                  <div className="c-form-field">
                    <div className="c-form-field__label">Password:</div>
                    <div className="c-form-field__wrapper">
                      <Input.Password
                        name="newPassCode"
                        onChange={handleChange}
                        type="text"
                        value={values.newPassCode}
                        onBlur={handleBlur}
                        placeholder={'*********'}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                      <div className="c-form-field__error">
                        {errors.newPassCode ? errors.newPassCode : undefined}
                      </div>
                    </div>
                  </div>
                  <div className="c-form-field">
                    <div className="c-form-field__label">Confirm Password:</div>
                    <div className="c-form-field__wrapper">
                      <Input.Password
                        name="comfirmPassCode"
                        onChange={handleChange}
                        type="text"
                        value={values.comfirmPassCode}
                        onBlur={handleBlur}
                        placeholder={'*********'}
                        iconRender={(visible) =>
                          visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                      />
                      <div className="c-form-field__error">
                        {errors.comfirmPassCode
                          ? errors.comfirmPassCode
                          : undefined}
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="c-form__btn-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="l-reset-pass__form-btn"
                  disabled={!(isValid && dirty) || isFormLoading}
                >
                  {formType === 'forgot' ? `Email me` : `Submit`}
                </Button>
              </div>

              <div className="l-reset-pass__additional-link">
                <span className="l-reset-pass__additional-link-msg">
                  Ready to log in?
                </span>
                <a
                  className="l-reset-pass__additional-link-trigger"
                  onClick={handleLoginClick}
                >
                  Login here
                </a>
              </div>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default ResetPasswordForm;
