import { Layout } from 'antd';
import React, { ReactNode } from 'react';

import { RouteType } from '../../../foundation/config/routes';

const { Content } = Layout;

type Props = {
  children: ReactNode;
  routeConfig: RouteType | undefined;
};

const ContentWrapper = ({ children, routeConfig }: Props) => {
  React.useEffect(() => {
    const contentElement = document.querySelector('.c-app-layout__content');
    if (contentElement) {
      contentElement.scrollTo({ top: 0 });
    }
  }, [routeConfig]);

  return (
    <Content className="c-app-layout__content">
      {routeConfig?.displayHeader && (
        <div className="l-content-header">
          <div className="l-content-header__title">
            {routeConfig?.headerTitle}
          </div>
          <div className="l-content-header__subtitle">
            {routeConfig?.headerSubTitle}
          </div>
        </div>
      )}
      <div>{children}</div>
    </Content>
  );
};

export default ContentWrapper;
