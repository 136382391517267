import * as Yup from 'yup';

import { passwordSchema } from '../../../foundation/utils/getValidationSchemas';

const requiredMessage = `This field is required`;

export const signupFormValidationSchema = (
  isLeadSignup?: boolean,
  isStrongPassword?: boolean,
) => {
  const fields: any = {
    firstName: Yup.string().required(requiredMessage).nullable(),
    lastName: Yup.string()
      .nullable()
      .when([], {
        is: () => !isLeadSignup,
        then: Yup.string().required(requiredMessage),
      }),
    email: Yup.string()
      .email('Please enter a valid email.')
      .required(requiredMessage)
      .nullable(),
    phone: Yup.string().when('isLeadSignup', {
      is: true,
      then: Yup.string()
        .required(requiredMessage)
        .matches(/^[0-9]{9,10}$/, 'Invalid Australian phone number'),
      otherwise: Yup.string().matches(
        /^[0-9]{9,10}$/,
        'Invalid Australian phone number',
      ),
    }),
    passCode: passwordSchema(undefined, isLeadSignup, isStrongPassword),
  };

  return Yup.object().shape(fields);
};
