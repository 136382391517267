import { Button, Modal } from 'antd';
import React from 'react';

import InviteSentIcon from '../../../../foundation/assets/svgs/InviteSentIcon';

type InviteSuccessModalProps = {
  closeModal: () => void;
  emails: string[];
  inviteType: 'user' | 'client';
};
const InviteSuccessModal = ({
  closeModal,
  emails,
  inviteType,
}: InviteSuccessModalProps) => {
  const handleModalClose = () => {
    closeModal();
  };

  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <InviteSentIcon />
        </span>
        <span>Invites Sent</span>
      </div>
    ),
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: false,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal">
      <div className="h-invite-success-modal__text">
        Invitations have been sent to the following email addresses:
      </div>
      <div className="h-invite-success-modal__emails-wrapper">
        {emails.map((email) => (
          <span key={email} className="h-invite-success-modal__email">
            {email}
          </span>
        ))}
      </div>
      <div className="h-invite-success-modal__text">
        {inviteType === 'user' ? 'Agency users or admins' : 'Clients'} will need
        to sign up to the platform using the link provided in their email
        invitation.
      </div>
      <div className="h-invite-success-modal__text">
        Wrong email? You can manage agency admins, users, and clients from your
        dashboard.
      </div>
      <div className="h-invite-success-modal__btn-wrapper">
        <Button
          type="primary"
          className="h-invite-success-modal__btn"
          onClick={handleModalClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default InviteSuccessModal;
