import { RootState } from '../../../store';

export const selectSuburb = (state: RootState) => state.ai_report.suburb;
export const selectTemplate = (state: RootState) => state.ai_report.template;
export const selectReportData = (state: RootState) =>
  state.ai_report.reportData;
export const selectReportHtml = (state: RootState) =>
  state.ai_report.reportHtml;

export const selectGraphDisplaySettings = (state: RootState) =>
  state.ai_report.graphDisplaySettings;
export const selectTimelineType = (state: RootState) =>
  state.ai_report.timelineType;

export const selectForecastTimelineType = (state: RootState) =>
  state.ai_report.forecastTimelineType;

export const selectGraphByType = (type: string) => (state: RootState) => {
  if (
    !state.ai_report.reportData?.[state.ai_report.template]?.allGraphs?.length
  ) {
    return undefined;
  }

  return state.ai_report.reportData[state.ai_report.template].allGraphs.find(
    (g: any) => {
      return g.graphType === type;
    },
  );
};
export const selectGraphIsLoadingByKey =
  (key: string) => (state: RootState) => {
    return state.ai_report.isGraphLoading[key];
  };
export const selectTimelineOptions = (state: RootState) =>
  state.ai_report.timelineOptions;

export const selectProjectSettings = (state: RootState) =>
  state.ai_report.projectSettings;

export const selectProjectsValues = (state: RootState) =>
  state.ai_report.projectsValues;

export const selectIsReportLoading = (state: RootState) =>
  state.ai_report.isReportLoading;

export const selectReportDataByCurrentTemplate = (state: RootState) => {
  return state.ai_report.reportData?.[state.ai_report.template];
};

export const selectReportHtmlByCurrentTemplate = (state: RootState) => {
  return state.ai_report.reportHtml?.[state.ai_report.template];
};

export const selectIsReportToggleEnabled = (state: RootState) => {
  return state.ai_report.isReportToggleEnabled;
};

export const selectIsEditedVersion = (state: RootState) => {
  return state.ai_report.isEditedVersion;
};

export const selectIsFavorite = (state: RootState) =>
  state.ai_report.isFavorite;

export const selectFavoriteReports = (state: RootState) =>
  state.ai_report.favoriteReports;

export const selectSharedReports = (state: RootState) =>
  state.ai_report.sharedReports;

export const selectIsGoingBack = (state: RootState) =>
  state.ai_report.isGoingBack;

export const selectDisclaimerModalActive = (state: RootState) =>
  state.ai_report.isDisclaimerModalActive;
