import * as Yup from 'yup';

const requiredMessage = `This field is required`;

export const enterpriseFormValidationSchema = () => {
  const fields: any = {
    enterpriseName: Yup.string().trim().required(requiredMessage).nullable(),
  };

  return Yup.object().shape(fields);
};
