import { Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

type Props = {
  name?: string;
  label: string;
  value?: string | number;
  onChange?: (e: any) => any;
  onBlur?: (e: any) => any;
  type?: string;
  disabled?: boolean;
  placeholder?: string;
  error?: any;
  addonBefore?: string;
  style?: any;
  labelStyle?: any;
  fieldStyle?: any;
  maxLength?: number;
  rows?: number;
};

const FormInputField = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  disabled,
  placeholder,
  error,
  style,
  labelStyle,
  fieldStyle,
  rows,
  maxLength,
}: Props) => {
  return (
    <div className="c-form-field" style={fieldStyle}>
      <div className="c-form-field__label" style={labelStyle}>
        {label}
      </div>
      <div className="c-form-field__wrapper">
        <TextArea
          name={name}
          onChange={onChange}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          disabled={disabled}
          style={style}
          rows={rows}
          maxLength={maxLength}
        />
        <div className="c-form-field__error">{error ? error : undefined}</div>
      </div>
    </div>
  );
};

export default FormInputField;
