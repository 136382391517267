import { Button, Modal, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import Close from '../../../foundation/assets/svgs/Close';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { selectFavoriteReports } from '../redux/selectors';
import {
  resetReport,
  resetSuburb,
  resetTemplate,
  setIsEditedVersion,
  setIsReportToggleEnabled,
} from '../redux/slice';

type FavoriteReportsModalProps = {
  closeModal: () => void;
};

const FavoriteReportsModal = ({ closeModal }: FavoriteReportsModalProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const favoriteReports = useSelector(selectFavoriteReports);

  const handleModalClose = () => {
    closeModal();
  };

  const { isMobileViewport } = useViewport();

  const columns: ColumnsType<any> = [
    {
      title: 'Suburb',
      dataIndex: 'suburbName',
      key: 'suburbName',
      fixed: 'left',
      width: isMobileViewport ? 150 : 200,
      render: (text, row) => {
        return (
          <>
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                dispatch(resetSuburb());
                dispatch(resetReport());
                dispatch(resetTemplate());

                dispatch(setIsReportToggleEnabled(false));
                dispatch(setIsEditedVersion(false));

                handleModalClose();
                history.push(
                  `/suburb-scoring-internal/report/${row.suburbCode}`,
                );
              }}
            >
              <strong>{text}</strong>
            </a>
            <br />
            <>
              {row.postCode}, {row.state}
            </>
          </>
        );
      },
    },
    {
      title: 'LGA',
      dataIndex: 'lgaName',
      key: 'lgaName',
    },
  ];

  const modalOpts = {
    title: 'Favorite Suburb Reports',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
  };

  return (
    <Modal
      {...modalOpts}
      className="c-form-modal"
      width={500}
      style={{ maxHeight: '400px' }}
    >
      <Table
        columns={columns}
        size={useTableSize()}
        pagination={false}
        scroll={{ x: 'max-content', y: 200 }}
        dataSource={favoriteReports}
      />
      <div className="h-invite-success-modal__btn-wrapper">
        <Button
          className="h-invite-success-modal__btn"
          onClick={handleModalClose}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default FavoriteReportsModal;
