import * as Yup from 'yup';

const requiredMessage = `OTP is required`;

export const deleteAccountValidationSchema = () => {
  const fields: any = {
    otp: Yup.string().required(requiredMessage).nullable(),
  };

  return Yup.object().shape(fields);
};
