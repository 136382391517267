import 'chartjs-adapter-moment';

import { Row } from 'antd';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import BubbleChart from '../../../foundation/components/bubble_chart/BubbleChart';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { hexToRgbA } from '../../../foundation/utils/helperFunctions';
import {
  selectDashboardPurchaseRoadmap,
  selectDashboardPurchaseRoadmapAxisData,
} from '../redux/selectors';

const PurchaseRoadmapSection = () => {
  const performaceGraphData = useSelector(selectDashboardPurchaseRoadmap);
  const { isMobileViewport, isTabletViewport } = useViewport();

  const performaceGraphAxisData = useSelector(
    selectDashboardPurchaseRoadmapAxisData,
  );

  const computeBubbleSize = (bubbleSize: number | undefined) => {
    if (!bubbleSize) {
      return 0;
    }

    const factor = isTabletViewport ? 0.66 : isMobileViewport ? 0.33 : 1;

    return bubbleSize * factor;
  };

  /**
   * X-axis labels.
   */
  const labels = useMemo(() => {
    return performaceGraphData
      ? performaceGraphData.map((item) => moment(item.date))
      : [];
  }, [performaceGraphData]);

  const datasets = useMemo(() => {
    const sets: any = [];

    if (performaceGraphData && performaceGraphAxisData) {
      const keys = Object.keys(performaceGraphData[0]);

      for (const key of keys) {
        if (
          key !== 'date' &&
          key !== 'bubblesize' &&
          key !== 'requiredamount' &&
          key !== 'propertyname'
        ) {
          const graphData: any = [];
          for (const i of performaceGraphData) {
            if (i[key] !== 0) {
              graphData.push({
                y: i[key],
                x: moment(i.date),
                r: computeBubbleSize(i.bubblesize),
                amount: i.requiredamount,
                propertyName: i.propertyname,
              });
            }
          }

          let axisData: any = null;

          for (const i of performaceGraphAxisData) {
            if (i.dataName === key) {
              axisData = i;
              break;
            }
          }
          sets.push({
            label: axisData?.label,
            data: graphData,
            segment: {},
            borderColor:
              axisData && axisData.colorHex !== ''
                ? hexToRgbA(axisData.colorHex, '0.7')
                : null,
            backgroundColor:
              axisData && axisData.colorHex !== ''
                ? hexToRgbA(axisData.colorHex, '0.6')
                : null,
            color: 'rgba(0,0,0,1)',
          });
        }
      }
    }
    return sets;
  }, [performaceGraphData, isTabletViewport, isMobileViewport]);

  /**
   * Formatted data used by the line graph.
   */
  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <Row className="dashboard__section dashboard__annual-cashflow-section">
      <div className="dashboard__header">
        <div className="dashboard__header-title dashboard__header-title--medium">
          Property Purchase Roadmap
        </div>
      </div>
      <div className="dashboard__chart-wrapper">
        <section className="dashboard__chart-content">
          <BubbleChart
            xAxesLabel="Graph Timeline"
            yAxesLabel="Amount"
            data={data}
            graphTitle="Purchase Roadmap"
            areaAboveFill="rgba(0, 178, 163,0.2)"
            xAxesUnit="month"
            xAxesType="time"
            titleMomentFormat="MMM DD, YYYY"
          />
        </section>
      </div>
    </Row>
  );
};

export default PurchaseRoadmapSection;
