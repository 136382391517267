import React from 'react';

function InviteSentIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      fill="none"
      viewBox="0 0 56 56"
    >
      <rect width="48" height="48" x="4" y="4" fill="#D1FADF" rx="24"></rect>
      <path
        stroke="#039855"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M25 27l3 3 10-10m-1 8v7a2 2 0 01-2 2H21a2 2 0 01-2-2V21a2 2 0 012-2h11"
      ></path>
      <rect
        width="48"
        height="48"
        x="4"
        y="4"
        stroke="#ECFDF3"
        strokeWidth="8"
        rx="24"
      ></rect>
    </svg>
  );
}

export default InviteSentIcon;
