import { Button, Modal } from 'antd';
import React from 'react';

import InviteSentIcon from '../../../foundation/assets/svgs/InviteSentIcon';

const LeadSignupModalSuccess = ({
  email,
  closeHandler,
}: {
  email: string;
  closeHandler?: () => void;
}) => {
  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <InviteSentIcon />
        </span>
        <span>Sign-up</span>
      </div>
    ),
    open: true,
    wrapClassName: '',
    closable: false,
    footer: null,
    centered: true,
    closeHandler: closeHandler,
    maskClosable: false,
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal">
      <div className="h-invite-success-modal__text">
        Your details have been submitted successfully!
      </div>
      <div className="h-invite-success-modal__text">
        Please check <strong>{email}</strong> for further instructions.
      </div>
      <div
        className="h-invite-success-modal__btn-wrapper"
        style={{ justifyContent: 'center', marginTop: '30px' }}
      >
        <Button
          type="primary"
          className="h-invite-success-modal__btn"
          onClick={() => {
            if (closeHandler) {
              closeHandler();
            }
          }}
          style={{ width: '100px' }}
        >
          Ok
        </Button>
      </div>
    </Modal>
  );
};

export default LeadSignupModalSuccess;
