import { WarningOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import React from 'react';

type WiderSearchProps = {
  value: boolean;
  onChange: () => void;
};
const WiderSearch = ({ value, onChange }: WiderSearchProps) => {
  console.log(value);
  return (
    <div className="l-filter-field">
      <label>
        <span style={{ marginRight: 20 }}>Wider Search</span>
        <Switch checked={value} onChange={onChange} />
      </label>
      <div>
        <p style={{ fontSize: 12, margin: '5px 0 0' }}>
          {' '}
          <WarningOutlined style={{ color: '#f00', fontSize: '14px' }} />{' '}
          <em>
            Include suburbs with less than 30 sales in the last 12 months or
            those lacking sufficient data to generate scores with a high level
            of confidence.
          </em>
        </p>
      </div>
    </div>
  );
};

export default WiderSearch;
