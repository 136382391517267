import * as Yup from 'yup';

export const contactUsFormValidationSchema = () => {
  const requiredMessage = `This field is required`;

  const fields: any = {
    contactNumber: Yup.string()
      .trim()
      .matches(/^[\d+ ()]*$/, {
        message: 'Only digits, plus sign, spaces, and parentheses are allowed',
      })
      .transform((value, originalValue) => {
        return originalValue.replace(/[+ ()]/g, '');
      })
      .max(15, 'Please enter a maximum of 15 digits')
      .nullable(),
    // privacyPolicyAgree: Yup.bool().oneOf([true], requiredMessage),
    message: Yup.string()
      .trim()
      .required(requiredMessage)
      .max(500, 'Message should not be longer than 500 characters'),
  };

  return Yup.object().shape(fields);
};
