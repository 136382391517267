import { Button, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import WarningIcon from '../../../../foundation/assets/svgs/WarningIcon';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { toggleEnterpriseState } from '../../redux/async_thunks';
import { selectEnterprise } from '../../redux/selectors';

type ToggleEnterpriseStateModalProps = {
  closeModal: () => void;
  fetchEnterpriseResults: (searchValue?: string) => Promise<void>;
};
const ToggleEnterpriseStateModal = ({
  closeModal,
  fetchEnterpriseResults,
}: ToggleEnterpriseStateModalProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const enterprise = useSelector(selectEnterprise);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <WarningIcon />
        </span>
        <span>
          {enterprise?.isActive
            ? 'Deactivate Enterprise'
            : 'Activate Enterprise'}
        </span>
      </div>
    ),
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: false,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  const handleToggleBtnClick = async () => {
    if (isLoading) {
      return;
    }

    try {
      if (enterprise && user) {
        setIsLoading(true);

        const data = {
          userId: user?.user_id,
          enterpriseId: enterprise.enterpriseId,
          enable: !enterprise.isActive,
        };

        await dispatch(
          toggleEnterpriseState({
            data,
            token: user.token,
          }),
        ).unwrap();

        setIsLoading(false);

        notification.success({
          message: 'Success!',
          description: enterprise.isActive
            ? 'Enterprise deactivated successfully.'
            : 'Enterprise activated successfully.',
        });

        handleModalClose();
        fetchEnterpriseResults('');
      }
    } catch (error) {
      setIsLoading(false);
      handleModalClose();
    }
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal" width={570}>
      {isLoading && <FullPageLoader />}
      <div className="h-invite-success-modal__text">
        Are you sure you want to{' '}
        {enterprise?.isActive ? 'deactivate' : 'activate'} the following
        enterprise account?
      </div>
      <div
        className="h-invite-success-modal__emails-wrapper"
        style={{ fontWeight: 600 }}
      >
        {enterprise?.enterpriseName}
      </div>
      <div className="h-invite-success-modal__text">
        {enterprise?.isActive
          ? 'The enterprise account will no longer be active and all enterprise admins, users, and clients will no longer have access to Property Pathfinder.'
          : 'The enterprise account will be reactivated, allowing all enterprise admins, users, and clients to regain access to Property Pathfinder.'}
      </div>
      <div className="h-invite-success-modal__btn-wrapper">
        <Button
          className="h-invite-success-modal__btn"
          onClick={handleModalClose}
        >
          Close
        </Button>
        <Button
          type="primary"
          danger={enterprise?.isActive}
          className="h-invite-success-modal__delete-btn"
          onClick={handleToggleBtnClick}
          style={{ width: '180px' }}
        >
          {enterprise?.isActive
            ? 'Deactivate Enterprise'
            : 'Activate Enterprise'}
        </Button>
      </div>
    </Modal>
  );
};

export default ToggleEnterpriseStateModal;
