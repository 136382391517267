import {
  AreaChartOutlined,
  BlockOutlined,
  HomeOutlined,
  LineChartOutlined,
  RadarChartOutlined,
  TeamOutlined,
} from '@ant-design/icons';
import { Tabs } from 'antd';
import {
  ArcElement,
  Chart as ChartJS,
  defaults,
  registerables,
} from 'chart.js';
import { merge } from 'lodash';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { selectTemplate } from '../redux/selectors';
import { setTemplate } from '../redux/slice';
import ReportContent from '../report_content/ReportContent';
import Dwelling from './dwelling/Dwelling';
import InvestmentSummary from './investment_summary/InvestmentSummary';
import KeyIndicators from './key_indicators/KeyIndicators';
import Population from './population/Population';
import PriceRentForecast from './price_rent_forecasts/PriceRentForecasts';
import Projects from './projects/Projects';
ChartJS.register(ArcElement);
ChartJS.register(...registerables);

merge(defaults, {
  color: '#696c6e',
  borderColor: '#f2f3f4',
  backgroundColor: [
    '#40a9ff', // Blue
    '#73d13d', // Green
    '#ffc53d', // Orange
    '#fff852', // Yellow
    '#C6A8EA', // Purple
    '#36cfc9', // Teal
  ],
});

merge(defaults.font, {
  size: 13,
  family: 'Inter',
});

merge(defaults.plugins.tooltip, {
  backgroundColor: 'rgba(0, 0, 0, 0.78)',
  cornerRadius: 5,
  caretPadding: 3,
  caretSize: 7,
  padding: 15,
  displayColors: false,
  position: 'nearest',
});

merge(defaults.elements.arc, {
  borderWidth: 0,
});

const SuburbDetails = () => {
  const dispatch = useDispatch();
  const template = useSelector(selectTemplate);
  const { isMobileViewport, isTabletViewport } = useViewport();

  const getTabLabel = (key: string) => {
    const style = { fontSize: '20px' };

    const labels = {
      'investment-summary': {
        default: (
          <>
            Investment{' '}
            <br style={{ display: isTabletViewport ? 'block' : 'none' }} />
            Summary
          </>
        ),
        mobile: <RadarChartOutlined style={style} />,
      },
      population: {
        default: 'Population',
        mobile: <TeamOutlined style={style} />,
      },
      dwelling: {
        default: 'Dwelling',
        mobile: <HomeOutlined style={style} />,
      },
      'key-indicators': {
        default: 'Key Indicators',
        mobile: <LineChartOutlined style={style} />,
      },
      'pricerent-forecast': {
        default: (
          <>
            Price & Rent{' '}
            <br style={{ display: isTabletViewport ? 'block' : 'none' }} />
            Forecasts
          </>
        ),
        mobile: <AreaChartOutlined style={style} />,
      },
      projects: {
        default: 'Projects',
        mobile: <BlockOutlined style={style} />,
      },
    };

    return labels[key][isMobileViewport ? 'mobile' : 'default'];
  };

  const tabItems: any = [
    {
      label: getTabLabel('investment-summary'),
      key: 'investment-summary',
      children: (
        <div className="l-report-tab">
          <ReportContent heading="Investment Summary" />
          {template === 'investment-summary' && <InvestmentSummary />}
        </div>
      ),
    },
    {
      label: getTabLabel('population'),
      key: 'population',
      children: (
        <div className="l-report-tab">
          <ReportContent heading="Economy & Population" />
          {template === 'population' && <Population />}
        </div>
      ),
    },
    {
      label: getTabLabel('dwelling'),
      key: 'dwelling',
      children: (
        <div className="l-report-tab">
          <ReportContent heading="Dwelling" />
          {template === 'dwelling' && <Dwelling />}
        </div>
      ),
    },
    {
      label: getTabLabel('key-indicators'),
      key: 'key-indicators',
      children: (
        <div className="l-report-tab">
          <ReportContent heading="Key Indicators" />
          {template === 'key-indicators' && <KeyIndicators />}
        </div>
      ),
    },
    {
      label: getTabLabel('pricerent-forecast'),
      key: 'pricerent-forecast',
      children: (
        <div className="l-report-tab">
          <ReportContent heading="Price & Rent Forecasts" />
          {template === 'pricerent-forecast' && <PriceRentForecast />}
        </div>
      ),
    },
    {
      label: getTabLabel('projects'),
      key: 'projects',
      children: (
        <div className="l-report-tab">
          <ReportContent heading="Projects" />
          {template === 'projects' && <Projects />}
        </div>
      ),
    },
  ];

  return (
    <div className="l-report-details">
      <div className="l-report-details__content">
        {!!tabItems.length && (
          <Tabs
            defaultActiveKey="investment-summary"
            centered={true}
            moreIcon={null}
            onChange={(activeKey) => {
              dispatch(setTemplate(activeKey));
            }}
            style={{ width: '100%' }}
            items={tabItems}
          />
        )}
      </div>
    </div>
  );
};

export default SuburbDetails;
