import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import { Alert, Popover } from 'antd';
import React from 'react';

import Info from '../../../foundation/assets/svgs/Info';

// eslint-disable-next-line
enum ValueType {
  Number = 0, // eslint-disable-line
  Percent = 1, // eslint-disable-line
  Currency = 2, // eslint-disable-line
}

type Props = {
  title: string;
  descriptor?: string | null;
  value: string;
  valueType: ValueType;
  changeValue: string | number;
  changeIndicator: any;
  forecastYear?: number;
  censusYear?: number;
  censusSame?: boolean;
  censusNoChange?: boolean;
  censusPreviousValue?: number;
  population?: number;
  populationNoChange?: boolean;
  populationCurrentValue?: number;
  stateCode?: string;
  stateValue?: string | number;
  stateValueType?: ValueType;
  comparedValue?: string | number;
  comparedValueType?: ValueType;
  isEmphasized?: boolean;
  isDataAvailable?: boolean;
  source?: string;
  sourceHtml?: any;
  populationForecastComparisonYear?: number;
};

const DashboardCard = ({
  title,
  descriptor,
  value,
  valueType,
  changeValue,
  changeIndicator,
  forecastYear,
  stateCode,
  stateValue,
  stateValueType,
  censusYear,
  censusSame,
  censusNoChange,
  censusPreviousValue,
  population,
  populationNoChange,
  populationCurrentValue,
  comparedValue,
  comparedValueType,
  isEmphasized,
  isDataAvailable,
  source,
  sourceHtml,
  populationForecastComparisonYear,
}: Props) => {
  const changeDirection = changeIndicator
    ? changeIndicator.arrow.toLocaleLowerCase()
    : 'down';

  let popoverContent: any = null;

  if (descriptor) {
    popoverContent = <p className="l-descriptor">{descriptor}</p>;
  }

  const getCheckValue = () => {
    return (
      (typeof value === 'string' && value) ||
      (typeof value === 'number' && value > -1)
    );
  };

  const renderValue = (type: ValueType = 0, param: string | number = 0) => {
    let result: string;

    if (param === null) {
      return '';
    }

    switch (type) {
      case ValueType.Number:
        result = param.toLocaleString();
        break;
      case ValueType.Currency:
        result = `$${param.toLocaleString()}`;
        break;
      default:
        result = param.toString();
        break;
    }

    return result;
  };

  const renderChangeValue = () => {
    if (!changeValue) {
      return null;
    }

    return (
      <span
        className={`l-dashboard-card__change-percent ${getChangeColorClass()}`}
      >
        {(typeof changeValue === 'string' && changeValue.includes('-')) ||
        (typeof changeValue === 'number' && changeValue < 0)
          ? changeValue.toString()
          : `+${changeValue.toString()}`}
        {changeValue !== '0.0%' && changeValue !== 0 && (
          <span className="l-dashboard-card__change-direction">
            {changeDirection === 'up' ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            )}
          </span>
        )}
      </span>
    );
  };

  const renderCensusYear = () => {
    if (!censusYear || (censusNoChange && censusSame)) {
      return null;
    }

    return (
      <dd className="l-dashboard-card__details">
        {censusNoChange ? (
          <>
            {!censusSame && (
              <span>
                Same with <strong>{censusYear}</strong> Census
              </span>
            )}
          </>
        ) : (
          <span>
            Compared to <strong>{censusYear}</strong> Census
            {!!censusPreviousValue && (
              <span>
                {' '}
                (<strong>{renderValue(0, censusPreviousValue)}</strong>)
              </span>
            )}
          </span>
        )}
      </dd>
    );
  };

  if (!isDataAvailable) {
    return (
      <div
        className={`l-dashboard-card l-dashboard-card--min-height${
          isEmphasized ? ' l-dashboard-card--emphasized' : ''
        }`}
      >
        <div className="l-dashboard-card__body">
          <h5 className="l-dashboard-card__title" style={{ width: '100%' }}>
            <span>{title}</span>
            {descriptor && (
              <Popover
                content={popoverContent}
                title={null}
                placement="top"
                trigger="click"
                overlayClassName="no-override"
              >
                <span className="l-descriptor-trigger">
                  <Info />
                </span>
              </Popover>
            )}
          </h5>
          <dl>
            <dt className="l-dashboard-card__values-wrapper">
              <Alert
                message="No data available"
                type="warning"
                showIcon
                style={{ fontWeight: 600 }}
              />
            </dt>
          </dl>
        </div>
      </div>
    );
  }

  const checkValue = getCheckValue();

  const getChangeColorClass = () => {
    if (changeValue === '0.0%' || changeValue === 0 || !changeIndicator) {
      return 'l-dashboard-card__change-percent--zero';
    }

    return changeIndicator && changeIndicator.arrowColor === '#00FF00'
      ? 'l-dashboard-card__change-percent--up'
      : 'l-dashboard-card__change-percent--down';
  };

  return (
    <div
      className={`l-dashboard-card l-dashboard-card--min-height${
        isEmphasized ? ' l-dashboard-card--emphasized' : ''
      }`}
    >
      <div className="l-dashboard-card__body">
        <h5 className="l-dashboard-card__title">
          {title}
          {descriptor && (
            <Popover
              content={popoverContent}
              title={null}
              placement="top"
              trigger="click"
              overlayClassName="no-override"
            >
              <span className="l-descriptor-trigger">
                <Info />
              </span>
            </Popover>
          )}
        </h5>
        <dl>
          <dt className="l-dashboard-card__values-wrapper">
            {checkValue ? (
              <span className="l-dashboard-card__value">
                {renderValue(valueType, value)}
              </span>
            ) : (
              <Alert message="Not available" type="warning" showIcon />
            )}
            {renderChangeValue()}
          </dt>
          {checkValue && (
            <>
              {renderCensusYear()}
              {!!population && (
                <dd className="l-dashboard-card__details">
                  {populationNoChange ? (
                    <span>
                      Same with{' '}
                      <strong>{populationForecastComparisonYear}</strong>{' '}
                      population
                    </span>
                  ) : (
                    <span>
                      Compared to{' '}
                      <strong>{populationForecastComparisonYear}</strong>{' '}
                      population
                      {!!populationCurrentValue && (
                        <span>
                          (
                          <strong>
                            {renderValue(0, populationCurrentValue)}
                          </strong>
                          )
                        </span>
                      )}
                    </span>
                  )}
                </dd>
              )}
              {!!stateValue && !!stateCode && (
                <dd className="l-dashboard-card__details">
                  <span>
                    (<strong>{stateCode}</strong> is{' '}
                    <strong>{renderValue(stateValueType, stateValue)}</strong>)
                  </span>
                </dd>
              )}
              {typeof comparedValue === 'string' && comparedValue !== value && (
                <dd className="l-dashboard-card__details">
                  Compared to{' '}
                  <strong>
                    {renderValue(comparedValueType, comparedValue)}
                  </strong>{' '}
                  last year
                </dd>
              )}
              {typeof comparedValue === 'string' && comparedValue === value && (
                <dd className="l-dashboard-card__details">
                  The same with last year
                </dd>
              )}
              {source && (
                <dd
                  className="l-dashboard-card__details"
                  dangerouslySetInnerHTML={{
                    __html: source,
                  }}
                ></dd>
              )}
              {sourceHtml && (
                <dd className="l-dashboard-card__details">{sourceHtml}</dd>
              )}
            </>
          )}
        </dl>
      </div>
    </div>
  );
};

export default DashboardCard;
