import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import CheckCircle from '../../../foundation/assets/svgs/CheckCircle';
import { sendMessage } from '../../profile/redux/async_thunks';
import { setNavIndex } from '../../profile/redux/slice';
import ContactUsForm from '../forms/ContactUsForm';

const ContactUs = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isMessageSent, setIsMessageSent] = useState(false);

  const handleFormSubmit = async (values: { token: string; data: any }) => {
    try {
      setIsFormLoading(true);

      // @ts-ignore
      await dispatch(sendMessage(values)).unwrap();

      setIsFormLoading(false);
      setIsMessageSent(true);
    } catch (error) {
      setIsFormLoading(false);
    }
  };

  return (
    <>
      <div className="contact-us">
        {isMessageSent ? (
          <>
            <div className="contact-us__success-message">
              <CheckCircle />
              <h3>Thank you!</h3>
              <p>Your message has been sent.</p>
              <p>
                Go back to{' '}
                <a
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(setNavIndex(1));
                    history.push('/');
                  }}
                >
                  homepage
                </a>
                .
              </p>
            </div>
          </>
        ) : (
          <ContactUsForm
            handleFormSubmit={handleFormSubmit}
            isFormLoading={isFormLoading}
          />
        )}
      </div>
    </>
  );
};

export default ContactUs;
