import React from 'react';

function Dwelling() {
  return (
    <svg
      width="37"
      height="40"
      viewBox="0 0 37 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.71777 15.456C1.71777 14.5024 2.17109 13.6057 2.93893 13.0403L18.3844 1.66675L33.83 13.0403C34.5978 13.6057 35.0511 14.5024 35.0511 15.456V35.8334C35.0511 37.2141 33.9318 38.3334 32.5511 38.3334H24.3844C23.8322 38.3334 23.3844 37.8857 23.3844 37.3334V26.3334C23.3844 26.0573 23.1606 25.8334 22.8844 25.8334H13.8844C13.6083 25.8334 13.3844 26.0573 13.3844 26.3334V37.3334C13.3844 37.8857 12.9367 38.3334 12.3844 38.3334H4.21778C2.83706 38.3334 1.71777 37.2141 1.71777 35.8334V15.456Z"
        stroke="#4E4B66"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Dwelling;
