import { DeleteOutlined, EditOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Image, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import AgencyIcon from '../../../foundation/assets/svgs/AgencyIcon';
import ArrowLeft from '../../../foundation/assets/svgs/ArrowLeft';
import UserIcon from '../../../foundation/assets/svgs/UserIcon';
import useTableSize from '../../../foundation/cutom_hooks/useTableSize';
import { useViewport } from '../../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import { setNavIndex } from '../../profile/redux/slice';
import DeleteEnterpriseAdminModal from '../modals/delete_enterprise_user_modal/DeleteEnterpriseUserModal';
import EnterpriseModal from '../modals/enterprise_modal/EnterpriseModal';
import EditInviteModal from '../modals/invite_modal/EditInviteModal';
import InviteModal from '../modals/invite_modal/InviteModal';
import InviteSuccessModal from '../modals/invite_success_modal/InviteSuccessModal';
import { fetchEnterprise } from '../redux/async_thunks';
import { selectEnterprise, selectEnterpriseAdmins } from '../redux/selectors';
import { EnterpriseAdmin } from '../redux/types';

const EnterpriseDetail = () => {
  const dispatch = useAppDispatch();
  const { isMobileViewport } = useViewport();
  const history = useHistory();
  const user = useSelector(selectUser);

  const [isEnterpriseModalVisible, setEnterpriseModalVisibility] =
    useState(false);

  const [isInviteModalVisible, setInviteModalVisibility] = useState(false);

  const [isEditInviteModalVisible, setEditInviteModalVisibility] =
    useState(false);

  const [isDeleteUserModalVisible, setDeleteUserModalVisibility] =
    useState(false);

  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

  const [selectedUser, setSelectedUser] = useState<EnterpriseAdmin | undefined>(
    undefined,
  );

  const [isInviteSuccessModalVisible, setInviteSuccessModalVisibility] =
    useState(false);

  const enterprise = useSelector(selectEnterprise);

  const enterpriseAdmins = useSelector(selectEnterpriseAdmins);

  const fetchEnterpriseData = async (enterpriseId?: string) => {
    try {
      if (user) {
        const data = {
          enterpriseId: enterpriseId ? enterpriseId : user.enterprise_id,
          token: user.token,
        };

        await dispatch(fetchEnterprise(data)).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onBackClickHandler = () => {
    dispatch(setNavIndex(1));
    history.push('/');
  };

  const handleDeleteUserBtnClick = () => {
    setDeleteUserModalVisibility(true);
  };

  const handleEditRowClick = (record: EnterpriseAdmin) => () => {
    setEditInviteModalVisibility(true);
    setSelectedUser(record);
  };

  const columns: ColumnsType<EnterpriseAdmin> = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      fixed: 'left',
      width: isMobileViewport ? 150 : 250,
      render: (text, record) => {
        return (
          <div className="l-enterprise-detail__name-row-wrapper">
            <Avatar
              src={record.userPicture}
              icon={<UserOutlined />}
              style={{ marginRight: 10 }}
              className="l-enterprise-detail__name-row-avatar"
            />
            {record.status === 'Invited' ? (
              <div className="l-enterprise-detail__name-row-awaiting">
                Awaiting sign up...
              </div>
            ) : (
              <div className="l-enterprise-detail__name-row-name">{text}</div>
            )}
          </div>
        );
      },
    },
    {
      title: 'Email address',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text) => {
        return (
          <span
            className={`l-enterprise-detail__user-status-wrapper ${
              text === 'Invited'
                ? ' l-enterprise-detail__user-status-wrapper--invited'
                : ' l-enterprise-detail__user-status-wrapper--active'
            }`}
          >
            <span
              className={`l-enterprise-detail__user-status-dot ${
                text === 'Invited'
                  ? ' l-enterprise-detail__user-status-dot--invited'
                  : ' l-enterprise-detail__user-status-dot--active'
              }`}
            ></span>
            {text}
          </span>
        );
      },
    },
    {
      title: '',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      render: (text, record) => {
        return (
          <div className="l-enterprise-detail__table-control-btns">
            {record.status === 'Invited' ? (
              <span
                className="l-enterprise-detail__table-control-btn"
                style={{ marginRight: 10 }}
                onClick={handleEditRowClick(record)}
              >
                <EditOutlined />
              </span>
            ) : (
              <span
                className="l-enterprise-detail__table-control-btn"
                style={{ marginRight: 10, width: 14 }}
              ></span>
            )}
            {record?.email !== user?.email && (
              // Hide delete button when the record is referring to the logged in user
              <span
                className="l-enterprise-detail__table-control-btn"
                onClick={() => {
                  setSelectedUser(record);
                  handleDeleteUserBtnClick();
                }}
              >
                <DeleteOutlined />
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleEnterpriseModalClose = () => {
    setEnterpriseModalVisibility(false);
  };

  const handleInviteModalClose = () => {
    setInviteModalVisibility(false);
    fetchEnterpriseData();
  };

  const handleInviteSuccessModalClose = () => {
    setInviteSuccessModalVisibility(false);
  };

  const handleEditEnterpriseClick = () => {
    setEnterpriseModalVisibility(true);
  };

  const handleEnterpriseAdminBtnClick = () => {
    setInviteModalVisibility(true);
  };

  const handleEditInviteModalClose = () => {
    setEditInviteModalVisibility(false);
  };

  const handleDeleteUserModalClose = () => {
    setDeleteUserModalVisibility(false);
    setSelectedUser(undefined);
  };

  return (
    <div className="l-enterprise-detail">
      {isEnterpriseModalVisible && (
        <EnterpriseModal
          closeModal={handleEnterpriseModalClose}
          fetchEnterpriseData={fetchEnterpriseData}
        />
      )}

      {isInviteModalVisible && (
        <InviteModal
          closeModal={handleInviteModalClose}
          setInviteSuccessModalVisibility={setInviteSuccessModalVisibility}
          setInvitedEmails={setInvitedEmails}
        />
      )}

      {isInviteSuccessModalVisible && (
        <InviteSuccessModal
          closeModal={handleInviteSuccessModalClose}
          emails={invitedEmails}
        />
      )}

      {isEditInviteModalVisible && selectedUser && (
        <EditInviteModal
          closeModal={handleEditInviteModalClose}
          setInviteSuccessModalVisibility={setInviteSuccessModalVisibility}
          setInvitedEmails={setInvitedEmails}
          selectedUser={selectedUser}
        />
      )}

      {isDeleteUserModalVisible && (
        <DeleteEnterpriseAdminModal
          closeModal={handleDeleteUserModalClose}
          selectedEnterpriseAdmin={selectedUser}
        />
      )}

      <div className="l-enterprise-detail__view">
        <div className="l-enterprise-detail__header">
          <div className="l-enterprise-detail__header-icon">
            <ArrowLeft />
          </div>
          <div
            className="l-enterprise-detail__header-text"
            onClick={onBackClickHandler}
          >
            Back to dashboard
          </div>
        </div>
        <div className="l-enterprise-detail__enterprise-info-wrapper">
          <div className="l-enterprise-detail__enterprise-picture">
            {enterprise?.enterprisePicture ? (
              <Image width={200} src={enterprise?.enterprisePicture} />
            ) : (
              <AgencyIcon />
            )}
          </div>
          <div className="l-enterprise-detail__enterprise-name">
            {enterprise?.enterpriseName}
          </div>
          <div className="l-enterprise-detail__enterprise-edit-btn-wrapper">
            <Button onClick={handleEditEnterpriseClick}>
              Edit enterprise profile
            </Button>
          </div>
        </div>
        <div className="l-enterprise-detail__users-section">
          <div className="l-enterprise-detail__users-section-header">
            <div className="l-enterprise-detail__users-section-header-title">
              Enterprise Admin
            </div>
            <div>
              <Button type="primary" onClick={handleEnterpriseAdminBtnClick}>
                Invite Enterprise Admin
              </Button>
            </div>
          </div>
          <Table
            columns={columns}
            size={useTableSize()}
            pagination={false}
            scroll={{ x: 'max-content' }}
            dataSource={enterpriseAdmins}
          />
        </div>
        <div className="l-enterprise-detail__permission-section">
          <div className="l-enterprise-detail__permission-section-title">
            Permissions
          </div>
          <div className="l-enterprise-detail__permission-info-wrapper">
            <div style={{ marginRight: 10 }}>
              <UserIcon />
            </div>
            <div>
              <div className="l-enterprise-detail__permission-info-title">
                Enterprise admins can perform the following functions:
              </div>
              <div className="l-enterprise-detail__permission-info-description">
                Edit enterprise profile, manage enterprise admins,
                create/edit/delete agencies and agency admins/users
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseDetail;
