import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectedPlanOffsetAccounts,
  selectedPlanRecords,
  selectPlanProperties,
} from '../../features/property/redux/selectors';
import { RouteType, useRoutes } from '../../foundation/config/routes';
import { useRole } from './useRole';

const useRouteConfig = (path: string) => {
  const planProperties = useSelector(selectPlanProperties);

  const planOffsetAccounts = useSelector(selectedPlanOffsetAccounts);

  const planRecords = useSelector(selectedPlanRecords);

  const [isClientView] = useRole();

  const routes = useRoutes(
    planProperties,
    isClientView,
    planOffsetAccounts,
    planRecords,
  );

  const getRouteConfig: any = (route: RouteType) => {
    if (route.completePath === path) {
      return route;
    } else if (route.routes) {
      for (const childRoute of route.routes) {
        const config: RouteType | undefined = getRouteConfig(childRoute);
        if (config) {
          return config;
        }
      }
    } else {
      return undefined;
    }
  };

  return useMemo(() => {
    for (const route of routes) {
      const config = getRouteConfig(route);
      if (config) {
        return config;
      }
    }
  }, [path, routes]);
};

export default useRouteConfig;
