import { ExclamationCircleOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Input, Modal, notification, Upload } from 'antd';
import { Formik } from 'formik';
import { compressAccurately } from 'image-conversion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../foundation/assets/svgs/Close';
import Lock from '../../foundation/assets/svgs/Lock';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import useRole from '../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import getBase64Image from '../../foundation/utils/getBase64Image';
import envConstant from '../../internals/env/env_constants.json';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import DeleteAccountModal from './modals/DeleteAccountModal';
import { updateProfile } from './redux/async_thunks';
import { selectProfile } from './redux/selectors';
import { profileFormValidationSchema } from './validation_schemas/profile_form_validation';

type ProfileProps = {
  closeModal: () => void;
  showChangePasswordModal: () => void;
  handleLogout: () => void;
};

const Profile = ({
  closeModal,
  showChangePasswordModal,
  handleLogout,
}: ProfileProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [fileList, setFileList] = useState<any>([]);

  const [imageUrl, setImageURL] = useState<string | undefined>(undefined);
  const [isDeleteAccountModalVisible, setIsDeleteAccountModalVisible] =
    useState(false);

  const profile = useSelector(selectProfile);

  const user = useSelector(selectUser);

  const { isMobileViewport } = useViewport();

  const [, , , , , isAgencyLeadView] = useRole();

  useEffect(() => {
    if (profile && profile.picture) {
      setImageURL(profile.picture);
    }
  }, [profile]);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: 'Edit Profile',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
    style: { marginBottom: isMobileViewport ? '50px' : 0 },
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);

      const data: any = {
        firstName: values.firstName,
        lastName: values.lastName,
        userId: user?.user_id,
        agencyId: user?.agency_id,
        email: values.email,
      };

      if (profile?.picture && !fileList[0] && !imageUrl) {
        data.removeProfilePicture = true;
      } else if (fileList[0]) {
        data.picture = fileList[0];
      }

      if (user) {
        await dispatch(
          updateProfile({
            token: user?.token,
            data: data,
            picture: data.picture,
          }),
        ).unwrap();

        notification.success({
          message: 'Success',
          description: 'Profile Saved Successfully!',
        });
      }

      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  const beforeUpload = async (file: any) => {
    const minSizeKB = envConstant.MIN_PICTURE_FILESIZE_UPLOAD_IN_KB;
    const maxSizeKB = envConstant.MAX_PICTURE_FILESIZE_UPLOAD_IN_KB;

    let maxSizeMessage: string;
    let isCompressionFailed = false;

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    const fileSizeKB = file.size / 1024;
    const isFileSizeAllowed =
      fileSizeKB >= minSizeKB && fileSizeKB <= maxSizeKB;

    if (!isJpgOrPng) {
      notification.warning({
        message: 'You can only upload a JPG/PNG file!',
      });
    }

    if (fileSizeKB < minSizeKB) {
      notification.warning({
        message: `Image should be at least ${minSizeKB}KB!`,
      });
    }

    if (maxSizeKB < 1024) {
      maxSizeMessage = `${maxSizeKB}KB`;
    } else {
      const maxSizeMB = maxSizeKB / 1024;

      maxSizeMessage = `${maxSizeMB}MB`;
    }

    if (fileSizeKB > maxSizeKB) {
      notification.warning({
        message: `Image should be no more than ${maxSizeMessage}!`,
      });
    }

    if (isJpgOrPng && isFileSizeAllowed) {
      try {
        const compressedBlob = await compressAccurately(file, 900); // Down to 900KB
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });

        file = compressedFile;

        setFileList([...fileList, file]);
      } catch (error) {
        console.error('Image compression error:', error);
        notification.error({
          message: `Something went wrong. Please try again or try a different picture.`,
        });

        isCompressionFailed = true;
      }
    }

    return (
      (isJpgOrPng && isFileSizeAllowed && !isCompressionFailed) ||
      Upload.LIST_IGNORE
    );
  };

  const handleImageChange =
    (setFieldValue) =>
    async ({ file }) => {
      if (file.status === 'uploading') {
        return;
      }

      if (file.status === 'done') {
        const preview: any = await getBase64Image(file.originFileObj);
        setImageURL(preview);
        setFieldValue('picture', file.originFileObj);
      }
    };

  const removePhotoClickHandler = (setFieldValue) => (e) => {
    e.preventDefault();
    setImageURL(undefined);
    setFileList([]);
    setFieldValue('picture', undefined);
  };

  const dummyRequest = async ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  const handleChangePasswordClick = () => {
    showChangePasswordModal();
    handleModalClose();
  };

  const handleDeleteAccountClose = () => {
    setIsDeleteAccountModalVisible(false);
  };

  return (
    <Modal {...modalOpts} className="l-profile c-form-modal">
      {isLoading && <FullPageLoader />}

      {isDeleteAccountModalVisible && (
        <DeleteAccountModal
          closeHandler={handleDeleteAccountClose}
          logoutHandler={handleLogout}
        />
      )}

      <Formik
        initialValues={{
          firstName: profile?.first_name,
          lastName: profile?.last_name,
          email: profile?.email,
          passCode: '*********',
          picture: profile?.picture,
        }}
        onSubmit={handleFormSubmit}
        validationSchema={profileFormValidationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="l-profile__picture-section">
                {imageUrl && (
                  <Avatar
                    className="l-profile__image-avatar"
                    src={imageUrl}
                    alt="avatar"
                  />
                )}
                {!imageUrl && (
                  <Avatar
                    className="l-profile__image-avatar l-profile__image-avatar--dummy"
                    alt="avatar"
                    icon={<UserOutlined />}
                  />
                )}
                <div className="l-profile__picture-controllers">
                  <Upload
                    name="avatar"
                    //   listType="picture-circle"
                    className="l-profile__change-photo-btn"
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    onChange={handleImageChange(setFieldValue)}
                    customRequest={dummyRequest}
                    accept=".png,.jpg,.jpeg"
                    maxCount={1}
                    multiple={false}
                  >
                    <Button>Change Photo</Button>
                  </Upload>
                  <Button
                    type="text"
                    onClick={removePhotoClickHandler(setFieldValue)}
                  >
                    Remove
                  </Button>
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">First Name:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="firstName"
                    onChange={handleChange}
                    type="text"
                    value={values.firstName}
                    onBlur={handleBlur}
                    placeholder={'First Name'}
                  />
                  <div className="c-form-field__error">
                    {errors.firstName ? errors.firstName : undefined}
                  </div>
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">Last Name:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="lastName"
                    onChange={handleChange}
                    type="text"
                    value={values.lastName}
                    onBlur={handleBlur}
                    placeholder={'Last Name'}
                  />
                  <div className="c-form-field__error">
                    {errors.lastName ? errors.lastName : undefined}
                  </div>
                </div>
              </div>
              <div className="c-form-field">
                <div className="c-form-field__label">Email:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="email"
                    onChange={handleChange}
                    type="text"
                    value={values.email}
                    onBlur={handleBlur}
                    placeholder={'Enter your email'}
                    disabled
                  />
                  <div className="c-form-field__error">
                    {errors.email ? errors.email : undefined}
                  </div>
                </div>
              </div>
              <div className="l-profile__dual-fields-wrapper">
                <div className="c-form-field">
                  <div className="c-form-field__label">Password:</div>
                  <div className="c-form-field__wrapper">
                    <Input.Password
                      name="passCode"
                      onChange={handleChange}
                      type="text"
                      value={values.passCode}
                      onBlur={handleBlur}
                      placeholder={'*********'}
                      disabled
                      addonBefore={<Lock />}
                      visibilityToggle={false}
                    />
                    <div className="c-form-field__error">
                      {errors.passCode ? errors.passCode : undefined}
                    </div>
                  </div>
                </div>
                <div className="l-profile__change-pass-btn-wrapper">
                  <Button
                    className="l-profile__change-pass-btn"
                    onClick={handleChangePasswordClick}
                  >
                    Change
                  </Button>
                </div>
              </div>

              <div className="l-profile__form-action-btn-wrapper">
                {isAgencyLeadView && (
                  <Button
                    className="l-profile__form-delete-btn"
                    disabled={isLoading}
                    onClick={() => {
                      setIsDeleteAccountModalVisible(true);
                    }}
                    danger
                    type="primary"
                  >
                    <ExclamationCircleOutlined /> Delete Account
                  </Button>
                )}
                <div className="l-profile__form-btn-wrapper">
                  <Button
                    className="l-profile__form-cancel-btn"
                    disabled={isLoading}
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="l-profile__form-submit-btn"
                    disabled={!(isValid && dirty) || isLoading}
                  >
                    Save Changes
                  </Button>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default Profile;
