import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';

import agency from '../features/agency/redux/slice';
import ai_report from '../features/ai_report/redux/slice';
import auth from '../features/authentication/redux/slice';
import client from '../features/client/redux/slice';
import dashboard from '../features/dashboard/redux/slice';
import enterprise from '../features/enterprise/redux/slice';
import focus from '../features/focus/redux/slice';
import offset_account from '../features/offset_account/redux/slice';
import plan from '../features/plan/redux/slice';
import profile from '../features/profile/redux/slice';
import property from '../features/property/redux/slice';
import property_types from '../features/property_type/redux/slice';
import stamp_and_lmi from '../features/stamp_duty_lmi/redux/slice';
import risk_profile from '../features/standard_tables/client_risk_profile/redux/slice';
import interest_rate from '../features/standard_tables/inrerest_rate_projection/redux/slice';
import suburb_scoring from '../features/suburb_scoring/redux/slice';

export interface IRootState {
  router: RouterState;
}

// Root Reducer
export default (history: History) => ({
  router: connectRouter(history),
  auth,
  profile,
  agency,
  enterprise,
  client,
  plan,
  focus,
  stamp_and_lmi,
  property_types,
  property,
  dashboard,
  offset_account,
  suburb_scoring,
  risk_profile,
  interest_rate,
  ai_report,
});
