import {
  AppstoreOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import UserIcon from '../../foundation/assets/svgs/UserIcon';
import UserIconCustom from '../../foundation/assets/svgs/UserIconCustom';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import useTableSize from '../../foundation/cutom_hooks/useTableSize';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import EnterpriseAdminModal from './modals/enterprise_admin_modal/EnterpriseAdminModal';
import InviteModal from './modals/invite_modal/InviteModal';
import InviteSuccessModal from './modals/invite_success_modal/InviteSuccessModal';
import ToggleEnterpriseStateModal from './modals/toggle_enterprise_state_modal/ToggleEnterpriseStateModal';
import { fetchEnterprises } from './redux/async_thunks';
import { selectEnterprises } from './redux/selectors';
import { setEnterprise, setEnterpriseAdmins } from './redux/slice';

const EnterpriseView = () => {
  const dispatch = useAppDispatch();
  const { isMobileViewport } = useViewport();
  const [isLoading, setIsLoading] = useState(false);

  const [isInviteModalVisible, setInviteModalVisibility] = useState(false);

  const [isInviteSuccessModalVisible, setInviteSuccessModalVisibility] =
    useState(false);

  const [isToggleEnterpriseStateVisible, setToggleEnterpriseStateVisibility] =
    useState(false);

  const [isEnterpriseAdminModalVisible, setEnterpriseAdminModalVisibility] =
    useState(false);

  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

  const user = useSelector(selectUser);

  const enterprises = useSelector(selectEnterprises);

  const columns: ColumnsType<any> = [
    {
      title: 'Name Of Enterprise',
      dataIndex: 'enterpriseName',
      key: 'enterpriseName',
      fixed: 'left',
      width: isMobileViewport ? 180 : 320,
      render: (text: string, record: any) => {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {record.enterprisePicture && (
              <Avatar
                src={record.enterprisePicture}
                style={{ minWidth: '50px', marginRight: '10px' }}
                size={50}
              />
            )}
            {!record.enterprisePicture && (
              <span
                style={{
                  display: 'flex',
                  height: '47px',
                  width: '47px',
                  minWidth: '47px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: '#eee',
                  borderRadius: '50%',
                  marginRight: '10px',
                  fontSize: '18px',
                }}
              >
                <AppstoreOutlined style={{ opacity: 0.7 }} />
              </span>
            )}
            <strong>{text}</strong>
          </div>
        );
      },
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive: boolean) => {
        return (
          <span
            className={`l-enterprise-detail__user-status-wrapper ${
              isActive
                ? ' l-enterprise-detail__user-status-wrapper--active'
                : ' l-enterprise-detail__user-status-wrapper--deactivated'
            }`}
          >
            <span
              className={`l-enterprise-detail__user-status-dot ${
                isActive
                  ? ' l-enterprise-detail__user-status-dot--active'
                  : ' l-enterprise-detail__user-status-dot--deactivated'
              }`}
            ></span>
            {isActive ? 'Active' : 'Deactivated'}
          </span>
        );
      },
    },
    {
      title: 'No. Of Agencies',
      dataIndex: 'totalAgencies',
      key: 'totalAgencies',
      render: (totalAgencies: any) => {
        return <strong>{totalAgencies ? totalAgencies : 0}</strong>;
      },
    },
    {
      title: 'No. Of Users',
      dataIndex: 'totalAgencyUsers',
      key: 'totalAgencyUsers',
      render: (totalAgencyUsers: any, record: any) => {
        return (
          <>
            <span
              className="l-enterprise-detail__user-status-wrapper l-enterprise-detail__user-status-wrapper--admin"
              onClick={() => {
                dispatch(setEnterpriseAdmins(record.adminUsers));
                setEnterpriseAdminModalVisibility(true);
              }}
            >
              <span className="l-enterprise-detail__user-status-flex">
                <UserIconCustom
                  color={'#175cd3'}
                  style={{ marginRight: '6px' }}
                />
                {record.totalEnterpriseAdmins
                  ? record.totalEnterpriseAdmins
                  : 0}
                {` Admin${record.totalEnterpriseAdmins > 1 ? 's' : ''}`}
              </span>
            </span>
            <span
              className="l-enterprise-detail__user-status-wrapper l-enterprise-detail__user-status-wrapper--user"
              style={{ margin: '0 8px' }}
            >
              <span className="l-enterprise-detail__user-status-flex">
                <UserIconCustom style={{ marginRight: '6px' }} />
                {totalAgencyUsers ? totalAgencyUsers : 0}
                {` User${totalAgencyUsers > 1 ? 's' : ''}`}
              </span>
            </span>
            <span className="l-enterprise-detail__user-status-wrapper l-enterprise-detail__user-status-wrapper--invited">
              <span className="l-enterprise-detail__user-status-flex">
                <UserIconCustom
                  color={'#b54708'}
                  style={{ marginRight: '6px' }}
                />
                {record.totalClients ? record.totalClients : 0}
                {` Client${record.totalClients > 1 ? 's' : ''}`}
              </span>
            </span>
          </>
        );
      },
    },
    {
      title: '',
      dataIndex: 'enterpriseName',
      key: 'enterpriseName',
      align: 'center',
      render: (text, record) => {
        return (
          <div className="l-enterprise-detail__table-control-btns">
            <span
              className="l-enterprise-detail__table-control-btn"
              onClick={() => {
                dispatch(
                  setEnterprise({
                    enterpriseAdminAgencyId: record.enterpriseAdminAgencyId,
                    enterpriseId: record.enterpriseId,
                    enterpriseName: record.enterpriseName,
                    enterprisePicture: record.enterprisePicture,
                    isActive: record.isActive,
                  }),
                );
                setToggleEnterpriseStateVisibility(true);
              }}
            >
              {record.isActive ? (
                <DeleteOutlined />
              ) : (
                <ExclamationCircleOutlined />
              )}
            </span>
          </div>
        );
      },
    },
  ];

  const fetchEnterpriseResults = async (searchValue?: string) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      if (user?.token) {
        await dispatch(
          fetchEnterprises({
            token: user.token,
            searchValue: searchValue,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleInviteEnterpriseAdminClick = () => {
    setInviteModalVisibility(true);
  };

  const handleInviteModalClose = () => {
    setInviteModalVisibility(false);
  };

  const handleInviteSuccessModalClose = () => {
    setInviteSuccessModalVisibility(false);
  };

  const handleToggleEnterpriseStateModalClose = () => {
    setToggleEnterpriseStateVisibility(false);
    dispatch(setEnterprise(undefined));
  };

  const handleEnterpriseAdminModalClose = () => {
    setEnterpriseAdminModalVisibility(false);
    dispatch(setEnterpriseAdmins([]));
  };

  useEffect(() => {
    fetchEnterpriseResults('');
  }, []);

  return (
    <div className="l-enterprise">
      {isLoading && <FullPageLoader />}

      {isInviteModalVisible && (
        <InviteModal
          closeModal={handleInviteModalClose}
          setInviteSuccessModalVisibility={setInviteSuccessModalVisibility}
          setInvitedEmails={setInvitedEmails}
        />
      )}

      {isInviteSuccessModalVisible && (
        <InviteSuccessModal
          closeModal={handleInviteSuccessModalClose}
          emails={invitedEmails}
        />
      )}

      {isToggleEnterpriseStateVisible && (
        <ToggleEnterpriseStateModal
          closeModal={handleToggleEnterpriseStateModalClose}
          fetchEnterpriseResults={fetchEnterpriseResults}
        />
      )}

      {isEnterpriseAdminModalVisible && (
        <EnterpriseAdminModal closeModal={handleEnterpriseAdminModalClose} />
      )}

      <div className="l-enterprise__view">
        <h4
          style={{
            padding: '0 15px',
            margin: isMobileViewport ? '20px 0' : '40px 0',
            textAlign: 'center',
          }}
        >
          Manage Property Pathfinder Enterprises
        </h4>
        <div className="l-enterprise-detail">
          <div className="l-enterprise-detail__view">
            <div className="l-enterprise-detail__users-section">
              <div className="l-enterprise-detail__users-section-header">
                <div className="l-enterprise-detail__users-section-header-title">
                  {isMobileViewport ? 'Enterprises' : 'Enterprise Accounts'}
                </div>
                <div>
                  <Button
                    type="primary"
                    onClick={handleInviteEnterpriseAdminClick}
                  >
                    Invite An Enterprise
                  </Button>
                </div>
              </div>
              <Table
                columns={columns}
                size={useTableSize()}
                pagination={false}
                scroll={{ x: 'max-content' }}
                dataSource={enterprises}
              />
            </div>
            <div className="l-enterprise-detail__permission-section">
              <div className="l-enterprise-detail__permission-section-title">
                Permissions
              </div>
              <div className="l-enterprise-detail__permission-info-wrapper">
                <div style={{ marginRight: 10 }}>
                  <UserIcon />
                </div>
                <div>
                  <div className="l-enterprise-detail__permission-info-title">
                    Enterprise admins can perform the following functions:
                  </div>
                  <div className="l-enterprise-detail__permission-info-description">
                    Edit enterprise profile, manage enterprise admins,
                    create/edit/delete agencies and agency admins/users
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterpriseView;
