import { Button, Checkbox, Input, Modal, Result, Tooltip } from 'antd';
import { Formik } from 'formik';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import { selectUser } from '../../../features/authentication/redux/selectors';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { selectClient } from '../../client/redux/selectors';
import { sharePlanDashboard } from '../redux/async_thunks';

const ShareReportValidationSchema = () => {
  const fields: any = {
    email: Yup.string().when('internalShare', {
      is: false,
      then: Yup.string().email().required('Email is required').nullable(),
      otherwise: Yup.string().email().optional().nullable(),
    }),
    sendInvite: Yup.boolean().optional(),
  };

  return Yup.object().shape(fields);
};

type Props = {
  closeModal: () => any;
};

const ShareReportModal = ({ closeModal }: Props) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const [reportLink, setReportLink] = useState<undefined | string>(undefined);
  const [inviteSent, setInviteSent] = useState<boolean>(false);

  const user = useSelector(selectUser);

  const client = useSelector(selectClient);

  const modalOpts = {
    title: 'Share Portfolio Plan Report',
    open: true,
    onCancel: () => {
      closeModal();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);

      if (user?.token) {
        const reqData = {
          userId: user.user_id,
          clientId: client?.clientId,
          sendInvite: values.sendInvite,
          email: values.email,
        };

        if (values.internalShare) {
          delete reqData['email'];
        }

        const response = await dispatch(
          sharePlanDashboard({ token: user.token, values: reqData }),
          // @ts-ignore
        ).unwrap();

        setReportLink(response.inviteLink);
        setInviteSent(response.inviteSent);

        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      closeModal();
    }
  };

  return (
    <Modal
      {...modalOpts}
      className="c-form-modal c-form-modal__dashboard-share"
    >
      {isLoading && <FullPageLoader />}
      {!reportLink && (
        <Formik
          initialValues={{
            email: '',
            sendInvite: true,
          }}
          onSubmit={handleFormSubmit}
          validationSchema={ShareReportValidationSchema}
        >
          {({
            errors,
            handleChange,
            values,
            handleSubmit,
            handleBlur,
            isValid,
            dirty,
            setFieldValue,
          }) => {
            return (
              <form onSubmit={handleSubmit}>
                <div className="c-form-field">
                  <div className="c-form-field__label">Email:</div>
                  <div className="c-form-field__wrapper">
                    <Input
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={values.email}
                      onBlur={handleBlur}
                      placeholder={'jonsnow@got.com'}
                    />
                    <div className="c-form-field__error">
                      {errors.email ? errors.email : undefined}
                    </div>
                  </div>
                </div>
                <div className="c-form-field">
                  <div className="c-form-field__wrapper">
                    <Checkbox
                      name="sendInvite"
                      checked={values.sendInvite}
                      onChange={handleChange}
                    >
                      Send an invite via email
                    </Checkbox>
                    <div className="c-form-field__error">
                      {errors.sendInvite ? errors.sendInvite : undefined}
                    </div>
                  </div>
                </div>
                <div className="c-form__btn-wrapper">
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={!(isValid && dirty) || isLoading}
                  >
                    Share
                  </Button>
                </div>
              </form>
            );
          }}
        </Formik>
      )}
      {reportLink && (
        <Result
          status="success"
          title={
            <p
              style={{
                fontSize: '18px',
                lineHeight: '25px',
              }}
            >
              {inviteSent ? (
                <span>
                  Report generated successfully <br />
                  &amp; the invite has been sent
                </span>
              ) : (
                <span>Report generated successfully.</span>
              )}
            </p>
          }
          extra={[
            <Tooltip
              key="copy"
              placement="bottom"
              trigger={'click'}
              title={'Copied!'}
            >
              <Button
                type="primary"
                onClick={() => {
                  navigator.clipboard.writeText(reportLink);
                }}
              >
                Copy Report Link
              </Button>
            </Tooltip>,
            <Button key="buy" onClick={closeModal}>
              Close
            </Button>,
          ]}
        />
      )}
    </Modal>
  );
};

export default ShareReportModal;
