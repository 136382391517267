import { Button, Col, DatePicker, notification, Row, Switch } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useRole } from '../../foundation/cutom_hooks/useRole';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import { selectPlan } from '../plan/redux/selectors';
import Accounts from './accounts/Accounts';
import {
  deleteOffsetAccount,
  fetchOffsetAccount,
  fetchOffsetAccountInfoByDate,
  updateOffsetAccount,
  UpdateOffsetAccountBodyType,
} from './redux/async_thunks';
import {
  selectCurrentOffsetAccount,
  selectOffsetAccountsInfo,
  selectOffsetAccountSourceAccounts,
} from './redux/selectors';
import { OffsetAccount, SourceAccount } from './redux/types';
import SourceAccounts from './source_accounts/SourceAccounts';
import { handleTotalForAccountOffset } from './utils/handleTotalForOffsetAccountArray';

const DATE_FORMAT = 'MM-YYYY';

const OffsetAccountView = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id: offsetAccountId }: any = useParams();

  const [isOffsetAccountLoading, setOffsetAccountLoading] = useState(true);

  const [isOffsetAccountActive, setOffsetAccountActive] = useState(false);

  const [offsetAccDataSource, setOffsetAccountDataSource] = useState<
    OffsetAccount[]
  >([]);

  const [sourceAccDataSource, setSourceAccDataSource] = useState<
    SourceAccount[]
  >([]);

  const [offsetAccountTransactionDate, setOffsetAccountTransactionDate] =
    useState<undefined | Dayjs>(undefined);

  const [isClientView] = useRole();

  const user = useSelector(selectUser);

  const plan = useSelector(selectPlan);

  const offsetAccount = useSelector(selectCurrentOffsetAccount);

  // Contains data for the offset account table.
  const offsetAccounts = useSelector(selectOffsetAccountsInfo);

  const sourceAccounts = useSelector(selectOffsetAccountSourceAccounts);

  const fetchOffsetAccountInfo = async () => {
    try {
      if (user && plan) {
        await dispatch(
          fetchOffsetAccount({
            token: user.token,
            planId: plan.planId,
            offsetAccountId,
          }),
        ).unwrap();
      }

      setOffsetAccountLoading(false);
    } catch (error) {
      setOffsetAccountLoading(false);
    }
  };

  useEffect(() => {
    if (offsetAccounts) {
      const source = handleTotalForAccountOffset(offsetAccounts);

      setOffsetAccountDataSource(source);
    }
  }, [offsetAccounts]);

  useEffect(() => {
    if (sourceAccounts) {
      setSourceAccDataSource(sourceAccounts);
    }
  }, [sourceAccounts]);

  useEffect(() => {
    if (offsetAccountId) {
      fetchOffsetAccountInfo();
    }
  }, [offsetAccountId]);

  useEffect(() => {
    if (offsetAccount) {
      setOffsetAccountActive(offsetAccount.isActive);
      setOffsetAccountTransactionDate(
        dayjs(offsetAccount.transactionDate, DATE_FORMAT),
      );
    }
  }, [offsetAccount]);

  const onTransactionDateChange = async (v: Dayjs | null) => {
    if (isOffsetAccountLoading || isClientView) {
      return;
    }
    try {
      setOffsetAccountLoading(true);

      if (user && plan && v) {
        const date = v.format(DATE_FORMAT);

        await dispatch(
          fetchOffsetAccountInfoByDate({
            token: user.token,
            planId: plan.planId,
            offsetAccountId,
            date,
          }),
        ).unwrap();
      }

      setOffsetAccountLoading(false);
    } catch (error) {
      setOffsetAccountLoading(false);
    }
  };

  const onOffsetAccountDeleteClick = async () => {
    if (isOffsetAccountLoading || isClientView) {
      return;
    }
    try {
      setOffsetAccountLoading(true);
      if (user && plan) {
        await dispatch(
          deleteOffsetAccount({
            token: user.token,
            planId: plan.planId,
            offsetAccountId,
            userId: user.user_id,
          }),
        ).unwrap();

        history.push('/dashboard');
      }

      setOffsetAccountLoading(false);
    } catch (error) {
      setOffsetAccountLoading(false);
    }
  };

  const updateClickHandler = async () => {
    if (isOffsetAccountLoading || isClientView) {
      return;
    }
    try {
      setOffsetAccountLoading(true);

      if (user && plan && offsetAccountTransactionDate) {
        const propertyTransactions: OffsetAccount[] = [];

        for (const item of offsetAccDataSource) {
          if (item.propertyName !== 'Total') {
            propertyTransactions.push(item);
          }
        }

        const data: UpdateOffsetAccountBodyType = {
          planId: plan.planId,
          offsetAccountId,
          userId: user.user_id,
          transactionDate: offsetAccountTransactionDate.format(DATE_FORMAT),
          isActive: isOffsetAccountActive,
          propertyTransactions,
        };

        for (let i = 0; i < sourceAccDataSource.length; i++) {
          const source = sourceAccDataSource[i];
          if (source.sourceName === 'Savings') {
            data.savings = {
              availableAmount: source.availableAmount,
              newAvailableAmount: source.newAvailableAmount,
            };
          } else if (source.sourceName === 'Portfolio Cashflow') {
            data.portfolioCashFlow = {
              availableAmount: source.availableAmount,
              newAvailableAmount: source.newAvailableAmount,
            };
          }
        }

        await dispatch(
          updateOffsetAccount({
            token: user.token,
            values: data,
          }),
        ).unwrap();

        notification.success({
          message: 'Success!',
          description: 'Offset Account updated successfully',
        });
      }
      setOffsetAccountLoading(false);
    } catch (error) {
      setOffsetAccountLoading(false);
    }
  };

  return (
    <div className="offset-account">
      {isOffsetAccountLoading && <FullPageLoader />}
      <Row align="middle" className="offset-account__row">
        <Col
          xs={24}
          md={6}
          style={{ fontSize: 20, fontWeight: 700, zIndex: 5 }}
        >
          Offset Account Transactions
        </Col>
        <Col xs={24} md={5} offset={7} className="offset-account__sub-action">
          <div className="info__field">
            <div className="info__field-label">Activate:</div>
            <Switch
              checked={isOffsetAccountActive}
              size={'default'}
              style={{ width: 80 }}
              disabled={isClientView}
              onChange={(v: boolean) => setOffsetAccountActive(v)}
            />
          </div>
        </Col>

        <Col xs={24} md={5} offset={1} className="offset-account__action">
          <Button
            type="primary"
            htmlType="submit"
            style={{ width: '100%', height: 40 }}
            disabled={isOffsetAccountLoading || isClientView}
            onClick={updateClickHandler}
          >
            Update
          </Button>
        </Col>
      </Row>
      <Row className="offset-account__row">
        <Col xs={24} md={6}>
          <div className="c-form-field">
            <div className="c-form-field__label">Date</div>
            <div className="c-form-field__wrapper">
              <DatePicker
                value={offsetAccountTransactionDate}
                picker="month"
                format={DATE_FORMAT}
                className="c-date-field"
                onChange={onTransactionDateChange}
                disabled={isClientView}
              />
            </div>
          </div>
        </Col>
      </Row>
      <SourceAccounts
        offsetAccDataSource={offsetAccDataSource}
        sourceAccDataSource={sourceAccDataSource}
        setSourceAccDataSource={setSourceAccDataSource}
      />
      <Accounts
        dataSource={offsetAccDataSource}
        setDataSource={setOffsetAccountDataSource}
        sourceAccDataSource={sourceAccDataSource}
      />
      <Row className="offset-account__row">
        <Col xs={24} md={6}>
          <Button
            type="primary"
            danger
            style={{ width: '100%' }}
            onClick={onOffsetAccountDeleteClick}
            disabled={isClientView}
          >
            Delete
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default OffsetAccountView;
