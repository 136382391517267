import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';

export const suburbScoreSuggestions: any = createAsyncThunk(
  'suburb-scoring/suggestions',
  async (apiParams: { token: string; data: any }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const data = {
      userId: apiParams.data.userId,
      searchKeyword: apiParams.data.searchKeyword,
      selectedValues: apiParams.data.selectedValues,
    };

    const encrypted = encryptData(data);

    await axios
      .post(
        `${env_constants.PP_API_BASE_URL}/SuburbScore/location-suggestion`,
        encrypted,
        {
          ...options,
        },
      )
      .then((response) => {
        if (response?.data) {
          result = decryptData(response.data).searchSuggestions;
        } else {
          result = [];
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const suburbScoreSearch: any = createAsyncThunk(
  'suburb-scoring/search',
  async (apiParams: { token: string; data: any }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .post(`${env_constants.PP_API_BASE_URL}/SuburbScore/search`, encrypted, {
        ...options,
      })
      .then((response) => {
        if (response.status === 204) {
          result = {
            pageCount: 0,
            pageIndex: 1,
            resultCount: 0,
            suburbs: [],
            itemsPerPage: 0,
            descriptors: {},
            suburbWarning: '',
          };
        } else {
          const decrypted = decryptData(response.data);

          result = {
            pageCount: decrypted.pageCount,
            resultCount: decrypted.resultCount,
            pageIndex: apiParams.data.pageIndex,
            suburbs: decrypted.suburbs,
            itemsPerPage: decrypted.itemsPerPage,
            descriptors: decrypted.descriptors,
            suburbWarning: decrypted.suburbWarning,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);
