import { Alert, Col, Popover, Row } from 'antd';
// eslint-disable-next-line
import { Chart as ChartJS, defaults } from 'chart.js';
import React from 'react';
import { Bubble, Doughnut } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import DwellingIcon from '../../../../foundation/assets/svgs/Dwelling';
import Info from '../../../../foundation/assets/svgs/Info';
import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import DashboardCard from '../../dashboard_card/DashboardCard';
import {
  selectReportDataByCurrentTemplate,
  selectSuburb,
  selectTemplate,
} from '../../redux/selectors';
import DisclaimerLink from '../../static_content/disclaimer_link/DisclaimerLink';

const Dwelling = () => {
  const dwelling = useSelector(selectReportDataByCurrentTemplate);
  const suburb = useSelector(selectSuburb);
  const template = useSelector(selectTemplate);

  const { isMobileViewport } = useViewport();

  const renderMedianSalePrice = () => {
    const { descriptor } = dwelling;
    const medianSalesPrice = dwelling.medianSalesPrice
      ? dwelling.medianSalesPrice
      : {};

    return (
      <DashboardCard
        title="Median Sale Price (12 Months)"
        descriptor={descriptor.medianSalePrice12Months}
        isDataAvailable={!!dwelling.medianSalesPrice}
        value={medianSalesPrice.medianSalesPrice}
        valueType={0}
        changeValue={medianSalesPrice.medianSalesPriceChange}
        changeIndicator={medianSalesPrice.change}
        comparedValue={medianSalesPrice.previousMedianSalesPrice}
        comparedValueType={0}
        sourceHtml={<DisclaimerLink />}
      ></DashboardCard>
    );
  };

  const renderMedianSalePrice3Months = () => {
    const { descriptor } = dwelling;
    const medianSalesPrice3Months = dwelling.medianSalesPrice3Months
      ? dwelling.medianSalesPrice3Months
      : {};

    return (
      <DashboardCard
        title="Median Sale Price (3 Months)"
        descriptor={descriptor.medianSalePrice3Months}
        isDataAvailable={!!dwelling.medianSalesPrice3Months}
        value={medianSalesPrice3Months.medianSalesPrice}
        valueType={0}
        changeValue={medianSalesPrice3Months.medianSalesPriceChange}
        changeIndicator={medianSalesPrice3Months.change}
        comparedValue={medianSalesPrice3Months.previousMedianSalesPrice}
        comparedValueType={0}
        sourceHtml={<DisclaimerLink />}
      ></DashboardCard>
    );
  };

  const renderMonth12Sales = () => {
    const { descriptor } = dwelling;
    const month12Sales = dwelling.month12Sales ? dwelling.month12Sales : {};

    return (
      <DashboardCard
        title="Sales Over The Last 12 Months"
        descriptor={descriptor.salesOverLast12Months}
        isDataAvailable={!!dwelling.month12Sales}
        value={month12Sales.month12Sales}
        valueType={0}
        changeValue={month12Sales.month12SalesChange}
        changeIndicator={month12Sales.change}
        comparedValue={month12Sales.previousMonth12Sales}
        comparedValueType={0}
        sourceHtml={<DisclaimerLink />}
      ></DashboardCard>
    );
  };

  const renderRentalYield = () => {
    const { descriptor } = dwelling;
    const rentalYield = dwelling.rentalYield ? dwelling.rentalYield : {};

    return (
      <DashboardCard
        title="Median Rental Yield"
        descriptor={descriptor.medianRentalYield}
        isDataAvailable={!!dwelling.rentalYield}
        value={rentalYield.rentalYield}
        valueType={1}
        changeValue={rentalYield.rentalYieldChange}
        changeIndicator={rentalYield.change}
        comparedValue={rentalYield.previousRentalYield}
        comparedValueType={1}
        sourceHtml={<DisclaimerLink />}
      ></DashboardCard>
    );
  };

  const renderVacancyRate = () => {
    const { descriptor } = dwelling;
    const vacancyRate = dwelling.vacancyRate ? dwelling.vacancyRate : {};

    return (
      <DashboardCard
        title="Vacancy Rate"
        descriptor={descriptor.vacancyRate}
        isDataAvailable={!!dwelling.vacancyRate}
        value={vacancyRate.vacancyRate}
        valueType={1}
        changeValue={vacancyRate.vacancyRateChange}
        changeIndicator={vacancyRate.change}
        comparedValue={vacancyRate.previousVacancyRate}
        comparedValueType={1}
      ></DashboardCard>
    );
  };

  const renderRentalMarket = () => {
    const { rentalMarketSize, ownerOccupierMarketSize } = dwelling;

    const data = {
      datasets: [
        {
          label: 'Sizes',
          data: [
            parseFloat(rentalMarketSize),
            parseFloat(ownerOccupierMarketSize),
            100 -
              (parseFloat(rentalMarketSize) +
                parseFloat(ownerOccupierMarketSize)),
          ],
          backgroundColor: [
            // @ts-ignore
            defaults.backgroundColor[0],
            // @ts-ignore
            defaults.backgroundColor[1],
            '#f1f5f9',
          ],
        },
      ],
      labels: ['Rental', 'Own/Occ', 'Others'],
    };

    const options = {
      cutout: '92%',
      responsive: true,
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: true,
          callbacks: {
            label: (context: any) => {
              return `${context.label}: ${context.parsed.toFixed(1)}%`;
            },
          },
        },
      },
    };

    let infoPopoverContent: any = null;

    if (dwelling?.descriptor?.rentalMarketSize) {
      infoPopoverContent = (
        <p className="l-descriptor">{dwelling.descriptor.rentalMarketSize}</p>
      );
    }

    return (
      <Row gutter={[30, 0]} justify="center" align="middle">
        <Col span={24} style={{ marginBottom: '32px' }}>
          <h5
            className="l-dashboard-card__title"
            style={{ paddingBottom: '10px' }}
          >
            Rental Market
            {dwelling?.descriptor?.rentalMarketSize && (
              <Popover
                content={infoPopoverContent}
                title={null}
                placement="top"
                trigger="click"
                overlayClassName="no-override"
              >
                <span className="l-descriptor-trigger">
                  <Info />
                </span>
              </Popover>
            )}
          </h5>
          <div className="l-rental-market">
            <div className="l-rental-market__chart-symbol">
              <DwellingIcon />
            </div>
            <div className="l-rental-market__chart">
              <Doughnut data={data} options={options} />
            </div>
          </div>
        </Col>
        <Col span={24}>
          <dl>
            <dt className="l-dashboard-card__values-wrapper">
              <span
                className="l-dashboard-card__value"
                style={{ fontSize: '20px', whiteSpace: 'nowrap' }}
              >
                <span
                  // @ts-ignore
                  style={{ background: defaults.backgroundColor[0] }}
                  className="l-dashboard-card__bullet"
                ></span>
                {rentalMarketSize || '0.0%'}
              </span>
            </dt>
            <dd className="l-dashboard-card__details">
              <span>Rental Market Size</span>
            </dd>
            <dt className="l-dashboard-card__values-wrapper">
              <span
                className="l-dashboard-card__value"
                style={{
                  fontSize: '20px',
                  marginTop: '30px',
                  whiteSpace: 'nowrap',
                }}
              >
                <span
                  // @ts-ignore
                  style={{ background: defaults.backgroundColor[1] }}
                  className="l-dashboard-card__bullet"
                ></span>
                {ownerOccupierMarketSize || '0.0%'}
              </span>
            </dt>
            <dd className="l-dashboard-card__details">
              <span>Owner/Occupier Market Size</span>
            </dd>
          </dl>
        </Col>
      </Row>
    );
  };

  const getPreferredDwellingData = (preferredDwelling: any) => {
    return {
      separateHouse: preferredDwelling?.separateHouse || [],
      semiDetachedHouse: preferredDwelling?.semiDetachedHouse || [],
      flatUnitApartment: preferredDwelling?.flatUnitApartment || [],
    };
  };

  const renderPreferredDwellingType = () => {
    const { separateHouse, semiDetachedHouse, flatUnitApartment } =
      getPreferredDwellingData(dwelling.preferredDwelling);

    const predicate = (p: any) => {
      return p.percentageShare !== '0.0%';
    };

    const separateHouseHasPercentage = !!separateHouse.filter(predicate).length;
    const semiDetachedHouseHasPercentage =
      !!separateHouse.filter(predicate).length;
    const flatUnitApartmentHasPercentage =
      !!separateHouse.filter(predicate).length;

    if (
      !separateHouseHasPercentage &&
      !semiDetachedHouseHasPercentage &&
      !flatUnitApartmentHasPercentage
    ) {
      return <Alert message="No data available" type="warning" showIcon />;
    }

    const data = {
      datasets: [
        {
          label: 'Separate House',
          data: separateHouse.map((s: any) => {
            const radius = parseFloat(s.percentageShare);

            return {
              x: 1,
              y: parseFloat(s.bedroomCount),
              r: radius > 0 ? radius + 5 : 0,
            };
          }),
          hoverRadius: 0,
        },
        {
          label: 'Semi-Detached',
          data: semiDetachedHouse.map((d: any) => {
            const radius = parseFloat(d.percentageShare);

            return {
              x: 2,
              y: parseFloat(d.bedroomCount),
              r: radius > 0 ? radius + 5 : 0,
            };
          }),
          hoverRadius: 0,
        },
        {
          label: 'Flat Unit Or Apartment',
          data: flatUnitApartment.map((f: any) => {
            const radius = parseFloat(f.percentageShare);

            return {
              x: 3,
              y: parseFloat(f.bedroomCount),
              r: radius > 0 ? radius + 5 : 0,
            };
          }),
          hoverRadius: 0,
        },
        {
          label: 'Placeholder', // Added for aesthetics: to extend the x-axis by one tick
          backgroundColor: 'transparent',
          data: flatUnitApartment.map(() => ({
            x: 4,
            y: 0,
            r: 0,
          })),
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      borderWidth: 0,
      scales: {
        y: {
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            autoSkip: false,
            callback: (value: any) => {
              if (value === 4) {
                return `${value}+ Bed`;
              }

              return `${value} Bed`;
            },
          },
        },
        x: {
          grid: {
            color: 'rgba(0, 0, 0, 0)',
          },
          beginAtZero: true,
          ticks: {
            stepSize: 1,
            max: 4,
            autoSkip: false,
            callback: (value: any) => {
              let display;

              switch (value) {
                case 1:
                  display = 'Separate House';
                  break;
                case 2:
                  display = 'Semi-Detached';
                  break;
                case 3:
                  display = 'Flat Unit Or Apartment';
                  break;
                default:
                  display = '';
              }

              return display;
            },
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (context: any) => {
              const radius = context.raw.r;

              return `${radius > 0 ? radius - 5 : radius}%`;
            },
          },
        },
      },
    };

    return (
      <div style={{ padding: '.8571rem', height: '100%' }}>
        <Bubble data={data} options={options} />
      </div>
    );
  };

  if (template !== 'dwelling') {
    return <></>;
  }

  if (suburb && !dwelling) {
    return (
      <Col span={24}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Alert
              message="No data available"
              description={<></>}
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      </Col>
    );
  }

  let bubbleInfoPopoverContent: any = null;

  if (dwelling?.descriptor?.preferredDwellingType) {
    bubbleInfoPopoverContent = (
      <p className="l-descriptor">
        {dwelling?.descriptor.preferredDwellingType}
      </p>
    );
  }

  return (
    <div className="about">
      {/* {isLoading && <FullPageLoader />} */}
      <Row gutter={[0, 15]}>
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col span={isMobileViewport ? 24 : 12}>
              <Row gutter={[0, 15]} style={{ height: '100%' }}>
                <Col span={24}>{renderMedianSalePrice()}</Col>
              </Row>
            </Col>
            <Col span={isMobileViewport ? 24 : 12}>
              <Row gutter={[0, 15]} style={{ height: '100%' }}>
                <Col span={24}>{renderMedianSalePrice3Months()}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[15, 15]}>
            <Col span={isMobileViewport ? 24 : 12}>
              {' '}
              <div className="l-dashboard-card">
                <div
                  className="l-dashboard-card__body"
                  style={{ minHeight: '410px' }}
                >
                  {renderRentalMarket()}
                </div>
              </div>
            </Col>
            <Col span={isMobileViewport ? 24 : 12}>
              <Row gutter={[0, 15]}>
                <Col span={24}>{renderRentalYield()}</Col>
                <Col span={24}>{renderVacancyRate()}</Col>
                <Col span={24}>{renderMonth12Sales()}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="l-dashboard-card" style={{ height: '400px' }}>
            <div className="l-dashboard-card__body l-dashboard-card__body--justify-start">
              <h5 className="l-dashboard-card__title">
                Dwelling Distribution
                {dwelling?.descriptor?.preferredDwellingType && (
                  <Popover
                    content={bubbleInfoPopoverContent}
                    title={null}
                    placement="top"
                    trigger="click"
                    overlayClassName="no-override"
                  >
                    <span className="l-descriptor-trigger">
                      <Info />
                    </span>
                  </Popover>
                )}
              </h5>
              {renderPreferredDwellingType()}
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dwelling;
