import { Alert, Col, Popover, Row } from 'antd';
// eslint-disable-next-line
import { Chart as ChartJS, defaults } from 'chart.js';
import React from 'react';
import { Radar } from 'react-chartjs-2';
// eslint-disable-next-line
import { Chart } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import Info from '../../../../foundation/assets/svgs/Info';
import { useViewport } from '../../../../foundation/cutom_hooks/useViewport';
import {
  selectReportDataByCurrentTemplate,
  selectSuburb,
  selectTemplate,
} from '../../redux/selectors';

const InvestmentSummary = () => {
  const investmentSummary = useSelector(selectReportDataByCurrentTemplate);
  const suburb = useSelector(selectSuburb);
  const template = useSelector(selectTemplate);

  const { isTabletViewport } = useViewport();

  const renderPercentCircle = (value?: number) => {
    if (!value) {
      return (
        <div className="l-investment-summary__percent-circle-wrap">
          <div
            className="l-investment-summary__percent-circle"
            style={{
              background: `radial-gradient(closest-side, white 89%, transparent 90% 100%), conic-gradient(#eff0f6 100%, #eff0f6 0)`,
            }}
          >
            <span
              className="l-investment-summary__percent-circle-value"
              style={{ fontSize: '30px' }}
            >
              N/A
            </span>
          </div>
        </div>
      );
    }

    let colorMatch: string;

    switch (value) {
      case 5:
        colorMatch = '#1cb002';
        break;
      case 4:
        colorMatch = '#82e003';
        break;
      case 3:
        colorMatch = '#ffd301';
        break;
      case 2:
        colorMatch = '#fc950c';
        break;
      default:
        colorMatch = '#f50603';
    }

    return (
      <div className="l-investment-summary__percent-circle-wrap">
        <div
          className="l-investment-summary__percent-circle"
          style={{
            background: `radial-gradient(closest-side, white 87%, transparent 88% 100%), conic-gradient(${colorMatch} ${
              value * 20
            }%, #eff0f6 0)`,
          }}
        >
          <span className="l-investment-summary__percent-circle-value">
            {value}
            <small>/5</small>
          </span>
        </div>
      </div>
    );
  };

  if (template !== 'investment-summary') {
    return <></>;
  }

  if (suburb && !investmentSummary) {
    return (
      <Col span={24}>
        <Row gutter={[0, 30]}>
          <Col span={24}>
            <Alert
              message="No data available"
              description={<></>}
              type="warning"
              showIcon
            />
          </Col>
        </Row>
      </Col>
    );
  }

  return (
    <Row gutter={[15, 15]}>
      <Col xs={24} md={12} style={!isTabletViewport ? { display: 'flex' } : {}}>
        <Row gutter={[15, 15]}>
          <Col span={12}>
            <Row gutter={[0, 15]} style={{ height: '100%' }}>
              <Col span={24}>
                <div className="l-dashboard-card">
                  <div className="l-dashboard-card__body l-dashboard-card__body--justify-start l-dashboard-card__body--flex">
                    <h5 className="l-dashboard-card__title">
                      Growth Score
                      {investmentSummary?.descriptor?.growth && (
                        <Popover
                          content={
                            <div className="l-descriptor">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: investmentSummary.descriptor.growth,
                                }}
                              />
                            </div>
                          }
                          title={null}
                          placement="top"
                          trigger="click"
                          overlayClassName="no-override"
                        >
                          <span className="l-descriptor-trigger">
                            <Info />
                          </span>
                        </Popover>
                      )}
                    </h5>
                    {renderPercentCircle(
                      investmentSummary.suburbScores?.growthScore,
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="l-dashboard-card">
                  <div className="l-dashboard-card__body l-dashboard-card__body--justify-start l-dashboard-card__body--flex">
                    <h5 className="l-dashboard-card__title">
                      Affordability Score
                      {investmentSummary?.descriptor?.affordability && (
                        <Popover
                          content={
                            <div className="l-descriptor">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    investmentSummary.descriptor.affordability,
                                }}
                              />
                            </div>
                          }
                          title={null}
                          placement="top"
                          trigger="click"
                          overlayClassName="no-override"
                        >
                          <span className="l-descriptor-trigger">
                            <Info />
                          </span>
                        </Popover>
                      )}
                    </h5>
                    {renderPercentCircle(
                      investmentSummary.suburbScores?.affordScore,
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col span={12}>
            <Row gutter={[0, 15]} style={{ height: '100%' }}>
              <Col span={24}>
                <div className="l-dashboard-card">
                  <div className="l-dashboard-card__body l-dashboard-card__body--justify-start l-dashboard-card__body--flex">
                    <h5 className="l-dashboard-card__title">
                      Cashflow Score
                      {investmentSummary?.descriptor?.cashflow && (
                        <Popover
                          content={
                            <div className="l-descriptor">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: investmentSummary.descriptor.cashflow,
                                }}
                              />
                            </div>
                          }
                          title={null}
                          placement="top"
                          trigger="click"
                          overlayClassName="no-override"
                        >
                          <span className="l-descriptor-trigger">
                            <Info />
                          </span>
                        </Popover>
                      )}
                    </h5>
                    {renderPercentCircle(
                      investmentSummary.suburbScores?.cashFlowScore,
                    )}
                  </div>
                </div>
              </Col>
              <Col span={24}>
                <div className="l-dashboard-card">
                  <div className="l-dashboard-card__body l-dashboard-card__body--justify-start l-dashboard-card__body--flex">
                    <h5 className="l-dashboard-card__title">
                      Socio-economic Score
                      {investmentSummary?.descriptor?.socioEconomic && (
                        <Popover
                          content={
                            <div className="l-descriptor">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html:
                                    investmentSummary.descriptor.socioEconomic,
                                }}
                              />
                            </div>
                          }
                          title={null}
                          placement="top"
                          trigger="click"
                          overlayClassName="no-override"
                        >
                          <span className="l-descriptor-trigger">
                            <Info />
                          </span>
                        </Popover>
                      )}
                    </h5>
                    {renderPercentCircle(
                      investmentSummary.suburbScores?.socioEcoScore,
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col xs={24} md={12} style={isTabletViewport ? { display: 'flex' } : {}}>
        <Row gutter={[15, 15]}>
          <Col span={24}>
            <div className="l-dashboard-card">
              <div className="l-dashboard-card__body l-dashboard-card__body--justify-start">
                <Radar
                  data={{
                    labels: [
                      'Cashflow Score',
                      'Affordability Score',
                      'Socio-economic Score',
                      'Growth Score',
                    ],
                    datasets: [
                      {
                        label: 'Suburb Score',
                        data: [
                          investmentSummary.suburbScores?.cashFlowScore,
                          investmentSummary.suburbScores?.affordScore,
                          investmentSummary.suburbScores?.socioEcoScore,
                          investmentSummary.suburbScores?.growthScore,
                        ],
                        fill: true,
                        backgroundColor: 'rgba(54, 207, 201, 0.2)',
                        borderColor: 'rgb(54, 207, 201)',
                        pointBackgroundColor: 'rgb(54, 207, 201)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(54, 207, 201)',
                      },
                      {
                        label: 'State Score',
                        data: [
                          investmentSummary.stateAvgScores?.cashFlowScore,
                          investmentSummary.stateAvgScores?.affordScore,
                          investmentSummary.stateAvgScores?.socioEcoScore,
                          investmentSummary.stateAvgScores?.growthScore,
                        ],
                        fill: true,
                        backgroundColor: 'rgba(226, 158, 21, 0.2)',
                        borderColor: 'rgb(226, 158, 21)',
                        pointBackgroundColor: 'rgb(226, 158, 21)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(226, 158, 21)',
                      },
                    ],
                  }}
                  options={{
                    elements: {
                      line: {
                        borderWidth: 2,
                      },
                    },
                    plugins: {
                      legend: {
                        position: 'bottom',
                      },
                    },
                    scales: {
                      r: {
                        ticks: {
                          stepSize: 1,
                        },
                        beginAtZero: true,
                        max: 5,
                      },
                    },
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default InvestmentSummary;
