import {
  Avatar,
  Button,
  Divider,
  Input,
  Modal,
  notification,
  Upload,
} from 'antd';
import { Formik } from 'formik';
import { compressAccurately } from 'image-conversion';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../../foundation/assets/svgs/Close';
import UploadIcon from '../../../../foundation/assets/svgs/Upload';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import getBase64Image from '../../../../foundation/utils/getBase64Image';
import {
  clearStorage,
  getItemFromStorage,
} from '../../../../foundation/utils/storageHandler';
import envConstant from '../../../../internals/env/env_constants.json';
import { useAppDispatch } from '../../../../store/hooks';
import { logout } from '../../../authentication/redux/async_thunks';
import { selectUser } from '../../../authentication/redux/selectors';
import { setUser } from '../../../authentication/redux/slice';
import { selectProfile } from '../../../profile/redux/selectors';
import { setProfile } from '../../../profile/redux/slice';
import { createEnterprise, updateEnterprise } from '../../redux/async_thunks';
import { selectEnterprise, selectEnterprises } from '../../redux/selectors';
import { setEnterprise, setEnterprises } from '../../redux/slice';
import { enterpriseFormValidationSchema } from '../../validation_schemas/enterprise_form_validation';
type CreateEnterpriseModalProps = {
  closeModal: () => void;
  fetchEnterpriseData: any;
};
const EnterpriseModal = ({
  closeModal,
  fetchEnterpriseData,
}: CreateEnterpriseModalProps) => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [fileList, setFileList] = useState<any>([]);

  const [imageUrl, setImageURL] = useState<string | undefined>(undefined);

  const [formType, setFormType] = useState<'create' | 'update'>('create');

  const enterprise = useSelector(selectEnterprise);

  const enterprises = useSelector(selectEnterprises);

  const user = useSelector(selectUser);

  const profile = useSelector(selectProfile);

  useEffect(() => {
    if (enterprise?.enterpriseId) {
      setFormType('update');
    }

    if (enterprise?.enterprisePicture) {
      setImageURL(enterprise.enterprisePicture);
    }
  }, [enterprise]);

  const initialFormValues = useMemo(() => {
    if (enterprise?.enterpriseId) {
      return {
        enterpriseName: enterprise.enterpriseName,
        picture: enterprise.enterprisePicture,
      };
    } else {
      return {
        enterpriseName: undefined,
        picture: undefined,
      };
    }
  }, [enterprise]);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title:
      formType === 'create'
        ? 'Set Up Enterprise Profile'
        : 'Edit Enterprise Profile',
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: formType !== 'create',
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
  };

  const handleEnterpriseCreate = async (values: any) => {
    try {
      const data: any = {
        enterpriseName: values.enterpriseName,
        userId: user?.user_id,
        // Use agencyId from the user's profile when creating the enterprise for the first time
        enterpriseAdminAgencyId: user?.agency_id,
      };

      if (fileList[0]) {
        data.picture = fileList[0];
      }

      if (user) {
        const createdEnterprise: any = await dispatch(
          createEnterprise({
            token: user?.token,
            data: data,
            picture: data.picture,
          }),
        ).unwrap();

        const newEnterpriseId =
          createdEnterprise.enterpriseProfile.enterpriseId;

        // Set enterprise data
        dispatch(setEnterprise(createdEnterprise.enterpriseProfile));

        // Update the enterpriseId in the user store
        dispatch(
          setUser({
            ...user,
            enterprise_id: newEnterpriseId,
          }),
        );

        // Update the enterpriseId in the profile store
        if (profile) {
          dispatch(
            setProfile({
              ...profile,
              ...{
                enterprise_id: newEnterpriseId,
              },
            }),
          );
        }

        // Fetch the enterprise admin data
        fetchEnterpriseData(newEnterpriseId);
      }

      notification.success({
        message: 'Success!',
        description: 'Enterprise profile setup is successful.',
      });

      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleEnterpriseUpdate = async (values: any) => {
    try {
      // Create data
      const data: any = {
        enterpriseName: values.enterpriseName,
        enterpriseId: enterprise?.enterpriseId,
        userId: user?.user_id,
      };

      // Handle picture logic.
      if (enterprise?.enterprisePicture && !fileList[0] && !imageUrl) {
        data.removeEnterprisePicture = true;
      } else if (fileList[0]) {
        data.picture = fileList[0];
      }

      if (user) {
        let updatedList: any[] = [];

        // API call
        const updatedEnterprise: any = await dispatch(
          updateEnterprise({
            token: user?.token,
            data: data,
            picture: data.picture,
          }),
        ).unwrap();

        dispatch(setEnterprise(updatedEnterprise));

        if (enterprises) {
          updatedList = enterprises?.map((item) => {
            if (item.enterpriseId === updatedEnterprise?.enterpriseId) {
              return { ...updatedEnterprise };
            }
            return item;
          });

          dispatch(setEnterprises(updatedList));
        }

        notification.success({
          message: 'Success!',
          description: 'Enterprise profile updated successfully.',
        });
      }

      setIsLoading(false);
      closeModal();
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    if (formType === 'create') {
      handleEnterpriseCreate(values);
    } else {
      handleEnterpriseUpdate(values);
    }

    setIsLoading(true);
  };

  const handleLogout = async () => {
    const getUser = getItemFromStorage('user');
    // @ts-ignore
    const userStorage = JSON.parse(getUser);
    const fixUser = user || userStorage;
    try {
      if (fixUser) {
        const data: any = {
          email: profile?.email,
          userId: fixUser?.user_id,
        };

        await dispatch(
          logout({
            token: fixUser?.token,
            data,
          }),
        ).unwrap();
        clearStorage();

        // Force browser redirect to take down trackers
        // If ever they were previously embedded
        window.location.href = '/';
      }
    } catch (error) {
      console.log(error);
    }
  };

  const beforeUpload = async (file: any) => {
    const minSizeKB = envConstant.MIN_PICTURE_FILESIZE_UPLOAD_IN_KB;
    const maxSizeKB = envConstant.MAX_PICTURE_FILESIZE_UPLOAD_IN_KB;

    let maxSizeMessage: string;
    let isCompressionFailed = false;

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    const fileSizeKB = file.size / 1024;
    const isFileSizeAllowed =
      fileSizeKB >= minSizeKB && fileSizeKB <= maxSizeKB;

    if (!isJpgOrPng) {
      notification.warning({
        message: 'You can only upload a JPG/PNG file!',
      });
    }

    if (fileSizeKB < minSizeKB) {
      notification.warning({
        message: `Image should be at least ${minSizeKB}KB!`,
      });
    }

    if (maxSizeKB < 1024) {
      maxSizeMessage = `${maxSizeKB}KB`;
    } else {
      const maxSizeMB = maxSizeKB / 1024;

      maxSizeMessage = `${maxSizeMB}MB`;
    }

    if (fileSizeKB > maxSizeKB) {
      notification.warning({
        message: `Image should be no more than ${maxSizeMessage}!`,
      });
    }

    if (isJpgOrPng && isFileSizeAllowed) {
      try {
        const compressedBlob = await compressAccurately(file, 900); // Down to 900KB
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });

        file = compressedFile;

        setFileList([...fileList, file]);
      } catch (error) {
        console.error('Image compression error:', error);
        notification.error({
          message: `Something went wrong. Please try again or try a different picture.`,
        });

        isCompressionFailed = true;
      }
    }

    return (
      (isJpgOrPng && isFileSizeAllowed && !isCompressionFailed) ||
      Upload.LIST_IGNORE
    );
  };

  const handleImageChange =
    (setFieldValue) =>
    async ({ file }) => {
      if (file.status === 'uploading') {
        return;
      }

      if (file.status === 'done') {
        const preview: any = await getBase64Image(file.originFileObj);
        setImageURL(preview);
        setFieldValue('picture', file.originFileObj);
      }
    };

  const removePhotoClickHandler = (setFieldValue) => (e) => {
    e.preventDefault();
    setImageURL(undefined);
    setFileList([]);
    setFieldValue('picture', undefined);
  };

  const dummyRequest = ({ file, onSuccess }: any) => {
    setTimeout(() => {
      onSuccess('ok');
    }, 0);
  };

  return (
    <Modal {...modalOpts} className="h-enterprise-modal c-form-modal">
      {isLoading && <FullPageLoader />}
      <Formik
        initialValues={initialFormValues}
        onSubmit={handleFormSubmit}
        validationSchema={enterpriseFormValidationSchema}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="c-form-field">
                <div className="c-form-field__label">Enterprise name:</div>
                <div className="c-form-field__wrapper">
                  <Input
                    name="enterpriseName"
                    onChange={handleChange}
                    type="text"
                    value={values.enterpriseName}
                    onBlur={handleBlur}
                  />
                  <div className="c-form-field__error">
                    {/* @ts-ignore */}
                    {errors.enterpriseName ? errors.enterpriseName : undefined}
                  </div>
                </div>
              </div>
              <Divider />
              <div className="h-enterprise-modal__picture-section">
                {imageUrl && (
                  <>
                    <Avatar
                      className="h-enterprise-modal__image-avatar"
                      src={imageUrl}
                      alt="avatar"
                      shape="square"
                    />
                    <Button
                      type="text"
                      onClick={removePhotoClickHandler(setFieldValue)}
                    >
                      Remove or Edit
                    </Button>
                  </>
                )}
                {!imageUrl && (
                  <div className="h-enterprise-modal__picture-controllers">
                    <Upload
                      name="avatar"
                      className="h-enterprise-modal__change-photo-btn"
                      showUploadList={false}
                      beforeUpload={beforeUpload}
                      onChange={handleImageChange(setFieldValue)}
                      customRequest={dummyRequest}
                      accept=".png,.jpg,.jpeg"
                      maxCount={1}
                      multiple={false}
                    >
                      <div className="h-enterprise-modal__picture-uploader">
                        <div>
                          <UploadIcon />
                        </div>
                        <div className="h-enterprise-modal__picture-uploader-text">
                          Upload Logo
                        </div>
                        <Button
                          type="primary"
                          className="h-enterprise-modal__picture-uploader-btn"
                        >
                          Browse to upload
                        </Button>
                      </div>
                    </Upload>
                  </div>
                )}
              </div>

              <div className="c-form__btn-wrapper h-enterprise-modal__form-btn-wrapper">
                {formType !== 'create' && (
                  <Button
                    className="h-enterprise-modal__form-cancel-btn"
                    disabled={isLoading}
                    onClick={handleModalClose}
                  >
                    Cancel
                  </Button>
                )}
                {formType === 'create' && (
                  <Button
                    className="h-enterprise-modal__form-cancel-btn"
                    disabled={isLoading}
                    onClick={handleLogout}
                  >
                    Logout For Now
                  </Button>
                )}
                <Button
                  type="primary"
                  htmlType="submit"
                  className="h-enterprise-modal__form-submit-btn"
                  disabled={!(isValid && dirty) || isLoading}
                >
                  Save & Continue
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EnterpriseModal;
