import { Button, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import WarningIcon from '../../../../foundation/assets/svgs/WarningIcon';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { deleteUser } from '../../redux/async_thunks';
import { selectAgency } from '../../redux/selectors';
import { AgencyUser } from '../../redux/types';

type DeleteAgencyUserModalProps = {
  closeModal: () => void;
  selectedAgencyUser: AgencyUser | undefined;
  deleteType: 'user' | 'client';
};
const DeleteAgencyUserModal = ({
  closeModal,
  selectedAgencyUser,
  deleteType,
}: DeleteAgencyUserModalProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const agency = useSelector(selectAgency);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <WarningIcon />
        </span>
        <span>{`Archive ${
          deleteType === 'user' ? 'Agency User' : 'Client'
        }`}</span>
      </div>
    ),
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: false,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  const handleDeleteBtnClick = async () => {
    if (isLoading) {
      return;
    }

    try {
      if (selectedAgencyUser && user) {
        setIsLoading(true);

        const data = {
          email: selectedAgencyUser.email,
          userId: user.user_id,
          agencyId: agency?.agencyId,
          roleId: selectedAgencyUser.roleId,
        };

        await dispatch(
          deleteUser({
            data,
            token: user.token,
          }),
        ).unwrap();

        setIsLoading(false);

        notification.success({
          message: 'Success!',
          description: 'User deleted successfully',
        });
        handleModalClose();
      }
    } catch (error) {
      setIsLoading(false);
      handleModalClose();
    }
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal" width={570}>
      {isLoading && <FullPageLoader />}
      {deleteType === 'user' ? (
        <div className="h-invite-success-modal__text">
          Please confirm that you want to delete the following user? <br /> Once
          deleted the user will no longer have access to the agency, its
          clients, and their plans.
        </div>
      ) : (
        <div className="h-invite-success-modal__text">
          Please confirm that you want to delete the following client? <br />{' '}
          Once deleted the user will no longer have access to their plans.
        </div>
      )}
      <div
        className="h-invite-success-modal__emails-wrapper"
        style={{ fontWeight: 600 }}
      >
        {selectedAgencyUser?.email}
      </div>
      <div className="h-invite-success-modal__btn-wrapper">
        <Button
          className="h-invite-success-modal__btn"
          onClick={handleModalClose}
        >
          Close
        </Button>
        <Button
          type="primary"
          danger
          className="h-invite-success-modal__delete-btn"
          onClick={handleDeleteBtnClick}
        >
          Delete User
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAgencyUserModal;
