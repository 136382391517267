import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectAgencies = (state: RootState) => state.agency.list;

export const selectAgency = (state: RootState) => state.agency.agency;

export const selectAgencyUsers = (state: RootState) => state.agency.agencyUsers;
