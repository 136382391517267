import { Button, Input, Modal } from 'antd';
import { FieldArray, Formik } from 'formik';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import Select from '../../../../foundation/components/select/Select';
import { useAppDispatch } from '../../../../store/hooks';
import {
  selectRoleOptions,
  selectUser,
} from '../../../authentication/redux/selectors';
import { inviteUsers } from '../../redux/async_thunks';
import { selectAgency } from '../../redux/selectors';
import { AgencyUser } from '../../redux/types';
import { inviteFormValidationSchema } from '../../validation_schemas/invite_form_validation';

type EditInviteModalProps = {
  closeModal: () => void;
  selectedUser: AgencyUser;
  setInviteSuccessModalVisibility: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setInvitedEmails: React.Dispatch<React.SetStateAction<string[]>>;
  editType: 'user' | 'client';
};

const EditInviteModal = ({
  closeModal,
  setInviteSuccessModalVisibility,
  setInvitedEmails,
  selectedUser,
  editType,
}: EditInviteModalProps) => {
  const ROLE_IDS = useSelector(selectRoleOptions);
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);
  const agency = useSelector(selectAgency);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const userRoles = useMemo(() => {
    if (ROLE_IDS) {
      return [
        {
          key: 'Client',
          value: ROLE_IDS?.client.toString(),
        },
        {
          key: 'Agency User',
          value: ROLE_IDS?.agencyUser.toString(),
        },
        {
          key: 'Agency Admin',
          value: ROLE_IDS?.agencyAdmin.toString(),
        },
      ];
    }
    return [];
  }, [ROLE_IDS]);

  const editTypeValues = {
    client: {
      title: 'Edit Clients',
      roleId: userRoles[0].value,
      info: (
        <div className="h-invite-modal__information">
          <strong>Clients</strong>{' '}
          {`have access to view the plans without editing.`}
        </div>
      ),
    },
    user: {
      title: 'Edit Agency Users',
      roleId: userRoles[1].value,
      info: (
        <>
          <div className="h-invite-modal__information">
            <strong>Agency users</strong>{' '}
            {`can add/edit/delete agency clients and client's plans.`}
          </div>
          <div className="h-invite-modal__information">
            <strong>Agency admins</strong>{' '}
            {`can edit an agency profile, manage agency admins/users/clients, and manage client's plans.`}
          </div>
        </>
      ),
    },
  };

  const modalOpts = {
    title: editTypeValues[editType].title,
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: true,
    footer: null,
    maskClosable: false,
    centered: true,
    closeIcon: <Close />,
    width: 560,
  };

  const handleFormSubmit = async (values: any) => {
    if (isLoading) {
      return;
    }

    try {
      if (user && agency) {
        setIsLoading(true);
        const data = {
          userId: user?.user_id,
          inviteeEmailsRoleIds: values.users.map((user) => {
            return { email: user.email, roleId: user.role };
          }),
          agencyId: agency?.agencyId,
        };

        const response = await dispatch(
          inviteUsers({
            token: user?.token,
            data: data,
          }),
        ).unwrap();

        setInvitedEmails(response.allInvitees);

        setIsLoading(false);
        closeModal();
        setInviteSuccessModalVisibility(true);
      }
    } catch (error) {
      handleModalClose();
    }
  };

  return (
    <Modal {...modalOpts} className="h-invite-modal c-form-modal">
      {isLoading && <FullPageLoader />}
      {editTypeValues[editType].info}
      <Formik
        initialValues={{
          users: [
            { email: selectedUser.email, role: selectedUser.roleId.toString() },
          ],
        }}
        validationSchema={inviteFormValidationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          values,
          handleSubmit,
          handleBlur,
          isValid,
          dirty,
          setFieldValue,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FieldArray name="users">
                {() => (
                  <div>
                    {values.users.map((user, index) => (
                      <div
                        className="h-invite-modal__dual-fields-wrapper"
                        key={index}
                      >
                        <div className="c-form-field">
                          <div className="c-form-field__label">
                            Email address
                          </div>
                          <div className="c-form-field__wrapper">
                            <Input
                              onChange={handleChange}
                              type="text"
                              name={`emails[${index}]`}
                              placeholder={`User ${index + 1}`}
                              value={values.users[index].email}
                              onBlur={handleBlur}
                              disabled
                            />
                            <div className="c-form-field__error">
                              {errors.users && errors.users[index]
                                ? // @ts-ignore
                                  errors.users[index].email
                                : undefined}
                            </div>
                          </div>
                        </div>

                        <div className="c-form-field">
                          <div className="c-form-field__label">Permissions</div>
                          <div className="c-form-field__wrapper">
                            <Select
                              options={userRoles}
                              value={values.users[index].role}
                              onChange={(v: any) => setFieldValue('state', v)}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </FieldArray>
              <div
                className="h-invite-modal__submit-btn-wrapper"
                style={{ paddingTop: 20 }}
              >
                <Button
                  type="primary"
                  className="h-invite-modal__submit-btn"
                  htmlType="submit"
                >
                  Re-send Invite
                </Button>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditInviteModal;
