import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  deleteEnterpriseAdmin,
  fetchEnterprise,
  fetchEnterprises,
} from './async_thunks';
import { Enterprise, EnterpriseState } from './types';

const initialState: EnterpriseState = {
  list: [],
  enterprise: undefined,
  enterpriseAdmins: [],
};

export const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState,
  reducers: {
    setEnterprise: (state, action: PayloadAction<Enterprise | undefined>) => {
      state.enterprise = action.payload;
    },
    setEnterpriseAdmins: (state, action) => {
      state.enterpriseAdmins = action.payload;
    },
    setEnterprises: (
      state,
      action: PayloadAction<Enterprise[] | undefined>,
    ) => {
      state.list = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchEnterprises.fulfilled, (state, action) => {
        if (action.payload) {
          state.list = action.payload;
        }
      })
      .addCase(fetchEnterprise.fulfilled, (state, action) => {
        state.enterprise = action.payload.enterpriseProfile;
        state.enterpriseAdmins = action.payload.enterpriseUsers;
      })
      .addCase(deleteEnterpriseAdmin.fulfilled, (state, action) => {
        state.enterprise = action.payload.enterpriseProfile;
        state.enterpriseAdmins = action.payload.enterpriseUsers;
      });
  },
});

export const { setEnterprises, setEnterprise, setEnterpriseAdmins } =
  enterpriseSlice.actions;

export default enterpriseSlice.reducer;
