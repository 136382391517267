import { Button, Modal } from 'antd';
import React from 'react';

import InviteSentIcon from '../../../foundation/assets/svgs/InviteSentIcon';

const DeleteAccountRequestModal = ({
  closeHandler,
}: {
  closeHandler?: () => void;
}) => {
  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <InviteSentIcon />
        </span>
        <span>Request Sent</span>
      </div>
    ),
    open: true,
    wrapClassName: '',
    closable: false,
    footer: null,
    centered: true,
    closeHandler: closeHandler,
    maskClosable: false,
    width: '400px',
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal">
      <div
        className="h-invite-success-modal__text"
        style={{ textAlign: 'center' }}
      >
        You&apos;ve requested to delete your account.
        <br />
        Kindly continue the process via your email.
      </div>
      <div
        className="h-invite-success-modal__btn-wrapper"
        style={{ justifyContent: 'center', marginTop: '30px' }}
      >
        <Button
          type="primary"
          className="h-invite-success-modal__btn"
          onClick={() => {
            if (closeHandler) {
              closeHandler();
            }
          }}
          style={{ width: '100px' }}
        >
          Got It
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAccountRequestModal;
