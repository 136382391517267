import React from 'react';

function Lock() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke="#667085"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.667"
        d="M5.833 9.167V5.833a4.167 4.167 0 018.334 0v3.334m-10 0h11.666c.92 0 1.667.746 1.667 1.666v5.834c0 .92-.746 1.666-1.667 1.666H4.167c-.92 0-1.667-.746-1.667-1.666v-5.834c0-.92.746-1.666 1.667-1.666z"
      ></path>
    </svg>
  );
}

export default Lock;
