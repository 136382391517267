import React from 'react';

function Upload() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="40"
      fill="none"
      viewBox="0 0 48 40"
    >
      <path
        fill="#019386"
        d="M29.964 8.956l-.649.376a.75.75 0 00.65.374v-.75zM4.288 24.237a.75.75 0 001.424-.474l-1.424.474zm37.041-.572a.75.75 0 101.342.67l-1.342-.67zm-10.683 6.213a.75.75 0 001.04-1.082l-1.04 1.082zM23 21.5l.52-.54a.75.75 0 00-1.04 0l.52.54zm-8.686 7.296a.75.75 0 101.039 1.082l-1.039-1.082zm9.436-5.235a.75.75 0 00-1.5 0h1.5zm-1.5 22a.75.75 0 001.5 0h-1.5zM17.913 2.75c4.87 0 9.125 2.646 11.402 6.582l1.298-.751C28.08 4.2 23.341 1.25 17.913 1.25v1.5zM4.75 15.913c0-6.568 5.918-13.163 13.163-13.163v-1.5C9.79 1.25 3.25 8.567 3.25 15.913h1.5zm.962 7.85c-.957-2.87-.962-5.756-.962-7.85h-1.5c0 2.08-.005 5.195 1.038 8.324l1.424-.474zM42.25 18c0 2.019-.01 3.845-.92 5.665l1.34.67c1.09-2.18 1.08-4.354 1.08-6.335h-1.5zM31.826 9.706c2.712 0 5.342.955 7.283 2.486 1.942 1.532 3.141 3.593 3.141 5.808h1.5c0-2.785-1.507-5.246-3.712-6.986-2.205-1.74-5.163-2.808-8.212-2.808v1.5zm-1.862 0h1.862v-1.5h-1.862v1.5zm1.721 19.09l-8.167-7.837-1.038 1.082 8.166 7.837 1.04-1.082zm-9.205-7.837l-8.167 7.837 1.039 1.082 8.166-7.837-1.038-1.082zm-.231 2.602v22h1.5v-22h-1.5z"
      ></path>
    </svg>
  );
}

export default Upload;
