import React from 'react';

import CheckCircle from '../../../foundation/assets/svgs/CheckCircle';

const EmailSuccessMessage = () => {
  return (
    <div className="l-reset-pass__success-message-wrapper ">
      <div>
        <CheckCircle />
      </div>
      <div className="l-reset-pass__success-message-title">Email Sent!</div>
      <div className="l-reset-pass__success-message">
        Please check your email for a link to reset your password.
      </div>
    </div>
  );
};

export default EmailSuccessMessage;
