import { createAsyncThunk } from '@reduxjs/toolkit';

import axios from '../../../foundation/config/axios';
import { decryptData, encryptData } from '../../../foundation/utils/api';
import getBasicAuthAPIOptions from '../../../foundation/utils/getBasicAuthAPIOptions';
import env_constants from '../../../internals/env/env_constants.json';
import { setFavoriteReports } from './slice';

export const fetchSharedReportData: any = createAsyncThunk(
  'aiReport/fetchShared',
  async (apiParams: { token: string; data: any; template: string }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .post(`${env_constants.PP_API_BASE_URL}/AiReport`, encrypted, {
        ...options,
      })
      .then((response) => {
        const decrypted = decryptData(response.data);

        result = {
          ...decrypted,
          template: 'investment-summary',
        };
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const fetchReportData: any = createAsyncThunk(
  'aiReport/fetch',
  async (apiParams: { token: string; data: any; template: string }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .post(
        `${env_constants.PP_API_BASE_URL}/AiReport/${apiParams.template}`,
        encrypted,
        {
          ...options,
        },
      )
      .then((response) => {
        const decrypted = decryptData(response.data);

        if (
          decrypted?.reportData &&
          !decrypted.reportData.descriptor &&
          apiParams.template !== 'key-indicators'
        ) {
          decrypted.reportData.descriptor = {};
        }

        if (apiParams.template === 'projects') {
          result = {
            ...decrypted,
            reportData: {
              totalInvestment: decrypted.reportData.totalInvestment,
              weightedInvestment: decrypted.reportData.weightedInvestment,
              weightedInvestmentPerCapita:
                decrypted.reportData.weightedInvestmentPerCapita,
              country: decrypted.reportData.country,
              countryInvestmentPerCapita:
                decrypted.reportData.countryInvestmentPerCapita,
              projects: decrypted.reportData.projects,
              totalProjects: decrypted.reportData.totalProjects,
              pageCount: decrypted.reportData.pageCount,
              allGraphs: [
                {
                  graphType: 'SuburbTotalWeightedInvesmentPerCapita',
                  graphs: decrypted.reportData.graphs,
                },
              ],
            },
            pageIndex: apiParams.data.pageIndex,
            template: apiParams.template,
          };
        } else {
          result = {
            ...decrypted,
            template: apiParams.template,
          };
        }
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const fetchGraphsData: any = createAsyncThunk(
  'aiReport/fetchGraphsData',
  async (apiParams: { token: string; data: any; template: string }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .post(`${env_constants.PP_API_BASE_URL}/AiReport/graphs`, encrypted, {
        ...options,
      })
      .then((response) => {
        result = decryptData(response.data);
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const fetchGraphData: any = createAsyncThunk(
  'aiReport/fetchGraphData',
  async (apiParams: {
    token: string;
    data: any;
    template: string;
    graphType: string;
  }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .post(`${env_constants.PP_API_BASE_URL}/AiReport/graph`, encrypted, {
        ...options,
      })
      .then((response) => {
        result = {
          ...decryptData(response.data),
          graphType: apiParams.graphType,
        };
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const updateReportHtml: any = createAsyncThunk(
  'aiReport/updateReportHtml',
  async (apiParams: { token: string; data: any; template: string }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .put(`${env_constants.PP_API_BASE_URL}/AiReport/edit`, encrypted, {
        ...options,
      })
      .then((response) => {
        result = {
          ...decryptData(response.data),
        };
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const favoriteSuburbReport: any = createAsyncThunk(
  'aiReport/favorite',
  async (
    apiParams: { token: string; data: any; template: string },
    thunkAPI,
  ) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .post(`${env_constants.PP_API_BASE_URL}/AiReport/favorite`, encrypted, {
        ...options,
      })
      .then((response) => {
        result = decryptData(response.data);

        thunkAPI.dispatch(
          setFavoriteReports(
            result.reportSuburbs?.length ? result.reportSuburbs : [],
          ),
        );
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);

export const shareSuburbReport: any = createAsyncThunk(
  'aiReport/share',
  async (apiParams: { token: string; data: any; template: string }) => {
    let result;

    const options = {
      ...getBasicAuthAPIOptions(apiParams.token),
    };

    const encrypted = encryptData(apiParams.data);

    await axios
      .post(`${env_constants.PP_API_BASE_URL}/AiReport/share`, encrypted, {
        ...options,
      })
      .then((response) => {
        result = decryptData(response.data);
      })
      .catch((error) => {
        return Promise.reject(decryptData(error.response.data));
      });

    return result;
  },
);
