import {
  ExclamationCircleOutlined,
  PlusOutlined,
  PlusSquareOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Input, Modal, Upload } from 'antd';
import { compressAccurately } from 'image-conversion';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import FieldError from '../../../foundation/components/field_error/FieldError';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { encryptData } from '../../../foundation/utils/api';
import { addItemToStorage } from '../../../foundation/utils/storageHandler';
import envConstant from '../../../internals/env/env_constants.json';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import {
  ClientStateType,
  PlanDuplicateSteps,
} from '../../plan/plan_duplicate/types';
import { createClient } from '../redux/async_thunks';

type FieldErrorType = {
  image?: string;
  name?: string;
};

type Props = {
  handleViewChange: (v: 'search' | 'create') => () => any;
  isPlanDuplicateView?: boolean;
  setClientForPlanDuplicate?: React.Dispatch<
    React.SetStateAction<ClientStateType>
  >;
  setPlanDuplicateStep?: React.Dispatch<
    React.SetStateAction<PlanDuplicateSteps>
  >;
};

const { confirm } = Modal;

const CreateClient = ({
  handleViewChange,
  isPlanDuplicateView,
  setClientForPlanDuplicate,
  setPlanDuplicateStep,
}: Props) => {
  const dispatch = useAppDispatch();

  const history = useHistory();

  const user = useSelector(selectUser);

  const [isLoading, setIsLoading] = useState(false);

  const [fileList, setFileList] = useState<any>([]);

  const [clientName, setClientName] = useState<string | undefined>(undefined);

  const [imageUrl, setImageURL] = useState<string | undefined>(undefined);

  const [error, setError] = useState<FieldErrorType | undefined>(undefined);

  function getBase64(img: any, callback: any) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  const beforeUpload = async (file: any) => {
    const minSizeKB = envConstant.MIN_PICTURE_FILESIZE_UPLOAD_IN_KB;
    const maxSizeKB = envConstant.MAX_PICTURE_FILESIZE_UPLOAD_IN_KB;

    let maxSizeMessage: string;
    let isCompressionFailed = false;

    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';

    const fileSizeKB = file.size / 1024;
    const isFileSizeAllowed =
      fileSizeKB >= minSizeKB && fileSizeKB <= maxSizeKB;

    if (!isJpgOrPng) {
      setError((v) => ({
        ...v,
        image: `You can only upload a JPG/PNG file!`,
      }));
    }

    if (fileSizeKB < minSizeKB) {
      setError((v) => ({
        ...v,
        image: `Image should be at least ${minSizeKB}KB!`,
      }));
    }

    if (maxSizeKB < 1024) {
      maxSizeMessage = `${maxSizeKB}KB`;
    } else {
      const maxSizeMB = maxSizeKB / 1024;

      maxSizeMessage = `${maxSizeMB}MB`;
    }

    if (fileSizeKB > maxSizeKB) {
      setError((v) => ({
        ...v,
        image: `Image should be no more than ${maxSizeMessage}!`,
      }));
    }

    if (isJpgOrPng && isFileSizeAllowed) {
      try {
        const compressedBlob = await compressAccurately(file, 900); // Down to 900KB
        const compressedFile = new File([compressedBlob], file.name, {
          type: file.type,
        });

        file = compressedFile;

        setFileList([...fileList, file]);
        setError((v) => ({
          ...v,
          image: undefined,
        }));
      } catch (error) {
        console.error('Image compression error:', error);
        setError((v) => ({
          ...v,
          image: `Something went wrong. Please try again or try a different picture.`,
        }));

        isCompressionFailed = true;
      }
    }

    return (
      (isJpgOrPng && isFileSizeAllowed && !isCompressionFailed) ||
      Upload.LIST_IGNORE
    );
  };

  const showProceedConfirm = (clientName?: string, existingClients?: any) => {
    confirm({
      title: 'Client Name Exists',
      icon: <ExclamationCircleOutlined />,
      content: (
        <div>
          <p>
            You&lsquo;re about to create a new client,{' '}
            <strong>{clientName}</strong>. However, the name already exists:
          </p>
          <ul className="client-view__existing-clients">
            {existingClients.map((c: any, index: number) => {
              return (
                <li key={index} className="client-view__existing-clients-item">
                  <Avatar size={40} src={c.clientPhoto} />
                  <span>{c.clientName}</span>
                </li>
              );
            })}
          </ul>
          <p>Should we proceed with the action anyway?</p>
        </div>
      ),
      okText: 'Proceed',
      cancelText: 'Cancel',
      autoFocusButton: 'cancel',
      keyboard: false,
      onOk: () => {
        handleCreateClient(true);
      },
    });
  };

  const handleChange = (info: any) => {
    // Get this url from response in real world.
    getBase64(info.file.originFileObj, (url: string) => {
      setImageURL(url);
    });
  };

  /**
   * Fetches the clients
   */
  const handleCreateClient = async (proceed?: boolean) => {
    if (isLoading) {
      return;
    }
    try {
      setIsLoading(true);
      if (user?.token && clientName) {
        if (isPlanDuplicateView && setClientForPlanDuplicate) {
          const data: ClientStateType = {
            clientPhoto: fileList[0],
            clientName,
            isNew: true,
          };

          setClientForPlanDuplicate(data);

          if (setPlanDuplicateStep) {
            setPlanDuplicateStep('plan');
          }

          setIsLoading(false);
        } else {
          const formData = new FormData();

          formData.append('f', fileList[0]);

          formData.append(
            'e',
            encryptData({
              ClientName: clientName,
              UserId: user.user_id,
              continue: proceed,
            }),
          );

          const clientData = await dispatch(
            createClient({ token: user.token, data: formData }),
            // @ts-ignore
          ).unwrap();

          addItemToStorage('client', JSON.stringify(clientData));

          setIsLoading(false);
          history.push('/plan');
        }
      }
    } catch (err) {
      if (err.responseCode === 409 && err.existingClients?.length) {
        showProceedConfirm(clientName, err.existingClients);
      }

      setIsLoading(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientName(e.target.value);
  };

  const handleInputBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (!value || value.trim().length < 3) {
      setError((v) => ({
        ...v,
        name: `Name should contain at least 3 characters!`,
      }));
    } else {
      setError((v) => ({
        ...v,
        name: undefined,
      }));
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const isBtnDisabled =
    !clientName ||
    (error && error.name !== undefined) ||
    clientName?.length < 3;

  return (
    <>
      {isLoading && <FullPageLoader />}
      <Card className="client-view__create-card">
        <div className="client-view__create-header">
          {isPlanDuplicateView ? 'New Client Details' : 'Create Client'}
        </div>
        <div className="client-view__field-wrapper">
          <div className="client-view__create-field-label">Client Name:</div>
          <Input
            placeholder="Enter client name"
            className="client-view__create-field"
            value={clientName}
            onChange={handleInputChange}
            onBlur={handleInputBlur}
          />
          {error && error.name && <FieldError message={error.name} />}
        </div>
        <div className="client-view__field-wrapper">
          <div className="client-view__create-field-label">Image:</div>
          <Upload
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            //   action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={handleChange}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: '100%' }} />
            ) : (
              uploadButton
            )}
          </Upload>
          {error && error.image && <FieldError message={error.image} />}
        </div>
        <Button
          type="primary"
          className="client-view__create-client-btn"
          icon={!isPlanDuplicateView && <PlusSquareOutlined />}
          disabled={isBtnDisabled}
          onClick={() => {
            handleCreateClient();
          }}
        >
          {isPlanDuplicateView ? 'Next' : 'Create Client'}
        </Button>
        <div className="client-view__link-view">
          <a onClick={handleViewChange('search')}>or Search a client</a>
        </div>
      </Card>
    </>
  );
};

export default CreateClient;
