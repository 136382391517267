import { Button, Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import WarningIcon from '../../../../foundation/assets/svgs/WarningIcon';
import FullPageLoader from '../../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../../store/hooks';
import { selectUser } from '../../../authentication/redux/selectors';
import { deleteAgency } from '../../redux/async_thunks';
import { selectAgency } from '../../redux/selectors';

type DeleteAgencyModalProps = {
  closeModal: () => void;
  fetchAgencyResults: (searchValue?: string) => Promise<void>;
};
const DeleteAgencyModal = ({
  closeModal,
  fetchAgencyResults,
}: DeleteAgencyModalProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector(selectUser);

  const agency = useSelector(selectAgency);

  const handleModalClose = () => {
    if (isLoading) {
      return;
    }
    closeModal();
  };

  const modalOpts = {
    title: (
      <div className="h-invite-success-modal__title-wrapper">
        <span className="h-invite-success-modal__title-icon-wrapper">
          <WarningIcon />
        </span>
        <span>Archive Agency</span>
      </div>
    ),
    open: true,
    onCancel: () => {
      handleModalClose();
    },
    wrapClassName: '',
    closable: false,
    footer: null,
    maskClosable: false,
    centered: true,
  };

  const handleDeleteBtnClick = async () => {
    if (isLoading) {
      return;
    }

    try {
      if (agency && user) {
        setIsLoading(true);

        const data = {
          userId: user.user_id,
          agencyId: agency.agencyId,
        };

        await dispatch(
          deleteAgency({
            data,
            token: user.token,
          }),
        ).unwrap();

        setIsLoading(false);

        notification.success({
          message: 'Success!',
          description: 'Agency deleted successfully',
        });
        handleModalClose();
        fetchAgencyResults('');
      }
    } catch (error) {
      setIsLoading(false);
      handleModalClose();
    }
  };

  return (
    <Modal {...modalOpts} className="h-invite-success-modal" width={570}>
      {isLoading && <FullPageLoader />}
      <div className="h-invite-success-modal__text">
        Are you sure you want to archive this agency? <br />
      </div>
      <div
        className="h-invite-success-modal__emails-wrapper"
        style={{ fontWeight: 600 }}
      >
        {agency?.agencyName}
      </div>
      <div className="h-invite-success-modal__btn-wrapper">
        <Button
          className="h-invite-success-modal__btn"
          onClick={handleModalClose}
        >
          Close
        </Button>
        <Button
          type="primary"
          danger
          className="h-invite-success-modal__delete-btn"
          onClick={handleDeleteBtnClick}
        >
          Archive Agency
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteAgencyModal;
