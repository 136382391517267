import { Modal, notification } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import Close from '../../../foundation/assets/svgs/Close';
import FullPageLoader from '../../../foundation/components/full_page_loader/FullPageLoader.index';
import { useAppDispatch } from '../../../store/hooks';
import { selectUser } from '../../authentication/redux/selectors';
import Client from '../../client/Client';
import { selectClient } from '../../client/redux/selectors';
import { setClientPlanIds } from '../../client/redux/slice';
import Plan from '../Plan';
import { duplicatePlan, DuplicatePlanRequestBody } from '../redux/async_thunks';
import { selectPlan } from '../redux/selectors';
import { ClientStateType, PlanDuplicateSteps } from './types';

type PlanDuplicateProps = {
  closeModal: () => any;
};

const PlanDuplicate = ({ closeModal }: PlanDuplicateProps) => {
  const dispatch = useAppDispatch();

  const [isPlanDuplicating, setIsPlanDuplicating] = useState(false);
  const [selectedClient, setSelectedClient] =
    useState<ClientStateType>(undefined);
  const [planDuplicateStep, setPlanDuplicateStep] =
    useState<PlanDuplicateSteps>('client');

  const client = useSelector(selectClient);

  const plan = useSelector(selectPlan);

  const user = useSelector(selectUser);

  const modalOpts = {
    title: 'Select or create a new client for the duplicate plan',
    open: true,
    onCancel: () => {
      closeModal();
    },
    wrapClassName: 'l-plan-dup',
    closable: true,
    footer: null,
    maskClosable: false,
    // centered: true,
    className: 'l-plan-dup__modal',
    centered: true,
    closeIcon: <Close />,
  };

  const handlePlanDuplicate = async (planName: string) => {
    if (isPlanDuplicating) {
      return;
    }
    try {
      if (client && plan && user && selectedClient) {
        setIsPlanDuplicating(true);

        const reqBody: DuplicatePlanRequestBody = {
          sourceClientId: client?.clientId,
          userId: user?.user_id,
          plans: [
            {
              planId: plan.planId,
              destinationPlanName: planName,
            },
          ],
          movePlan: false,
        };

        if (selectedClient.isNew) {
          reqBody.newDestinationClientName = selectedClient.clientName;
          reqBody.createNewDestinationClient = true;
        } else {
          reqBody.createNewDestinationClient = false;
          if (selectedClient.clientId) {
            reqBody.destinationClientId = selectedClient.clientId;
          }
        }

        const resp = await dispatch(
          duplicatePlan({
            token: user.token,
            data: reqBody,
            picture: selectedClient.clientPhoto,
          }),
          // @ts-ignore
        ).unwrap();

        notification.success({
          message: 'Success!',
          description: 'Plan duplicated successfully',
        });

        setIsPlanDuplicating(false);

        /**
         * If the client is not new and the selected client Id is same as the current client.
         * Which means user selected the current plan so we need to update the planIds.
         */
        if (
          !selectedClient.isNew &&
          client.clientId === selectedClient.clientId
        ) {
          dispatch(setClientPlanIds(resp.planIds));
        }

        closeModal();
      }
    } catch (error) {
      console.log(error);
      setIsPlanDuplicating(false);
    }
  };
  return (
    <Modal {...modalOpts} width={900}>
      {isPlanDuplicating && <FullPageLoader />}
      {planDuplicateStep === 'client' && (
        <Client
          setClientForPlanDuplicate={setSelectedClient}
          isPlanDuplicateView={true}
          planDuplicateStep={planDuplicateStep}
          setPlanDuplicateStep={setPlanDuplicateStep}
        />
      )}

      {planDuplicateStep === 'plan' && (
        <Plan
          isPlanDuplicateView={true}
          handlePlanDuplicate={handlePlanDuplicate}
        />
      )}
    </Modal>
  );
};

export default PlanDuplicate;
