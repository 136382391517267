/**
 * This includes all the private routes which are child of dashboard/
 */
import {
  ApartmentOutlined,
  ControlOutlined,
  PieChartOutlined,
  TableOutlined,
} from '@ant-design/icons';
import { useEffect, useState } from 'react';

import Agency from '../../features/agency/Agency';
import AgencyDetail from '../../features/agency/agency_detail/AgencyDetail';
import AiReport from '../../features/ai_report/AiReport';
import ReportAccess from '../../features/ai_report/report_access/ReportAccess';
import Client from '../../features/client/Client';
import Dashboard from '../../features/dashboard/Dashboard';
import Enterprise from '../../features/enterprise/Enterprise';
import EnterpriseDetail from '../../features/enterprise/enterprise_detail/EnterpriseDetail';
import Focus from '../../features/focus/Focus';
import MonthlyModeling from '../../features/monthly_modeling/MonthlyModeling';
import OffsetAccount from '../../features/offset_account/OffsetAccount';
import { OffsetAccountSummary } from '../../features/offset_account/redux/types';
import Plan from '../../features/plan/Plan';
import AnnualFigures from '../../features/property/annual_figures/AnnualFigures';
import CashRequiredEstimates from '../../features/property/cash_required_estimates/CashRequiredEstimates';
import Info from '../../features/property/info/Info';
import Property from '../../features/property/Property';
import {
  PlanRecords,
  PropertyListItem,
} from '../../features/property/redux/types';
import PropertyStrategy from '../../features/property_strategy/PropertyStrategy';
import PropertyType from '../../features/property_type/PropertyType';
import PurchaseScenario from '../../features/purchase_scenario/PurchaseScenario';
import SettlementPeriod from '../../features/settlement_period/SettlementPeriod';
import StampDutyLMI from '../../features/stamp_duty_lmi/StampDutyLMI';
import ClientRiskProfile from '../../features/standard_tables/client_risk_profile/ClientRiskProfile';
import InterestRateProjection from '../../features/standard_tables/inrerest_rate_projection/InterestRateProjection';
import SuburbScoring from '../../features/suburb_scoring/SuburbScoring';
import ContactUs from '../../features/support/contact_us/ContactUs';
import Inputs from '../../features/user_inputs/UserInput';
import { useRole } from '../cutom_hooks/useRole';

const headerTitle = `Portfolio Planner`;

export type RouteType = {
  path: string;
  name: string;
  component?: () => JSX.Element;
  icon?: any;
  routes?: RouteType[];
  displayHeader?: boolean;
  exact?: boolean;
  strict?: boolean;
  isSubMenu?: boolean;
  hideInMenu?: boolean;
  headerTitle?: string;
  headerSubTitle?: string;
  displaySidebar?: boolean;
  isPublic?: boolean;
  completePath: string;
  data?: any;
};

export const useRoutes = (
  properties: PropertyListItem[] | undefined,
  isClientView: boolean,
  offsetAccounts: OffsetAccountSummary[] | undefined,
  planRecords: PlanRecords[] | undefined,
) => {
  const [routes, setRoutes] = useState<RouteType[]>([]);

  const [
    ,
    isEnterpriseAdminView,
    isSuperAdminView,
    isAgencyAdminView,
    ,
    isAgencyLeadView,
  ] = useRole();

  useEffect(() => {
    const propertiesRoutes: RouteType[] = [];

    if (!isSuperAdminView && !isEnterpriseAdminView && !isClientView) {
      propertiesRoutes.push({
        path: `/properties/new`,
        name: 'Add New Property',
        headerSubTitle: 'Add New Property',
        component: Property,
        exact: true,
        strict: true,
        displayHeader: true,
        headerTitle,
        displaySidebar: true,
        completePath: `/properties/new`,
        hideInMenu:
          properties && properties[properties.length - 1]?.isActive === false,
      });
      propertiesRoutes.push({
        path: `/oa-transactions/new`, // Offset Account Transactions.
        name: 'Add New Offset Account Transactions',
        headerSubTitle: 'Add New Offset Account Transactions',
        component: OffsetAccount,
        exact: true,
        strict: true,
        displayHeader: false,
        headerTitle,
        displaySidebar: true,
        completePath: `/oa-transactions/new`,
        hideInMenu:
          (properties && properties[0]?.isActive === false) ||
          (offsetAccounts &&
            offsetAccounts[offsetAccounts.length - 1]?.isActive === false),
      });
    }

    if (!isSuperAdminView && !isEnterpriseAdminView && planRecords) {
      let i = 0;
      for (const planRecord of planRecords) {
        if (planRecord.type === 'Property') {
          if (properties) {
            for (const item of properties) {
              if (item.propertyId === planRecord.recordId) {
                const propertyRoutes: any = [
                  {
                    path: `/properties/:id/info`,
                    name: 'Info',
                    headerSubTitle: '',
                    component: Info,
                    exact: true,
                    strict: true,
                    displayHeader: false,
                    headerTitle: item.headerTitle,
                    displaySidebar: true,
                    completePath: `/properties/${item.propertyId}/info`,
                    data: {
                      isLastProperty:
                        properties.length === 1
                          ? false
                          : i + 1 === properties.length,
                      isFirstProperty: i === 0,
                      numberOfProperties: properties.length,
                      propertyIndex: i,
                      propertyId: item.propertyId,
                    },
                  },
                ];

                if (item.purchaseType !== 'Existing') {
                  propertyRoutes.push(
                    {
                      path: `/properties/:id/cash-req-estimates`,
                      name: `Cash Required Estimates`,
                      headerSubTitle: 'Standard Properties',
                      component: CashRequiredEstimates,
                      exact: true,
                      strict: true,
                      displayHeader: false,
                      headerTitle: item.headerTitle,
                      displaySidebar: true,
                      completePath: `/properties/${item.propertyId}/cash-req-estimates`,
                      data: {
                        propertyId: item.propertyId,
                      },
                    },
                    {
                      path: `/properties/:id/purchase-scenario`,
                      name: `Purchase Scenario`,
                      headerSubTitle: 'Standard Properties',
                      component: PurchaseScenario,
                      exact: true,
                      strict: true,
                      displayHeader: false,
                      headerTitle: item.headerTitle,
                      displaySidebar: true,
                      hideInMenu: i === 0,
                      completePath: `/properties/${item.propertyId}/purchase-scenario`,
                      data: {
                        propertyId: item.propertyId,
                      },
                    },
                  );
                }

                propertyRoutes.push(
                  {
                    path: `/properties/:id/annual-figures`,
                    name: `Annual Figures`,
                    headerSubTitle: 'Standard Properties',
                    component: AnnualFigures,
                    exact: true,
                    strict: true,
                    displayHeader: false,
                    headerTitle: item.headerTitle,
                    displaySidebar: true,
                    completePath: `/properties/${item.propertyId}/annual-figures`,
                    data: {
                      propertyId: item.propertyId,
                    },
                  },
                  {
                    path: `/properties/:id/monthly-modeling`,
                    name: `Financial Modeling`,
                    headerSubTitle: 'Standard Properties',
                    component: MonthlyModeling,
                    exact: true,
                    strict: true,
                    displayHeader: false,
                    headerTitle: item.headerTitle,
                    displaySidebar: true,
                    completePath: `/properties/${item.propertyId}/monthly-modeling`,
                    data: {
                      propertyId: item.propertyId,
                    },
                  },
                );

                propertiesRoutes.push({
                  path: `/properties/:id`,
                  name: item.propertyName,
                  headerSubTitle: 'Standard Properties',
                  exact: true,
                  strict: true,
                  displayHeader: true,
                  headerTitle,
                  displaySidebar: true,
                  isSubMenu: true,
                  routes: propertyRoutes,
                  completePath: `/properties/${item.propertyId}`,
                  data: {
                    isSold: item.isSold,
                  },
                });

                break;
              }
            }
            i++;
          }
        }

        if (!isClientView && planRecord.type === 'OffsetAccount') {
          if (offsetAccounts) {
            for (const item of offsetAccounts) {
              if (item.offsetAccountId === planRecord.recordId) {
                propertiesRoutes.push({
                  path: `/oa-transactions/:id`,
                  name: `Offset Account ${item.transactionDate}`,
                  headerSubTitle: '',
                  component: OffsetAccount,
                  exact: true,
                  strict: true,
                  displayHeader: false,
                  headerTitle: `Offset Account ${item.transactionDate}`,
                  displaySidebar: true,
                  completePath: `/oa-transactions/${item.offsetAccountId}`,
                  data: {
                    offsetAccountId: item.offsetAccountId,
                  },
                });
                break;
              }
            }
          }
        }
      }
    }

    let initialRoutes: RouteType[] = [];

    if (!isSuperAdminView && !isEnterpriseAdminView && !isAgencyLeadView) {
      initialRoutes = [
        {
          path: '/',
          name: 'Client',
          // @ts-ignore
          component: isClientView ? Plan : Client,
          icon: PieChartOutlined,
          exact: true,
          strict: true,
          displayHeader: false,
          hideInMenu: true,
          displaySidebar: false,
          completePath: '/',
        },
        {
          path: '/plan',
          name: 'Plan',
          // @ts-ignore
          component: Plan,
          icon: PieChartOutlined,
          exact: true,
          strict: true,
          displayHeader: false,
          hideInMenu: true,
          displaySidebar: false,
          completePath: '/plan',
        },
        {
          path: '/dashboard',
          name: 'Dashboard',
          component: Dashboard,
          icon: PieChartOutlined,
          exact: true,
          strict: true,
          displayHeader: false,
          displaySidebar: true,
          // hideInMenu: true,
          completePath: '/dashboard',
        },
        {
          name: 'Your Inputs',
          icon: ControlOutlined,
          isSubMenu: false,
          path: '/inputs',
          exact: true,
          strict: true,
          displayHeader: false,
          displaySidebar: true,
          component: Inputs,
          completePath: '/inputs',
        },
        {
          name: 'Portfolio Planner',
          icon: ApartmentOutlined,
          isSubMenu: true,
          path: '/properties',
          completePath: '/properties',
          displaySidebar: true,
          routes: propertiesRoutes,
          hideInMenu: false,
        },
        {
          path: '/suburb-scoring-internal/report/:suburbCodeParam',
          name: 'AI Report Internal',
          component: AiReport,
          icon: null,
          exact: true,
          strict: true,
          displayHeader: false,
          displaySidebar: false,
          hideInMenu: true,
          completePath: '/suburb-scoring-internal/report/:suburbCodeParam',
        },
      ];
    }

    if (
      !isSuperAdminView &&
      !isEnterpriseAdminView &&
      !isClientView &&
      !isAgencyLeadView
    ) {
      initialRoutes.push({
        name: 'Standard Tables',
        icon: TableOutlined,
        isSubMenu: true,
        path: 'st',
        displaySidebar: true,
        completePath: 'st',
        routes: [
          {
            path: '/property-type',
            name: 'Property Type',
            headerSubTitle: 'Property Types',
            component: PropertyType,
            exact: true,
            strict: true,
            displayHeader: true,
            headerTitle: `Standard Tables`,
            displaySidebar: true,
            completePath: '/property-type',
          },
          {
            path: '/cost-by-state-lmi',
            completePath: '/cost-by-state-lmi',
            name: 'Costs by State & LMI',
            headerSubTitle: 'Lender Mortgage Insurance',
            component: StampDutyLMI,
            exact: true,
            strict: true,
            displayHeader: true,
            headerTitle: `Standard Tables`,
            displaySidebar: true,
          },
          {
            path: '/settlement_period',
            completePath: '/settlement_period',
            name: 'Settlement Period',
            headerSubTitle: 'Settlement Period',
            component: SettlementPeriod,
            exact: true,
            strict: true,
            displayHeader: true,
            headerTitle: `Standard Tables`,
            displaySidebar: true,
          },
          {
            path: '/focus',
            completePath: '/focus',
            name: 'Focus',
            headerSubTitle: 'Focus',
            component: Focus,
            exact: true,
            strict: true,
            displayHeader: true,
            headerTitle: `Standard Tables`,
            displaySidebar: true,
          },
          {
            path: '/risk-profile',
            completePath: '/risk-profile',
            name: 'Client Risk Profile',
            headerSubTitle: 'Client Risk Profile',
            component: ClientRiskProfile,
            exact: true,
            strict: true,
            displayHeader: true,
            headerTitle: `Standard Tables`,
            displaySidebar: true,
          },
          {
            path: '/interest-rate',
            completePath: '/interest-rate',
            name: 'Interest Rate Projections',
            headerSubTitle: 'Interest Rate Projections',
            component: InterestRateProjection,
            exact: true,
            strict: true,
            displayHeader: true,
            headerTitle: `Standard Tables`,
            displaySidebar: true,
          },
          {
            path: '/suburb-scoring-internal',
            name: 'Suburb Scoring Internal',
            component: SuburbScoring,
            icon: null,
            exact: true,
            strict: true,
            displayHeader: false,
            displaySidebar: false,
            hideInMenu: true,
            completePath: '/suburb-scoring-internal',
          },
        ],
        hideInMenu: false,
      });
    }

    if (isClientView) {
      initialRoutes.push({
        path: '/suburb-scoring/shared-report/:reportCodeParam',
        name: 'Client Access',
        component: ReportAccess,
        icon: null,
        exact: true,
        strict: true,
        displayHeader: false,
        displaySidebar: false,
        hideInMenu: true,
        completePath: '/suburb-scoring/shared-report/:reportCodeParam',
      });
    }

    if (isEnterpriseAdminView) {
      initialRoutes = [
        {
          path: '/',
          name: 'Agency',
          // @ts-ignore
          component: Agency,
          exact: true,
          strict: true,
          displayHeader: true,
          hideInMenu: true,
          displaySidebar: false,
          completePath: '/',
        },
        {
          path: '/enterprise-settings',
          name: 'Enterprise Detail',
          // @ts-ignore
          component: EnterpriseDetail,
          exact: true,
          strict: true,
          displayHeader: true,
          hideInMenu: true,
          displaySidebar: false,
          completePath: '/enterprise-settings',
        },
      ];
    }

    if (isSuperAdminView) {
      initialRoutes = [
        {
          path: '/',
          name: 'Enterprise',
          component: Enterprise,
          exact: true,
          strict: true,
          displayHeader: true,
          hideInMenu: true,
          displaySidebar: false,
          completePath: '/',
        },
      ];
    }

    if (isEnterpriseAdminView || isAgencyAdminView) {
      initialRoutes.push({
        path: '/agency/:id',
        name: 'Agency Detail',
        // @ts-ignore
        component: AgencyDetail,
        exact: true,
        strict: true,
        displayHeader: true,
        hideInMenu: true,
        displaySidebar: false,
        completePath: '/agency/:id',
      });
    }

    if (isAgencyLeadView) {
      initialRoutes = [
        {
          path: '/',
          name: 'Suburb Scoring',
          component: SuburbScoring,
          icon: null,
          exact: true,
          strict: true,
          displayHeader: false,
          displaySidebar: false,
          hideInMenu: true,
          completePath: '/',
        },
        {
          path: '/suburb-scoring',
          name: 'Suburb Scoring',
          component: SuburbScoring,
          icon: null,
          exact: true,
          strict: true,
          displayHeader: false,
          displaySidebar: false,
          hideInMenu: true,
          completePath: '/suburb-scoring',
        },
        {
          path: '/suburb-scoring/report/:suburbCodeParam',
          name: 'AI Report',
          component: AiReport,
          icon: null,
          exact: true,
          strict: true,
          displayHeader: false,
          displaySidebar: false,
          hideInMenu: true,
          completePath: '/suburb-scoring/report/:suburbCodeParam',
        },
        {
          path: '/free-pgp',
          name: 'Free Portfolio Growth Plan',
          component: PropertyStrategy,
          icon: null,
          exact: true,
          strict: true,
          displayHeader: false,
          displaySidebar: false,
          hideInMenu: true,
          completePath: '/free-pgp',
        },
      ];
    }

    initialRoutes.push({
      path: '/contact-us',
      name: 'Contact Us',
      // @ts-ignore
      component: ContactUs,
      icon: null,
      exact: true,
      strict: true,
      displayHeader: false,
      hideInMenu: true,
      displaySidebar: false,
      completePath: '/contact-us',
    });

    setRoutes(initialRoutes);
  }, [
    properties,
    isClientView,
    offsetAccounts,
    planRecords,
    isEnterpriseAdminView,
  ]);

  return routes;
};
