import * as Yup from 'yup';

const requiredMessage = 'This field is required';

export const passwordSchema = (
  incorrectMessage = 'Password must contain 8 or more characters with at least one of each: uppercase, lowercase, number and special',
  isLeadSignup?: boolean,
  isStrongPass?: boolean,
) => {
  if (isLeadSignup) {
    return Yup.string().nullable();
  } else if (isStrongPass) {
    return Yup.string()
      .required(requiredMessage)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%&*()\-_=+<>?])(?=.{8,})/,
        'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, one digit, and one special character (!@#$%&*()-_=+<>?)',
      )
      .nullable();
  } else {
    return Yup.string()
      .required(requiredMessage)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.{8,})/,
        'Password must be at least 8 characters long and include at least one uppercase letter, one lowercase letter, and one digit',
      )
      .nullable();
  }
};
