import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  selectRoleOptions,
  selectUser,
} from '../../features/authentication/redux/selectors';

export const useRole = () => {
  const ROLE_IDS = useSelector(selectRoleOptions);
  const user = useSelector(selectUser);

  const [role, setRole] = useState<boolean[]>([]);

  const checkRole = (key: string) => {
    if (ROLE_IDS) {
      return ROLE_IDS[key] === user?.role_id;
    }
    return false;
  };

  useEffect(() => {
    if (user && ROLE_IDS) {
      const isSuperAdminView = checkRole('superAdmin');
      const isEnterpriseAdminView = checkRole('enterpriseAdmin');
      const isClientView = checkRole('client');
      const isAgencyAdminView = checkRole('agencyAdmin');
      const isAgencyUserView = checkRole('agencyUser');
      const isAgencyLeadView = checkRole('agencyLead');

      setRole([
        isClientView,
        isEnterpriseAdminView,
        isSuperAdminView,
        isAgencyAdminView,
        isAgencyUserView,
        isAgencyLeadView,
      ]);
    }
  }, [user, ROLE_IDS]);

  return role;
};

export default useRole;
