import { RootState } from '../../../store';

/*
 * The function below is called a selector and allows us to select a value from
 * the state. Selectors can also be defined inline where they're used instead of
 * in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
 */
export const selectOAuthInfo = (state: RootState) => state.auth.oauth_info;

export const selectUser = (state: RootState) => state.auth.user;

export const selectUserLocationInfo = (state: RootState) =>
  state.auth.location_info;

export const selectRoleOptions = (state: RootState) => state.auth?.roles;

export const selectAuthStrongPassword = (state: RootState) =>
  state.auth?.strongPassword;

export const selectMarketingCampaigns = (state: RootState) =>
  state.auth?.marketingCampaigns;
