import 'mapbox-gl/dist/mapbox-gl.css';

import {
  ArrowLeftOutlined,
  HomeFilled,
  ShareAltOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { Button, notification, Radio, RadioChangeEvent } from 'antd';
import { Buffer } from 'buffer';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import useRole from '../../foundation/cutom_hooks/useRole';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import { selectUser } from '../authentication/redux/selectors';
import { setClients } from '../client/redux/slice';
import StickyFooter from '../property_strategy/agency_lead_access/sticky_footer/StickyFooter';
import { selectResults } from '../suburb_scoring/redux/selectors';
import ShareSuburbReportModal from './modals/ShareSuburbReportModal';
import ShareSuburbReportSuccessModal from './modals/ShareSuburbReportSuccessModal';
import { favoriteSuburbReport, fetchReportData } from './redux/async_thunks';
import {
  selectDisclaimerModalActive,
  selectIsEditedVersion,
  selectIsFavorite,
  selectIsReportLoading,
  selectIsReportToggleEnabled,
  selectReportDataByCurrentTemplate,
  selectSuburb,
  selectTemplate,
} from './redux/selectors';
import {
  resetReport,
  setIsEditedVersion,
  setIsFavorite,
  setIsGoingBack,
  setIsReportLoading,
  setIsReportToggleEnabled,
} from './redux/slice';
import DisclaimerModal from './static_content/disclaimer_modal/DisclaimerModal';
import SuburbDetails from './suburb_details/SuburbDetails';
import SuburbMap from './suburb_map/SuburbMap';

/* Take note that this component & its sub-components are utilized by:
 * - Registered accounts with specific roles
 * - Users who have verified access to the report link
 *
 * Refer to the getReportPayload() function for the user required params
 */
const AiReport = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const results = useSelector(selectResults);

  const { suburbCodeParam }: any = useParams();

  const [
    isClientView,
    ,
    ,
    isAgencyAdminView,
    isAgencyUserView,
    isAgencyLeadView,
  ] = useRole();

  const { isDesktopViewport } = useViewport();

  const suburb = useSelector(selectSuburb);
  const template = useSelector(selectTemplate);
  const user = useSelector(selectUser);
  const isReportLoading = useSelector(selectIsReportLoading);
  const currentReportData = useSelector(selectReportDataByCurrentTemplate);
  const isFavorite = useSelector(selectIsFavorite);
  const isReportToggleEnabled = useSelector(selectIsReportToggleEnabled);
  const isEditedVersion = useSelector(selectIsEditedVersion);
  const isDisclaimerModalActive = useSelector(selectDisclaimerModalActive);

  const defaultLoadingMessage = 'Please wait...';

  const [loadingMessage, setLoadingMessage] = useState(defaultLoadingMessage);
  const [isFavoriteLoading, setIsFavoriteLoading] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [isShareSuccessModalVisible, setIsShareSuccessModalVisible] =
    useState(false);

  const [isClientRecipient, setIsClientRecipient] = useState(true);
  const [email, setEmail] = useState<any>('');
  const [reportLink, setReportLink] = useState<any>('');

  const [selectedClientName, setSelectedClientName] = useState('');

  const [isNotify, setIsNotify] = useState(false); // Indicates if client will receive an email notification
  const [isEmailNotified, setIsEmailNotified] = useState(false); // Indicates if client ACTUALLY received an email notification

  const elapsedTimeRef = useRef<any>(1);
  const timerRef = useRef<any>(null);

  const { isMobileViewport } = useViewport();

  const { r, t, u } = queryString.parse(history.location.search);
  const isPublicView = !!r && !!t && !!u;

  const getReportPayload = (
    checkReportToggleDisplay: any,
    toggleValue?: boolean,
  ) => {
    const data: any = {
      userId: !isPublicView
        ? user?.user_id
        : Buffer.from(u as string, 'base64').toString('utf-8'),
      suburbCode: !isPublicView ? suburbCodeParam : suburb?.suburbCode,
      editedReport: toggleValue ?? checkReportToggleDisplay,
    };

    if (template === 'key-indicators') {
      data.timeLineYears = '10';
      data.trendLineYears = '3';
    }

    if (template === 'pricerent-forecast') {
      data.timeLineYears = '10';
    }

    if (template === 'projects') {
      data.kmRadius = 50;
      data.pageIndex = 1;
      data.orderType = 'Stage';
    }

    return {
      data: data,
      template: template,
      token: !isPublicView
        ? user?.token
        : Buffer.from(t as string, 'base64').toString('utf-8'),
    };
  };

  const errorHandler = (error: any) => {
    console.log(error);
  };

  const markAsFavorite = async () => {
    setIsFavoriteLoading(true);

    try {
      await dispatch(
        favoriteSuburbReport({
          data: {
            suburbCode: suburbCodeParam,
            userId: user?.user_id,
            isFavorite: !isFavorite,
          },
          token: user?.token,
        }),
      )
        // @ts-ignore
        .unwrap();

      dispatch(setIsFavorite(!isFavorite));

      notification.success({
        message: 'Success!',
        description: `Suburb report has been ${
          !isFavorite ? 'added to' : 'removed from'
        } your favorite list.`,
      });
    } catch (error) {
      errorHandler(error);
    }

    setIsFavoriteLoading(false);
  };

  const getReport = async (toggleValue?: boolean) => {
    dispatch(setIsReportLoading(true));

    // To request for the actual editedReport value from the server, set this to null
    const checkReportToggleDisplay =
      isReportToggleEnabled || template !== 'investment-summary'
        ? isEditedVersion
        : null;

    try {
      const response = await dispatch(
        fetchReportData(
          getReportPayload(checkReportToggleDisplay, toggleValue),
        ),
      )
        // @ts-ignore
        .unwrap();

      if (checkReportToggleDisplay === null && response.editedReport) {
        dispatch(setIsReportToggleEnabled(true));
      }

      dispatch(setIsEditedVersion(response.editedReport));
    } catch (error) {
      errorHandler(error);

      if (isPublicView) {
        history.push('/');
      }
    }

    clearInterval(timerRef.current);
    elapsedTimeRef.current = 0;

    dispatch(setIsReportLoading(false));
    setLoadingMessage(defaultLoadingMessage);
  };

  const onToggleChange = (e: RadioChangeEvent) => {
    dispatch(setIsEditedVersion(e.target.value));
    dispatch(resetReport());
    getReport(e.target.value);
  };

  const shareModalCloseHandler = (isSuccess: any) => {
    setIsShareModalVisible(false);
    dispatch(setClients([]));

    if (typeof isSuccess === 'boolean' && !!isSuccess) {
      setIsShareSuccessModalVisible(true);
    }
  };

  const shareSuccessModalCloseHandler = () => {
    setIsShareSuccessModalVisible(false);
    setIsEmailNotified(false);
    setSelectedClientName('');
    setEmail('');
    setReportLink('');
  };

  useEffect(() => {
    if (
      (user && suburbCodeParam && !currentReportData) ||
      (isPublicView && !currentReportData)
    ) {
      getReport();
    }
  }, [template, suburbCodeParam]);

  const suburbReportLoadingMessages = () => {
    const intervalDuration = 4;

    elapsedTimeRef.current += 1;

    if (elapsedTimeRef.current <= intervalDuration * 1) {
      setLoadingMessage(defaultLoadingMessage);
    } else if (elapsedTimeRef.current <= intervalDuration * 2) {
      setLoadingMessage('Calculating suburb growth score');
    } else if (elapsedTimeRef.current <= intervalDuration * 3) {
      setLoadingMessage('Analysing population growth numbers');
    } else if (elapsedTimeRef.current <= intervalDuration * 4) {
      setLoadingMessage('Assessing current and projected rental yields');
    } else if (elapsedTimeRef.current <= intervalDuration * 5) {
      setLoadingMessage('Researching price growth projections');
    } else if (elapsedTimeRef.current <= intervalDuration * 6) {
      setLoadingMessage('Evaluating population forecasts');
    } else if (elapsedTimeRef.current <= intervalDuration * 7) {
      setLoadingMessage('Finalising report');
    } else if (elapsedTimeRef.current <= intervalDuration * 8) {
      setLoadingMessage('Not long to go now…');
    } else {
      setLoadingMessage(`Okay it's almost ready...`);
      clearInterval(timerRef.current);
    }
  };

  useEffect(() => {
    setLoadingMessage(defaultLoadingMessage);

    timerRef.current = setInterval(suburbReportLoadingMessages, 1000);

    return () => {
      clearInterval(timerRef.current);
      elapsedTimeRef.current = 0;
    };
  }, [suburbCodeParam]);

  return (
    <>
      <div style={{ position: 'relative', height: '100%' }}>
        {isDisclaimerModalActive && <DisclaimerModal />}
        {isShareModalVisible && (
          <ShareSuburbReportModal
            closeHandler={shareModalCloseHandler}
            editedReport={isEditedVersion}
            setSelectedClientName={setSelectedClientName}
            setIsEmailNotified={setIsEmailNotified}
            isNotify={isNotify}
            setIsNotify={setIsNotify}
            isClientRecipient={isClientRecipient}
            setIsClientRecipient={setIsClientRecipient}
            email={email}
            setEmail={setEmail}
            setReportLink={setReportLink}
          />
        )}
        {isShareSuccessModalVisible && (
          <ShareSuburbReportSuccessModal
            closeHandler={shareSuccessModalCloseHandler}
            editedReport={isEditedVersion}
            isEmailNotified={isEmailNotified}
            clientName={selectedClientName}
            isNotify={isNotify}
            isClientRecipient={isClientRecipient}
            email={email}
            reportLink={reportLink}
          />
        )}
        {isReportLoading && (
          <FullPageLoader classNames="h-fixed" message={loadingMessage} />
        )}
        {suburb && (
          <section className="l-report">
            <div className="l-report-main">
              <div className="l-report-main__content">
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    minHeight: '30px',
                  }}
                >
                  {results?.suburbs?.length && (
                    <Button
                      className="l-report__back"
                      onClick={() => {
                        history.push(
                          `/suburb-scoring${!isAgencyLeadView ? '-internal' : ''}`,
                        );
                      }}
                    >
                      <ArrowLeftOutlined />
                      {!isMobileViewport && ' Back to results'}
                    </Button>
                  )}
                  {isClientView && (
                    <Button
                      className="l-report__back"
                      onClick={() => {
                        dispatch(setIsGoingBack(true));
                        history.push('/');
                      }}
                    >
                      <ArrowLeftOutlined />
                      {!isMobileViewport && ' Shared reports'}
                    </Button>
                  )}
                  {(isAgencyAdminView || isAgencyUserView) && (
                    <div style={{ marginLeft: 'auto', display: 'flex' }}>
                      {isReportToggleEnabled && (
                        <div className="l-report__button-group-wrap l-report__button-group-wrap--border">
                          <Radio.Group
                            onChange={onToggleChange}
                            value={isEditedVersion}
                          >
                            <Radio.Button value={false}>
                              {isMobileViewport ? (
                                <strong>AI</strong>
                              ) : (
                                'Jasper AI'
                              )}
                            </Radio.Button>
                            <Radio.Button value={true}>Edited</Radio.Button>
                          </Radio.Group>
                        </div>
                      )}
                      <Button
                        className="l-report__favorite"
                        style={{ marginRight: '8px' }}
                        onClick={() => {
                          markAsFavorite();
                        }}
                        disabled={isFavoriteLoading}
                      >
                        {isFavorite ? (
                          <StarFilled style={{ color: '#00b2a3' }} />
                        ) : (
                          <StarOutlined />
                        )}
                      </Button>
                      <Button
                        className="l-report__share"
                        type="primary"
                        onClick={() => {
                          setIsShareModalVisible(true);
                        }}
                      >
                        {!isMobileViewport && 'Share report '}
                        <ShareAltOutlined />
                      </Button>
                    </div>
                  )}
                </div>
                <SuburbMap />
                <div className="l-report__heading">
                  <div className="l-report__tag">
                    <HomeFilled /> Houses Market
                  </div>
                  <h4>
                    {suburb.suburbName}, {suburb.postCode} {suburb.state} Suburb
                    Report
                  </h4>
                </div>
              </div>
            </div>
            <SuburbDetails />
          </section>
        )}
      </div>
      {isAgencyLeadView && !isDesktopViewport && <StickyFooter />}
    </>
  );
};

export default AiReport;
