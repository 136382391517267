import React from 'react';

function Logo() {
  return (
    <svg
      width="169"
      height="48"
      viewBox="0 0 169 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_129_5253)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1 35.8199V43L8.46742 35.8169L16.5005 35.8199C25.04 35.8169 32.0011 29.1208 32.0011 20.9094C32 12.693 25.039 5.99694 16.5005 6C7.96104 5.99694 1 12.693 1 20.9105V28.6337V35.8199ZM16.5005 16.6815C14.0937 16.6815 12.1084 18.5911 12.1084 20.9064C12.1053 23.2257 14.0905 25.1354 16.5005 25.1313C18.9116 25.1343 20.8969 23.2247 20.8926 20.9064C20.8926 18.5911 18.9074 16.6815 16.5005 16.6815ZM8.46742 35.8169V28.6368V20.9064C8.46742 16.656 12.0819 13.1801 16.5005 13.1791C20.9191 13.1791 24.5336 16.656 24.5336 20.9064C24.5368 25.1598 20.9233 28.6368 16.5005 28.6337L15.9317 28.6368L8.46742 35.8169Z"
          fill="url(#paint0_linear_129_5253)"
        />
      </g>
      <path
        d="M41.9703 12.6742C41.8512 12.6742 41.7503 12.6332 41.6687 12.5512C41.5872 12.4693 41.5464 12.369 41.5464 12.2482V6.71108C41.5464 6.586 41.5872 6.48356 41.6687 6.40484C41.7503 6.32612 41.8501 6.28622 41.9703 6.28622H43.5985C44.0053 6.28622 44.367 6.37572 44.6814 6.5558C44.9959 6.73588 45.2417 6.98497 45.4188 7.30415C45.5948 7.62333 45.6839 7.98996 45.6839 8.40403C45.6839 8.81811 45.5959 9.1567 45.4188 9.46725C45.2427 9.77781 44.997 10.0204 44.6814 10.1951C44.367 10.3698 44.0053 10.4571 43.5985 10.4571H42.3932V12.2482C42.3932 12.3679 42.3535 12.4693 42.2751 12.5512C42.1968 12.6332 42.0948 12.6742 41.9703 12.6742ZM42.3932 9.67213H43.5985C43.8486 9.67213 44.0675 9.61822 44.2575 9.50823C44.4475 9.39932 44.5966 9.24943 44.705 9.05857C44.8134 8.86771 44.8682 8.64989 44.8682 8.40403C44.8682 8.142 44.8134 7.91232 44.705 7.71283C44.5966 7.51335 44.4475 7.35699 44.2575 7.24269C44.0675 7.12839 43.8475 7.07124 43.5985 7.07124H42.3932V9.67213Z"
        fill="#1D2939"
      />
      <path
        d="M52.9037 12.6742C52.7845 12.6742 52.6837 12.6332 52.6021 12.5512C52.5205 12.4693 52.4797 12.369 52.4797 12.2482V6.71108C52.4797 6.586 52.5205 6.48356 52.6021 6.40484C52.6837 6.32612 52.7835 6.28622 52.9037 6.28622H54.3687C54.7755 6.28622 55.1372 6.37249 55.4516 6.54394C55.7661 6.71539 56.0119 6.95154 56.189 7.25132C56.365 7.55109 56.4541 7.90046 56.4541 8.29836C56.4541 8.6693 56.3661 8.99927 56.189 9.28825C56.013 9.57724 55.7672 9.80369 55.4516 9.96759C55.1372 10.1315 54.7755 10.2134 54.3687 10.2134H53.3266V12.2504C53.3266 12.3701 53.2868 12.4714 53.2085 12.5534C53.1301 12.6353 53.0282 12.6763 52.9037 12.6763V12.6742ZM53.3266 9.42628H54.3687C54.6188 9.42628 54.8377 9.37883 55.0277 9.28286C55.2177 9.18797 55.3669 9.05426 55.4753 8.88173C55.5837 8.71028 55.6384 8.5151 55.6384 8.29728C55.6384 8.05682 55.5837 7.84439 55.4753 7.65892C55.3669 7.47345 55.2177 7.32896 55.0277 7.22544C54.8377 7.12192 54.6177 7.07016 54.3687 7.07016H53.3266V9.42628ZM56.2824 12.6742C56.2061 12.6742 56.1375 12.658 56.0752 12.6246C56.013 12.5922 55.9593 12.5405 55.9164 12.4693L54.4513 10.097L55.2735 9.85976L56.6086 12.0196C56.7063 12.1781 56.7203 12.3259 56.6494 12.465C56.5786 12.6041 56.4562 12.6731 56.2834 12.6731L56.2824 12.6742Z"
        fill="#1D2939"
      />
      <path
        d="M66.4282 12.7563C65.9506 12.7563 65.5137 12.6754 65.1177 12.5147C64.7217 12.3541 64.3803 12.1276 64.0959 11.8354C63.8104 11.5432 63.5893 11.197 63.4326 10.797C63.2749 10.3959 63.1965 9.9559 63.1965 9.47605C63.1965 8.9962 63.2749 8.55733 63.4326 8.15943C63.5904 7.76153 63.8115 7.41647 64.0959 7.12425C64.3814 6.83203 64.7217 6.60666 65.1177 6.44491C65.5137 6.28424 65.9506 6.20337 66.4282 6.20337C66.9058 6.20337 67.3415 6.28424 67.7354 6.44491C68.1293 6.60558 68.4695 6.83203 68.7572 7.12425C69.0448 7.41647 69.267 7.76153 69.4247 8.15943C69.5825 8.55733 69.6609 8.9962 69.6609 9.47605C69.6609 9.9559 69.5825 10.3959 69.4247 10.797C69.267 11.1981 69.0448 11.5443 68.7572 11.8354C68.4695 12.1276 68.1293 12.353 67.7354 12.5147C67.3415 12.6754 66.9058 12.7563 66.4282 12.7563ZM66.4282 11.9874C66.7813 11.9874 67.1054 11.9238 67.4016 11.7955C67.6978 11.6672 67.9544 11.4903 68.1712 11.2639C68.388 11.0375 68.5554 10.7722 68.6724 10.4659C68.7894 10.1608 68.8473 9.83082 68.8473 9.47605C68.8473 9.12129 68.7894 8.79132 68.6724 8.48616C68.5554 8.181 68.389 7.91465 68.1712 7.68821C67.9544 7.46176 67.6978 7.286 67.4016 7.16091C67.1054 7.03583 66.7813 6.97329 66.4282 6.97329C66.0751 6.97329 65.7509 7.03583 65.4547 7.16091C65.1585 7.286 64.9041 7.46176 64.6895 7.68821C64.4748 7.91465 64.3084 8.17992 64.1882 8.48616C64.0691 8.79132 64.009 9.12129 64.009 9.47605C64.009 9.83082 64.0691 10.1608 64.1882 10.4659C64.3074 10.7711 64.4748 11.0375 64.6895 11.2639C64.9041 11.4903 65.1585 11.6672 65.4547 11.7955C65.7509 11.9238 66.0751 11.9874 66.4282 11.9874Z"
        fill="#1D2939"
      />
      <path
        d="M77.2731 12.6742C77.1539 12.6742 77.053 12.6332 76.9715 12.5512C76.8899 12.4693 76.8491 12.369 76.8491 12.2482V6.71108C76.8491 6.586 76.8899 6.48356 76.9715 6.40484C77.053 6.32612 77.1529 6.28622 77.2731 6.28622H78.9012C79.308 6.28622 79.6697 6.37572 79.9842 6.5558C80.2986 6.73588 80.5444 6.98497 80.7215 7.30415C80.8975 7.62333 80.9866 7.98996 80.9866 8.40403C80.9866 8.81811 80.8986 9.1567 80.7215 9.46725C80.5455 9.77781 80.2997 10.0204 79.9842 10.1951C79.6697 10.3698 79.308 10.4571 78.9012 10.4571H77.6959V12.2482C77.6959 12.3679 77.6562 12.4693 77.5779 12.5512C77.4995 12.6332 77.3976 12.6742 77.2731 12.6742ZM77.6959 9.67213H78.9012C79.1513 9.67213 79.3702 9.61822 79.5602 9.50823C79.7502 9.39932 79.8994 9.24943 80.0078 9.05857C80.1162 8.86771 80.1709 8.64989 80.1709 8.40403C80.1709 8.142 80.1162 7.91232 80.0078 7.71283C79.8994 7.51335 79.7502 7.35699 79.5602 7.24269C79.3702 7.12839 79.1502 7.07124 78.9012 7.07124H77.6959V9.67213Z"
        fill="#1D2939"
      />
      <path
        d="M88.2059 12.6742C88.0868 12.6742 87.9859 12.6332 87.9043 12.5512C87.8228 12.4693 87.782 12.369 87.782 12.2482V6.71108C87.782 6.586 87.8228 6.48356 87.9043 6.40484C87.9859 6.32612 88.0857 6.28622 88.2059 6.28622H91.8862C92.0107 6.28622 92.1116 6.32289 92.1878 6.39621C92.264 6.46954 92.3015 6.56659 92.3015 6.68628C92.3015 6.80597 92.264 6.89332 92.1878 6.96448C92.1116 7.03565 92.0118 7.07124 91.8862 7.07124H88.6299V9.05857H90.9664C91.0909 9.05857 91.1918 9.09523 91.268 9.16856C91.3442 9.24189 91.3818 9.33678 91.3818 9.45108C91.3818 9.56538 91.3442 9.65919 91.268 9.7336C91.1918 9.80692 91.092 9.84358 90.9664 9.84358H88.6299V11.8881H91.8862C92.0107 11.8881 92.1116 11.9237 92.1878 11.9948C92.264 12.066 92.3015 12.1587 92.3015 12.273C92.3015 12.3873 92.264 12.4898 92.1878 12.5631C92.1116 12.6364 92.0118 12.6731 91.8862 12.6731H88.2059V12.6742Z"
        fill="#1D2939"
      />
      <path
        d="M99.7675 12.6742C99.6483 12.6742 99.5474 12.6332 99.4659 12.5512C99.3843 12.4693 99.3435 12.369 99.3435 12.2482V6.71108C99.3435 6.586 99.3843 6.48356 99.4659 6.40484C99.5474 6.32612 99.6472 6.28622 99.7675 6.28622H101.232C101.639 6.28622 102.001 6.37249 102.315 6.54394C102.63 6.71539 102.876 6.95154 103.053 7.25132C103.229 7.55109 103.318 7.90046 103.318 8.29836C103.318 8.6693 103.23 8.99927 103.053 9.28825C102.877 9.57724 102.631 9.80369 102.315 9.96759C102.001 10.1315 101.639 10.2134 101.232 10.2134H100.19V12.2504C100.19 12.3701 100.151 12.4714 100.072 12.5534C99.9939 12.6353 99.892 12.6763 99.7675 12.6763V12.6742ZM100.19 9.42628H101.232C101.483 9.42628 101.701 9.37883 101.891 9.28286C102.081 9.18797 102.231 9.05426 102.339 8.88173C102.447 8.71028 102.502 8.5151 102.502 8.29728C102.502 8.05682 102.447 7.84439 102.339 7.65892C102.231 7.47345 102.081 7.32896 101.891 7.22544C101.701 7.12192 101.481 7.07016 101.232 7.07016H100.19V9.42628ZM103.146 12.6742C103.07 12.6742 103.001 12.658 102.939 12.6246C102.877 12.5922 102.823 12.5405 102.78 12.4693L101.315 10.097L102.137 9.85976L103.472 12.0196C103.57 12.1781 103.584 12.3259 103.513 12.465C103.442 12.6041 103.32 12.6731 103.147 12.6731L103.146 12.6742Z"
        fill="#1D2939"
      />
      <path
        d="M110.051 7.07137C109.937 7.07137 109.843 7.03471 109.769 6.96138C109.696 6.88806 109.66 6.79316 109.66 6.67886C109.66 6.56456 109.696 6.47075 109.769 6.39634C109.842 6.32194 109.937 6.28636 110.051 6.28636H114.406C114.52 6.28636 114.613 6.32302 114.687 6.39634C114.76 6.46967 114.797 6.56456 114.797 6.67886C114.797 6.79316 114.76 6.88698 114.687 6.96138C114.614 7.03471 114.521 7.07137 114.406 7.07137H110.051ZM112.233 12.6743C112.113 12.6743 112.013 12.6333 111.931 12.5514C111.849 12.4694 111.809 12.3691 111.809 12.2484V6.28528H112.655V12.2484C112.655 12.3681 112.615 12.4694 112.533 12.5514C112.452 12.6333 112.352 12.6743 112.231 12.6743H112.233Z"
        fill="#1D2939"
      />
      <path
        d="M123.501 12.6742C123.387 12.6742 123.29 12.6332 123.208 12.5513C123.127 12.4693 123.086 12.369 123.086 12.2483V10.3418L120.913 6.94728C120.842 6.83298 120.815 6.72515 120.831 6.62379C120.847 6.52243 120.894 6.44155 120.974 6.37793C121.052 6.31539 121.143 6.28412 121.246 6.28412C121.339 6.28412 121.412 6.30461 121.466 6.34558C121.52 6.38656 121.573 6.44479 121.621 6.52135L123.689 9.81776H123.364L125.464 6.489C125.507 6.42322 125.559 6.37362 125.619 6.33803C125.679 6.30245 125.752 6.2852 125.839 6.2852C125.937 6.2852 126.018 6.3197 126.084 6.38764C126.149 6.45557 126.187 6.54076 126.197 6.64104C126.208 6.7424 126.181 6.84376 126.116 6.94728L123.925 10.3418V12.2483C123.925 12.368 123.884 12.4693 123.803 12.5513C123.721 12.6332 123.621 12.6742 123.501 12.6742Z"
        fill="#1D2939"
      />
      <path
        d="M42.0346 41.0488C41.7341 41.0488 41.4873 40.9507 41.2919 40.7555C41.0966 40.5593 41 40.3113 41 40.0093V29.5475C41.015 28.3722 41.2962 27.31 41.8436 26.3611C42.391 25.4122 43.1294 24.666 44.0588 24.1236C44.9883 23.5812 46.038 23.3105 47.2078 23.3105C48.3777 23.3105 49.4531 23.5855 50.3901 24.1355C51.3271 24.6854 52.0687 25.4316 52.6171 26.373C53.1645 27.3143 53.4382 28.3883 53.4382 29.5928C53.4382 30.7973 53.1795 31.8519 52.6622 32.8019C52.1449 33.7508 51.444 34.497 50.5597 35.0394C49.6753 35.5818 48.6782 35.8524 47.5684 35.8524C46.624 35.8524 45.7696 35.6573 45.0044 35.2647C44.2402 34.8733 43.5952 34.346 43.0703 33.6829V40.0093C43.0703 40.3102 42.977 40.5593 42.7892 40.7555C42.6013 40.9507 42.3502 41.0488 42.0357 41.0488H42.0346ZM47.2078 33.9988C48.002 33.9988 48.7179 33.8069 49.3554 33.423C49.993 33.0391 50.4942 32.5118 50.8623 31.8411C51.2294 31.1715 51.4129 30.421 51.4129 29.5928C51.4129 28.7647 51.2294 27.9926 50.8623 27.3219C50.4953 26.6522 49.993 26.1239 49.3554 25.74C48.7179 25.3561 48.002 25.1642 47.2078 25.1642C46.4136 25.1642 45.6967 25.3604 45.0602 25.7519C44.4227 26.1433 43.9247 26.6706 43.5651 27.3337C43.2056 27.9969 43.0253 28.7496 43.0253 29.5928C43.0253 30.4361 43.2056 31.1715 43.5651 31.8411C43.9247 32.5118 44.4238 33.0391 45.0602 33.423C45.6977 33.8069 46.4136 33.9988 47.2078 33.9988Z"
        fill="#1D2939"
      />
      <path
        d="M62.7701 35.8515C61.6453 35.8515 60.6408 35.5808 59.7564 35.0384C58.872 34.496 58.1744 33.7509 57.6646 32.8009C57.1548 31.852 56.9004 30.7823 56.9004 29.5919C56.9004 28.4014 57.1741 27.3134 57.7214 26.372C58.2688 25.4306 59.0105 24.6855 59.9485 24.1345C60.8855 23.5846 61.9394 23.3096 63.1082 23.3096C64.277 23.3096 65.3492 23.5846 66.2797 24.1345C67.2092 24.6845 67.9476 25.4339 68.495 26.3828C69.0424 27.3317 69.3236 28.4014 69.3386 29.5919L68.5293 29.7957C68.5293 30.9408 68.2739 31.9728 67.7652 32.8915C67.2554 33.8102 66.5695 34.5338 65.7077 35.0611C64.8458 35.5884 63.867 35.8515 62.7723 35.8515H62.7701ZM63.1071 33.9989C63.9164 33.9989 64.6366 33.807 65.2666 33.4231C65.8966 33.0392 66.3946 32.5119 66.7616 31.8412C67.1287 31.1716 67.3122 30.4211 67.3122 29.5929C67.3122 28.7648 67.1287 27.9927 66.7616 27.322C66.3946 26.6524 65.8955 26.124 65.2666 25.7401C64.6366 25.3562 63.9175 25.1643 63.1071 25.1643C62.2968 25.1643 61.6153 25.3562 60.9702 25.7401C60.3252 26.124 59.8197 26.6513 59.4526 27.322C59.0856 27.9927 58.9021 28.7497 58.9021 29.5929C58.9021 30.4362 59.0856 31.1716 59.4526 31.8412C59.8197 32.5119 60.3263 33.0392 60.9702 33.4231C61.6153 33.807 62.3269 33.9989 63.1071 33.9989ZM68.2803 35.7835C67.9798 35.7835 67.733 35.6854 67.5376 35.4902C67.3423 35.2951 67.2457 35.046 67.2457 34.7441V31.1285L67.6729 28.9589L69.3375 29.5919V34.7441C69.3375 35.046 69.2366 35.294 69.0338 35.4902C68.8309 35.6865 68.5798 35.7835 68.2803 35.7835Z"
        fill="#1D2939"
      />
      <path
        d="M72.778 25.6377C72.5086 25.6377 72.2832 25.5547 72.1029 25.3886C71.9226 25.2226 71.8335 25.0123 71.8335 24.7557C71.8335 24.485 71.9237 24.2661 72.1029 24.1C72.2832 23.934 72.5075 23.852 72.778 23.852H78.198C78.4674 23.852 78.6928 23.9351 78.8731 24.1C79.0534 24.2661 79.1425 24.4839 79.1425 24.7557C79.1425 25.0123 79.0523 25.2226 78.8731 25.3886C78.6928 25.5547 78.4685 25.6377 78.198 25.6377H72.778ZM78.0177 35.7609C77.2535 35.7609 76.5634 35.569 75.9484 35.1851C75.3334 34.8012 74.8537 34.2815 74.5092 33.6259C74.1636 32.9703 73.9919 32.2219 73.9919 31.3776V20.5093C73.9919 20.2084 74.0852 19.9593 74.273 19.7631C74.4598 19.5679 74.7034 19.4698 75.0039 19.4698C75.3045 19.4698 75.5513 19.5679 75.7467 19.7631C75.9409 19.9593 76.0386 20.2073 76.0386 20.5093V31.3776C76.0386 32.0558 76.2264 32.6133 76.601 33.0501C76.9756 33.4868 77.4478 33.7057 78.0177 33.7057H78.7153C78.9697 33.7057 79.1833 33.8038 79.3561 33.999C79.5278 34.1952 79.6147 34.4432 79.6147 34.7441C79.6147 35.0449 79.5053 35.2908 79.2885 35.4784C79.0706 35.6671 78.7894 35.7609 78.4449 35.7609H78.0177Z"
        fill="#1D2939"
      />
      <path
        d="M84.1345 31.0617C83.834 31.0617 83.5871 30.9603 83.3918 30.7565C83.1965 30.5527 83.0999 30.3015 83.0999 29.9996V19.1539C83.0999 18.8379 83.1975 18.5856 83.3918 18.3969C83.5861 18.2082 83.834 18.1144 84.1345 18.1144C84.449 18.1144 84.7001 18.2082 84.8879 18.3969C85.0758 18.5856 85.1691 18.8379 85.1691 19.1539V29.9996C85.1691 30.3015 85.0758 30.5527 84.8879 30.7565C84.7001 30.9603 84.449 31.0617 84.1345 31.0617ZM84.1345 35.7847C83.834 35.7847 83.5871 35.6866 83.3918 35.4914C83.1965 35.2963 83.0999 35.0472 83.0999 34.7452V24.4419C83.0999 24.126 83.1975 23.8737 83.3918 23.685C83.5861 23.4973 83.834 23.4024 84.1345 23.4024C84.449 23.4024 84.7001 23.4963 84.8879 23.685C85.0758 23.8737 85.1691 24.126 85.1691 24.4419V34.7452C85.1691 35.0472 85.0758 35.2952 84.8879 35.4914C84.7001 35.6877 84.449 35.7847 84.1345 35.7847ZM93.5578 35.7847C93.2573 35.7847 93.0073 35.6834 92.8044 35.4796C92.6016 35.2758 92.5007 35.0321 92.5007 34.7452V29.0507C92.5007 28.1621 92.3354 27.4353 92.0059 26.8703C91.6764 26.3053 91.2331 25.8836 90.6793 25.6054C90.1244 25.3272 89.4869 25.187 88.7678 25.187C88.0927 25.187 87.4863 25.3229 86.9465 25.5936C86.4066 25.8642 85.9752 26.2265 85.6532 26.6784C85.3301 27.1302 85.1691 27.6499 85.1691 28.2376H83.82C83.835 27.2887 84.0744 26.4411 84.5402 25.696C85.0049 24.9498 85.6349 24.3632 86.4292 23.933C87.2234 23.5038 88.1163 23.2892 89.1059 23.2892C90.1556 23.2892 91.0925 23.5113 91.9168 23.9556C92.7411 24.3999 93.3936 25.0512 93.8734 25.9106C94.3531 26.7689 94.5936 27.816 94.5936 29.0517V34.7463C94.5936 35.0331 94.4927 35.2768 94.2898 35.4806C94.087 35.6844 93.8433 35.7858 93.5589 35.7858L93.5578 35.7847Z"
        fill="#1D2939"
      />
      <path
        d="M98.3493 25.6377C98.0638 25.6377 97.8352 25.5547 97.6634 25.3886C97.4906 25.2226 97.4048 25.0123 97.4048 24.7556C97.4048 24.485 97.4906 24.2661 97.6634 24.1C97.8352 23.934 98.0648 23.852 98.3493 23.852H104.04C104.324 23.852 104.557 23.935 104.737 24.1C104.918 24.2661 105.007 24.4839 105.007 24.7556C105.007 25.0123 104.917 25.2226 104.737 25.3886C104.557 25.5547 104.325 25.6377 104.04 25.6377H98.3493ZM100.531 35.7609C100.231 35.7609 99.9839 35.6596 99.7896 35.4558C99.5943 35.252 99.4977 35.0072 99.4977 34.7214V23.4013C99.4977 22.3165 99.7005 21.3719 100.105 20.5653C100.51 19.7598 101.158 19.1301 102.051 18.6783C102.943 18.2264 104.101 18 105.526 18C106.201 18 106.808 18.0755 107.348 18.2264C107.887 18.3774 108.31 18.5877 108.618 18.8594C108.925 19.1301 109.08 19.4546 109.08 19.831C109.08 20.4941 108.69 20.8252 107.91 20.8252C107.625 20.8252 107.366 20.7573 107.134 20.6214C106.901 20.4855 106.669 20.3313 106.436 20.1577C106.203 19.9852 105.957 19.8299 105.695 19.694C105.432 19.5582 105.122 19.4902 104.761 19.4902C104.191 19.4902 103.663 19.6035 103.176 19.8288C102.689 20.0553 102.299 20.4542 102.006 21.0268C101.714 21.5994 101.567 22.3898 101.567 23.3991V34.7193C101.567 35.0061 101.469 35.2498 101.275 35.4536C101.08 35.6574 100.833 35.7588 100.532 35.7588L100.531 35.7609ZM107.818 35.7609C107.517 35.7609 107.27 35.6628 107.076 35.4676C106.881 35.2725 106.784 35.0234 106.784 34.7225V24.4419C106.784 24.1259 106.882 23.8736 107.076 23.6849C107.27 23.4972 107.518 23.4024 107.818 23.4024C108.132 23.4024 108.383 23.4962 108.571 23.6849C108.759 23.8736 108.852 24.1259 108.852 24.4419V34.7225C108.852 35.0244 108.759 35.2725 108.571 35.4676C108.383 35.6639 108.132 35.7609 107.818 35.7609Z"
        fill="#1D2939"
      />
      <path
        d="M114.767 35.7835C114.467 35.7835 114.22 35.6854 114.024 35.4902C113.829 35.295 113.732 35.0459 113.732 34.744V24.4407C113.732 24.1248 113.83 23.8724 114.024 23.6837C114.219 23.4961 114.467 23.4012 114.767 23.4012C115.082 23.4012 115.333 23.495 115.521 23.6837C115.708 23.8724 115.802 24.1248 115.802 24.4407V34.744C115.802 35.0459 115.708 35.294 115.521 35.4902C115.333 35.6865 115.082 35.7835 114.767 35.7835ZM124.19 35.7835C123.89 35.7835 123.64 35.6821 123.437 35.4783C123.234 35.2745 123.133 35.0308 123.133 34.744V29.0494C123.133 28.1609 122.968 27.4341 122.638 26.8691C122.309 26.304 121.866 25.8824 121.312 25.6042C120.757 25.326 120.119 25.1858 119.4 25.1858C118.725 25.1858 118.119 25.3217 117.579 25.5924C117.039 25.863 116.608 26.2253 116.286 26.6771C115.963 27.129 115.802 27.6487 115.802 28.2364H114.453C114.468 27.2875 114.707 26.4399 115.173 25.6948C115.637 24.9486 116.268 24.362 117.062 23.9317C117.856 23.5026 118.749 23.288 119.738 23.288C120.788 23.288 121.725 23.5101 122.549 23.9544C123.374 24.3987 124.026 25.05 124.506 25.9094C124.986 26.7677 125.226 27.8148 125.226 29.0505V34.7451C125.226 35.0319 125.125 35.2756 124.922 35.4794C124.72 35.6832 124.476 35.7846 124.191 35.7846L124.19 35.7835Z"
        fill="#1D2939"
      />
      <path
        d="M135.277 35.8515C134.108 35.8515 133.055 35.5765 132.118 35.0266C131.181 34.4766 130.438 33.7272 129.891 32.7783C129.343 31.8294 129.07 30.7597 129.07 29.5692C129.07 28.3787 129.324 27.3134 129.834 26.372C130.344 25.4306 131.04 24.6855 131.926 24.1345C132.81 23.5846 133.815 23.3096 134.939 23.3096C135.869 23.3096 136.72 23.5058 137.492 23.8973C138.263 24.2887 138.905 24.816 139.415 25.4792V19.1527C139.415 18.8367 139.513 18.5844 139.707 18.3957C139.901 18.207 140.149 18.1132 140.448 18.1132C140.763 18.1132 141.018 18.207 141.213 18.3957C141.407 18.5844 141.505 18.8367 141.505 19.1527V29.6145C141.49 30.7899 141.208 31.852 140.661 32.8009C140.114 33.7498 139.371 34.496 138.434 35.0384C137.497 35.5808 136.443 35.8515 135.274 35.8515H135.277ZM135.277 33.9989C136.087 33.9989 136.807 33.807 137.437 33.4231C138.067 33.0392 138.565 32.5119 138.932 31.8412C139.299 31.1716 139.482 30.4146 139.482 29.5703C139.482 28.726 139.299 27.9733 138.932 27.3112C138.565 26.6481 138.066 26.1251 137.437 25.7412C136.807 25.3573 136.088 25.1654 135.277 25.1654C134.467 25.1654 133.786 25.3573 133.141 25.7412C132.495 26.1251 131.99 26.6491 131.623 27.3112C131.256 27.9744 131.072 28.727 131.072 29.5703C131.072 30.4135 131.256 31.1716 131.623 31.8412C131.99 32.5119 132.497 33.0392 133.141 33.4231C133.786 33.807 134.497 33.9989 135.277 33.9989Z"
        fill="#1D2939"
      />
      <path
        d="M151.944 35.8515C150.699 35.8515 149.601 35.5841 148.649 35.0492C147.697 34.5144 146.951 33.7768 146.411 32.8344C145.871 31.893 145.602 30.8125 145.602 29.5919C145.602 28.3712 145.856 27.2681 146.366 26.3267C146.876 25.3854 147.581 24.6467 148.48 24.1119C149.379 23.577 150.422 23.3096 151.607 23.3096C152.791 23.3096 153.757 23.5695 154.598 24.0892C155.437 24.609 156.082 25.325 156.532 26.2361C156.981 27.1473 157.207 28.1987 157.207 29.3881C157.207 29.6447 157.12 29.8593 156.948 30.0318C156.775 30.2054 156.547 30.2917 156.262 30.2917H147.019V28.6192H156.285L155.363 29.2748C155.348 28.4769 155.19 27.7609 154.891 27.1279C154.59 26.4949 154.163 25.9978 153.609 25.6366C153.054 25.2754 152.387 25.0942 151.608 25.0942C150.753 25.0942 150.014 25.2905 149.392 25.6819C148.77 26.0733 148.294 26.6082 147.964 27.2864C147.634 27.9647 147.469 28.7324 147.469 29.5908C147.469 30.4491 147.66 31.2136 148.042 31.8844C148.424 32.5551 148.949 33.0856 149.617 33.477C150.283 33.8685 151.059 34.0647 151.945 34.0647C152.424 34.0647 152.923 33.9741 153.44 33.7941C153.957 33.6129 154.365 33.4026 154.665 33.1611C154.875 32.995 155.1 32.9088 155.34 32.9012C155.58 32.8937 155.79 32.9659 155.97 33.1158C156.196 33.3271 156.315 33.5568 156.33 33.8048C156.345 34.0539 156.24 34.2685 156.016 34.4486C155.506 34.8551 154.873 35.1905 154.115 35.4536C153.357 35.7178 152.634 35.8493 151.945 35.8493L151.944 35.8515Z"
        fill="#1D2939"
      />
      <path
        d="M161.816 35.7612C161.502 35.7612 161.254 35.6749 161.073 35.5013C160.893 35.3288 160.804 35.0754 160.804 34.7444V24.4184C160.804 24.1025 160.894 23.8534 161.073 23.6722C161.254 23.4911 161.501 23.4016 161.816 23.4016C162.161 23.4016 162.419 23.4889 162.592 23.6614C162.764 23.835 162.851 24.0874 162.851 24.4184V34.7444C162.851 35.0603 162.764 35.3094 162.592 35.4906C162.419 35.6717 162.161 35.7612 161.816 35.7612ZM161.748 28.0782C161.778 27.1595 162.015 26.3378 162.457 25.6153C162.899 24.8918 163.487 24.3203 164.222 23.8976C164.956 23.476 165.767 23.2646 166.651 23.2646C167.475 23.2646 168.094 23.3929 168.507 23.6485C168.919 23.9051 169.072 24.2286 168.968 24.6201C168.923 24.8465 168.833 25.0083 168.699 25.1064C168.564 25.2045 168.407 25.2573 168.227 25.2649C168.046 25.2724 167.852 25.2617 167.642 25.2315C166.727 25.0816 165.91 25.1031 165.19 25.2994C164.47 25.4957 163.9 25.8342 163.481 26.3162C163.061 26.7983 162.851 27.3859 162.851 28.0793H161.748V28.0782Z"
        fill="#1D2939"
      />
      <defs>
        <linearGradient
          id="paint0_linear_129_5253"
          x1="1"
          y1="24.4995"
          x2="32"
          y2="24.4995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#32D583" />
          <stop offset="0.99" stopColor="#00B2A3" />
        </linearGradient>
        <clipPath id="clip0_129_5253">
          <rect
            width="31"
            height="37"
            fill="white"
            transform="translate(1 6)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default Logo;
