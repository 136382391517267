import * as Yup from 'yup';

import { passwordSchema } from '../../../foundation/utils/getValidationSchemas';

const requiredMessage = `This field is required`;

export const resetPasswordFormValidationSchema = (
  isStrongPassword?: boolean,
) => {
  const fields: any = {
    newPassCode: passwordSchema(undefined, undefined, isStrongPassword),
    comfirmPassCode: Yup.string()
      .required(requiredMessage)
      .oneOf([Yup.ref('newPassCode'), null], 'Passwords must match'),
  };

  return Yup.object().shape(fields);
};
