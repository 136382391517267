import { Image, Input, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CloseIcon from '../../foundation/assets/svgs/Close';
import Search from '../../foundation/assets/svgs/Search';
import FullPageLoader from '../../foundation/components/full_page_loader/FullPageLoader.index';
import { useViewport } from '../../foundation/cutom_hooks/useViewport';
import { useAppDispatch } from '../../store/hooks';
import { selectUser } from '../authentication/redux/selectors';
import EnterpriseModal from '../enterprise/modals/enterprise_modal/EnterpriseModal';
import { fetchEnterprise } from '../enterprise/redux/async_thunks';
import { selectEnterprise } from '../enterprise/redux/selectors';
import AgencyCard from './cards/AgencyCard';
import CreateAgencyCard from './cards/CreateAgencyCard';
import AgencyModal from './modals/agency_modal/AgencyModal';
import InviteModal from './modals/invite_modal/InviteModal';
import InviteSuccessModal from './modals/invite_success_modal/InviteSuccessModal';
import { fetchAgencies } from './redux/async_thunks';
import { selectAgencies } from './redux/selectors';
import { setAgency } from './redux/slice';
import { Agency } from './redux/types';

const AgencyView = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { isMobileViewport } = useViewport();

  const [isAgencyModalVisible, setAgencyModalVisibility] = useState(false);

  const [isInviteModalVisible, setInviteModalVisibility] = useState(false);

  const [isEnterpriseModalVisible, setEnterpriseModalVisibility] =
    useState(false);

  const [isInviteSuccessModalVisible, setInviteSuccessModalVisibility] =
    useState(false);

  const [invitedEmails, setInvitedEmails] = useState<string[]>([]);

  const [searchText, setSearchText] = useState<string | undefined>(undefined);

  const user = useSelector(selectUser);

  const agencies = useSelector(selectAgencies);

  const enterprise = useSelector(selectEnterprise);

  const handleCreateAgencyClick = () => {
    setAgencyModalVisibility(true);
  };

  const handleAgencySearch = () => {
    if (searchText) {
      fetchAgencyResults(searchText);
    }
  };

  /**
   * Fetch the agencies
   */
  const fetchAgencyResults = async (searchValue?: string) => {
    if (isLoading) {
      return;
    }

    try {
      setIsLoading(true);
      if (user?.token && user?.enterprise_id) {
        await dispatch(
          fetchAgencies({
            token: user.token,
            searchValue: searchValue,
            enterpriseId: user.enterprise_id,
          }),
          // @ts-ignore
        ).unwrap();
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  };

  const fetchEnterpriseData = async (enterpriseId?: string) => {
    try {
      if (user) {
        const data = {
          enterpriseId: enterpriseId ? enterpriseId : user.enterprise_id,
          token: user.token,
        };

        await dispatch(fetchEnterprise(data)).unwrap();
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleResetClick = () => {
    setSearchText(undefined);
    fetchAgencyResults();
  };

  const handleAgencyModalClose = () => {
    setAgencyModalVisibility(false);
  };

  const handleEnterpriseModalClose = () => {
    setEnterpriseModalVisibility(false);
  };

  const handleInviteModalClose = () => {
    setInviteModalVisibility(false);
    dispatch(setAgency(undefined));
  };

  const handleInviteSuccessModalClose = () => {
    setInviteSuccessModalVisibility(false);
  };

  useEffect(() => {
    fetchAgencyResults('');

    if (user) {
      if (!user.enterprise_id) {
        setEnterpriseModalVisibility(true);
      } else {
        fetchEnterpriseData();
      }
    }
  }, []);

  return (
    <div className="l-agency">
      {isLoading && <FullPageLoader />}

      {isEnterpriseModalVisible && (
        <EnterpriseModal
          closeModal={handleEnterpriseModalClose}
          fetchEnterpriseData={fetchEnterpriseData}
        />
      )}

      {isAgencyModalVisible && (
        <AgencyModal
          closeModal={handleAgencyModalClose}
          setInviteModalVisibility={setInviteModalVisibility}
        />
      )}

      {isInviteModalVisible && (
        <InviteModal
          closeModal={handleInviteModalClose}
          setInviteSuccessModalVisibility={setInviteSuccessModalVisibility}
          setInvitedEmails={setInvitedEmails}
        />
      )}

      {isInviteSuccessModalVisible && (
        <InviteSuccessModal
          closeModal={handleInviteSuccessModalClose}
          emails={invitedEmails}
          inviteType={'user'}
        />
      )}

      <div
        className="l-agency__view"
        style={enterprise?.enterprisePicture ? { paddingTop: '30px' } : {}}
      >
        {enterprise?.enterprisePicture && (
          <div className="l-agency__enterprise-picture">
            <Image
              width={isMobileViewport ? 80 : 150}
              src={enterprise?.enterprisePicture}
            />
          </div>
        )}
        <div className="l-agency__search-field-wrapper">
          <Input
            value={searchText}
            className="l-agency__search-input"
            prefix={<Search />}
            placeholder={`Search Agency`}
            onChange={(e) => setSearchText(e.target.value)}
            onPressEnter={handleAgencySearch}
            suffix={
              <>
                {searchText && (
                  <span
                    style={{ display: 'block', lineHeight: 1 }}
                    onClick={handleResetClick}
                  >
                    <CloseIcon />
                  </span>
                )}
              </>
            }
          />
        </div>
        <div className="l-agency__search-results-wrapper">
          <Row gutter={[15, 15]} style={{ width: '100%', margin: 0 }}>
            <CreateAgencyCard onClick={handleCreateAgencyClick} />
            {agencies?.map((agency: Agency) => (
              <AgencyCard
                key={agency.agencyId}
                agency={agency}
                fetchAgencyResults={fetchAgencyResults}
              />
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default AgencyView;
