import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { fetchProfile, updateProfile } from './async_thunks';
import { Profile, ProfileState } from './types';

const initialState: ProfileState = {
  user_profile: undefined,
  navIndex: 1,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setProfile: (state, action: PayloadAction<Profile | undefined>) => {
      state.user_profile = action.payload;
    },
    setNavIndex: (state, action) => {
      state.navIndex = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      state.user_profile = action.payload;
    });
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      state.user_profile = action.payload;
    });
  },
});

export const { setProfile, setNavIndex } = profileSlice.actions;

export default profileSlice.reducer;
